import { ReactNode } from 'react';

interface LayoutProps {
  children?: ReactNode;
}

export const NestedLayout = ({ children }: LayoutProps) => (
  <>
    <main className="relative mt-[5rem] flex-grow">{children}</main>
  </>
);
