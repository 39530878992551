import { PreQualifiedQuoteFailedReasonEnum } from '@prisma/client';

export enum PreQualifiedQuoteFailedReasonCodes {
  ASSESSMENT_UNSUCCESSFUL = '10001',
  CREDIT_FILE_PULL_UNSUCCESSFUL = '10002',
  TECHNICAL = '10003',
  USER_EXISTS = '10004',
  CREDIT_FILE_EXISTS = '10005',
}

export const preQualifiedQuoteFailedReasonMapper: Record<PreQualifiedQuoteFailedReasonEnum, string> = {
  [PreQualifiedQuoteFailedReasonEnum.ASSESSMENT_UNSUCCESSFUL]: 'Assessment unsuccessful',
  [PreQualifiedQuoteFailedReasonEnum.CREDIT_FILE_PULL_UNSUCCESSFUL]: 'Credit file pull unsuccessful',
  [PreQualifiedQuoteFailedReasonEnum.TECHNICAL]: 'Technical issue',
};

export const preQualifiedQuoteFailedReasonCodeMapper = {
  [preQualifiedQuoteFailedReasonMapper[PreQualifiedQuoteFailedReasonEnum.ASSESSMENT_UNSUCCESSFUL]]:
    PreQualifiedQuoteFailedReasonCodes.ASSESSMENT_UNSUCCESSFUL,
  [preQualifiedQuoteFailedReasonMapper[PreQualifiedQuoteFailedReasonEnum.CREDIT_FILE_PULL_UNSUCCESSFUL]]:
    PreQualifiedQuoteFailedReasonCodes.CREDIT_FILE_PULL_UNSUCCESSFUL,
  [preQualifiedQuoteFailedReasonMapper[PreQualifiedQuoteFailedReasonEnum.TECHNICAL]]:
    PreQualifiedQuoteFailedReasonCodes.TECHNICAL,
  'User exists': PreQualifiedQuoteFailedReasonCodes.USER_EXISTS,
  'Credit file exists': PreQualifiedQuoteFailedReasonCodes.CREDIT_FILE_EXISTS,
};
