import { DebtPurchaser } from '@harmoney/api-interfaces';
import { Alert } from '@harmoney/ui-design-system';

interface DebtSoldAlertProps {
  purchaser: DebtPurchaser;
}

export const DebtSoldAlert = ({ purchaser }: DebtSoldAlertProps) => {
  const { name, email, phone } = purchaser;
  return (
    <Alert className="my-2" variant="warning" title="Debts sold">
      <span>
        Due to overdue payments, this loan was sold to {name}. For more details, please contact them on{' '}
        <a href={`mailto:${email}`}>{email}</a> or <a href={`tel:${phone}`}>{phone}</a>.
      </span>
    </Alert>
  );
};
