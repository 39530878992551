import Image from 'next/image';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ABTest, bankNameMapper, DegreesOfFreedom } from '@harmoney/api-interfaces';
import { useBranch, useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetBankStatementConfigurationQuery,
  useGetVariablesQuery,
  useUpdateBankStatementBankNameAndStatusMutation,
} from '@harmoney/redux';
import {
  eventAnalytics,
  PROVISO_IFRAME_DISPLAYED,
  PROVISO_IFRAME_ERROR_OCCURRED,
  PROVISO_IFRAME_INVALID_TOKEN,
  PROVISO_IFRAME_LOADED,
  PROVISO_IFRAME_LOGIN_COMPLETE,
  PROVISO_IFRAME_LOGIN_FAILED,
} from '@harmoney/ui-app-shell';
import {
  Accordion,
  Alert,
  BankStatementIcon,
  Button,
  Card,
  LoginNoViewIcon,
  NoAccountChangesIcon,
  Spinner,
  TextLink,
} from '@harmoney/ui-design-system';
import { BranchEnum } from '@prisma/client';
import { SeverityLevel } from '@sentry/node';
import * as Sentry from '@sentry/react';

import { CommonProps } from '../../common-props';

import { BankStatementUIStatus } from './bank-statement-ui-status';

interface IntroIconText {
  icon: JSX.Element;
  content: string;
}

const introIconText: IntroIconText[] = [
  {
    icon: <BankStatementIcon size="large" />,
    content: 'Your bank statements allow us to verify your income and spending.',
  },
  {
    icon: <LoginNoViewIcon size="large" />,
    content: 'Your log in credentials are never shared with us, or any other third party. ',
  },
  {
    icon: <NoAccountChangesIcon size="large" />,
    content: 'We do not gain access to your account, we just receive a categorised report.',
  },
];

export function BankStatementIFrame({ taskId, completeTaskWithData, taskFriendlyURL, taskDefinitionId }: CommonProps) {
  const branch = useBranch();
  const userId = useAppSelector((state) => state.userId.value);
  const { data: variables } = useGetVariablesQuery(taskId);
  const [updateBankStatementBankNameAndStatus] = useUpdateBankStatementBankNameAndStatusMutation();
  const {
    data: bankStatementData,
    isLoading,
    refetch: refetchBankStatementConfiguration,
  } = useGetBankStatementConfigurationQuery(
    { applicationId: variables?.loanApplicationId?.toString() },
    { skip: !variables?.loanApplicationId, refetchOnMountOrArgChange: true }
  );

  const bankStatementExperimentValue =
    branch === BranchEnum.AU
      ? (variables?.degreesOfFreedom as DegreesOfFreedom)?.abExperiments?.find((exp) => exp?.bankStatementsExperiment)
          .bankStatementsExperiment ?? ('A' as ABTest)
      : 'A';

  const isVariantB = bankStatementExperimentValue && bankStatementExperimentValue === 'B';
  const isVariantC = bankStatementExperimentValue && bankStatementExperimentValue === 'C';

  const parseEventData = (eventData: string) => {
    let data;
    try {
      data = JSON.parse(eventData);
    } catch (e) {
      return eventData;
    }
    return data;
  };

  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const handleIframeLoad = useCallback(() => {
    setIsIframeLoaded(true);
  }, []);

  const trackEvent = (event: string, props: { [key: string]: string }) => {
    const commonProps = {
      userid_str: userId,
      taskid_str: taskId,
    };
    props = { ...commonProps, ...props };
    eventAnalytics.track(event, props);
  };

  const eventOriginatesFromBankStatement = useCallback(
    (url) => {
      const iframeUrl = bankStatementData?.url;
      return iframeUrl?.includes(url);
    },
    [bankStatementData]
  );

  const handleCancelBankStatementAddition = async () => {
    await completeTaskWithData({ taskId });
  };

  const trackBankStatementEvent = (
    message: string,
    action: string,
    level: SeverityLevel,
    userId: string,
    extra: { [key: string]: string }
  ) => {
    const BANK_STATEMENT_IFRAME = 'BankStatementIFrame';
    Sentry.captureEvent({
      message,
      level,
      tags: { component: BANK_STATEMENT_IFRAME, action, userId, page: window.location.href },
      extra: {
        page: window.location.href,
        ...extra,
      },
    });
  };

  const handleBankStatementMessage = useCallback(
    async (event: MessageEvent) => {
      if (!eventOriginatesFromBankStatement(event.origin)) {
        return;
      }

      const eventData = parseEventData(event.data);

      const eventStatus = eventData?.status || eventData;

      const props: { [key: string]: string } = {};

      const bank_statement_experiment = `bank-statement-variant-${bankStatementExperimentValue}`;

      switch (eventStatus) {
        case BankStatementUIStatus.LOADED: {
          props.status_message_str = PROVISO_IFRAME_LOADED;
          props.bank_statement_experiment = bank_statement_experiment;
          trackEvent(PROVISO_IFRAME_LOADED, props);
          trackBankStatementEvent('Bank statement iframe loaded', 'loaded', 'info', userId, props);
          break;
        }
        case BankStatementUIStatus.COMPLETE: {
          props.status_message_str = PROVISO_IFRAME_LOGIN_COMPLETE;
          props.bank_name_str = eventData?.data;
          props.bank_statement_experiment = bank_statement_experiment;
          trackEvent(PROVISO_IFRAME_LOGIN_COMPLETE, props);
          updateBankStatementBankNameAndStatus({
            bankName: bankNameMapper[eventData?.data] ?? eventData?.data,
            appReference: bankStatementData?.appReference,
            status: BankStatementUIStatus.COMPLETE,
          });
          trackBankStatementEvent('Bank statement login complete', 'login_complete', 'info', userId, props);
          await completeTaskWithData({ taskId });
          break;
        }
        case BankStatementUIStatus.LOGIN_FAIL: {
          props.status_code_int = eventData?.status_code;
          props.status_message_str = eventData?.status_message;
          props.bank_statement_experiment = bank_statement_experiment;
          props.bank_name_str = eventData?.data;
          trackEvent(PROVISO_IFRAME_LOGIN_FAILED, props);
          trackBankStatementEvent('Bank statement login failed', 'login_fail', 'error', userId, props);
          setTimeout(() => {
            refetchBankStatementConfiguration();
          }, 5000);
          break;
        }
        case BankStatementUIStatus.ERROR: {
          props.status_code_int = eventData?.status_code;
          props.status_message_str = eventData?.status_message;
          props.bank_statement_experiment = bank_statement_experiment;
          trackEvent(PROVISO_IFRAME_ERROR_OCCURRED, props);
          trackBankStatementEvent('Bank statement error occurred', 'error', 'error', userId, props);
          refetchBankStatementConfiguration();
          break;
        }
        case BankStatementUIStatus.INVALID_TOKEN: {
          props.status_code_int = eventData?.status_code;
          props.status_message_str = eventData?.status_message;
          props.bank_statement_experiment = bank_statement_experiment;
          trackEvent(PROVISO_IFRAME_INVALID_TOKEN, props);
          trackBankStatementEvent('Bank statement invalid token', 'invalid_token', 'error', userId, props);
          refetchBankStatementConfiguration();
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      bankStatementData?.appReference,
      bankStatementExperimentValue,
      completeTaskWithData,
      eventOriginatesFromBankStatement,
      refetchBankStatementConfiguration,
      taskId,
      trackEvent,
      updateBankStatementBankNameAndStatus,
      userId,
    ]
  );

  useEffect(() => {
    (window as any).addEventListener('message', handleBankStatementMessage);

    return () => {
      (window as any).removeEventListener('message', handleBankStatementMessage);
    };
  }, [handleBankStatementMessage]);

  useEffect(() => {
    if (isIframeLoaded) {
      const props = {
        status_message_str: PROVISO_IFRAME_DISPLAYED,
      };
      trackEvent(PROVISO_IFRAME_DISPLAYED, props);
      trackBankStatementEvent('Bank statement iframe displayed', 'displayed', 'info', userId, props);
    }
  }, [isIframeLoaded, trackEvent, userId]);

  useFriendlyURL(taskFriendlyURL);

  const isRepeat = useMemo(() => {
    return taskDefinitionId?.startsWith('user-task-bank-statement-iframe-repeat');
  }, [taskDefinitionId]);

  return (
    <>
      {isVariantC && (
        <div className="w-full z-40  fixed top-16 left-0 p-2 bg-white shadow-bottom border-t-[1px] border-t-grey-2">
          <div className="flex items-center justify-center gap-x-2">
            <p className="text-xs font-medium mb-0 lg:text-sm">We partner with these trusted banks</p>
            <Image
              src="/assets/images/commonwealth-bank-logo.svg"
              alt="Commonwealth bank logo"
              width={24}
              height={24}
            />
            <Image
              src="/assets/images/westpack-bank-logo.svg"
              alt="Westpack bank logo"
              width={41.375}
              height={16.862}
            />
            <Image src="/assets/images/nab-bank-logo.svg" alt="NAB bank logo" width={24} height={24} />
          </div>
        </div>
      )}

      {isVariantB ? (
        <>
          <h1>
            Understanding your <span className="text-primary">finances</span>
          </h1>
          <p>
            Next, <span className="font-medium">share your recent bank statements</span> by connecting to your bank
            account.
          </p>
        </>
      ) : (
        <h1 className="mb-6">
          <span className="text-primary">{isRepeat ? 'Reconnect' : 'Verify'}</span>{' '}
          {isRepeat ? 'your bank statements' : 'your income and spending'}
        </h1>
      )}

      {isVariantB ? (
        <Alert
          variant="tip"
          title="Why should you trust us?"
          collapsible
          defaultCollapsed
          collapsibleButtonId="bs-why-trust-us"
        >
          <p>
            <span className="font-medium">We&rsquo;re as secure as a bank.</span> Our secure system is highly encrypted
            and we do not see or store your username or password.
          </p>
          <p>
            <span className="font-medium">Bank statements only.</span> We only see your bank statements, and can&rsquo;t
            make any transactions on your account.
          </p>
          <p>
            <span className="font-medium">What actually happens?</span> We receive the bank statements information from
            Illion, a highly secure international credit bureau. The data is then analysed so that we can give you a
            personalised interest rate. It only takes a few minutes.
          </p>
        </Alert>
      ) : (
        <Card className="!p-0">
          {introIconText.map((introItem, index) => (
            <div key={index}>
              <div className="flex items-center gap-x-6 px-4 py-6">
                <div className="flex-none">{introItem.icon}</div>
                <p>{introItem.content}</p>
              </div>
              {index + 1 !== introIconText.length && <hr className="border-grey-1" />}
            </div>
          ))}
        </Card>
      )}
      <Card className="mt-6">
        <h2 className="font-medium text-xl">{isVariantB ? `Sign in to your banks` : `Submit your bank statements`}</h2>
        {isVariantB ? (
          <p>
            Select or search for the banks where you have your accounts. If you use multiple banks, select each bank and
            sign in to each.
          </p>
        ) : (
          <ol className="p-auto ml-8 list-decimal">
            <li>Select and log in to your bank</li>
            <li>Choose the transaction account(s) that show your:</li>
            <ul className=" list-disc pb-6">
              <li>income (salary, wages, rental income, benefits)</li>
              <li>expenses (utilities, groceries, mortgage, debt repayment, etc)</li>
            </ul>
          </ol>
        )}
        {isLoading ? (
          <Spinner variant="secondary" size="large" />
        ) : (
          <iframe className="h-[600px] w-full" src={bankStatementData?.url} onLoad={handleIframeLoad}></iframe>
        )}
      </Card>
      {variables?.requestToAddBankStatement && !variables?.retryBankStatement && (
        <div className="text-center mb-12">
          <Button size="medium" variant="text" onClick={handleCancelBankStatementAddition}>
            No other banks to add
          </Button>
        </div>
      )}

      {isVariantB && (
        <>
          <Accordion
            id="bs-faq"
            items={[
              {
                content: `       <p>\n         Because the process is <span class='font-medium'>simple, fast and secure.</span></p>\n   
              <p>With a clear and current understanding of your finances we can offer you a loan amount and an interest rate that are personalised to your circumstances - in minutes rather than days.\n       </p>\n
              <p>Doing this via a secure online process means you don't have to spend time finding and uploading up-to-date bank information, plus we can automatically verify the information we need rather than having to manually verify documents you provide to us.</p>\n     `,
                title: 'Why should I let you retrieve my bank transaction data?',
                id: 'bs-faq-why-let-retrieve',
              },
              {
                content: `\n       <p>\n         Our secure, bank-grade technology takes a <span class='font-medium'>read-only</span> copy of your bank transactions. This means we can view the account information (for example, to estimate your income), but cannot make any changes to personal details and cannot make any transactions.</p>\n     `,
                title: 'How do you access my bank transaction data?',
                id: 'bs-faq-how-access-bank-data',
              },
              {
                content: `\n       <p>\n Our <span class='font-medium'>bank grade</span> technology means your bank user ID and password are instantly encrypted making them unusable by any unauthorised person.\n       </p>`,
                title: 'How do I know this service is secure?',
                id: 'bs-faq-how-secure',
              },
              {
                content: `\n       <p>\n No problem, you can connect as many accounts as you have for your regular income and expenses.\n       </p>`,
                title: 'What if I have more than one bank account?',
                id: 'bs-faq-more-than-one-account',
              },
              {
                content: `\n       <p>\n We only offer this automated process, so we can give you your quote faster, and assess your situation more accurately. It enables us to personalise a loan solution for you in minutes - rather than days.\n       </p>`,
                title: 'Can I share my financial details another way?',
                id: 'bs-faq-share-financial-details',
              },
              {
                content: `\n       <p>\n Expenses are any cost of living. Common expenses include the cost of food, housing, internet, power, phone, transport, clothing, school fees etc.\n       </p>`,
                title: 'What are examples of expenses?',
                id: 'bs-faq-expenses-example',
              },
              {
                content: `\n       <p>\n A transaction account is an account you use for day-to-day banking, such as for paying bills and getting your wages. Transaction accounts are often called 'everyday accounts'.\n       </p>`,
                title: 'What is a transaction account?',
                id: 'bs-faq-transaction-account',
              },
            ]}
            title="Frequently asked questions"
          />
          <div className="flex flex-col mt-6 items-center gap-y-4" id="bs-links">
            <TextLink href="https://help.harmoney.com/en/" target="_blank" rel="noopener noreferrer" id="bs-faq-link">
              FAQs
            </TextLink>
            <TextLink
              href="https://www.harmoney.com.au/legal/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              id="bs-privacy-policy-link"
            >
              Privacy policy
            </TextLink>
            <TextLink
              href="https://www.harmoney.com.au/contact"
              target="_blank"
              rel="noopener noreferrer"
              id="bs-contact-us-link"
            >
              Contact us
            </TextLink>
            <div className="flex mt-3 gap-x-2">
              <Image src="/assets/images/lock.svg" alt="Lock icon" width={16} height={16} />
              <p className="text-sm">256 bit encryption</p>
            </div>
            <Image src="/assets/images/cloudflare-logo.svg" alt="Cloudflare logo" width={101.4} height={33.5} />
          </div>
        </>
      )}
    </>
  );
}
