import { LoanPurposeDisplayNameEnum, PreQualifiedQuoteBpmnVariables } from '@harmoney/api-interfaces';
import { ExtendedPreQualifiedQuote } from '@harmoney/redux';
import { valueOrDash } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import dayjs from 'dayjs';

import { Field } from '../shared';

type Props = {
  preQualifiedQuote: ExtendedPreQualifiedQuote;
};

export const RequirementsAndObjectivesDetails = ({ preQualifiedQuote }: Props) => {
  const pqVariables = preQualifiedQuote.variables as unknown as PreQualifiedQuoteBpmnVariables;

  return (
    <div className="grid grid-cols-2 px-4">
      <div className="border-r-grey-1 border-r-2 p-4">
        <Field className="grid-cols-[50%_80%]">
          <span>Product:</span>
          <span>{valueOrDash(pqVariables?.product?.name)}</span>
        </Field>
        <Field className="grid-cols-[50%_80%]">
          <span>Source:</span>
          <span>{valueOrDash(preQualifiedQuote.referral)}</span>
        </Field>
        <Field className="grid-cols-[50%_80%]">
          <span>Requested amount:</span>
          <span>{formatCurrency(preQualifiedQuote.requestedAmount)}</span>
        </Field>
        <Field className="grid-cols-[50%_80%]">
          <span>Requested term:</span>
          <span>{valueOrDash(preQualifiedQuote.requestedTerm)}</span>
        </Field>
        <Field className="grid-cols-[50%_80%]">
          <span>Loan purpose:</span>
          <span>{valueOrDash(LoanPurposeDisplayNameEnum[preQualifiedQuote.loanPurpose])}</span>
        </Field>
        <Field className="grid-cols-[50%_80%]">
          <span>Branch:</span>
          <span>{valueOrDash(pqVariables?.product?.branch)}</span>
        </Field>
      </div>
      <div className="p-4">
        <Field className="grid-cols-[50%_80%]">
          <span>Created date:</span>
          <span>{dayjs(preQualifiedQuote.createdAt).format('DD/MM/YYYY')}</span>
        </Field>
        <Field className="grid-cols-[50%_80%]">
          <span>Quote expiry date:</span>
          <span>
            {dayjs(preQualifiedQuote?.quoteExpiryDate).isValid()
              ? dayjs(preQualifiedQuote.quoteExpiryDate).format('DD/MM/YYYY')
              : '-'}
          </span>
        </Field>
      </div>
    </div>
  );
};
