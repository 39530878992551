import { DebtPurchaser, DebtSaleDto } from '@harmoney/api-interfaces';
import { DebtSaleStatusEnum } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const debtSaleApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getDebtSaleData: builder.query<DebtSaleDto, string>({
      query: (applicationId) => ({
        url: '/debt-sale',
        method: 'GET',
        params: {
          applicationId,
        },
      }),
    }),
    getDebtSalePurchaser: builder.query<{ purchaser: DebtPurchaser; status: DebtSaleStatusEnum }, string>({
      query: (applicationId) => ({
        url: '/debt-sale/purchaser',
        method: 'GET',
        params: {
          applicationId,
        },
      }),
    }),
  }),
});

export const { useGetDebtSaleDataQuery, useGetDebtSalePurchaserQuery } = debtSaleApi;
