import { NetworthSourceKind } from './networth-source-kind.enum';

export class NetworthSourceDto {
  id: number;
  name: string;
  code: string;
  kind: NetworthSourceKind;
  description: string | null;
  display: boolean;
  displayOrder: number;
}

export enum NetworthSourceEnum {
  INCOME_SALARY_WAGES_ID = 1,
  INCOME_SELF_EMPLOYED_ID = 2,
  INCOME_RENT_ID = 3,
  INCOME_BENEFIT_ID = 16,
  INCOME_PENSION_ID = 18,
  INCOME_INTEREST_DIVIDEND_ID = 19,
  INCOME_SPOUSAL_SUPPORT_ID = 17,
  INCOME_CHILD_SUPPORT_ID = 32,
  INCOME_OTHER_ID = 4,
  INCOME_NO_ID = 15,
  INCOME_CHANGE_ID = 37,
  INCOME_PARTNER_ID = 44,
  INCOME_HOUSEHOLD_ID = 45,
  INCOME_RETIREMENT_FUNDS_ID = 39,
  INCOME_STUDENT_PAYMENTS_ID = 40,
  EXPENSE_RENT_ID = 31,
  EXPENSE_CHILD_SUPPORT_ID = 33,
  EXPENSE_BOARDER_ID = 34,
  EXPENSE_UTILITIES_ID = 5,
  EXPENSE_GROCERIES_ID = 6,
  EXPENSE_TRANSPORT_ID = 7,
  EXPENSE_INSURANCE_ID = 8,
  EXPENSE_MEDICAL_ID = 14,
  EXPENSE_FUEL_ID = 46,
  EXPENSE_HOUSEHOLD_ITEM_ID = 47,
  EXPENSE_INTERNET_ID = 48,
  EXPENSE_PET_CARE_ID = 49,
  EXPENSE_PHONE_PLAN_ID = 50,
  EXPENSE_POWER_ID = 51,
  EXPENSE_WATER_ID = 52,
  EXPENSE_NOT_LISTED_ID = 53,
  EXPENSE_LIVE_WITH_PARENTS_ID = 35,
  EXPENSE_OTHER_ID = 13,
  EXPENSE_NO_ID = 20,
  EXPENSE_CHANGE_ID = 38,
  EXPENSE_HOUSEHOLD_CHANGE_ID = 36,
  EXPENSE_GOVERNMENT_HOUSING_ID = 41,
  EXPENSE_COMPANY_HOUSING_ID = 42,
  EXPENSE_SHARED_ID = 43,
  EXPENSE_TOTAL_ESSENTIAL_EXPENSES_ID = 57,
  EXPENSE_OTHER_REGULAR_EXPENSES_ID = 58,
  LIABILITY_CREDIT_CARD_ID = 9,
  LIABILITY_PERSONAL_LOAN_ID = 10,
  LIABILITY_MORTGAGE_ID = 11,
  LIABILITY_OVERDRAFT_ID = 22,
  LIABILITY_BUY_NOW_PAY_LATER_ID = 30,
  LIABILITY_OTHER_ID = 12,
  LIABILITY_NO_ID = 21,
  LIABILITY_CAR_LOAN_ID = 54,
  LIABILITY_LINE_OF_CREDIT_ID = 56,
  ASSET_PROPERTY_LAND_ID = 23,
  ASSET_VEHICLE_ID = 24,
  ASSET_SAVINGS_ID = 25,
  ASSET_SHARES_BONDS_FUNDS_ID = 27,
  ASSET_BOAT_ID = 28,
  ASSET_OTHER_ID = 29,
  ASSET_NO_ID = 26,
}

export enum NetworthSourceDisplayNameEnum {
  INCOME_SALARY_WAGES_NAME = 'Salary/Wages',
  INCOME_SELF_EMPLOYED_NAME = 'Self employed',
  INCOME_RENT_NAME = 'Rental income',
  INCOME_BENEFIT_NAME = 'Benefit',
  INCOME_PENSION_NAME = 'Pension',
  INCOME_INTEREST_DIVIDEND_NAME = 'Interest/Dividends',
  INCOME_SPOUSAL_SUPPORT_NAME = 'Spousal support',
  INCOME_CHILD_SUPPORT_NAME = 'Child support',
  INCOME_OTHER_NAME = 'Other incomes',
  INCOME_NO_NAME = 'No income',
  INCOME_CHANGE_NAME = 'Income change over the loan period',
  INCOME_PARTNER_NAME = 'Partner income',
  INCOME_HOUSEHOLD_NAME = 'Household income',
  INCOME_SUPERANNUATION_NAME = 'Superannuation',
  INCOME_KIWI_SAVER_NAME = 'Kiwisaver',
  INCOME_STUDENT_PAYMENTS_NAME = 'Student payments',
  EXPENSE_ACCOMMODATION_NAME = 'Accommodation expenses',
  EXPENSE_LIVING_NAME = 'Living expenses',
  EXPENSE_CHILD_SUPPORT_NAME = 'Child support expenses',
  EXPENSE_OTHER_NAME = 'Other expenses',
  EXPENSE_INCREASED_NAME = 'Expense change over the loan period',
  LIABILITY_CREDIT_CARD_NAME = 'Credit card',
  LIABILITY_PERSONAL_LOAN_NAME = 'Personal loan',
  LIABILITY_MORTGAGE_NAME = 'Mortgage',
  LIABILITY_OVERDRAFT_NAME = 'Overdraft',
  LIABILITY_BUY_NOW_PAY_LATER_NAME = 'Buy now, pay later',
  LIABILITY_OTHER_NAME = 'Other debts',
  LIABILITY_CAR_LOAN_NAME = 'Car loan',
  LIABILITY_LINE_OF_CREDIT_NAME = 'Line of credit',
  LIABILITY_UNDISCLOSED_CAR_LOAN = 'Undisclosed car loan',
}

export const INCOME_DISPLAY_NAMES = [
  NetworthSourceDisplayNameEnum.INCOME_SALARY_WAGES_NAME,
  NetworthSourceDisplayNameEnum.INCOME_SELF_EMPLOYED_NAME,
  NetworthSourceDisplayNameEnum.INCOME_RENT_NAME,
  NetworthSourceDisplayNameEnum.INCOME_BENEFIT_NAME,
  NetworthSourceDisplayNameEnum.INCOME_PENSION_NAME,
  NetworthSourceDisplayNameEnum.INCOME_INTEREST_DIVIDEND_NAME,
  NetworthSourceDisplayNameEnum.INCOME_SPOUSAL_SUPPORT_NAME,
  NetworthSourceDisplayNameEnum.INCOME_CHILD_SUPPORT_NAME,
  NetworthSourceDisplayNameEnum.INCOME_OTHER_NAME,
  NetworthSourceDisplayNameEnum.INCOME_NO_NAME,
  NetworthSourceDisplayNameEnum.INCOME_CHANGE_NAME,
  NetworthSourceDisplayNameEnum.INCOME_PARTNER_NAME,
  NetworthSourceDisplayNameEnum.INCOME_HOUSEHOLD_NAME,
  NetworthSourceDisplayNameEnum.INCOME_SUPERANNUATION_NAME,
  NetworthSourceDisplayNameEnum.INCOME_KIWI_SAVER_NAME,
  NetworthSourceDisplayNameEnum.INCOME_STUDENT_PAYMENTS_NAME,
];

export const EXPENSE_DISPLAY_NAMES = [
  NetworthSourceDisplayNameEnum.EXPENSE_ACCOMMODATION_NAME,
  NetworthSourceDisplayNameEnum.EXPENSE_LIVING_NAME,
  NetworthSourceDisplayNameEnum.EXPENSE_CHILD_SUPPORT_NAME,
  NetworthSourceDisplayNameEnum.EXPENSE_OTHER_NAME,
  NetworthSourceDisplayNameEnum.EXPENSE_INCREASED_NAME,
];

export const LIABILITY_DISPLAY_NAMES = [
  NetworthSourceDisplayNameEnum.LIABILITY_CREDIT_CARD_NAME,
  NetworthSourceDisplayNameEnum.LIABILITY_PERSONAL_LOAN_NAME,
  NetworthSourceDisplayNameEnum.LIABILITY_MORTGAGE_NAME,
  NetworthSourceDisplayNameEnum.LIABILITY_OVERDRAFT_NAME,
  NetworthSourceDisplayNameEnum.LIABILITY_BUY_NOW_PAY_LATER_NAME,
  NetworthSourceDisplayNameEnum.LIABILITY_OTHER_NAME,
  NetworthSourceDisplayNameEnum.LIABILITY_CAR_LOAN_NAME,
  NetworthSourceDisplayNameEnum.LIABILITY_LINE_OF_CREDIT_NAME,
];
