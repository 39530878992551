export enum CodeEnum {
  HEADER = '00',
  TRANSACTION_RECORD = '01',
  TRAILER = '09',
  END_OF_FILE = '99',
}

export enum SpecialReferenceEnum {
  DISHONOURS = 'DISHONOURS',
  IFT = 'IFT',
  LPT = 'LPT',
  DST = 'DST',
}

export enum PaymentModeEnum {
  DIRECT_CREDIT = 'Direct Credit',
  TERMINAL_REWRITE_LPT = 'TERMINAL REWRITE LPT',
  FF_DIRECT_CREDIT = 'FF-Direct Credit',
  FF_TERMINAL_REWRITE_LPT = 'FF-TERMINAL REWRITE LPT',
  WH1_DIRECT_CREDIT = 'WH1-Direct Credit',
  WH1_TERMINAL_REWRITE_LPT = 'WH1-TERMINAL REWRITE LPT',
}
