import {
  BasicUserDto,
  EmailCheckResult,
  PaginatedQueryInput,
  PaginatedResult,
  ResponseResult,
  UserDto,
} from '@harmoney/api-interfaces';
import { BranchEnum, InternalUserRoleEnum, LoanApplicationStatusEnum, User, UserProfile } from '@prisma/client';

import { emptySplitApi } from './empty-api';

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserWithConfig: builder.query<UserDto, void>({
      query: () => ({
        url: `/users/config`,
        method: 'GET',
      }),
    }),
    updateUser: builder.mutation<ResponseResult, UserDto>({
      query: (data) => ({
        url: `/users`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    getUser: builder.query<UserDto, void>({
      query: () => ({
        url: '/users',
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getBasicUser: builder.query<BasicUserDto, void>({
      query: () => ({
        url: '/users/basic',
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getUserById: builder.query<User, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getAllUsers: builder.query<
      PaginatedResult<
        User & {
          loanApplication: {
            id: string;
            status: LoanApplicationStatusEnum;
            createdAt: Date;
          }[];
        }
      >,
      PaginatedQueryInput
    >({
      query: (params) => ({
        url: '/users/all-users',
        method: 'GET',
        params,
      }),
      providesTags: ['User'],
    }),
    checkEmail: builder.query<EmailCheckResult, { email: string }>({
      query: ({ email }) => ({
        url: `/users/check?email=${encodeURIComponent(email)}`,
        method: 'GET',
      }),
    }),
    getRedirection: builder.query<EmailCheckResult, { email: string; branch: BranchEnum }>({
      query: ({ email, branch }) => ({
        url: `/users/redirection?email=${encodeURIComponent(email)}&branch=${branch}`,
        method: 'GET',
      }),
    }),
    updateEmail: builder.mutation<ResponseResult, UserDto>({
      query: (data) => ({
        url: `/users/update-email`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    searchUsers: builder.query({
      query: (keyword) => ({
        url: `/users/search`,
        method: 'GET',
        params: {
          keyword,
        },
        timeout: 60000,
      }),
      providesTags: ['Search'],
    }),
    getNZUserCount: builder.query<number, void>({
      query: () => ({
        url: `/users/count`,
        method: 'GET',
        params: {
          branch: BranchEnum.NZ,
        },
      }),
    }),
    getNZTrafficConfig: builder.query<{ isAllowed: boolean; threshold: number }, void>({
      query: () => ({
        url: `/users/nz-traffic-config`,
        method: 'GET',
      }),
      providesTags: ['NzTraffic'],
    }),
    updateNZTrafficConfig: builder.mutation<
      { enabled: boolean; numericValue: number },
      { isEnabled: boolean; trafficValue?: number }
    >({
      query: (data) => ({
        url: `/users/nz-traffic`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['NzTraffic'],
    }),
    listUsersByRole: builder.query<Array<User & { userProfile?: UserProfile }>, { role: InternalUserRoleEnum }>({
      query: ({ role }) => ({
        url: '/users/role',
        method: 'GET',
        params: {
          role: role,
        },
      }),
    }),
  }),
});

export const {
  useUpdateUserMutation,
  useGetUserQuery,
  useGetBasicUserQuery,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useGetAllUsersQuery,
  useLazyCheckEmailQuery,
  useLazyGetRedirectionQuery,
  useUpdateEmailMutation,
  useLazySearchUsersQuery,
  useGetUserWithConfigQuery,
  useGetNZUserCountQuery,
  useGetNZTrafficConfigQuery,
  useUpdateNZTrafficConfigMutation,
  useListUsersByRoleQuery,
} = userApi;
