import Image from 'next/image';
import { BranchEnum } from '@prisma/client';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button } from '../Button';
import { Card } from '../Card';
import { Divider } from '../Divider';
import { IconV2 } from '../Icon';
import { TextLink } from '../TextLink';

import 'swiper/css';

export enum DeclineTypeEnum {
  PRE_QUOTE = 'pre-quote',
  POST_QUOTE = 'post-quote',
}

interface DeclineProps {
  type: DeclineTypeEnum;
  branch: BranchEnum;
}

const quoteOrLoan = {
  [DeclineTypeEnum.PRE_QUOTE]: 'quote',
  [DeclineTypeEnum.POST_QUOTE]: 'loan',
};

const declineReasons = (branch: BranchEnum) => [
  {
    icon: <IconV2 icon="material-symbols:checklist-rtl" className="text-secondary-lighter-1" width={24} />,
    reason: 'Eligibility requirements',
  },
  {
    icon: <IconV2 icon="mdi:work-outline" className="text-secondary-lighter-1" width={24} />,
    reason: 'Employment security',
  },
  {
    icon: <IconV2 icon="material-symbols:savings-outline" className="text-secondary-lighter-1" width={24} />,
    reason: 'Affordability',
  },
  {
    icon: <IconV2 icon="material-symbols:price-check" className="text-secondary-lighter-1" width={24} />,
    reason: 'Our credit criteria',
  },
  {
    icon: <IconV2 icon="mdi:file-find-outline" className="text-secondary-lighter-1" width={24} />,
    reason:
      branch === BranchEnum.AU ? (
        <>
          <span className="block">An issue in your credit file</span>
          <TextLink
            href="https://www.harmoney.com.au/how-can-i-check-my-credit-file"
            target="_blank"
            rel="noopener noreferrer"
          >
            How can I check my credit file?
          </TextLink>
        </>
      ) : (
        'An issue in your credit file'
      ),
  },
];

const financialResources = [
  {
    imageSrc: '/assets/images/financial-resources/bootcamp.png',
    title: 'Credit score bootcamp',
    content: 'Do you know your credit score? Do you know the...',
    link: 'https://www.harmoney.com.au/borrower-blog/credit-score-bootcamp',
  },
  {
    imageSrc: '/assets/images/financial-resources/credit-file.png',
    title: 'How to fix your credit file',
    content: 'In this session of Credit Score Bootcamp, we...',
    link: 'https://www.harmoney.com.au/borrower-blog/fix-your-credit-file',
  },
  {
    imageSrc: '/assets/images/financial-resources/credit-score.png',
    title: 'Nine ways to improve your credit score',
    content: `It's a simple formula: the high...`,
    link: 'https://www.harmoney.com.au/borrower-blog/improve-your-credit-score',
  },
];

export function DeclinePage({ type, branch }: DeclineProps) {
  const ArticleCard = ({
    imageSrc,
    title,
    content,
    link,
  }: {
    imageSrc: string;
    title: string;
    content: string;
    link: string;
  }) => (
    <>
      <Image src={imageSrc} alt={title} width={500} height={100} quality={100} />
      <h3 className="my-2 text-base font-medium">{title}</h3>
      <p className="flex-1 text-sm">{content}</p>
      <Button className="justify-self-end" as="a" variant="text" href={link} target="_blank" rel="noopener noreferrer">
        Read Now
      </Button>
    </>
  );

  return (
    <>
      <h1>
        We are <strong className="text-primary">unable to give</strong> you a {quoteOrLoan[type]} today
      </h1>
      <p>
        Thank you for taking the time to{' '}
        {type === DeclineTypeEnum.PRE_QUOTE ? 'apply for a quote' : 'fill out your application'}. We have carefully
        assessed your financial situation and we are unable to give you a {quoteOrLoan[type]} today.
      </p>
      <Card className="!p-0">
        <h2 className="mb-0 p-4 text-sm font-medium">Common reasons for {quoteOrLoan[type]} decline</h2>
        <Divider className="!my-0" />
        <ul className="px-4 pt-4">
          {declineReasons(branch).map(({ icon, reason }, index) => (
            <li key={index} className="flex items-center pb-4">
              <span className="mr-2 flex">{icon}</span>
              <span className="text-sm">{reason}</span>
            </li>
          ))}
        </ul>
      </Card>
      <p>Remember, timing is everything. If your financial situation changes don&rsquo;t hesitate to apply again.</p>

      {branch === BranchEnum.AU && (
        <>
          <h3 className="mt-6 mb-4 text-base font-medium">Resources to improve your financial situation</h3>
          <div className="md:hidden">
            <Swiper slidesPerView={2} className="md:hidden">
              {financialResources.map(({ imageSrc, title, content, link }, index) => (
                <SwiperSlide key={index} className="shadow-drop mr-4 flex flex-col rounded-lg bg-white p-2">
                  <ArticleCard imageSrc={imageSrc} title={title} content={content} link={link} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <ul className="hidden grid-cols-2 gap-4 md:grid md:grid-cols-3">
            {financialResources.map(({ imageSrc, title, content, link }, index) => (
              <li key={index} className="shadow-drop flex flex-col rounded-lg bg-white p-2">
                <ArticleCard imageSrc={imageSrc} title={title} content={content} link={link} />
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}
