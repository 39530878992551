import { useRouter } from 'next/router';
import { CONTACT_NUMBER, CONTACT_NUMBER_HREF } from '@harmoney/api-interfaces';
import { useBranch } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button, Card, TextLink } from '@harmoney/ui-design-system';
import { openChatWindow } from '@harmoney/ui-utils';

export const QuickQuoteTimeOut = () => {
  const branch = useBranch();
  const router = useRouter();

  return (
    <>
      <h1 className="mt-6 font-bold">
        Oops, we <span className="text-primary">couldn&rsquo;t generate your quote</span>
      </h1>
      <Card className="!px-4">
        <p className="text-sm">
          We&rsquo;re experiencing some technical difficulties. Our team is working to resolve this issue as quickly as
          possible.
        </p>
        <p className="text-sm">
          Please try again in an hour. If it still doesn&rsquo;t work,{' '}
          <span className="text-secondary cursor-pointer " onClick={() => openChatWindow()}>
            chat with us
          </span>{' '}
          or call us at <TextLink href={CONTACT_NUMBER_HREF[branch]}>{CONTACT_NUMBER[branch]}</TextLink>.
        </p>
      </Card>
      <div className="flex justify-center">
        <Button
          type="submit"
          variant="primary"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          onClick={() => router.push('/quick-quote')}
        >
          Try again
        </Button>
      </div>
    </>
  );
};
