import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CompleteTaskDto,
  FORM_KEY,
  PaymentArrangementDto,
  ValidateDirectDebitPlanResponse,
  VaultLoanInformationDto,
  VaultPaymentInformationDto,
} from '@harmoney/api-interfaces';
import {
  useGetDDSuspensionDetailsQuery,
  useGetPaymentArrangementQuery,
  useGetUserByIdQuery,
  useValidateDirectDebitPlanMutation,
} from '@harmoney/redux';
import {
  Alert,
  AmountFrequency,
  Checkbox,
  ControlledInput,
  DatePicker,
  Form,
  Spinner,
  Textarea,
  useForm,
} from '@harmoney/ui-design-system';
import { DATE_FORMAT, frequencyOptions } from '@harmoney/ui-utils';
import { dayjsUTCToSydney, formatCurrency, isDateOverlapDetected } from '@harmoney/utilities';
import { InboxType, RepaymentFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { CommonProps } from '../../../../common-props';
import { PaymentPlanFormFooter } from '../PaymentPlanFormFooter';
import { PaymentPlanFormHeader } from '../PaymentPlanFormHeader';
import { ChangeSummaryTypeEnum } from '../util';

import { defaultFormValues, paymentArrangementFormSchema } from './form-config';
import { PaymentArrangementModal } from './PaymentArrangementModal';
import { PaymentArrangementSchedule } from './PaymentArrangementSchedule';
import { RepaymentPlan, RepaymentPlanProps } from './RepaymentPlan';

dayjs.extend(isSameOrBefore);
const formatDate = (date: string | Date) => dayjs(date).format('YYYY-MM-DD');

const getNumberOfPayments = (startDate: Date, endDate: Date, frequency: RepaymentFrequencyEnum) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  let diff;

  switch (frequency) {
    case RepaymentFrequencyEnum.weekly:
      diff = end.diff(start, 'week');
      break;
    case RepaymentFrequencyEnum.fortnightly:
      diff = end.diff(start, 'week') / 2;
      break;
    case RepaymentFrequencyEnum.monthly:
      diff = end.diff(start, 'month');
      break;

    default:
      diff = 0;
  }

  //add 1 to include the start date
  return Math.floor(diff) + 1;
};

// calculate endDate based on start date and number of payments
const calculateEndDate = (startDate: Date, numberOfPayments: number, frequency: RepaymentFrequencyEnum) => {
  if (!startDate || !numberOfPayments || !frequency) return null;

  const start = dayjs(startDate);

  // subtract 1 to include the start date
  numberOfPayments = numberOfPayments - 1;
  switch (frequency) {
    case RepaymentFrequencyEnum.weekly:
      return start.add(numberOfPayments, 'week').toDate();
    case RepaymentFrequencyEnum.fortnightly:
      return start.add(numberOfPayments * 2, 'week').toDate();
    case RepaymentFrequencyEnum.monthly:
      return start.add(numberOfPayments, 'month').toDate();
    default:
      return null;
  }
};
interface PaymentArrangementProps extends CommonProps {
  paymentInfo: VaultPaymentInformationDto;
  loanInfo: VaultLoanInformationDto;
  loanApplicationId: string;
  spokeId: string;
  userId: string;
  isEdit: boolean;
  redirectToMakeExtraPayment: () => void;
  scrollIntoView: () => void;
  targetRef: React.RefObject<HTMLDivElement>;
}

const surfaceNotificationToCustomer = (preferredName: string) => {
  const message = `Hey ${preferredName}, just to let you know your payment arrangement has been updated.`;
  return {
    content: message,
    inboxType: InboxType.Message,
  };
};

export const PaymentArrangement = ({
  loanApplicationId,
  taskId,
  completeTaskWithData,
  paymentInfo: vaultPaymentInfo,
  loanInfo: vaultLoanInfo,
  userId,
  isEdit,
  targetRef,
  scrollIntoView,
}: PaymentArrangementProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [showMakeExtraPayment, setShowMakeExtraPayment] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(null);
  const [extraPaymentAmount, setExtraPaymentAmount] = useState<number>(0);

  const [validateDirectDebitPlan] = useValidateDirectDebitPlanMutation();
  const { data: userData } = useGetUserByIdQuery(userId as string, { skip: !userId });
  const { data: existingPaymentArrangement, isLoading } = useGetPaymentArrangementQuery(loanApplicationId as string, {
    skip: !userId || !loanApplicationId,
  });
  const { data: ddSuspensionData } = useGetDDSuspensionDetailsQuery(loanApplicationId, {
    skip: !loanApplicationId,
  });

  // default payment arrangement details
  const defaultPaymentArrangement: PaymentArrangementDto = {
    paymentFrequency: null,
    startDate: null,
    endDate: null,
    noEndDate: false,
    paymentAmount: 0,
    numberOfPayments: 0,
    note: '',
  };
  const [paymentArrangement, setPaymentArrangement] = useState<PaymentArrangementDto>(defaultPaymentArrangement);

  // recurring repayment details for card
  const [repaymentDetails, setRepaymentDetails] = useState<RepaymentPlanProps>(null);

  const populateOriginalPaymentArrangement = useCallback(() => {
    if (existingPaymentArrangement && isEdit) {
      const existingPaymentArrangementData: PaymentArrangementDto = {
        paymentFrequency: existingPaymentArrangement?.paymentFrequency,
        startDate: existingPaymentArrangement?.startDate,
        endDate: existingPaymentArrangement?.endDate,
        noEndDate: !!existingPaymentArrangement?.noEndDate,
        paymentAmount: Number(existingPaymentArrangement?.paymentAmount),
        numberOfPayments: existingPaymentArrangement?.numberOfPayments,
        note: existingPaymentArrangement?.note,
      };

      setValue('paymentAmount', existingPaymentArrangement?.paymentAmount);
      setValue('paymentFrequency', existingPaymentArrangement?.paymentFrequency);
      setValue('noEndDate', existingPaymentArrangement?.noEndDate);
      setValue('numberOfPayments', existingPaymentArrangement?.numberOfPayments);
      setValue(
        'startDate',
        existingPaymentArrangement?.startDate && dayjs(existingPaymentArrangement?.startDate).toDate()
      );
      setValue('endDate', existingPaymentArrangement?.endDate && dayjs(existingPaymentArrangement?.endDate).toDate());

      setPaymentArrangement(existingPaymentArrangementData);
    }
  }, [existingPaymentArrangement, isEdit]);

  useEffect(() => {
    if (!isLoading) {
      populateOriginalPaymentArrangement();
    }
  }, [isLoading]);

  useEffect(() => {
    if (paymentArrangement?.paymentAmount && paymentArrangement?.paymentFrequency && paymentArrangement?.startDate) {
      setRepaymentDetails(RepaymentPlan(paymentArrangement, vaultPaymentInfo));
    }
  }, [paymentArrangement, vaultPaymentInfo]);

  const form = useForm({
    mode: 'onTouched',
    schema: paymentArrangementFormSchema,
    defaultValues: defaultFormValues,
  });

  const { register, watch, getValues, setValue } = form;

  const [paymentAmount, paymentFrequency, startDate, endDate, noEndDate, numberOfPayments] = watch([
    'paymentAmount',
    'paymentFrequency',
    'startDate',
    'endDate',
    'noEndDate',
    'numberOfPayments',
  ]);

  useEffect(() => {
    if (paymentAmount && paymentFrequency && startDate) {
      handleRepaymentScheduleChange();
    }
  }, [paymentAmount, paymentFrequency, startDate, endDate, noEndDate]);

  useEffect(() => {
    if (paymentArrangement?.startDate && paymentArrangement?.paymentFrequency && paymentArrangement?.paymentAmount) {
      overridePaymentArrangementInRepaymentDetails(
        dayjs(paymentArrangement.startDate).toDate(),
        dayjs(paymentArrangement.endDate).toDate(),
        paymentArrangement.noEndDate,
        paymentArrangement.paymentFrequency,
        paymentArrangement.paymentAmount
      );
    }
  }, [paymentArrangement]);

  useEffect(() => {
    if (paymentAmount && paymentFrequency && startDate && numberOfPayments) {
      setValue('endDate', null);
      handleRepaymentScheduleChange();
    }
  }, [numberOfPayments]);

  const isValidDirectDebitPlan = useMemo(async () => {
    if (!paymentAmount || !paymentFrequency || !startDate || !loanApplicationId) return null;
    const result: ValidateDirectDebitPlanResponse = await validateDirectDebitPlan({
      loanApplicationId,
      paymentFrequency: RepaymentFrequencyEnum[paymentFrequency],
      paymentDate: formatDate(startDate),
      paymentAmount: paymentAmount,
    }).unwrap();
    return result;
  }, [startDate, paymentFrequency, paymentAmount, loanApplicationId]);

  const validatePaymentArrangement = async (paymentAmount, paymentFrequency, startDate) => {
    if (!paymentAmount || !paymentFrequency || !startDate) return;
    const result = await isValidDirectDebitPlan;
    if (!result?.isValid) {
      setShowMakeExtraPayment(true);
      setExtraPaymentAmount(result.pendingPaymentAmount);
    } else {
      setShowMakeExtraPayment(false);
      setExtraPaymentAmount(0);
    }
  };

  const overridePaymentArrangementInRepaymentDetails = (
    rescheduledStartDate: Date,
    rescheduledEndDate: Date,
    noEndDate: boolean,
    frequency: RepaymentFrequencyEnum,
    repaymentAmount: number
  ) => {
    if (!rescheduledStartDate || (!rescheduledEndDate && !noEndDate) || !frequency || !repaymentAmount) return;
    const repaymentPlan = RepaymentPlan(paymentArrangement, vaultPaymentInfo);

    setRepaymentDetails(repaymentPlan);
    if (!repaymentPlan) {
      return;
    }
    setRepaymentDetails((prevDetails) => ({
      ...prevDetails,
      paymentArrangementPlan: {
        ...prevDetails?.paymentArrangementPlan,
        amount: repaymentAmount,
        frequency: frequency,
        date: dayjs(rescheduledStartDate).format('YYYY-MM-DD'),
      },
      originalPaymentPlan: {
        ...prevDetails?.originalPaymentPlan,
        date: dayjs(rescheduledEndDate).format('YYYY-MM-DD'),
      },
    }));
  };

  const handleRepaymentScheduleChange = () => {
    const rescheduledStartDate = getValues('startDate');
    const rescheduledFrequency = RepaymentFrequencyEnum[getValues('paymentFrequency')];
    const repaymentAmount = getValues('paymentAmount');
    const note = getValues('note');
    const noEndDate = getValues('noEndDate');

    let rescheduledEndDate = getValues('endDate');
    let numberOfPayments = getValues('numberOfPayments');

    validatePaymentArrangement(rescheduledStartDate, rescheduledFrequency, repaymentAmount);

    if (!noEndDate) {
      if (!rescheduledEndDate && numberOfPayments) {
        const endDate = calculateEndDate(rescheduledStartDate, numberOfPayments, rescheduledFrequency);
        setValue('endDate', endDate);
      } else if (rescheduledEndDate) {
        numberOfPayments = getNumberOfPayments(rescheduledStartDate, rescheduledEndDate, rescheduledFrequency);
        setValue('numberOfPayments', numberOfPayments);
      }
      rescheduledEndDate = getValues('endDate');
    } else {
      setValue('numberOfPayments', null);
      setValue('endDate', null);
      rescheduledEndDate = null;
    }

    setPaymentArrangement((prevPlan) => ({
      ...prevPlan,
      startDate: rescheduledStartDate && formatDate(rescheduledStartDate),
      endDate: rescheduledEndDate ? formatDate(rescheduledEndDate) : null,
      noEndDate: noEndDate,
      paymentFrequency: rescheduledFrequency,
      paymentAmount: repaymentAmount,
      numberOfPayments: numberOfPayments,
      note: note,
    }));
  };

  const getErrorMsg = () => {
    //step1 : validate ddSuspensionEffectiveDate and ddSuspensionExpiryDate
    //check if ddSuspensionEffectiveDate and ddSuspensionExpiryDate does not falls between formData.startDate and formData.endDate
    const ddSuspensionEffectiveDate = dayjs(ddSuspensionData?.ddSuspensionEffectiveDate).toDate();
    const ddSuspensionExpiryDate = dayjs(ddSuspensionData?.ddSuspensionExpiryDate).toDate();

    if (isDateOverlapDetected(ddSuspensionEffectiveDate, ddSuspensionExpiryDate, startDate, endDate)) {
      return ` This loan has a Direct Debit Suspension from ${' '}
                    ${dayjsUTCToSydney(ddSuspensionData.ddSuspensionEffectiveDate).format(DATE_FORMAT)}${' '}
                    to ${dayjsUTCToSydney(ddSuspensionData.ddSuspensionExpiryDate).format(DATE_FORMAT)}.
                    You cannot schedule a payment arrangement that conflicts with these dates. To be able to proceed with the dates you have
                    selected, you must first update the Direct Debit Suspension.`;
    }

    //step2: validate if end date does not fall between last payment cycle
    if (dayjs(vaultLoanInfo.maturityDate).isBefore(endDate)) {
      return `End date must be before loan maturity date`;
    }
    return null;
  };

  const handleSubmit = () => {
    setShowError(false);
    const errorMsg = getErrorMsg();
    setErrorMsg(errorMsg);

    if (errorMsg) {
      setShowError(true);
      setIsSubmitting(false);
      if (targetRef) {
        scrollIntoView();
      }
    } else {
      setIsConfirmModalOpen(true);
    }
  };

  const onModalSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const formData = form.getValues();
    const data: PaymentArrangementDto = {
      loanApplicationId: loanApplicationId,
      userId,
      paymentFrequency: RepaymentFrequencyEnum[formData.paymentFrequency],
      startDate: formatDate(formData?.startDate),
      endDate: formData?.endDate && formatDate(formData?.endDate),
      noEndDate: formData?.noEndDate,
      paymentAmount: formData?.paymentAmount,
      numberOfPayments: formData?.numberOfPayments,
      note: formData.note,
    };

    const notification = surfaceNotificationToCustomer(userData?.preferredName);

    const completeTaskDto: CompleteTaskDto = {
      taskId,
      formKey: FORM_KEY.PAYMENT_ARRANGEMENT,
      formData: {
        paymentArrangement: data,
        isEdit: isEdit,
      },
      variables: { loanApplicationId, notification, ...data },
    };
    await completeTaskWithData(completeTaskDto);
  }, [form, loanApplicationId, userId, userData, completeTaskWithData, taskId]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // handle the watched values here if needed
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  if (isLoading || !paymentArrangement) {
    return <Spinner />;
  }

  return (
    <>
      <Form form={form} onSubmit={handleSubmit}>
        <PaymentPlanFormHeader
          type={ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT}
          headerTitle={ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT}
        />
        <div className="px-4" key="error-dd-suspension" ref={targetRef}>
          {showError && (
            <div>
              <Alert variant="error" className="mb-6">
                {errorMsg}
              </Alert>
            </div>
          )}
          {ddSuspensionData?.directDebitSuspended && (
            <Alert variant="warning" className="mb-6">
              This loan has a Direct Debit Suspension from{' '}
              {dayjsUTCToSydney(ddSuspensionData.ddSuspensionEffectiveDate).format(DATE_FORMAT)} to{' '}
              {dayjsUTCToSydney(ddSuspensionData.ddSuspensionExpiryDate).format(DATE_FORMAT)}
            </Alert>
          )}
          {isEdit && <PaymentArrangementSchedule repaymentDetails={repaymentDetails} hasEndDate={!noEndDate} />}

          {!isEdit && (
            <AmountFrequency
              register={register}
              label="Payment amount"
              inputKey={`paymentAmount`}
              selectKey={`paymentFrequency`}
              name="payment-amount"
              options={frequencyOptions}
              className="mb-6"
              isDecimal={true}
            />
          )}
          <DatePicker
            className="mb-6"
            {...register('startDate')}
            label="First payment for this plan"
            placeholderText="DD/MM/YYYY"
            disabled={isEdit}
            minDate={dayjs().add(1, 'day').toDate()}
            selected={paymentArrangement?.startDate ? dayjs(paymentArrangement?.startDate).toDate() : null}
            flexiblePopperPlacement
            onKeyDown={(e) => e.preventDefault()}
          />

          <DatePicker
            className="mb-2"
            {...register('endDate')}
            disabled={noEndDate}
            label="End date"
            placeholderText="DD/MM/YYYY"
            minDate={dayjs().add(1, 'day').toDate()}
            selected={paymentArrangement?.endDate ? dayjs(paymentArrangement?.endDate).toDate() : null}
            flexiblePopperPlacement
            onKeyDown={(e) => e.preventDefault()}
            textColor={noEndDate ? '!text-grey-3' : ''}
          />

          <Checkbox {...register('noEndDate')} className="mb-6 ml-1" label="No end date" />

          {!noEndDate && (
            <ControlledInput
              className="mb-6"
              name="numberOfPayments"
              label="No of payments"
              {...register('numberOfPayments')}
            />
          )}

          {showMakeExtraPayment && (
            <Alert variant="warning" className="mb-6">
              This change may not meet the current contractual amount for the loan by{' '}
              {formatCurrency(extraPaymentAmount)}.
            </Alert>
          )}

          {!isEdit && <PaymentArrangementSchedule repaymentDetails={repaymentDetails} hasEndDate={!noEndDate} />}

          <Textarea {...register('note')} label="Notes" placeholder="Add a note" className="mb-6" />
          <PaymentPlanFormFooter
            type={ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT}
            buttonText="Save"
            isSubmitting={isSubmitting}
          />
        </div>
      </Form>

      <PaymentArrangementModal
        isConfirmModalOpen={isConfirmModalOpen}
        setIsConfirmModalOpen={setIsConfirmModalOpen}
        onModalSubmit={onModalSubmit}
        isSubmitting={isSubmitting}
        extraPaymentAmount={extraPaymentAmount}
        repaymentDetails={repaymentDetails}
        noEndDate={noEndDate}
        isEdit={isEdit}
      />
    </>
  );
};
