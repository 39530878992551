import { useCallback } from 'react';

export const useCookie = () => {
  const getCookie = useCallback((key: string) => {
    const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${key}=`));
    return cookie ? cookie.split('=')[1] : null;
  }, []);

  return getCookie;
};
