import { useCallback, useEffect, useMemo, useState } from 'react';
import { ABTest, DegreesOfFreedom, FORM_KEY } from '@harmoney/api-interfaces';
import { useBranch, useFormatQuoteOptions, useFriendlyURL, useQuoteData } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetLoanApplicationQuery,
  useGetQuoteOptionsQuery,
  useGetUserQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_APPLICATION_SUBMITTED, VALID_QUOTE_DISPLAYED } from '@harmoney/ui-app-shell';
import { Button, Form, Spinner, useForm } from '@harmoney/ui-design-system';
import { BranchEnum, LoanApplicationStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { CommonProps } from '../../common-props';
import {
  calculateEstablishmentFee,
  LoanPeriodAlert,
  QuoteDisclaimer,
  QuoteIntroduction,
  QuoteSummary,
  RepaymentOptions,
} from '../shared';

import { LoanPurposeAmount } from './components';
import { createFormSchema, getDefaultValues } from './form-config';

dayjs.extend(relativeTime);

export function ValidQuoteV3({ taskId, taskFriendlyURL, completeTaskWithData }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const { data: variables } = useGetVariablesQuery(taskId);

  const userId = useAppSelector((state) => state.userId.value);
  const [borrowingLimit, setBorrowingLimit] = useState<{ lowerLimit: number; upperLimit: number }>({
    lowerLimit: 0,
    upperLimit: 0,
  });

  const quoteRepaymentHeroExperimentFeb25Value =
    (variables?.degreesOfFreedom as DegreesOfFreedom)?.abExperiments?.find(
      (exp) => exp?.quoteRepaymentHeroExperimentFeb25
    )?.quoteRepaymentHeroExperimentFeb25 ?? ('A' as ABTest);
  const branch = useBranch();

  const formSchema = createFormSchema(borrowingLimit.lowerLimit, borrowingLimit.upperLimit);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: getDefaultValues(quoteRepaymentHeroExperimentFeb25Value as string, branch),
  });
  const {
    watch,
    register,
    formState: { isSubmitting, isSubmitSuccessful },
    setValue,
  } = form;

  const watchFields = watch();

  const { data: userData } = useGetUserQuery();
  const { quoteData } = useQuoteData(taskId);
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });

  const { totalAcceptedAmount, establishmentFee, totalLoanAmount } = useMemo(() => {
    const totalAccepted = watchFields.amount;
    const fee = calculateEstablishmentFee(totalAccepted, quoteData);
    const totalLoan = totalAccepted + fee;
    return { totalAcceptedAmount: totalAccepted, establishmentFee: fee, totalLoanAmount: totalLoan };
  }, [watchFields.amount, quoteData]);

  const { data: quoteOptionsData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: totalAcceptedAmount,
      establishmentFee,
    },
    {
      skip: !variables || !totalAcceptedAmount || !establishmentFee,
      refetchOnMountOrArgChange: true,
    }
  );

  const formattedQuoteOptions = useFormatQuoteOptions(watchFields.repaymentFrequency, quoteOptionsData);

  const isSameLoanLimitAcrossOptions = useMemo(() => {
    return quoteOptionsData?.every(({ loanLimitFromUmi }) => loanLimitFromUmi === quoteOptionsData[0].loanLimitFromUmi);
  }, [quoteOptionsData]);

  useEffect(() => {
    if (!quoteData || !loanApplicationData) return;
    const isVariantB = quoteRepaymentHeroExperimentFeb25Value && quoteRepaymentHeroExperimentFeb25Value === 'B';
    if (isVariantB && branch === BranchEnum.AU) {
      setValue('amount', +quoteData.requestedAmount);
    } else {
      setValue('amount', +quoteData.maximumBorrowingLimit);
    }
    setValue('termInMonths', +quoteData.termInMonths);
    setBorrowingLimit({
      lowerLimit: quoteData.minimumBorrowingLimit,
      upperLimit: loanApplicationData.quotePresentedAmount,
    });
  }, [quoteData, loanApplicationData, quoteRepaymentHeroExperimentFeb25Value, branch]);

  useEffect(() => {
    if (quoteRepaymentHeroExperimentFeb25Value && branch) {
      const newDefaults = getDefaultValues(quoteRepaymentHeroExperimentFeb25Value, branch);

      setValue('termInMonths', newDefaults.termInMonths);
      setValue('repaymentFrequency', newDefaults.repaymentFrequency);
    }
  }, [quoteRepaymentHeroExperimentFeb25Value, branch]);

  const eventProperties: { [key: string]: string | number } = useMemo(() => {
    return {};
  }, []);

  const amountsComparison = useCallback(() => {
    if (loanApplicationData?.quotePresentedAmount > loanApplicationData?.requestedAmount) return 'higher';
    if (loanApplicationData?.quotePresentedAmount < loanApplicationData?.requestedAmount) return 'lower';
    return 'equal';
  }, [loanApplicationData?.quotePresentedAmount, loanApplicationData?.requestedAmount]);

  useEffect(() => {
    if (branch === BranchEnum.AU && quoteRepaymentHeroExperimentFeb25Value) {
      eventProperties['amounts_comparison'] = amountsComparison();
      eventProperties['quote_repayment_hero_experiment_feb25'] =
        `quote-repayment-hero-variant-${quoteRepaymentHeroExperimentFeb25Value}`;
    }
  }, [amountsComparison, branch, eventProperties, quoteRepaymentHeroExperimentFeb25Value]);

  useEffect(() => {
    eventAnalytics.track(VALID_QUOTE_DISPLAYED, {
      taskid_str: taskId,
      ...eventProperties,
    });
  }, [eventProperties, taskId]);

  const handleSubmit = async () => {
    await completeTaskWithData({
      taskId,
      formKey: FORM_KEY.LOAN_APPLICATION_SUBMIT,
      formData: {
        loanApplication: {
          id: variables.loanApplicationId.toString(),
          termInMonths: +form.getValues('termInMonths'),
          fundedAmount: +totalAcceptedAmount,
          repaymentFrequency: form.getValues('repaymentFrequency'),
          status: LoanApplicationStatusEnum.application_in_progress,
        },
      },
    });

    eventAnalytics.track(LOAN_APPLICATION_SUBMITTED, {
      userid_str: userId,
      taskid_str: taskId,
      ...eventProperties,
    });
  };

  if (!quoteData) return <Spinner />;

  return (
    <>
      <QuoteIntroduction
        preferredName={userData?.preferredName}
        interestRate={loanApplicationData?.finalInterestRate as unknown as number}
        quoteRepaymentHeroExperimentFeb25Value={quoteRepaymentHeroExperimentFeb25Value as string}
        branch={branch}
      />

      <Form form={form} onSubmit={handleSubmit}>
        {loanApplicationData?.loanApplicationPurposes?.length > 0 && (
          <LoanPurposeAmount
            remainingAmount={borrowingLimit.upperLimit - watchFields.amount}
            loanApplicationData={loanApplicationData}
            register={register}
          />
        )}

        {!isSameLoanLimitAcrossOptions && <LoanPeriodAlert className="mb-4" />}

        <RepaymentOptions formattedQuoteOptions={formattedQuoteOptions} register={register} />

        {watchFields.repaymentFrequency && (
          <QuoteSummary
            primaryPurpose={loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName}
            primaryPurposeAmount={watchFields.amount}
            establishmentFee={establishmentFee}
            totalLoanAmount={totalLoanAmount}
            interestRate={loanApplicationData?.finalInterestRate as unknown as number}
          />
        )}

        <QuoteDisclaimer />

        <div className="flex justify-center">
          <Button variant="primary" isLoading={isSubmitting || isSubmitSuccessful} type="submit" hasShadow>
            Submit application
          </Button>
        </div>
      </Form>
    </>
  );
}
