import React, { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  IIncomeOverrideReasonFields,
  IncomeAndExpensesOverrideDto,
  NetworthSourceEnum,
} from '@harmoney/api-interfaces';
import { useOverrideIncomeAndExpenseMutation } from '@harmoney/redux';
import { Button, Card, CurrencyWithFrequency, Divider, Form, useForm } from '@harmoney/ui-design-system';
import { booleanToYesNo } from '@harmoney/ui-utils';
import {
  BranchEnum,
  IncomeAndExpense,
  IncomeAndExpenseFrequencyEnum,
  LoanApplicationStatusEnum,
  NetworthSource,
} from '@prisma/client';
import { Entry } from 'contentful';

import { Field } from '../../shared';
import {
  nonSharedOverrideIncomeFormSchema,
  type NonSharedOverrideIncomeFormSchemaType,
} from '../Override/OverrideForm';

import { IncomeItem } from './IncomeItem';

type Props = {
  applicationStatus: LoanApplicationStatusEnum;
  incomeData: (IncomeAndExpense & {
    networthSource: NetworthSource;
  })[];
  overrideReasons?: Entry<IIncomeOverrideReasonFields>[];
  withForm?: boolean;
  branch: BranchEnum;
};

export const IncomeCategories = ({ incomeData, overrideReasons, applicationStatus, withForm, branch }: Props) => {
  const [overrideForm, setOverrideForm] = useState(false);
  const [overrideIncome] = useOverrideIncomeAndExpenseMutation();

  const form = useForm({
    schema: nonSharedOverrideIncomeFormSchema,
    defaultValues: {
      overrideItem: incomeData.map((income) => ({
        itemId: income.id,
        overrideAmount: undefined,
        overrideFrequency: undefined,
        overrideReason: undefined,
        overrideReasonForOther: undefined,
      })),
    },
  });

  const { control, reset } = form;

  const { fields } = useFieldArray({
    control,
    name: 'overrideItem',
  });

  const handleOverrideSubmit = async (data: NonSharedOverrideIncomeFormSchemaType) => {
    const overrideDto: IncomeAndExpensesOverrideDto = data.overrideItem.map((item) => ({
      id: item.itemId,
      creditOfficerAmount: item.overrideAmount,
      creditOfficerFrequency: item.overrideFrequency as IncomeAndExpenseFrequencyEnum,
      overrideReason:
        item.overrideReason === 'other'
          ? `Other - ${item.overrideReasonForOther}`
          : overrideReasons.find((option) => option.sys.id === item.overrideReason).fields.reason,
      overrideReasonId: item.overrideReason,
    }));
    await overrideIncome(overrideDto);
    setOverrideForm(false);
  };

  if (incomeData[0].networthSourceId === NetworthSourceEnum.INCOME_CHANGE_ID) {
    return (
      <Card key={incomeData[0].id} className="shadow-none border border-grey-2 h-fit !mb-0 space-y-2">
        <Field>
          <span>Income change:</span>
          <span>{booleanToYesNo(incomeData[0]?.expectIncreaseDecreaseChange)}</span>
        </Field>
        {incomeData[0]?.expectIncreaseDecreaseChange && (
          <Field>
            <span>By how much will it reduce:</span>
            <CurrencyWithFrequency amount={incomeData[0]?.declaredAmount} frequency={incomeData[0]?.frequency} />
          </Field>
        )}
      </Card>
    );
  }

  return (
    <Card key={incomeData[0].networthSourceId} className="shadow-none border border-grey-2 h-fit !p-0 !mb-0">
      <div className="p-4">
        {fields &&
          incomeData.map((income, i, arr) => (
            <Form
              form={form}
              key={income.id}
              id={`incomeOverride.${income.networthSourceId}-form`}
              onSubmit={handleOverrideSubmit}
            >
              <IncomeItem
                key={fields.find((field) => field.id === income.id)?.id}
                income={income}
                overrideReasons={overrideReasons}
                displayOverrideForm={overrideForm}
                fields={fields}
                branch={branch}
              />
              {i !== arr.length - 1 && <Divider className="text-grey-2" />}
            </Form>
          ))}
      </div>
      {withForm && (
        <>
          <Divider className="text-grey-2" />
          {/* 
            TODO: Remove incomeData[0].networthSourceId === NetworthSourceEnum.INCOME_HOUSEHOLD_ID
            from disabled condition once all app with household income reach the decision successful/unsuccessful
             */}
          <div className="flex flex-row justify-end p-4">
            {!overrideForm ? (
              <Button
                size="small"
                variant="outline-secondary"
                className="!min-w-fit"
                disabled={
                  applicationStatus !== LoanApplicationStatusEnum.quote_in_review ||
                  incomeData[0].networthSourceId === NetworthSourceEnum.INCOME_HOUSEHOLD_ID
                }
                onClick={() => {
                  reset();
                  setOverrideForm(true);
                }}
              >
                Override
              </Button>
            ) : (
              <div className="flex flex-row gap-2">
                <Button size="small" variant="text" className="!min-w-fit" onClick={() => setOverrideForm(false)}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="accent"
                  className="!min-w-fit"
                  type="submit"
                  form={`incomeOverride.${incomeData[0].networthSourceId}-form`}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </Card>
  );
};
