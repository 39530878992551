import { useCallback, useMemo, useState } from 'react';
import { DisbursementDto } from '@harmoney/api-interfaces';
import { Card, Label, ToggleGroup, useFormContext } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { PaymentTypeEnum } from '@prisma/client';

import { BpayForm } from './components/BpayForm';
import { BsbForm } from './components/BsbForm';
import { CurrentBankDetails } from './components/CurrentBankDetails';

type Props = {
  disbursal: DisbursementDto;
};

export default function UpdateSingleDebtConDisbursalBankAccount({ disbursal }: Props) {
  const [updateDisbursal, setUpdateDisbursal] = useState(false);
  const [whichBsbOrBpay, setWhichBsbOrBay] = useState<'bsb' | 'bpay' | undefined>(undefined);
  const { setValue, resetField } = useFormContext();

  const networthSourceName = useMemo(() => {
    return disbursal?.networthSource?.name.charAt(0).toUpperCase() + disbursal?.networthSource?.name.slice(1);
  }, [disbursal?.networthSource?.name]);

  const changeFromBankAccount = useCallback(() => {
    const {
      paidTo,
      disbursalBankDetails: { accountName, accountNumber, bankCode, reference, bpayBillerCode, bsb: accountBsb },
    } = disbursal ?? {};
    setValue(
      `updates.${disbursal.id}.fromBankAccount`,
      disbursal.paymentType === PaymentTypeEnum.BPAY
        ? {
            bpayBillerCode,
            reference,
            bankName: paidTo ?? accountName,
            paymentId: disbursal.id,
            type: 'bpay',
          }
        : {
            accountBsb,
            accountNumber,
            accountName,
            bankName: bankCode ?? accountName,
            reference,
            bankSlug: '',
            paymentId: disbursal.id,
            type: 'bsb',
          }
    );
  }, [disbursal, setValue]);

  const changeBsbOrBpay = useCallback(
    (which: typeof whichBsbOrBpay, updateDisbursal: boolean) => {
      setWhichBsbOrBay(which);
      if (!which) {
        if (updateDisbursal) return setValue(`updates.${disbursal.id}`, {});
        return resetField(`updates.${disbursal.id}`);
      }
      changeFromBankAccount();
      resetField(`updates.${disbursal.id}.newBankAccount`);
    },
    [changeFromBankAccount, disbursal.id, resetField, setValue]
  );

  return (
    <Card>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <span>Paid To</span>
          <span>{disbursal.paidTo}</span>
        </div>
        <div className="flex flex-row justify-between">
          <span>Type</span>
          <span>{networthSourceName}</span>
        </div>
        <div className="flex flex-row justify-between">
          <span>Amount they will receive</span>
          <span>{formatCurrency(disbursal.amount ?? 0)}</span>
        </div>

        <hr className="border-grey-2" />

        <strong>Current</strong>
        <CurrentBankDetails disbursal={disbursal} />

        <hr className="border-grey-2" />

        <span>Do you want to update this disbursal account?</span>
        <div className="flex flex-col items-center">
          <ToggleGroup
            name="__updateDisbursal"
            onValueChange={(e) => {
              const value = e.toLowerCase() === 'true';
              setUpdateDisbursal(value);
              changeBsbOrBpay(value ? whichBsbOrBpay : undefined, value);
            }}
            options={[
              { label: <Label>Yes</Label>, value: 'true' },
              { label: <Label>No</Label>, value: 'false' },
            ]}
          />
        </div>

        {updateDisbursal && (
          <>
            <span>How should we pay this off?</span>
            <div className="flex flex-col items-center">
              <ToggleGroup
                name="__disbursalPaymentTo"
                onValueChange={(e) => {
                  changeBsbOrBpay(e as typeof whichBsbOrBpay, updateDisbursal);
                }}
                options={[
                  { label: <Label>BPAY</Label>, value: 'bpay' },
                  { label: <Label>BSB</Label>, value: 'bsb' },
                ]}
              />
            </div>
          </>
        )}

        {!!whichBsbOrBpay && (
          <>
            <small className="text-grey-3">
              {whichBsbOrBpay === 'bsb'
                ? 'You can find your BSB number on the payment details on your statement, or you can call your provider directly.'
                : 'On a bill, you can find your BPAY biller code in the payment options section.'}
            </small>

            {whichBsbOrBpay === 'bpay' ? <BpayForm disbursal={disbursal} /> : <BsbForm disbursal={disbursal} />}
          </>
        )}
      </div>
    </Card>
  );
}
