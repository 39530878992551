import { PaymentCard } from '@harmoney/ui-design-system';
import dayjs from 'dayjs';

import { RepaymentPlanProps } from './RepaymentPlan';

export interface PaymentArrangementScheduleProps {
  repaymentDetails: RepaymentPlanProps;
  hasEndDate: boolean;
}

export const PaymentArrangementSchedule = ({ repaymentDetails, hasEndDate }: PaymentArrangementScheduleProps) => {
  return (
    <div className="mb-6">
      {repaymentDetails?.paymentArrangementPlan && (
        <PaymentCard
          className="mb-8"
          title={repaymentDetails.paymentArrangementPlan.title}
          amount={repaymentDetails.paymentArrangementPlan.amount}
          frequency={repaymentDetails.paymentArrangementPlan.frequency}
          date={
            hasEndDate
              ? `From ${dayjs(repaymentDetails.paymentArrangementPlan.startDate).format('ddd, DD MMMM YYYY')} to ${dayjs(repaymentDetails.paymentArrangementPlan.endDate).format('ddd, DD MMMM YYYY')} (${repaymentDetails.paymentArrangementPlan.numberOfPayments} payments)`
              : `From ${dayjs(repaymentDetails.paymentArrangementPlan.startDate).format('ddd, DD MMMM YYYY')} onwards`
          }
          icon={repaymentDetails.paymentArrangementPlan.icon}
        />
      )}

      {repaymentDetails?.originalPaymentPlan && hasEndDate && (
        <PaymentCard
          title={repaymentDetails.originalPaymentPlan.title}
          amount={repaymentDetails.originalPaymentPlan.amount}
          frequency={repaymentDetails.originalPaymentPlan.frequency}
          date={`From ${dayjs(repaymentDetails.originalPaymentPlan.startDate).format('ddd, DD MMMM YYYY')} onwards`}
          icon={repaymentDetails.originalPaymentPlan.icon}
        />
      )}
    </div>
  );
};
