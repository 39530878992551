import Link from 'next/link';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  auditDecisionMapper,
  branchEmojiMapper,
  branchMapper,
  INTERNAL_TASK,
  loanStatusEmojiMapper,
  loanStatusMapper,
  TaskListDetailsDto,
  TaskState,
  taskStatusMapper,
} from '@harmoney/api-interfaces';
import {
  useAppSelector,
  useAssignTaskByUserMutation,
  useGetAllTasksQuery,
  useUnassignTaskByUserMutation,
} from '@harmoney/redux';
import { isDevelopment, isPreview } from '@harmoney/ui-app-shell';
import { Badge, CommonOptionProps, CopyButton, Spinner, UncontrolledSelect } from '@harmoney/ui-design-system';
import { DATE_FORMAT, SOMETHING_WENT_WRONG, valueOrDash } from '@harmoney/ui-utils';
import { readLocalStorageValue, useDebouncedValue } from '@mantine/hooks';
import { User, UserProfile } from '@prisma/client';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { DataTableWithCursor } from '../DataTableWithCursor';

type Props = {
  users: Array<User & { userProfile?: UserProfile }>;
  filters: Record<string, string[]>;
};

export const TaskDataTable = ({ users, filters }: Props) => {
  const accessToken = useAppSelector((state) => state.accessToken.value);

  const userId = useAppSelector((state) => state.userId.value);
  const [globalFilter, setGlobalFilter] = useState('');

  const [taskData, setTaskData] = useState<TaskListDetailsDto[] | null>(null);

  const [cursor, setCursor] = useState<[string, string] | null>(null);
  const [hasCursor, setHasCursor] = useState(false);

  const [updatedRecord, setUpdatedRecord] = useState(null);
  const [taskOwnerOptions, setTaskOwnerOptions] = useState<CommonOptionProps[]>([]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const extractUserName = useCallback(({ preferredName, email, userProfile }: (typeof users)[number]) => {
    let label = preferredName ?? email;
    if (userProfile?.firstName) {
      label = userProfile.firstName;
      if (userProfile.lastName) label += ` ${userProfile.lastName}`;
    }
    return label;
  }, []);

  const maxWidthUser = useMemo(() => {
    return (
      (users
        ?.map((user) => extractUserName(user))
        .sort((aName, bName) => {
          return bName.length - aName.length;
        })[0]?.length ?? 0) + 5
    );
  }, [extractUserName, users]);

  const [debounced] = useDebouncedValue(globalFilter, 400);
  const TASK_TABLE_CUSTOMISE_COLUMNS_LOCAL_STORAGE_KEY = 'customised-columns-state:task-table';
  const DEFAULT_FULL_COLUMN_VISIBILITY = {};
  const defaultColumnVisibility = readLocalStorageValue<Record<string, boolean>>({
    key: TASK_TABLE_CUSTOMISE_COLUMNS_LOCAL_STORAGE_KEY,
    defaultValue: DEFAULT_FULL_COLUMN_VISIBILITY,
  });

  const {
    data: allTaskData,
    isError: isAllTaskDataError,
    isLoading: isAllTaskDataLoading,
    isFetching: isAllTaskDataFetching,
  } = useGetAllTasksQuery(
    {
      page: pageIndex + 1,
      limit: pageSize,
      search: encodeURI(debounced),
      filter: JSON.stringify({
        ...filters,
        // FIXME: remove this filter
        taskName: [INTERNAL_TASK.DISBURSEMENT_DECISION],
      }),
      cursor: cursor?.join(','),
    },
    {
      skip: !accessToken || !userId || !Object.keys(filters).length,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const [unassignTask, { isError: isUnassignTaskError }] = useUnassignTaskByUserMutation();
  const [assignTask, { isError: isAssignTaskError }] = useAssignTaskByUserMutation();

  useEffect(() => {
    if (users) {
      setTaskOwnerOptions([
        { label: 'Unassigned', value: 'unassigned' },
        ...users
          .map((user) => {
            const label = extractUserName(user);
            if ((isDevelopment() || isPreview()) && (label.startsWith('e2e') || label.includes('QA'))) return;
            return { label, value: user.id };
          })
          .filter((v) => !!v),
      ]);
    }
  }, [extractUserName, users]);

  useEffect(() => {
    if (allTaskData) {
      setHasCursor(!!allTaskData?.cursor);
    }

    setTaskData(allTaskData?.data ?? []);
  }, [allTaskData]);

  useEffect(() => {
    if (pageIndex > 0 && allTaskData) {
      setCursor(allTaskData?.cursor);
    }
    if (pageIndex === 0 && allTaskData) {
      setCursor(null);
    }
  }, [pageIndex]);

  const columns = useMemo<ColumnDef<TaskListDetailsDto>[]>(
    () => [
      {
        header: 'Task name',
        accessorKey: 'name',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link
              href={`/admin/details/?taskId=${row.original.id}&userId=${row.original.userId}#applications/${row.original.loanApplicationId}`}
              passHref
            >
              {row.original.name}
            </Link>
          </div>
        ),
      },
      {
        header: 'Task owner',
        accessorKey: 'assignee',
        cell: ({ row }) => {
          const user = row.original.userAssignee;
          const taskOwners = [...taskOwnerOptions];
          if (user && !taskOwners.find((taskOwner) => taskOwner.value === user.id))
            taskOwners.push({ label: extractUserName(user), value: user.id });

          return (
            <div className={classNames('flex flex-row gap-2 text-sm', `min-w-[${maxWidthUser}ch]`)}>
              {taskOwnerOptions && (
                <UncontrolledSelect
                  className="flex align-center flex-auto text-sm overflow-hidden"
                  value={row.original.assignee}
                  options={taskOwners}
                  onChange={(value) => handleOnChangeTaskOwner(value, row.original.id)}
                  formFieldClassName="p-2 text-sm "
                  disabled={row.original.taskState === 'COMPLETED'}
                ></UncontrolledSelect>
              )}
            </div>
          );
        },
      },
      {
        header: 'Task status',
        accessorKey: 'taskState',
        enableSorting: true,
        cell: ({ row }) => (
          <div className="flex flex-row gap-2 min-w-[6ch]">
            <Badge
              variant={row.original.taskState === TaskState.Completed ? 'success' : 'secondary'}
              label={capitalize(taskStatusMapper[row?.original?.taskState])}
            />
          </div>
        ),
      },
      {
        header: 'Application no.',
        accessorKey: 'businessKey',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <span>{valueOrDash(row.original.businessKey)} </span>
            <CopyButton valueToCopy={row.original.businessKey} size="small" />
          </div>
        ),
      },
      {
        header: 'Branch',
        accessorKey: 'branch',
        enableSorting: true,
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            {branchEmojiMapper[row.original.branch]} {branchMapper[row.original.branch]}
          </div>
        ),
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            {loanStatusEmojiMapper[row.original.status]} - {capitalize(loanStatusMapper[row.original.status])}
          </div>
        ),
      },

      {
        header: 'Disbursement decision',
        accessorKey: 'decision',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">{auditDecisionMapper[row.original.disbursementDecision]}</div>
        ),
      },
      {
        header: 'No. of audits',
        accessorKey: 'numberOfAudits',
        cell: ({ row }) => <div className="flex flex-row gap-2">{row.original.numberOfAudits}</div>,
      },
      {
        header: 'Created on',
        accessorKey: 'createdAt',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">{dayjs(row.original.createdAt).format(DATE_FORMAT)}</div>
        ),
      },
      {
        header: 'Updated on',
        accessorKey: 'updatedAt',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">{dayjs(row.original.updatedAt).format(DATE_FORMAT)}</div>
        ),
      },
    ],
    [taskData, taskOwnerOptions]
  );

  const handleOnChangeTaskOwner = async (value, taskId) => {
    if (
      taskData?.find((task) => task.id === taskId)?.assignee === value ||
      (value === 'unassigned' && !taskData?.find((task) => task.id === taskId)?.assignee)
    ) {
      return;
    }
    setUpdatedRecord(taskId);
    if (value === 'unassigned') {
      await unassignTask({ taskId });
      setTaskData((prev) => prev?.map((task) => (task.id === taskId ? { ...task, assignee: value } : task)));
    } else {
      await assignTask({
        taskId,
        data: {
          assignee: value,
          allowOverrideAssignment: true,
        },
      });
      setTaskData((prev) => prev?.map((task) => (task.id === taskId ? { ...task, assignee: value } : task)));
    }
    setUpdatedRecord(null);
  };

  if (isAllTaskDataLoading || isAllTaskDataFetching) {
    return (
      <div className="align-center mt-32 flex justify-center">
        <Spinner />
      </div>
    );
  }

  if (isAllTaskDataError || isUnassignTaskError || isAssignTaskError) {
    return <div className="m-3">{SOMETHING_WENT_WRONG}</div>;
  }

  return (
    <div className="flex flex-col p-6 flex-1 overflow-auto align-center ">
      <div className="col-span-7 align-center">
        {columns && taskData && (
          <DataTableWithCursor
            title={'Tasks'}
            data={taskData}
            columns={columns}
            pageIndex={pageIndex}
            pageSize={pageSize}
            hasNextPage={hasCursor}
            setPagination={setPagination}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
            defaultColumnVisibility={defaultColumnVisibility}
            customisedColumnsLocalStorageKey={TASK_TABLE_CUSTOMISE_COLUMNS_LOCAL_STORAGE_KEY}
            updating={updatedRecord}
            noDataMessage={'No tasks to display.'}
          />
        )}
      </div>
    </div>
  );
};
