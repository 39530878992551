/**
 * This file is the configuration of Smart UI.
 * More details see https://apidocs.frankiefinancial.com/docs/smart-ui-configuration#configuration-parameters
 */

import { FrankieSmartUIStyle } from './frankie-smartui-style';

const NZ_COUNTRY_CODE = 'NZL';
const AU_COUNTRY_CODE = 'AUS';
const maxAttemptCount = 3;
const ageRange = [18, 125];
const disableThirdPartyAnalytics = false;

export const privacyPolicyLinkAU =
  '<a href="https://www.harmoney.com.au/legal/privacy-policy" target="_blank" rel="noreferrer" >Privacy Policy</a>';

export const privacyPolicyLinkNZ =
  '<a href="https://www.harmoney.co.nz/legal/privacy-policy" target="_blank" rel="noreferrer" >Privacy Policy</a>';

const consentTextAU = `I understand that my ID info will be checked with the issuer to confirm my identity, and this may involve using third party systems.`;
const consentTextNZ = `I understand Harmoney will verify my identity in accordance with its ${privacyPolicyLinkNZ}`;
const newConsentTextAU = `<span class='text-bold'>Verify my ID</span>
<p>I understand that my ID info will be checked with the issuer to confirm my identity, and this may involve using third party systems.</p>
<span class='text-bold'>Credit report request</span>
<p>I authorise Harmoney Services Australia Pty Ltd to request my credit report from any credit reporting body for the purpose of assisting me in seeking a rate offer from Harmoney Australia Pty Ltd.</p>`;
const phrases = {
  document: {
    type_drivers_licence: {
      label: "Driver's Licence",
    },
  },
  common: {
    next_button_cta: 'Continue',
  },
  document_select: {
    title: `Let's verify your identity`,
    subtitle: `Before we proceed, we have to confirm you are you.`,
    hint_message: "Choose which ID you'd like to provide.",
  },
  name_input_screen: {
    title: 'What is your full name?',
    given_name_label: 'First Name',
    family_name_label: 'Last Name',
  },
  date_of_birth_input_screen: {
    title: 'What is your date of birth?',
    minimum_age_error_label: 'To be eligible for a quote, you must be {0} years or older.',
  },
  current_address_screen: {
    title: 'Where do you live?',
  },
  review_details_screen: {
    title: 'Take a moment to check your details',
    submit_button_cta: 'Verify my ID',
  },
  credit_header_failure_screen: {
    title: 'Welcome! Just a few things before we start',
  },
  drivers_licence_input_screen: {
    title: 'What are your driver’s licence details?',
    state_screen_title: "Where was your driver's licence issued?",
  },
  success_screen: {
    title: 'ID successfully verified',
    subtitle: 'Your identity has been successfully verified.',
    credit_header_title: '',
    credit_header_description_p_1: '',
    credit_header_description_p_2: '',
    credit_header_description_p_3: '',
  },
  pending_screen: {
    title: 'ID successfully verified',
    innner_p_1: 'Your identity has been successfully verified',
    innner_p_2: '',
  },
};

export const FRANKIE_CONFIGURATION_AU = {
  documentTypes: ['DRIVERS_LICENCE', { type: 'PASSPORT', acceptedCountries: 'ALL', idExpiry: true }],
  maxAttemptCount,
  progressBar: false,
  checkProfile: process.env['NEXT_PUBLIC_FRANKIEONE_RECIPE'],
  googleAPIKey: process.env['NEXT_PUBLIC_FRANKIEONE_GOOGLE_API_KEY'],
  consentText: newConsentTextAU,
  requestAddress: { acceptedCountries: [AU_COUNTRY_CODE] },
  idScanVerification: false,
  welcomeScreen: false,
  failureScreen: false,
  injectedCss: FrankieSmartUIStyle,
  phrases,
  ageRange,
  disableThirdPartyAnalytics,
  enableDeviceCharacteristics: true,
};

export const PHRASES_AU = {
  ...FRANKIE_CONFIGURATION_AU.phrases,
  review_details_screen: {
    title: 'Take a moment to check your details',
    submit_button_cta: 'I confirm',
  },
};

export const NEW_FRANKIE_CONFIGURATION_AU = {
  ...FRANKIE_CONFIGURATION_AU,
  phrases: PHRASES_AU,
};

export const FRANKIE_CONFIGURATION_NZ = {
  documentTypes: [
    { type: 'DRIVERS_LICENCE', acceptedCountries: [NZ_COUNTRY_CODE] },
    { type: 'PASSPORT', acceptedCountries: [NZ_COUNTRY_CODE], idExpiry: true },
  ],
  maxAttemptCount,
  progressBar: false,
  checkProfile: process.env['NEXT_PUBLIC_FRANKIEONE_RECIPE_NZ'],
  googleAPIKey: process.env['NEXT_PUBLIC_FRANKIEONE_GOOGLE_API_KEY'],
  consentText: consentTextNZ,
  requestAddress: { acceptedCountries: [NZ_COUNTRY_CODE] },
  idScanVerification: false,
  welcomeScreen: false,
  failureScreen: false,
  injectedCss: FrankieSmartUIStyle,
  phrases,
  ageRange,
  disableThirdPartyAnalytics,
  enableDeviceCharacteristics: true,
};
