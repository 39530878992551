import React from 'react';
import { PreQualifiedQuoteDetails } from '@harmoney/api-interfaces';
import { Badge, Card, Divider, IconV2 } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import classNames from 'classnames';

import { getBenefitsList } from './utils';

type Props = {
  data: PreQualifiedQuoteDetails;
};

export const SimplifiedPreQualifiedQuoteCard = ({ data }: Props) => {
  const { loanAmount } = data;
  const benefits = getBenefitsList();

  return (
    <Card className="relative mt-8 sm:mt-6 w-full !px-0">
      <div className="px-6 py-1">
        <Badge label="Approximate" className="px-1 py-0 text-sm mb-3" variant="tertiary" />
        <small className="font-medium text-sm block">Borrow up to</small>
        <h3 className="font-bold text-3xl mb-2">{formatCurrency(loanAmount)}</h3>
        <div className="text-grey-4 text-sm text-balance">
          <span>Complete our form to finalise your quote.</span>
        </div>
      </div>
      <Divider className="text-grey-2 mt-4" />
      <div className="p-6 pb-2">
        <h4 className="font-semibold text-h4 mb-4">Your next steps</h4>
        {benefits.map((benefit, index) => (
          <div
            key={benefit.title}
            className={classNames('flex flex-row items-center gap-2 text-sm', {
              'mb-3': index !== benefits.length - 1,
            })}
          >
            <IconV2 icon={benefit.icon} className="size-6" />
            <span>{benefit.title}</span>
          </div>
        ))}
      </div>
    </Card>
  );
};
