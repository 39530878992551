import { LoanVariationPurposeEnum } from '@prisma/client';

export type LoanVariationSimulationDto = {
  loanVariationType: string;
  contractualPaymentDate?: string;
  loanTerm?: string;
  purpose: LoanVariationPurposeEnum;
  otherPurpose?: string;
};

export enum LoanVariationFlag {
  DIRECT_DEBIT_SUSPEND_FLAG = 'DIRECT_DEBIT_SUSPEND_FLAG',
  INTEREST_ACCRUED_PAUSE_FLAG = 'INTEREST_ACCRUED_PAUSE_FLAG',
  REPAYMENT_HOLIDAY_FLAG = 'REPAYMENT_HOLIDAY_FLAG',
  SCHEDULE_EXTRA_PAYMENT_FLAG = 'SCHEDULE_EXTRA_PAYMENT_FLAG',
  UPDATE_DIRECT_DEBIT_FLAG = 'UPDATE_DIRECT_DEBIT_FLAG',
  PAYMENT_ARRANGEMENT_FLAG = 'PAYMENT_ARRANGEMENT_FLAG',
}
