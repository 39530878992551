import { browserName, osName, browserVersion, getUA, deviceDetect, isMobile } from 'react-device-detect';

interface Cookies {
  [key: string]: string;
}

const AcceptedCookies = [
  '_fbc',
  '_fbp',
  '_ga',
  '_gid',
  '_ga_3XCM42S995',
  '_ga_WSYQB65YZ4',
  '_ga_QZP7QGLF61',
  '_ga_VX88TQK84F',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'hmy_gclid',
];

export class Metadata {
  private device = deviceDetect(getUA);

  get() {
    return {
      TIME: new Date().toISOString(),
      'RESOURCE ADDRESS': window.location.origin + window.location.pathname,
      'FULL URL': window.location.href,
      'REFERRER URL': window.document.referrer,
      COOKIES: this.cookies(),
      DEVICE: isMobile
        ? `mobile - ${this.device.vendor} - ${this.device.model}`
        : `${this.device.osName} - ${this.device.osVersion}`,
      OS: osName,
      BROWSER: browserName,
      'BROWSER VERSION': browserVersion,
      'SCREEN WIDTH': window.screen.width,
      'SCREEN HEIGHT': window.screen.height,
    };
  }

  private cookies(): Cookies {
    const cookies: string = window.document.cookie;
    return cookies
      .split(/\s*;\s*/)
      .filter((cookie: string) => {
        return AcceptedCookies.includes(cookie.split('=')[0]);
      })
      .reduce((result: Cookies, cookie) => {
        const match = cookie.match(/([^=]+)=(.*)/);
        if (match) {
          const name = match[1].trim();
          const value = match[2].trim();
          result[name] = value;
        }
        return result;
      }, {} as Cookies);
  }
}
