import React, { useEffect } from 'react';
import type { MonoovaBSBValidationOutputDto } from '@harmoney/api-interfaces';
import { useBsbSearch } from '@harmoney/hooks';
import { Checkbox, ControlledInput, Spinner, useFormContext } from '@harmoney/ui-design-system';
import { accountNumberInputFormat, bsbInputFormat } from '@harmoney/utilities';
import { Icon } from '@iconify/react';

type Props = {
  registerFieldPrefix?: string;
  onBankDetailsChanged?: (bankDetails?: MonoovaBSBValidationOutputDto) => void | Promise<void>;

  onCheckProofOfOwnership?: (checked: boolean) => void | Promise<void>;
};

export default function NewBankAccountSection({
  onBankDetailsChanged,
  onCheckProofOfOwnership,
  registerFieldPrefix,
}: Props) {
  const registerPrefix = registerFieldPrefix ? `${registerFieldPrefix}.` : '';

  const { register, setError, clearErrors, watch } = useFormContext();

  const { invalidBsb, isLoadingBsb, bsbBankDetails, setBsbInputValue } = useBsbSearch({
    onBankDetailsChanged,
    onError(message) {
      const fieldName = `${registerPrefix}newBankAccount.accountBsb`;
      if (!message) clearErrors(fieldName);
      setError(fieldName, { message });
    },
  });

  useEffect(() => {
    const hasSuppliedOwnership = watch(`${registerPrefix}newBankAccount.hasSuppliedProofOfAccountOwnership`);
    onCheckProofOfOwnership?.(hasSuppliedOwnership);
  }, [onCheckProofOfOwnership, registerPrefix, watch]);

  return (
    <div className="grid grid-cols-1 gap-4">
      <ControlledInput type="text" label="Account name" {...register(`${registerPrefix}newBankAccount.accountName`)} />
      <ControlledInput
        helpText="6 digit number"
        type="string"
        minLength={6}
        pattern={bsbInputFormat.source}
        label="BSB number"
        suffix={
          isLoadingBsb ? (
            <Spinner />
          ) : bsbBankDetails ? (
            bsbBankDetails?.bankCode
          ) : invalidBsb ? (
            <Icon fontSize="1.2rem" icon="mingcute:warning-line" />
          ) : (
            ''
          )
        }
        onInput={(e) => {
          setBsbInputValue((e.target as HTMLInputElement).value);
        }}
        {...register(`${registerPrefix}newBankAccount.accountBsb`)}
      />
      <ControlledInput
        helpText="4-9 digit number"
        type="text"
        minLength={4}
        pattern={accountNumberInputFormat.source}
        label="Account number"
        {...register(`${registerPrefix}newBankAccount.accountNumber`)}
      />
      <Checkbox
        {...register(`${registerPrefix}newBankAccount.hasSuppliedProofOfAccountOwnership`)}
        label="Customer has supplied proof of account ownership"
        gap="gap-x-2"
      />
    </div>
  );
}
