import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Role, UserDto } from '@harmoney/api-interfaces';
import { useUtm } from '@harmoney/hooks';
import { RootState, useAppSelector, useGetUserWithConfigQuery, useSaveUtmsMutation } from '@harmoney/redux';
import { CUSTOMER_ACCOUNT_SIGN_IN, eventAnalytics } from '@harmoney/ui-app-shell';
import { Spinner } from '@harmoney/ui-design-system';
import * as Sentry from '@sentry/react';

import { useAuth } from '../../hooks/use-auth';
import { setAccessToken } from '../../redux/slice/access-token';
import { setUserId } from '../../redux/slice/user-id';

export function withAuth(WrappedComponent) {
  const AuthComponent = (props) => {
    const { login, isLoading, isAuthenticated, user, getUserRoles, getUserToken } = useAuth();
    const accessToken = useAppSelector((state) => state.accessToken.value);
    const userId = useSelector((state: RootState) => state.userId.value);
    const router = useRouter();
    const utms = useUtm();
    const dispatch = useDispatch();

    const [saveUtms] = useSaveUtmsMutation();
    const { data: userData } = useGetUserWithConfigQuery(null, {
      refetchOnMountOrArgChange: true,
      skip: !accessToken,
    });
    const roles = getUserRoles(user);
    const isAdmin = roles.includes(Role.ADMIN);

    const identifyUser = useCallback(
      ({ id: userId, branch, email, preferredName: name, intercomUserHash, s2Id }: UserDto & { s2Id: string }) => {
        const userHash = !isAdmin ? intercomUserHash : null;
        Sentry.setUser({
          userId,
        });
        eventAnalytics.identify(
          s2Id,
          {
            name,
            email,
            platform: 's2',
            branch,
          },
          {
            Intercom: { user_hash: userHash },
            gtmOptions: { event: CUSTOMER_ACCOUNT_SIGN_IN },
          }
        );
      },
      [isAdmin]
    );

    useEffect(() => {
      if (isLoading) {
        return;
      }
      if (!isAuthenticated) {
        login();
        return;
      }
      getUserToken()
        // eslint-disable-next-line promise/always-return
        .then((token) => {
          dispatch(setAccessToken(token.access_token));
        })
        .catch(() => {
          login();
        });
    }, [isAuthenticated, isLoading, dispatch, login, getUserToken]);

    useEffect(() => {
      if (isAuthenticated && !userId && accessToken && userData) {
        dispatch(setUserId(userData?.id));
        identifyUser({ ...userData, s2Id: user['https://stellare.harmoney.com/s2-account-id'] });
        if (utms?.length && saveUtms) {
          saveUtms(utms);
        }
      }
    }, [isAuthenticated, dispatch, userId, accessToken, user, userData, identifyUser, saveUtms, utms]);

    useEffect(() => {
      if (!isAuthenticated || !user || !getUserRoles) return;

      if (!isAdmin) {
        eventAnalytics.loadFullStory();

        if (router.pathname.includes('admin')) {
          router.push('/');
        }
      }
    }, [user, getUserRoles, router, isAuthenticated, isAdmin]);

    if (isLoading || !isAuthenticated) {
      return <Spinner size="large" />;
    }

    return <WrappedComponent {...props} />;
  };

  AuthComponent.getLayout = WrappedComponent.getLayout;

  return AuthComponent;
}
