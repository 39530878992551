import { useAuth } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button, Card } from '@harmoney/ui-design-system';
interface Props {
  email: string;
}

export const ExistingCustomerBlock = ({ email }: Props) => {
  const { login } = useAuth();

  const handleClick = async () => {
    await login({
      authorizationParams: {
        login_hint: email,
        screen_hint: 'signin',
      },
      appState: { returnTo: `${window.location.origin}/` },
    });
  };
  return (
    <>
      <h1 className="mt-6 font-bold">
        <span className="text-primary">Quick quote</span> coming soon!
      </h1>
      <Card className="!px-4">
        <p className="font-medium">Thank you for your interest in our quick quote feature!</p>
        <p className="text-sm">
          This feature is currently being rolled out to a limited group of customers as part of our initial testing
          phase.
        </p>
        <p className="text-sm">We&rsquo;re excited to get it into your hands as soon as possible.</p>
      </Card>
      <div className="flex justify-center">
        <Button
          type="submit"
          variant="primary"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          onClick={handleClick}
        >
          Sign in
        </Button>
      </div>
    </>
  );
};
