import { truncateToTwoDecimalPlaces } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { Prisma } from '@prisma/client';

import { Field } from './../shared';

interface Props {
  affordabilityData: Prisma.JsonValue;
}

const PartnerShareExpensesDetails = ({ affordabilityData }: Props) => {
  const finalPartnerShareExpense = affordabilityData?.['sharedExpenseResult']?.['finalPartnerShareExpense'];
  const ninetyPercentOfPartnerIncome = affordabilityData?.['sharedExpenseResult']?.['ninetyPercentOfPartnerIncome'];
  const partnerShareRatio = affordabilityData?.['sharedExpenseResult']?.['partnerShareRatio'];
  const partnerExpensesContribution = affordabilityData?.['sharedExpenseResult']?.['partnerExpensesContribution'];

  return (
    <div className="px-4">
      <p className="mb-4 font-medium">Shared expenses</p>
      <div className="grid grid-cols-2 odd:border-r-grey-1">
        <div className="space-y-1">
          <Field className="space-x-4">
            <span>Partner share ratio</span>
            <span>
              {typeof partnerShareRatio === 'number'
                ? `${truncateToTwoDecimalPlaces(Number(partnerShareRatio) * 100)}%`
                : 'N/A'}
            </span>
          </Field>
          <Field className="space-x-4">
            <span>90% of partner income</span>
            <span>
              {typeof ninetyPercentOfPartnerIncome === 'number'
                ? formatCurrency(truncateToTwoDecimalPlaces(ninetyPercentOfPartnerIncome))
                : 'N/A'}
            </span>
          </Field>
          <Field className="space-x-4">
            <span>Partner&apos;s contribution towards expenses</span>
            <span>
              {typeof partnerExpensesContribution === 'number'
                ? formatCurrency(truncateToTwoDecimalPlaces(partnerExpensesContribution))
                : 'N/A'}
            </span>
          </Field>
        </div>
        <div className="space-y-1">
          <Field className="space-x-4">
            <span>Final partner share</span>
            <span>
              {typeof finalPartnerShareExpense === 'number'
                ? formatCurrency(truncateToTwoDecimalPlaces(finalPartnerShareExpense))
                : 'N/A'}
            </span>
          </Field>
        </div>
      </div>
    </div>
  );
};

export default PartnerShareExpensesDetails;
