import { CreateUtmsRequestPayload } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const marketingAttributionApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    saveUtms: builder.mutation<any, CreateUtmsRequestPayload>({
      query: (createUtmsRequestPayload) => ({
        url: `/marketing-attribution/utms`,
        method: 'POST',
        body: createUtmsRequestPayload,
      }),
    }),
  }),
});

export const { useSaveUtmsMutation } = marketingAttributionApi;
