import { useEffect } from 'react';
import { omit } from 'lodash';

import { useGetDashboardProgressByLoanApplicationIdQuery, useGetVariablesQuery } from '../redux/services';
import { useAppSelector } from '../redux/store';

export const useApplicationProgress = (taskId: string) => {
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const userId = useAppSelector((state) => state.userId.value);
  const { data: variables } = useGetVariablesQuery(taskId, {
    skip: !accessToken || !taskId,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const { data: userInstancesData, refetch } = useGetDashboardProgressByLoanApplicationIdQuery(
    { loanApplicationId: variables?.loanApplicationId as string },
    { skip: !userId || !variables?.loanApplicationId || !accessToken, refetchOnMountOrArgChange: true }
  );

  // when taskID changes, refetch the userInstancesData
  useEffect(() => {
    userInstancesData && refetch();
  }, [taskId, userInstancesData]);

  return {
    progress: omit(userInstancesData, ['applicationStatus']),
    loanApplicationStatus: userInstancesData?.applicationStatus,
  };
};
