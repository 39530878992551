import { getDayjsInTimezoneByBranch } from '@harmoney/utilities';
import { BranchEnum } from '@prisma/client';

export function isWithinCutOffTime(branch: BranchEnum) {
  const timeZone = getDayjsInTimezoneByBranch(branch);
  const hour = timeZone.hour();
  const withinCutOffTime = hour >= 0 && hour < 22;

  return withinCutOffTime;
}
