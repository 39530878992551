import { renderToStaticMarkup } from 'react-dom/server';
import Html from 'react-pdf-html';
import { formatCurrency, isDebtCon } from '@harmoney/utilities';
import { Document, Font, Page, StyleSheet } from '@react-pdf/renderer';
import classNames from 'classnames';
import { capitalize } from 'lodash';

import { EMPTY_HTML, ENABLE_SECURED_LOAN_IN_LOAN_DOCS } from './constants';

interface LoanDisclosureTemplateProps {
  withHtml?: boolean;
  fonts?: any;
  data: any;
}

export const LoanDisclosureTemplateNZ = ({ data, fonts, withHtml = true }: LoanDisclosureTemplateProps) => {
  Font.register({
    family: 'GothamHTF-Bold',
    src: fonts?.gothamBold?.url,
  });
  Font.register({ family: 'GothamHTF-Book', src: fonts?.gothamBook?.url });
  Font.registerHyphenationCallback((word) => [word]);

  const styles = StyleSheet.create({
    page: {
      padding: 35,
    },
  });

  const paddingValue = withHtml ? 0 : 20;
  const fontSize = withHtml ? 12 : 14;

  const getPaymentsTable = (allPaymentDetails: any[]) => {
    const jsx = [];
    let totalAmount = 0;

    const labels = allPaymentDetails[0].map(({ label }: any) => label);
    jsx.push(
      <tr>
        {labels.map((label: string) => {
          return (
            <td>
              <p className="bold">{label}</p>
            </td>
          );
        })}
      </tr>
    );

    allPaymentDetails.forEach((dataSet: any[]) => {
      jsx.push(
        <tr>
          {dataSet.map(({ label, value }: any) => {
            if (label === 'Amount:') {
              totalAmount += parseInt(value, 10) || 0;
            }
            return (
              <td>
                <p
                  className={classNames({
                    bold: label === 'Amount:',
                  })}
                >
                  {label === 'Amount:' ? formatCurrency(value) : value}
                </p>
              </td>
            );
          })}
        </tr>
      );

      !withHtml && jsx.push(<p style={{ fontSize: 1, margin: 0 }}>&nbsp;</p>);
    });

    jsx.push(
      <tr>
        <td>
          <p>
            <b>Total Amount:</b>
          </p>
        </td>
        <td />
        <td />
        <td>
          <p className="bold">{formatCurrency(totalAmount)}</p>
        </td>
      </tr>
    );

    const htmlString = renderToStaticMarkup(
      <>
        {allPaymentDetails.map((apd) => {
          return (
            <div className="payment-details-container">
              <table className="payment-details-table">
                {apd.map((paymentDetail: any) => (
                  <tr className="payment-details-row">
                    <td>
                      <p>{paymentDetail.label}</p>
                    </td>
                    <td className="text-right">
                      <p>
                        <span className="bold">
                          {paymentDetail.label === 'Amount:'
                            ? formatCurrency(paymentDetail.value)
                            : paymentDetail.value}
                        </span>
                      </p>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          );
        })}
      </>
    );
    return htmlString;
  };

  const getLoanDetailsTable = (data: any) => {
    return renderToStaticMarkup(
      <table className="payment-details-table">
        <tr className="payment-details-row">
          <td>
            <p>
              <span className="bold">Loan amount:</span>
            </p>
          </td>
          <td className="text-right">
            <p>
              <span className="bold">{formatCurrency(data?.amount)}</span>
            </p>
          </td>
        </tr>
        <tr className="payment-details-row">
          <td style={{ width: '100%' }}>
            <p>
              This is the amount you owe to Harmoney Lender as at the Date of Advance, including fees charged on that
              date.
            </p>
            <p>
              <span className="bold">This amount is made up of:</span>
            </p>
          </td>
        </tr>
        <tr className="payment-details-row">
          <td>
            <p>Amount credited to your bank account: </p>
          </td>
          <td className="text-right">
            <p>
              <span className="bold">
                {formatCurrency(data?.fundedAmountBreakdown.amountCreditedToCustomerBankAccount)}
              </span>
            </p>
          </td>
        </tr>
        <tr className="payment-details-row">
          <td>
            <p>Establishment fee: </p>
          </td>
          <td className="text-right">
            <p>
              <span className="bold">{formatCurrency(data?.establishmentFee)}</span>
            </p>
          </td>
        </tr>
        {data?.loanProduct && isDebtCon(data?.loanProduct) && (
          <tr className="payment-details-row">
            <td>
              <p>Amount paid to repay your existing debt:</p>
            </td>
            <td className="text-right">
              <p>
                <span className="bold">{formatCurrency(data?.fundedAmountBreakdown.amountPayableToDebts)}</span>
              </p>
            </td>
          </tr>
        )}
      </table>
    );
  };

  const getRequiredPaymentsTable = (data: any) => {
    return renderToStaticMarkup(
      <table className="payment-details-table">
        <tr className="payment-details-row">
          <td>
            <p>Frequency:</p>
          </td>
          <td className="text-right">
            <p>
              <span className="bold">{capitalize(data?.repaymentFrequency)}</span>
            </p>
          </td>
        </tr>
        <tr className="payment-details-row">
          <td>
            <p>Amount of each payment (except final payment):</p>
          </td>
          <td className="text-right">
            <p>
              <span className="bold">{formatCurrency(data?.recurringRepayment)}</span>
            </p>
          </td>
        </tr>
        <tr className="payment-details-row">
          <td>
            <p>Amount of final payment:</p>
          </td>
          <td className="text-right">
            <p>
              <span className="bold">{formatCurrency(data?.lastRepayment)}</span>
            </p>
          </td>
        </tr>
        <tr className="payment-details-row">
          <td>
            <p>Total number of payments:</p>
          </td>
          <td className="text-right">
            <p>
              <span className="bold">{data?.numberOfRepayments}</span>
            </p>
          </td>
        </tr>
        <tr className="payment-details-row">
          <td>
            <p>Total amount of payments:</p>
          </td>
          <td className="text-right">
            <p>
              <span className="bold">{formatCurrency(data?.totalAmountOfRepayments)}</span>
            </p>
          </td>
        </tr>
      </table>
    );
  };

  const styleElement = `<style>
    .loan-doc-container p {
      font-family: ${withHtml ? 'GothamHTF-Book' : ''};
    }

    .logo {
      width: 150px;
      height: auto;
    }

    .text-right {
      text-align: right;
    }

    h1.bold {
      font-size: 30px;
    }

    .bold,
    .bold p {
      font-family: ${withHtml ? 'GothamHTF-Bold' : ''};
      ${withHtml ? '' : 'font-weight: 600;'}
    }

    .text-center {
      text-align: center;
    }

    table.table-container {
      width: 100%;
    }

    ol {
      list-style: auto;
      font-size: 14px;
      padding-left: ${paddingValue}px;
    }

    ul {
      list-style: disc;
      max-width: 95%;
      padding-left: ${paddingValue}px;
      margin: 14px 0;
    }

    .section {
      border-top: 1px solid #DBDBDB;
    }

    .section > td {
      padding: 20px 4px;
    }

    p {
      font-size: ${fontSize}px;
      margin: 0 0 12px 0;
      padding: 0;
    }

    .secured-loan-text-container {
      background: #F6F9FE;
      border-radius: 12px;
      padding: 16px;
      margin: 16px 0;
    }

    .payment-details-container {
      background: #F6F9FE;
      border-radius: 12px;
      padding: 8px;
      margin: 8px 0;
    }

    .payment-details-table,
    .required-payments-table {
      width: 100%;
    }

    .payment-details-row {
      width: 100%;
    }

    .payment-details-row td {
      padding: 4px !important;
      width: 50%;
    }

    @media screen and (max-width: 700px) {
      table {
        display: block;
      }
      .logo {
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  </style>`;

  const templateString = `
    <html>
    ${styleElement}
      <body>
        <div class="loan-doc-container">
          <div class="logo">
            <img
              src="https://production-harmoney-cms-shared-assets.s3.ap-southeast-2.amazonaws.com/public/Logos/hmy-logo.png"
              alt="Company Logo"
            />
          </div>
          <br />

          <h1 class="bold">Loan Disclosure Statement</h1>
          <br />
          <p>Version: <span class="bold">NZ-S2-2025-1</span></p>

          <table class="table-container">
            <tbody>
              <tr>
                <td>
                  <p>Loan number: <span class="bold">${data?.loanNumber}</span></p>
                </td>
                <td>
                  <p>Date: <span class="bold">${data?.disclosureDate}</span></p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p><span class="bold">Important</span></p>
          <p>
            This document sets out key information about your Loan Contract. You should read it
            thoroughly. If you do not understand anything in this document, you should seek independent advice.
            You should keep this disclosure statement and a copy of your Loan Contract in a safe place.
          </p>
          <p>
            The law gives you a limited right to cancel your Loan Contract (see below for further details). Note that
            strict time limits apply.
          </p>
          ${
            ENABLE_SECURED_LOAN_IN_LOAN_DOCS
              ? `
            <div class="secured-loan-text-container">
              <p><span class="bold">Important</span></p>
              <p>
                If you have elected for your Loan to be a Secured Loan, the annual secured interest rate
                (set out in the “Interest” section below) will apply provided that you comply with the Secured Loan
                Conditions set out in the Loan Contract. If you do not comply with those Secured Loan Conditions
                within the time specified, the interest rate will change to the annual unsecured interest rate with effect
                from the date 60 days after the Date of Advance (set out in the “Interest” section below).
              </p>
            </div>
          `
              : EMPTY_HTML
          }

          <table>
            <tr class="section">
              <td>
                <p><span class="bold">Borrower Details</span></p>
                <p>Name: ${data?.fullName} <span class="bold">("you")</span></p>
                <p>Address: ${data?.address}</p>
                <p>Email: ${data?.email}</p>
              </td>
            </tr>

            <tr class="section">
              <td>
                <p><span class="bold">Creditor details</span></p>
                <p>Name: Harmoney Services Limited <span class="bold">("Harmoney Lender")</span></p>
                <p>Harmoney Services Limited as provider of all platform services <span class="bold">("Harmoney
                Servicer")</span></p>
                <p>Harmoney Lender and Harmoney Servicer are referred to as <span class="bold">"we", "us"</span>
                and <span class="bold">"our".</span></p>
                <p>Our trading name: Harmoney</p>
                <p>Physical Address: Level 3, 110 Customs Street West, Auckland CBD, 1010</p>
                <p>Postal Address: PO Box 106-507, Customs Street East, Auckland, 1143</p>
                <p>Email: <a>customerservice@harmoney.co.nz</a></p>
                <p>You may send notices to us by:</p>
                <ul>
                  <li>
                    <p>writing to the postal address specified above; or</p>
                  </li>
                  <li>
                    <p>sending an email to the email address specified above.</p>
                  </li>
                </ul>
              </td>
            </tr>

            <tr class="section">
              <td>
                <p><span class="bold">Loan contract</span></p>
                <p>The terms and conditions of your Loan Contract are set out in this document, together with the Vehicle Details Form (if applicable), and the Loan Contract Terms document that has also been provided to you. Terms defined in the Loan Contract Terms document have the same meanings where used in this document.</p>
              </td>
            </tr>

            <tr class="section">
              <td>
                <p><span class="bold">Loan details</span></p>
                <p>
                  The loan amount (specified below) is lent to you by Harmoney Lender when our
                  bank is requested to pay out that amount <span class="bold">("Date of Advance")</span>. The Date of
                  Advance may not be the same date as the date that the funds are received in
                  cleared funds by you or any other payee.
                </p>
                <p>
                  This document has been prepared in the expectation that the Date of Advance will
                  be the same as the effective date of your Loan Contract. However, the Date of
                  Advance may not be the same date as the effective date of your Loan Contract
                  (for example, for systems, processing, or other reasons). If the Date of Advance is
                  not the same date as the effective date of your Loan Contract, we will notify you
                  of the actual Date of Advance and any changes to any amounts payable under
                  your Loan Contract due to the Date of Advance falling on a different date.
                </p>
                <div class="payment-details-container">
                  ${getLoanDetailsTable(data)}
                </div>
                <br />
                <p>
                  This amount is also the total amount to be lent to you under your Loan Contract,
                  assuming that you make your required payments when due and no other changes
                  are made to your Loan Contract.
                </p>
                <p>
                  You do not owe any amount to Harmoney Lender until the Date of Advance.
                </p>
              </td>
            </tr>


            ${
              ENABLE_SECURED_LOAN_IN_LOAN_DOCS
                ? `
            <tr class="section">
              <td>
                <p><span class="bold">Purpose</span></p>
                <p>
                  If you have elected for your Loan to be a Secured Loan, the Loan proceeds must
                  be used towards the purchase price of a vehicle. You will have 60 days from the
                  Date of Advance to purchase a vehicle, however upon purchase you must
                  immediately satisfy the Secured Loan Conditions.
                </p>
                <p>
                  Harmoney Lender agrees to lend the Loan amount to you at the annual secured
                  interest rate on the condition (among other things) that you grant a security
                  interest in the Vehicle to Harmoney Lender.
                </p>
                <p>
                  If you have not purchased a Vehicle within 60 days of the Date of Advance of
                  your Loan, you must notify Harmoney Lender, and advise Harmoney Lender how
                  the Loan proceeds have otherwise been, or may be, used.
                </p>
              </td>
            </tr>
            `
                : EMPTY_HTML
            }

            <tr class="section">
              <td>
                <p><span class="bold">Interest</span></p>
                <p>
                  <span class="bold">Annual ${ENABLE_SECURED_LOAN_IN_LOAN_DOCS ? `secured` : EMPTY_HTML} interest rate: ${data?.interestRate}% per annum</span>
                </p>
                <p>
                  Fixed for the whole term of your Loan Contract, being ${data?.loanTerm} months.
                </p>
                ${
                  ENABLE_SECURED_LOAN_IN_LOAN_DOCS
                    ? `
                  <div class="secured-loan-text-container">
                    <p><span class="bold">Important</span></p>
                    <p>
                      If you have elected for your Loan to be a Secured Loan, the above
                      interest rate will apply provided that you comply with the Secured Loan
                      Conditions. If you do not comply with the Secured Loan Conditions, the interest
                      rate will change to a rate of [XX]% per annum (the “annual unsecured interest
                      rate”) for the remaining term of your Loan Contract, with effect from the date 60
                      days after the Date of Advance. We will send you a letter explaining the
                      consequential changes to your payments under your Loan Contract.
                    </p>
                  </div>
                `
                    : EMPTY_HTML
                }
                <p>
                  <span class="bold">Total interest charges: ${formatCurrency(data?.totalInterestPayable)}</span>
                </p>
                <p>
                  This is the total amount of the interest charges payable under your Loan Contract,
                  calculated on the basis of the annual ${ENABLE_SECURED_LOAN_IN_LOAN_DOCS ? `secured` : EMPTY_HTML} interest rate.
                </p>
                ${
                  ENABLE_SECURED_LOAN_IN_LOAN_DOCS
                    ? `
                  <div class="secured-loan-text-container">
                    <p><span class="bold">Important</span></p>
                    <p>
                      If you have elected for your Loan to be a Secured Loan and you do
                      not comply with the Secured Loan Conditions, the total interest charges
                      applicable to your Loan will be higher, with effect from the date 60 days after the
                      Date of Advance. We will send you a letter explaining the consequential changes
                      to your payments under your Loan Contract.
                    </p>
                  </div>
                `
                    : EMPTY_HTML
                }
                <p><span class="bold">Method of charging interest</span></p>
                <p>
                  Interest charges are calculated by multiplying the unpaid balance at the end of the
                  day by a daily interest rate. The daily interest rate is calculated by dividing the
                  annual interest rate by 365 (or 366 in a leap year). Interest is charged and payable
                  monthly in arrears as part of your required payments (specified in the "Required
                  payments" section below).
                </p>
              </td>
            </tr>

            <tr class="section">
              <td>
                <p><span class="bold">Required payments</span></p>
                <p>You must make the following payments, which comprise principal and interest:</p>
                <div style="margin-bottom:16px;">
                  ${getRequiredPaymentsTable(data)}
                </div>
                ${
                  ENABLE_SECURED_LOAN_IN_LOAN_DOCS
                    ? `
                  <br />
                  <div class="secured-loan-text-container">
                    <p><span class="bold">Important</span></p>
                    <p>
                      If you have elected for your Loan to be a Secured Loan and you do
                      not comply with the Secured Loan Conditions, the required payments applicable
                      to your Loan will be different, with effect from the date 60 days after the Date of
                      Advance. We will send you a letter explaining the consequential changes to your
                      payments under your Loan Contract.
                    </p>
                  </div>
                `
                    : EMPTY_HTML
                }
                <p>
                  Your first payment is due to Harmoney Lender one month after the Date of
                  Advance. Each required payment will be direct debited from your Nominated
                  Account on the date it is due (unless otherwise agreed).
                </p>
                <p>
                  You may select a direct debit start date and frequency that suits you, provided
                  that each required payment is paid in full by its due date. If you choose at any
                  time to make direct debit payments more frequently than the due date each
                  month:
                </p>
                <ul>
                  <li>
                    <p>
                      these are prepayments under your Loan Contract, which means that
                      there is no formal variation to your required monthly payments and
                      you can choose to change your direct debit frequency again at any
                      time (provided that each required payment is paid in full by its due
                      date); and
                    </p>
                  </li>
                  <li>
                    <p>
                      like any prepayment, the total amount of the interest charges payable
                      under your Loan Contract (and therefore the total amount of
                      payments) may be less, because these prepayments reduce the
                      unpaid balance of your Loan earlier each month.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>

            <tr class="section">
              <td>
                <p><span class="bold">Fees and charges</span></p>
                <p>As at the date of this document, no fees or charges are payable to Harmoney
                Lender under, or in connection with, your Loan Contract except for:</p>
                <ul>
                  <li>
                    <p>
                    the fees that are included in the loan amount (specified in the "Loan
                    details" section above);
                    </p>
                  </li>
                  <li>
                    <p>
                    interest charges (described in the "Interest" section above); and
                    </p>
                  </li>
                  <li>
                    <p>
                    any default fees and charges payable in the event of a breach of your
                    Loan Contract or on the enforcement of your Loan Contract (described
                    in the "What could happen if you fail to meet your commitments"
                    section below).
                    </p>
                  </li>
                </ul>
                <p style="margin-top: 12px;">
                Information about fees and charges is provided with your Loan Contract and can
                also be found on the <a target="__blank" href="https://www.harmoney.co.nz/personal-loans/interest-rates-and-fees">Website</a>. Fees and
                charges can be varied, or new fees and charges introduced, in accordance with
                your Loan Contract.
                </p>
              </td>
            </tr>
          </table>

          <div class="section" style="padding: 20px 0px;">
            <p><span class="bold">Loan payment instructions</span></p>
            <p>You instruct and authorise payment of the loan amount (specified in the "Loan
            details" section above) on the Date of Advance as follows:</p>
            ${getPaymentsTable(data?.payments)}
          </div>

          <table>
            <tr class="section">
              <td>
                <p><span class="bold">Continuing Disclosure</span></p>
                <p>You consent to obtaining ongoing disclosure information required by law about
                your Loan online. Such information can be accessed by logging on to the
                Website. You consent to receiving all other notices, documents and
                communications relating to your Loan in electronic form and by electronic means
                and you agree that (subject to applicable legal requirements) we may use any
                electronic message address specified by you or we may communicate with you
                through the Website (including the dashboard) for that purpose.</p>
              </td>
            </tr>

            ${
              ENABLE_SECURED_LOAN_IN_LOAN_DOCS
                ? `
            <tr class="section">
              <td>
                <p><span class="bold">Security</span></p>
                <p>If you have elected for your Loan to be a Secured Loan, then immediately upon
                the purchase of the Vehicle:</p>
                <ul>
                  <li>
                    <p>you grant Harmoney Lender a first-ranking security interest in the
                    Vehicle. That security interest secures the payment of all money that
                    you owe Harmoney Lender, and the performance of all your
                    obligations to Harmoney Lender, under your Loan Contract; and</p>
                  </li>
                  <li>
                    <p>the Secured Loan Conditions must be met.</p>
                  </li>
                </ul>
                <br />
                <p>
                If your Loan is unsecured, we do not take any security interest in connection with
                your Loan Contract.
                </p>
              </td>
            </tr>

            <tr class="section">
              <td>
                <p><span class="bold">Secured loan conditions</span></p>
                <p>In addition to this clause, the Loan Contract contains terms and conditions which
                apply to a Secured Loan on an ongoing basis.</p>
                <p>Immediately upon the purchase of a Vehicle (which must occur within 60 days of
                the Date of Advance), You must:</p>
                <ul>
                  <li>
                    <p>complete the Vehicle Details Form and return it to Harmoney Lender.
                    The details you will need to provide are the year, make, model,
                    registration number, vehicle identification number and colour;</p>
                  </li>
                  <li>
                    <p>provide Harmoney Lender with a certificate of insurance for the
                    Vehicle which:</p>
                    <ul>
                    <li>
                      <p>
                        is in your name;
                      </p>
                    </li>
                    <li>
                      <p>
                        is with a reputable insurance company;
                      </p>
                    </li>
                    <li>
                      <p>
                        evidences that there is a comprehensive policy over the Vehicle for
                        the full insurable value of the Vehicle or an agreed value of
                        $10,000 (whichever is higher); and
                      </p>
                    </li>
                    <li>
                      <p>
                        names “Harmoney Services Limited” as an interested party; and
                      </p>
                    </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      do any other things, and provide any other information or documents,
                      that Harmoney Lender reasonably requests.
                    </p>
                  </li>
                </ul>
                <br />
                <p>
                  These are fundamental conditions of your Secured Loan (the <span class="bold">"Secured Loan
                  Conditions"</span>).
                </p>
                <p>
                  The Vehicle described in the Vehicle Details Form will be the asset securing your
                  Loan.
                </p>
                <div class="secured-loan-text-container">
                  <p><span class="bold">Important</span></p>
                  <p>
                    If you do not meet the above Secured Loan Conditions, the interest
                    rate that applies to your Loan will change to the annual unsecured interest rate,
                    with effect from the date 60 days after the Date of Advance. The required
                    payments under your Loan will also change. We will send you a letter explaining
                    the consequential changes under the Loan Contract.
                  </p>
                </div>
              </td>
            </tr>
            `
                : EMPTY_HTML
            }

            <tr class="section">
              <td>
                <p><span class="bold">What could happen if you fail to meet your commitments</span></p>
                <p>
                  <span class="bold">Default interest charges: </span>
                  No additional default interest charges (over and above
                  the annual interest rate charges described in the "Interest" section above) are
                  payable under your Loan Contract.
                </p>
                <p>
                  <span class="bold">Default fees: </span>
                  Additional default fees and charges may become payable to
                  Harmoney Lender in the event of a breach of your Loan Contract or on the
                  enforcement of your Loan Contract. Information about default fees and charges is
                  provided with your Loan Contract and can also be found on the
                  <a target="__blank" href="https://www.harmoney.co.nz/personal-loans/interest-rates-and-fees">Website</a>.
                  These default fees and charges can be varied in accordance with your Loan Contract.
                </p>
                ${
                  ENABLE_SECURED_LOAN_IN_LOAN_DOCS
                    ? `
                <p>
                  <span class="bold">Enforcement of security: </span>
                  If your Loan is a Secured Loan, and you cannot make
                  the required payments under your Loan Contract or do not comply with the
                  requirements of your Loan Contract, Harmoney Lender may exercise its right to
                  repossess and sell the Vehicle. If the sale of the Vehicle does not cover the full
                  amount you owe Harmoney Lender, you will remain liable for any shortfall.
                </p>
                <p>
                In addition to this clause, the Loan Contract contains terms and conditions which
                apply in relation to enforcement of security.
                </p>
              </td>
              `
                    : EMPTY_HTML
                }
            </tr>

            <tr class="section">
              <td>
                <p><span class="bold">Right to cancel</span></p>
                <p style="margin-bottom: 16px;">You are entitled to cancel your Loan Contract by giving notice to us.</p>
                <p><span class="bold">Time limits for cancellation</span></p>
                <p>
                  You must give notice that you intend to cancel your Loan Contract within 7
                  working days of the date on which this document is emailed to you.
                </p>
                <p style="margin-bottom: 16px;">
                  Saturdays, Sundays, and national public holidays are not counted as working
                  days.
                </p>
                <p><span class="bold">How to cancel</span></p>
                <p>
                  To cancel, you must give us written notice that you intend to cancel your Loan
                  Contract by:
                </p>
                <ul>
                  <li>
                    <p>
                      giving notice to us or an employee or agent of ours;
                    </p>
                  </li>
                  <li>
                    <p>
                      posting the notice to us or an agent of ours; or
                    </p>
                  </li>
                  <li>
                    <p>
                      emailing the notice to our email address specified on the front of this
                      document.
                    </p>
                  </li>
                </ul>
                <p style="margin-bottom: 16px;">
                  You must also return to us any advance received by you under your Loan
                  Contract.
                </p>
                <p><span class="bold">What you may have to pay if you cancel</span></p>
                <p>
                  If you cancel your Loan Contract, you may be charged:
                </p>
                <ul>
                  <li>
                    <p>
                      the amount of any reasonable expenses incurred in connection with
                      your Loan Contract and its cancellation (including legal fees and fees
                      for credit reports, etc); and
                    </p>
                  </li>
                  <li>
                    <p>
                      interest for the period from the day you received the advance until the
                      day you repay the advance.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>

            <tr class="section">
              <td>
                <p><span class="bold">What to do if you suffer unforeseen hardship</span></p>
                <p>
                  You may be able to apply to us for a hardship variation if you are unable
                  reasonably to keep up your payments or other obligations because of illness,
                  injury, loss of employment, the end of a relationship, or other reasonable cause.
                </p>
                <p>To apply for a hardship variation, you need to:</p>
                <ul>
                  <li>
                    <p>
                      make an application in writing; and
                    </p>
                  </li>
                  <li>
                    <p>
                      explain your reason(s) for the application; and
                    </p>
                  </li>
                  <li>
                    <p>
                      request one of the following:
                    </p>
                    <ul>
                      <li>
                        <p>
                          an extension of the term of your Loan Contract (which will reduce
                          the amount of each payment due under your Loan Contract); or
                        </p>
                      </li>
                      <li>
                        <p>
                          a postponement of the dates on which payments are due under
                          your Loan Contract (specify the period for which you want this to
                          apply); or
                        </p>
                      </li>
                      <li>
                        <p>
                          both of the above; and
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                    give the application to us.
                    </p>
                  </li>
                </ul>
                <p style="margin-bottom: 16px;">
                  Do this as soon as possible. If you leave it for too long, we may not have to
                  consider your application.
                </p>
              </td>
            </tr>

            <tr class="section">
              <td>
                <p><span class="bold">Dispute resolution</span></p>
                <p>
                  <span class="bold">Name of dispute resolution scheme: </span>
                  Financial Services Complaints Limited
                </p>
                <p>
                  It is free to make a complaint to this independent dispute resolution scheme. This
                  scheme can help you to resolve any disagreements you have with us.
                </p>
                <p><span class="bold">Contact details of dispute resolution scheme:</span></p>
                <p>Phone: 0800 347 257</p>
                <p>Website: http://www.fscl.org.nz/</p>
                <p>
                  Business address: Level 4, 101 Lambton Quay, Wellington 6011 or PO Box 5967,
                  Wellington 6145
                </p>
              </td>
            </tr>

            <tr class="section">
              <td>
                <p><span class="bold">Registration on Financial Service provider register</span></p>
                <p>
                  Registration name:
                  <span class="bold">Harmoney Services Limited</span>
                </p>
                <p>
                  Registration number:
                  <span class="bold">FSP593769</span>
                </p>
              </td>
            </tr>
          </table>
        </div>
      </body>
    </html>
  `;

  if (withHtml) {
    return (
      <Document>
        <Page style={styles.page}>
          <Html>{templateString}</Html>
        </Page>
      </Document>
    );
  }

  return <div dangerouslySetInnerHTML={{ __html: templateString }} />;
};
