import Image from 'next/image';
import { motion } from 'framer-motion';

export const InfoHeader = () => {
  return (
    <>
      <Image
        width="105"
        height="24"
        src="/assets/logos/hmy-white-logo-lg.svg"
        alt="Harmoney Logo"
        className="mb-4 sm:mb-6"
      />
      <motion.h1 className="text-white text-wrap">Here&rsquo;s your estimated quote</motion.h1>
      <motion.p className="text-white text-sm mb-0">
        Based on information you&rsquo;ve given <b className="text-white">ClearScore.</b>
      </motion.p>
    </>
  );
};
