import React from 'react';
import { PreQualifiedQuoteDetails } from '@harmoney/api-interfaces';
import { Badge, Card, Divider } from '@harmoney/ui-design-system';
import { DATE_FORMAT_WITH_MONTH } from '@harmoney/ui-utils';
import { dayjsSydney, formatCurrency } from '@harmoney/utilities';
import { RepaymentFrequencyEnum } from '@prisma/client';

import { QuoteRepayment } from '../quote/shared';

type Props = {
  data: PreQualifiedQuoteDetails;
};

export const PreQualifiedQuoteCard = ({ data }: Props) => {
  const { interestRate, loanAmount, loanTerm, monthlyRepayment, quoteExpiryDate } = data;
  return (
    <Card className="relative mt-8 sm:mt-6 w-full !px-0">
      <div className="flex flex-row items-start justify-between divide-x divide-grey-2 text-center py-4">
        <div className="flex flex-col justify-center items-center flex-1">
          <span className="font-medium text-sm">Interest rate</span>
          <span className="font-bold text-2xl">{interestRate}%</span>
          <div className="text-grey-4 text-xs text-balance px-4 mt-2 flex flex-col gap-0 items-center">
            <Badge label="Guaranteed" className="px-1 py-0 text-xs" />
            <span>until {dayjsSydney(quoteExpiryDate).format(DATE_FORMAT_WITH_MONTH)}.</span>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center flex-1">
          <span className="font-medium text-sm">Borrow up to</span>
          <span className="font-bold text-2xl">{formatCurrency(loanAmount)}</span>
          <div className="text-grey-4 text-xs text-balance px-4 mt-2 flex flex-col gap-0 items-center">
            <Badge label="Approximate" className="px-1 py-0 text-xs" variant="tertiary" />
            <span>Complete our form to finalise your quote.</span>
          </div>
        </div>
      </div>
      <Divider className="text-grey-2 mt-4" />
      <div className="p-4 pb-0 py-6 flex flex-col flex-1 h-full my-auto justify-center items-center">
        <QuoteRepayment
          repaymentAmount={monthlyRepayment}
          repaymentFrequency={RepaymentFrequencyEnum.monthly}
          termInMonths={loanTerm}
          className="mb-4"
        />
      </div>
    </Card>
  );
};
