import { useEffect, useMemo } from 'react';
import { TaskState } from '@harmoney/api-interfaces';
import { useAppSelector } from '@harmoney/redux';
import { isDevelopment, isPreview } from '@harmoney/ui-app-shell';
import { User, UserProfile } from '@prisma/client';

import SideFilters, {
  convertFilterToFilterStatus,
  convertToStringIds,
  FilterStatuses,
  SideFiltersProps,
} from '../common/SideFilters';

type Props = {
  onFilterChanged?: (
    statuses: FilterStatuses,
    groupsToIds: ReturnType<typeof convertToStringIds>
  ) => void | Promise<void>;
  users?: Array<User & { userProfile?: UserProfile }>;
};

export const FILTER_TASK_STATUS = 'taskStatus' as const;
export const FILTER_TASK_OWNER = 'taskOwner' as const;
export const FILTER_COUNTRY = 'taskBranch' as const;
export const FILTER_TASK_NAME = 'taskName' as const;

export default function TaskFilters({ onFilterChanged, users }: Props) {
  const userId = useAppSelector((state) => state.userId.value);

  const filters: SideFiltersProps['filters'] = useMemo(() => {
    return [
      {
        id: FILTER_TASK_STATUS,
        label: 'Task status',
        hasAllTrigger: true,
        foldable: true,
        defaultOpened: true,
        filters: [
          {
            id: TaskState.Created,
            label: 'To do',
            defaultChecked: true,
          },
          {
            id: TaskState.Completed,
            label: 'Completed',
            defaultChecked: false,
          },
        ],
      },
      {
        id: FILTER_TASK_OWNER,
        label: 'Task owner',
        hasAllTrigger: true,
        foldable: true,
        defaultOpened: true,
        filters: [
          {
            id: userId,
            label: 'Assigned to me',
            defaultChecked: true,
          },
          {
            id: 'unassigned',
            label: 'Unassigned',
            defaultChecked: true,
          },
          ...(
            users?.map(({ id, email, preferredName, userProfile }) => {
              let label = preferredName ?? email;
              if (userProfile?.firstName) {
                label = userProfile.firstName;
                if (userProfile.lastName) label += ` ${userProfile.lastName}`;
              }

              if ((isDevelopment() || isPreview()) && (label.startsWith('e2e') || label.includes('QA'))) return;

              return {
                id,
                label,
              };
            }) ?? []
          ).filter((user) => !!user && user.id !== userId),
        ],
      },
    ];
  }, [userId, users]);

  useEffect(() => {
    const filterStatuses = convertFilterToFilterStatus(filters);
    onFilterChanged?.(filterStatuses, convertToStringIds(filterStatuses));
  }, [filters]);

  if (!userId) return <></>;

  return (
    <SideFilters
      filters={filters}
      onFilterChanged={(filterStatuses) => onFilterChanged?.(filterStatuses, convertToStringIds(filterStatuses))}
    ></SideFilters>
  );
}
