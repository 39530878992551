import {
  DebtSaleDto,
  debtSaleStatusBadgeVariantMapper,
  debtSaleStatusMapper,
  VaultLoanInformationDto,
} from '@harmoney/api-interfaces';
import { Badge, CollapsibleCard } from '@harmoney/ui-design-system';
import { formatCurrency, formatNumber } from '@harmoney/utilities';
import { DebtSaleStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';

import { Field } from '../shared';

interface Props {
  loanInformationData: VaultLoanInformationDto;
  collectionStatus?: string;
  debtSaleData?: DebtSaleDto;
}

export const CollectionsInformationDetails = ({ loanInformationData, collectionStatus, debtSaleData }: Props) => {
  const arrearsStatusBadgeVariant = {
    'Good Standing': 'success',
    'Bad Standing': 'action',
    'Debt Sold': 'action',
  };

  const arrearsStatus =
    debtSaleData?.status === DebtSaleStatusEnum.debt_sold ? 'Debt Sold' : loanInformationData?.arrearsStatus;

  const isDebtSold = debtSaleData?.status === DebtSaleStatusEnum.debt_sold;
  const isDebtProcessing = debtSaleData?.status === DebtSaleStatusEnum.processing;

  if (!loanInformationData) return null;

  return (
    <CollapsibleCard title="Collections information" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="border-r-grey-1 border-r-2 p-4">
          <Field>
            <span>Arrears status</span>
            <Badge variant={arrearsStatusBadgeVariant[arrearsStatus]} label={arrearsStatus} />
          </Field>
          <Field>
            <span>Collections status</span>
            <span>{collectionStatus ? collectionStatus : 'No status'}</span>
          </Field>
          {isDebtProcessing && (
            <>
              <Field>
                <span>Debt sale status</span>
                <Badge
                  variant={debtSaleStatusBadgeVariantMapper[debtSaleData?.status]}
                  label={debtSaleStatusMapper[debtSaleData?.status]}
                />
              </Field>
              <Field>
                <span>Assignment date</span>
                <span>{dayjs(debtSaleData?.assignmentDate).format('DD/MM/YYYY')}</span>
              </Field>
            </>
          )}
        </div>
        <div className="border-t-grey-1 border-t-1 p-4">
          <Field>
            <span>Written off amount</span>
            <span>{formatCurrency(loanInformationData?.writtenOffAmount)}</span>
          </Field>
          <Field>
            <span>Written off principal</span>
            <span>{formatCurrency(loanInformationData?.principalWrittenOff)}</span>
          </Field>
          <Field>
            <span>Written off interest</span>
            <span>{formatCurrency(loanInformationData?.interestWrittenOff)}</span>
          </Field>
          <Field>
            <span>Written off penalities</span>
            <span>{formatCurrency(loanInformationData?.penaltiesWrittenOff)}</span>
          </Field>
        </div>
        {isDebtSold && (
          <>
            <div className="border-t-grey-1 border-r-grey-1 border-r-2 border-t-2 p-4">
              <Field>
                <span>Debt sale status</span>
                <Badge
                  variant={debtSaleStatusBadgeVariantMapper[debtSaleData?.status]}
                  label={debtSaleStatusMapper[debtSaleData?.status]}
                />
              </Field>
              <Field>
                <span>Assignment date</span>
                <span>{dayjs(debtSaleData?.assignmentDate).format('DD/MM/YYYY')}</span>
              </Field>
              <Field>
                <span>Debt sale date</span>
                <span>{dayjs(debtSaleData?.saleDate).format('DD/MM/YYYY')}</span>
              </Field>
              <Field>
                <span>Debt sale batch reference</span>
                <span>{debtSaleData?.batchReference}</span>
              </Field>
              <Field>
                <span>Purchaser name</span>
                <span>{debtSaleData?.purchaser?.name}</span>
              </Field>
              <Field>
                <span>Purchaser email</span>
                <span>{debtSaleData?.purchaser?.email}</span>
              </Field>
              <Field>
                <span>Purchaser phone</span>
                <span>{debtSaleData?.purchaser?.phone}</span>
              </Field>
            </div>
            <div className="border-t-grey-1 border-t-2 p-4">
              <Field>
                <span>Debt sale loan amount</span>
                <span>{formatCurrency(debtSaleData?.payoffQuote)}</span>
              </Field>
              <Field>
                <span>Broker commission fee</span>
                <span>{formatCurrency(debtSaleData?.brokerCommissionFee)}</span>
              </Field>
              <Field>
                <span>Sale price</span>
                <span>{formatNumber(debtSaleData?.salePricePercentage * 100)}%</span>
              </Field>
              <Field>
                <span>Total sale (proceeds)</span>
                <span>{formatCurrency(debtSaleData?.debtSaleAmount)}</span>
              </Field>
              <Field>
                <span>Unfunded amount</span>
                <span>{formatCurrency(debtSaleData?.unfundedAmount)}</span>
              </Field>
              <Field>
                <span>Purchaser comp number</span>
                <span>{debtSaleData?.purchaser?.compNumber}</span>
              </Field>
              <Field>
                <span>Purchaser EDR number</span>
                <span>{debtSaleData?.purchaser?.edrNumber}</span>
              </Field>
              <Field>
                <span>Purchaser FSP number</span>
                <span>{debtSaleData?.purchaser?.fspNumber}</span>
              </Field>
            </div>
          </>
        )}
      </div>
    </CollapsibleCard>
  );
};
