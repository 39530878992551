import {
  EssentialExpenseDetailDto,
  NetworthSourceEnum,
  SaveExpensesDto,
  SaveNewExpenseOverrideDto,
} from '@harmoney/api-interfaces';
import { NetworthSource } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const expenseApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getEssentialExpensesDetails: builder.query<EssentialExpenseDetailDto[], string>({
      query: (taskId: string) => ({
        url: '/financial-profile/expenses/essential/details',
        method: 'GET',
        params: {
          taskId,
        },
      }),
      providesTags: ['EssentialExpense'],
    }),
    getEssentialExpensesDetailsAdmin: builder.query<
      EssentialExpenseDetailDto[],
      { financialProfileId: string; customerId: string }
    >({
      query: ({ financialProfileId, customerId }) => ({
        url: '/financial-profile/expenses/essential/details/admin',
        method: 'GET',
        params: {
          financialProfileId,
          customerId,
        },
      }),
      providesTags: ['EssentialExpense'],
    }),
    getEssentialExpenseDetailById: builder.query<EssentialExpenseDetailDto, string>({
      query: (id: string) => ({
        url: `/financial-profile/expenses/${id}`,
        method: 'GET',
      }),
      providesTags: ['EssentialExpense'],
    }),
    getEssentialExpensesCategories: builder.query<
      NetworthSource[],
      {
        taskId: string;
        excludeIds?: NetworthSourceEnum[];
      }
    >({
      query: ({ taskId, excludeIds = [] }) => ({
        url: '/financial-profile/expenses/essential/categories',
        method: 'GET',
        params: {
          taskId,
          excludeIds: JSON.stringify(excludeIds),
        },
      }),
      providesTags: ['EssentialExpense'],
    }),
    getEssentialExpensesCategoriesAdmin: builder.query<
      NetworthSource[],
      { financialProfileId: string; customerId: string }
    >({
      query: ({ financialProfileId, customerId }) => ({
        url: '/financial-profile/expenses/essential/categories/admin',
        method: 'GET',
        params: {
          financialProfileId,
          customerId,
        },
      }),
      providesTags: ['EssentialExpense'],
    }),
    saveExpenses: builder.mutation<void, SaveExpensesDto>({
      query: (data) => {
        return {
          url: `/financial-profile/expenses`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['EssentialExpense'],
    }),
    submitExpensesByCO: builder.mutation<void, SaveNewExpenseOverrideDto>({
      query: (data) => {
        return {
          url: `/financial-profile/expenses/override-new`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['FinancialProfile'],
    }),
    removeExpense: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `/financial-profile/expenses/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['EssentialExpense'],
    }),
  }),
});

export const {
  useGetEssentialExpensesDetailsQuery,
  useGetEssentialExpensesDetailsAdminQuery,
  useGetEssentialExpensesCategoriesQuery,
  useGetEssentialExpensesCategoriesAdminQuery,
  useLazyGetEssentialExpensesCategoriesQuery,
  useLazyGetEssentialExpensesCategoriesAdminQuery,
  useSaveExpensesMutation,
  useLazyGetEssentialExpenseDetailByIdQuery,
  useRemoveExpenseMutation,
  useSubmitExpensesByCOMutation,
} = expenseApi;
