import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { CreditAuditTag, CreditAuditUpsertDto, FORM_KEY } from '@harmoney/api-interfaces';
import { useAppDispatch, useAppSelector, useSaveAuditMutation } from '@harmoney/redux';
import {
  Alert,
  AmountInput,
  Button,
  CheckboxGroup,
  Divider,
  Form,
  MultiSelect,
  RadioRange,
  Textarea,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import {
  approvedOptions,
  CreditAuditQuestions,
  CreditAuditSections,
  mightHaveApprovedOptions,
  moreLessOptions,
  rangeOptions,
  recommendedOptions,
  SOMETHING_WENT_WRONG,
  toggleStringOptions,
} from '@harmoney/ui-utils';
import { Icon } from '@iconify/react';
import { useIdle, usePageLeave } from '@mantine/hooks';
import { COAuditDecisionEnum, COAuditStatusEnum, CreditAudit } from '@prisma/client';

import { CommonProps } from '../../../../../components/common-props';
import { setIsCreditAuditConducted } from '../../../../../redux/slice/admin';

import { creditAuditFormSchema, type CreditAuditFormValues } from './form-config';
import { FormSection } from './FormSection';

type TagSection =
  | 'Eligibility, identity & fraud mitigation'
  | 'Additional Enquiries'
  | 'Responsible Lending'
  | 'Credit & Risk'
  | 'Affordability'
  | 'Requirements & Objectives'
  | 'Eligibility, identity & fraud mitigation';

type TagOption = {
  label: string;
  value: string;
};

export interface CreditAuditFormProps extends CommonProps {
  auditData?: CreditAudit;
  tags: CreditAuditTag[];
  applicationId?: string;
  internalTaskId?: string;
}

const delay = 5000;

export const CreditAuditForm = ({
  auditData,
  tags,
  applicationId,
  internalTaskId,
  completeTaskWithData,
}: CreditAuditFormProps) => {
  const dispatch = useAppDispatch();
  const [submitAudit] = useSaveAuditMutation();
  const router = useRouter();
  const accessToken = useAppSelector((state) => state?.accessToken?.value);

  const [allTags, setAllTags] = useState<TagOption[]>([]);
  const [requirementsAndObjectivesTags, setRequirementsAndObjectivesTags] = useState<TagOption[]>([]);
  const [eligibilityIdentityAndFraudMitigationTags, setEligibilityIdentityAndFraudMitigationTags] = useState<
    TagOption[]
  >([]);
  const [creditRiskTags, setCreditRiskTags] = useState<TagOption[]>([]);
  const [affordabilityTags, setAffordabilityTags] = useState<TagOption[]>([]);
  const [responsibleLendingTags, setResponsibleLendingTags] = useState<TagOption[]>([]);
  const [additionalEnquiriesTags, setAdditionalEnquiriesTags] = useState<TagOption[]>([]);

  const [isAuditSaved, setIsAuditSaved] = useState(false);
  const [lastSavedState, setLastSavedState] = useState<CreditAuditFormValues | null>(null);
  const [auditId, setAuditId] = useState<string | undefined>(auditData?.id ?? undefined);
  const [isError, setIsError] = useState(false);
  const idle = useIdle(delay);

  const form = useForm({
    mode: 'onSubmit',
    schema: creditAuditFormSchema,
    defaultValues: {
      requirementsAndObjectives: auditData?.requirementsAndObjectives ?? null,
      requirementsAndObjectivesTags: auditData?.additionalEnquiriesTags ?? [],
      eligibilityIdentityAndFraudMitigation: auditData?.eligibilityIdentityAndFraudMitigation ?? null,
      eligibilityIdentityAndFraudMitigationTags: auditData?.eligibilityIdentityAndFraudMitigationTags ?? [],
      creditRisk: auditData?.creditRisk ?? null,
      creditRiskTags: auditData?.creditRiskTags ?? [],
      affordability: auditData?.affordability ?? null,
      affordabilityTags: auditData?.affordabilityTags ?? [],
      responsibleLending: auditData?.responsibleLending ?? null,
      responsibleLendingTags: auditData?.responsibleLendingTags ?? [],
      additionalEnquiries: auditData?.additionalEnquiries ?? undefined,
      additionalEnquiriesTags: auditData?.additionalEnquiriesTags ?? [],
      additionalEnquiriesNotes: auditData?.additionalEnquiriesNotes ?? '',
      coAuditDecision: auditData?.coAuditDecision ?? undefined,
      decisionBasis: auditData?.decisionBasis ?? [],
      mightHaveApprovedIf: auditData?.mightHaveApprovedIf ?? [],
      wouldHaveApprovedFor: auditData?.wouldHaveApprovedFor ?? undefined,
      maxOffer: auditData?.maxOffer ?? undefined,
      newTagsSuggestions: auditData?.newTagsSuggestions ?? '',
      tagsToBeRemoved: auditData?.tagsToBeRemoved ?? [],
      notes: auditData?.notes ?? '',
    },
  });
  const {
    register,
    watch,
    formState: { isDirty },
    getValues,
  } = form;

  const saveAudit = async () => {
    if (!isDirty) return;
    const data = getValues();

    if (JSON.stringify(data) === JSON.stringify(lastSavedState)) return;
    setLastSavedState(data);
    setIsAuditSaved(true);

    const payload: CreditAuditUpsertDto = {
      ...data,
      id: auditId,
      applicationId: applicationId || (router.query.applicationId as string),
      coAuditStatus: COAuditStatusEnum.DRAFT,
    };
    const { id } = await submitAudit(payload).unwrap();
    if (!auditId) {
      setAuditId(id);
    }
    setIsAuditSaved(false);
  };

  usePageLeave(saveAudit);

  useEffect(() => {
    if (idle) {
      saveAudit();
    }
  }, [idle]);

  useMemo(() => {
    if (tags) {
      setAllTags(
        tags.map((tag) => ({
          label: `${tag.category} - ${tag.detail}`,
          value: `${tag.category} - ${tag.detail}`,
        }))
      );
      const tagsBySection = tags.reduce(
        (acc, tag) => {
          const section = tag.section as TagSection;
          if (!acc[section]) {
            acc[section] = [];
          }
          acc[section].push({
            label: `${tag.category} - ${tag.detail}`,
            value: `${tag.category} - ${tag.detail}`,
          });
          return acc;
        },
        {} as Record<TagSection, TagOption[]>
      );
      setRequirementsAndObjectivesTags(tagsBySection['Requirements & Objectives']);
      setEligibilityIdentityAndFraudMitigationTags(tagsBySection['Eligibility, identity & fraud mitigation']);
      setCreditRiskTags(tagsBySection['Credit & Risk']);
      setAffordabilityTags(tagsBySection['Affordability']);
      setResponsibleLendingTags(tagsBySection['Responsible Lending']);
      setAdditionalEnquiriesTags(tagsBySection['Additional Enquiries']);
    }
  }, [tags]);

  const handleSubmit = async (data: CreditAuditFormValues) => {
    if (data.coAuditDecision === COAuditDecisionEnum.APPROVED) {
      data.mightHaveApprovedIf = [];
    }
    const payload = {
      ...data,
      id: auditId,
      applicationId: applicationId || (router.query.applicationId as string),
      coAuditStatus: COAuditStatusEnum.SUBMITTED,
    };
    if (internalTaskId) {
      try {
        await completeTaskWithData({
          taskId: internalTaskId,
          formKey: FORM_KEY.CREDIT_AUDIT_DECISION,
          formData: { accessToken, payload },
          isInternalTask: true,
        }).unwrap();
        dispatch(setIsCreditAuditConducted(false));
      } catch (error) {
        setIsError(true);
      }
    } else {
      try {
        await submitAudit(payload).unwrap();
        dispatch(setIsCreditAuditConducted(false));
      } catch (error) {
        setIsError(true);
      }
    }
  };

  const handleCancel = () => {
    dispatch(setIsCreditAuditConducted(false));
  };

  const getPrefillTags = (tags: string[]) => {
    if (!tags || tags?.length < 1) return [];
    return tags?.map((tag) => {
      return {
        label: tag,
        value: tag,
      };
    });
  };

  return (
    <>
      <div className=" sticky top-0 z-50 rounded-b-sm bg-white px-4 pb-2">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-baseline justify-end gap-4">
            <h2 className="text-primary">Credit Audit</h2>
            {isAuditSaved && <span className="text-grey-4 text-sm">Saving Audit</span>}
          </div>
          <Icon icon="ic:round-close" onClick={handleCancel} className="cursor-pointer" width={24} />
        </div>
        {isError && <Alert variant="error">{SOMETHING_WENT_WRONG}</Alert>}
        <Alert variant="info" className="mb-0">
          <p>Base your recommendation on the information that is available.</p>
        </Alert>
      </div>
      <Form form={form} onSubmit={handleSubmit} className="p-4">
        <FormSection title={CreditAuditSections.requirementsAndObjectives}>
          <span className="font-normal">{CreditAuditQuestions.requirementsAndObjectivesMet}</span>
          <RadioRange {...register('requirementsAndObjectives')} options={rangeOptions} />
          <Controller
            control={form.control}
            {...register('requirementsAndObjectivesTags')}
            render={(props) => (
              <MultiSelect
                options={requirementsAndObjectivesTags}
                label={CreditAuditQuestions.whyThisAnswer}
                defaultValue={getPrefillTags(auditData?.requirementsAndObjectivesTags)}
                className="w-full"
                onChange={(option) => props.field.onChange(Object.values(option).map((o) => o.value))}
                onBlur={props.field.onBlur}
                {...props}
              />
            )}
          />
        </FormSection>
        <FormSection title={CreditAuditSections.eligibilityIdentityAndFraudMitigation}>
          <span className="font-normal">{CreditAuditQuestions.eligibilityMet}</span>
          <RadioRange options={rangeOptions} {...register('eligibilityIdentityAndFraudMitigation')} />
          <Controller
            control={form.control}
            {...register('eligibilityIdentityAndFraudMitigationTags')}
            render={(props) => (
              <MultiSelect
                options={eligibilityIdentityAndFraudMitigationTags}
                label={CreditAuditQuestions.whyThisAnswer}
                defaultValue={getPrefillTags(auditData?.eligibilityIdentityAndFraudMitigationTags)}
                className="w-full"
                onChange={(option) => props.field.onChange(Object.values(option).map((o) => o.value))}
                onBlur={props.field.onBlur}
                {...props}
              />
            )}
          />
        </FormSection>
        <FormSection title={CreditAuditSections.creditRisk}>
          <span className="font-normal">{CreditAuditQuestions.happyToLend}</span>
          <RadioRange options={rangeOptions} {...register('creditRisk')} />
          <Controller
            control={form.control}
            {...register('creditRiskTags')}
            render={(props) => (
              <MultiSelect
                options={creditRiskTags}
                label={CreditAuditQuestions.whyThisAnswer}
                defaultValue={getPrefillTags(auditData?.creditRiskTags)}
                className="w-full"
                onChange={(option) => props.field.onChange(Object.values(option).map((o) => o.value))}
                onBlur={props.field.onBlur}
                {...props}
              />
            )}
          />
        </FormSection>
        <FormSection title={CreditAuditSections.affordability}>
          <span className="font-normal">{CreditAuditQuestions.affordable}</span>
          <RadioRange options={rangeOptions} {...register('affordability')} />
          <Controller
            control={form.control}
            {...register('affordabilityTags')}
            render={(props) => (
              <MultiSelect
                options={affordabilityTags}
                label={CreditAuditQuestions.whyThisAnswer}
                defaultValue={getPrefillTags(auditData?.affordabilityTags)}
                className="w-full"
                onChange={(option) => props.field.onChange(Object.values(option).map((o) => o.value))}
                onBlur={props.field.onBlur}
                {...props}
              />
            )}
          />
        </FormSection>
        <FormSection title={CreditAuditSections.responsibleLending}>
          <span className="font-normal">{CreditAuditQuestions.responsibleLendingMet}</span>
          <RadioRange options={rangeOptions} {...register('responsibleLending')} />
          <Controller
            control={form.control}
            {...register('responsibleLendingTags')}
            render={(props) => (
              <MultiSelect
                options={responsibleLendingTags}
                label={CreditAuditQuestions.whyThisAnswer}
                defaultValue={getPrefillTags(auditData?.responsibleLendingTags)}
                className="w-full"
                onChange={(option) => props.field.onChange(Object.values(option).map((o) => o.value))}
                onBlur={props.field.onBlur}
                {...props}
              />
            )}
          />
        </FormSection>
        <FormSection title={CreditAuditSections.additionalEnquiries}>
          <ToggleGroup
            {...register('additionalEnquiries')}
            isBoolean={true}
            label={CreditAuditQuestions.additionalEnquiriesToggle}
            options={toggleStringOptions}
          />
          <Controller
            control={form.control}
            {...register('additionalEnquiriesTags')}
            render={(props) => (
              <MultiSelect
                options={additionalEnquiriesTags}
                label={CreditAuditQuestions.additionalEnquiriesTags}
                defaultValue={getPrefillTags(auditData?.additionalEnquiriesTags)}
                className="w-full"
                onChange={(option) => props.field.onChange(Object.values(option).map((o) => o.value))}
                onBlur={props.field.onBlur}
                {...props}
              />
            )}
          />
          <Textarea
            {...register('additionalEnquiriesNotes')}
            className="mb-6"
            label={CreditAuditQuestions.additionalEnquiriesNotes}
          />
        </FormSection>
        <Divider className="mb-8" />
        <h3 className="mb-2 font-semibold">{CreditAuditSections.cORecommendation}</h3>
        <div className="flex flex-col gap-8 pb-8">
          <ToggleGroup
            {...register('coAuditDecision')}
            label={CreditAuditQuestions.decision}
            options={approvedOptions}
          />
          <div className="flex flex-col gap-2">
            <label>{CreditAuditQuestions.decisionBasis} (can select multiple)</label>
            <CheckboxGroup {...register('decisionBasis')} options={recommendedOptions} className="my-1" />
          </div>
          {watch('coAuditDecision') === COAuditDecisionEnum.UNSUCCESSFUL && (
            <div className="flex flex-col gap-2">
              <label>{CreditAuditQuestions.mightHaveApprovedIf}</label>
              <CheckboxGroup {...register('mightHaveApprovedIf')} options={mightHaveApprovedOptions} className="my-1" />
            </div>
          )}
          <ToggleGroup
            {...register('wouldHaveApprovedFor')}
            label={CreditAuditQuestions.wouldHaveApprovedFor}
            options={moreLessOptions}
          />
          <AmountInput {...register('maxOffer')} label={CreditAuditQuestions.maxOffer} />
          <div className="flex flex-col gap-1">
            <Textarea {...register('newTagsSuggestions')} label={CreditAuditQuestions.newTagsSuggestions} />
            <Controller
              control={form.control}
              {...register('tagsToBeRemoved')}
              render={(props) => (
                <MultiSelect
                  options={allTags}
                  label={`${CreditAuditQuestions.tagsToBeRemoved} (can select multiple):`}
                  defaultValue={getPrefillTags(auditData?.tagsToBeRemoved)}
                  className="w-full"
                  onChange={(option) => props.field.onChange(Object.values(option).map((o) => o.value))}
                  onBlur={props.field.onBlur}
                  {...props}
                />
              )}
            />
          </div>
          <Textarea {...register('notes')} label={`${CreditAuditQuestions.notes} (if can’t be covered above)`} />
        </div>
        <div className="mb-4 flex flex-row items-end justify-center gap-8 max-w-full pb-8">
          <Button variant="tertiary" onClick={handleCancel} size="medium" className="!min-w-fit">
            Cancel
          </Button>
          <Button variant="primary" type="submit" size="medium" disabled={isError}>
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
};
