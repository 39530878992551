import { isWithinCutOffTime } from '@harmoney/ui-utils';
import { LoanProductName } from '@harmoney/utilities';
import { BranchEnum } from '@prisma/client';

const PERSONAL_LOAN_TEXT = 'Most customers will receive their money';
const DEBT_CONSOLIDATION_TEXT = 'Most customers debts will be paid off';
const IN_MINUTES = 'in minutes.';
const TOMORROW = 'tomorrow.';

const getTipText = (productName: LoanProductName, branch: BranchEnum): string => {
  if (branch === BranchEnum.AU) {
    return productName === LoanProductName.PERSONAL_LOAN
      ? `${PERSONAL_LOAN_TEXT} ${IN_MINUTES}`
      : `${DEBT_CONSOLIDATION_TEXT} ${IN_MINUTES}`;
  }
  const withinCutOffTime = isWithinCutOffTime(branch);

  if (productName === LoanProductName.PERSONAL_LOAN) {
    return withinCutOffTime ? `${PERSONAL_LOAN_TEXT} ${IN_MINUTES}` : `${PERSONAL_LOAN_TEXT} ${TOMORROW}`;
  } else if (productName === LoanProductName.DEBT_CONSOLIDATION) {
    return withinCutOffTime ? `${DEBT_CONSOLIDATION_TEXT} ${IN_MINUTES}` : `${DEBT_CONSOLIDATION_TEXT} ${TOMORROW}`;
  }

  return '';
};

const createCopyText = (productName: LoanProductName, branch: BranchEnum) => ({
  title: 'Congratulations',
  subtitle: productName === LoanProductName.PERSONAL_LOAN ? 'is coming your way!' : 'is on its way!',
  ctaText: 'go to dashboard',
  tipText: getTipText(productName, branch),
});

export const moneyDisbursingCopyText = {
  [LoanProductName.PERSONAL_LOAN]: {
    [BranchEnum.AU]: createCopyText(LoanProductName.PERSONAL_LOAN, BranchEnum.AU),
    [BranchEnum.NZ]: createCopyText(LoanProductName.PERSONAL_LOAN, BranchEnum.NZ),
  },
  [LoanProductName.DEBT_CONSOLIDATION]: {
    [BranchEnum.AU]: createCopyText(LoanProductName.DEBT_CONSOLIDATION, BranchEnum.AU),
    [BranchEnum.NZ]: createCopyText(LoanProductName.DEBT_CONSOLIDATION, BranchEnum.NZ),
  },
};
