import { MAXIMUM_LOAN_AMOUNT, MINIMUM_LOAN_AMOUNT } from '@harmoney/api-interfaces';
import { ComparisonOperator, errors } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { z } from 'zod';

export enum AddressInputMode {
  autocomplete = 'autocomplete',
  manual = 'manual',
}

export const quickQuoteFormFieldKeys = {
  LOAN_TYPE: { loanPurpose: 'loanPurpose', amount: 'amount' },
  IDENTITY: {
    firstName: 'firstName',
    lastName: 'lastName',
    emailAddress: 'emailAddress',
    dateOfBirth: 'dateOfBirth',
    address: 'address',
  },
  ABOUT_YOU: {
    residencyStatus: 'residencyStatus',
    relationshipStatus: 'relationshipStatus',
    livingType: 'livingType',
    accommodationExpense: 'accommodationExpense',
    numberOfDependants: 'numberOfDependants',
  },
  INCOME: {
    income: 'income',
    employmentStatus: 'employmentStatus',
  },
};

export const sortedQuickQuoteFormFieldKeys = [
  quickQuoteFormFieldKeys.LOAN_TYPE.loanPurpose,
  quickQuoteFormFieldKeys.LOAN_TYPE.amount,
  quickQuoteFormFieldKeys.IDENTITY.firstName,
  quickQuoteFormFieldKeys.IDENTITY.lastName,
  quickQuoteFormFieldKeys.IDENTITY.emailAddress,
  quickQuoteFormFieldKeys.IDENTITY.dateOfBirth,
  quickQuoteFormFieldKeys.IDENTITY.address,
  quickQuoteFormFieldKeys.ABOUT_YOU.livingType,
  quickQuoteFormFieldKeys.ABOUT_YOU.accommodationExpense,
  quickQuoteFormFieldKeys.ABOUT_YOU.numberOfDependants,
  quickQuoteFormFieldKeys.ABOUT_YOU.relationshipStatus,
  quickQuoteFormFieldKeys.ABOUT_YOU.residencyStatus,
  quickQuoteFormFieldKeys.INCOME.employmentStatus,
  quickQuoteFormFieldKeys.INCOME.income,
];

export const getDefaultValues = (savedFormValues?: FormSchemaType) => ({
  amount: savedFormValues?.amount,
  address: {
    addressInputMode: savedFormValues?.address?.addressInputMode || AddressInputMode.autocomplete,
    fullAddress: savedFormValues?.address?.fullAddress,
    placeId: savedFormValues?.address?.placeId,
    postcode: savedFormValues?.address?.postcode,
    state: savedFormValues?.address?.state,
    suburb: savedFormValues?.address?.suburb,
    streetAddress: savedFormValues?.address?.streetAddress,
    unitNumber: savedFormValues?.address?.unitNumber,
  },
  dateOfBirth: savedFormValues?.dateOfBirth ? dayjs(savedFormValues?.dateOfBirth).toDate() : null,
  emailAddress: savedFormValues?.emailAddress,
  employmentStatus: savedFormValues?.employmentStatus,
  firstName: savedFormValues?.firstName,
  income: {
    amount: savedFormValues?.income?.amount,
    frequency: savedFormValues?.income?.frequency,
  },
  lastName: savedFormValues?.lastName,
  livingType: savedFormValues?.livingType,
  loanPurpose: savedFormValues?.loanPurpose,
  middleName: savedFormValues?.middleName,
  numberOfDependants: savedFormValues?.numberOfDependants,
  relationshipStatus: savedFormValues?.relationshipStatus,
  residencyStatus: savedFormValues?.residencyStatus,
  accommodationExpense: {
    amount: savedFormValues?.accommodationExpense?.amount,
    frequency: savedFormValues?.accommodationExpense?.frequency,
  },
});

const addressSchema = z
  .object({
    addressInputMode: z.nativeEnum(AddressInputMode).optional(),

    // used if addressInputMode === AddressInputMode.autocomplete
    fullAddress: z.string().optional(),
    placeId: z.string().optional(),

    // used if addressInputMode === AddressInputMode.manual
    unitNumber: z.string().optional(),
    streetAddress: z.string().optional(),
    suburb: z.string().optional(),
    state: z.string().optional(),
    postcode: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.addressInputMode === AddressInputMode.autocomplete && isEmpty(data.placeId)) return false;
      return true;
    },
    {
      message: errors.requiredField('Address'),
      path: ['placeId'],
    }
  )
  .refine(
    (data) => {
      if (data.addressInputMode === AddressInputMode.manual && isEmpty(data.streetAddress)) return false;
      return true;
    },
    {
      message: errors.requiredField('Street address'),
      path: ['streetAddress'],
    }
  )
  .refine(
    (data) => {
      if (data.addressInputMode === AddressInputMode.manual && isEmpty(data.suburb)) return false;
      return true;
    },
    {
      message: errors.requiredField('Suburb'),
      path: ['suburb'],
    }
  )
  .refine(
    (data) => {
      if (data.addressInputMode === AddressInputMode.manual && isEmpty(data.postcode)) return false;
      return true;
    },
    {
      message: errors.requiredField('Postcode'),
      path: ['postcode'],
    }
  )
  .refine(
    (data) => {
      if (data.addressInputMode === AddressInputMode.manual && isEmpty(data.state)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredField,
      path: ['state'],
    }
  );

const incomeSchema = z
  .object({
    amount: z.number({
      message: errors.defaultValidAmount,
    }),
    frequency: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.amount > 0 && data.frequency === '') return false;
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['frequency'],
    }
  );

const accommodationSchema = z
  .object({
    amount: z.number({
      message: errors.requiredField('Amount'),
    }),
    frequency: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.amount >= 0 && data.frequency === '') return false;
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['frequency'],
    }
  );

export const formSchema = z.object({
  // loan type section
  loanPurpose: z.string().min(1, { message: errors.defaultRequiredField }),
  amount: z
    .number({
      required_error: `The borrowing amount should be between ${formatCurrency(MINIMUM_LOAN_AMOUNT)} and ${formatCurrency(MAXIMUM_LOAN_AMOUNT)}`,
      invalid_type_error: `The borrowing amount should be between ${formatCurrency(MINIMUM_LOAN_AMOUNT)} and ${formatCurrency(MAXIMUM_LOAN_AMOUNT)}`,
    })
    .gte(MINIMUM_LOAN_AMOUNT, {
      message: errors.amountComparison(ComparisonOperator.GREATER_THAN, MINIMUM_LOAN_AMOUNT),
    })
    .lte(MAXIMUM_LOAN_AMOUNT, {
      message: errors.amountComparison(ComparisonOperator.LESS_THAN, MAXIMUM_LOAN_AMOUNT),
    }),

  // identity section
  firstName: z.string().min(1, { message: errors.requiredField('First name') }),
  middleName: z.string().optional(),
  lastName: z.string().min(1, { message: errors.requiredField('Last name') }),
  emailAddress: z.string().email({ message: errors.defaultValidEmail }),
  dateOfBirth: z.date({ message: errors.requiredField('Date of birth') }),
  address: addressSchema,

  // about you section
  relationshipStatus: z.string().min(1, { message: errors.defaultRequiredField }),
  residencyStatus: z.string({
    required_error: errors.defaultRequiredField,
  }),
  livingType: z.string().min(1, { message: errors.defaultRequiredField }),
  accommodationExpense: accommodationSchema,
  numberOfDependants: z
    .string()
    .trim()
    .min(1, { message: 'Number of children or dependants is required' })
    .refine((val) => !isNaN(Number(val)), { message: 'Please enter a number between 0 and 30' })
    .refine((num) => Number(num) >= 0 && Number(num) <= 30, { message: 'Please enter a number between 0 and 30' }),

  // income section
  employmentStatus: z.string().min(1, { message: errors.defaultRequiredField }),
  income: incomeSchema,
});

export type FormSchemaType = z.infer<typeof formSchema>;
