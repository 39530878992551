// Event names
export const CUSTOMER_ACCOUNT_SIGN_IN = 'customer_account_sign_in';
export const CUSTOMER_PROFILE_COMPLETED = 'customer_profile_completed'; //TODO not tracked

// TODO: Confirm the event name of SMART_UI_LOADED_EVENT since it is inconsistent with the other events
export const SMART_UI_LOADED_EVENT = 'Smart UI Loaded';
export const IDENTITY_VERIFICATION_COMPLETED = 'identity_verification_completed';
export const IDENTITY_SOFT_ID_STARTED = 'identity_soft_id_started';
export const IDENTITY_SOFT_ID_SUCCESS = 'identity_soft_id_success';
export const IDENTITY_SOFT_ID_FAIL = 'identity_soft_id_fail';
export const IDENTITY_HARD_ID_STARTED = 'identity_hard_id_started';
export const IDENTITY_HARD_ID_SUCCESS = 'identity_hard_id_success';
export const IDENTITY_HARD_ID_FAIL = 'identity_hard_id_fail';

export const FINANCIALPROFILE_BANKPROFILE_UPLOADED = 'financialprofile_bankprofile_uploaded';
export const FINANCIALPROFILE_ASSETS_PROVIDED = 'financialprofile_assets_provided';
export const FINANCIALPROFILE_EXPENSES_PROVIDED = 'financialprofile_expenses_provided';
export const FINANCIALPROFILE_DEBTS_PROVIDED = 'financialprofile_debts_provided';
export const FINANCIALPROFILE_INCOME_PROVIDED = 'financialprofile_income_provided';
export const FINANCIALPROFILE_LIVING_SITUATION_PROVIDED = 'financialprofile_living_situation_provided';
export const FINANCIALPROFILE_HOUSEHOLD_INCOME_PROVIDED = 'financialprofile_household_income_provided';

export const LOAN_APPLICATION_SUBMITTED = 'loan_application_submitted';
export const LOAN_APPLICATION_WITHDRAWN = 'loan_application_withdrawn'; // TODO not tracked

export const LOAN_CONTRACT_ACCEPTED = 'loan_contract_accepted';
export const LOAN_PAYMENTS_ACCEPTED = 'loan_payments_accepted';

export const PROVISO_IFRAME_DISPLAYED = 'proviso_iframe_displayed';
export const PROVISO_IFRAME_LOADED = 'proviso_iframe_loaded';
export const PROVISO_IFRAME_LOGIN_COMPLETE = 'proviso_iframe_login_complete';
export const PROVISO_IFRAME_LOGIN_FAILED = 'proviso_iframe_login_failed';
export const PROVISO_IFRAME_ERROR_OCCURRED = 'proviso_iframe_error_occurred';
export const PROVISO_IFRAME_INVALID_TOKEN = 'proviso_iframe_invalid_token';

export const GETTING_TO_KNOW_YOU_PROVIDED = 'gtky_provided';
export const DEBTCON_OBJECTIVES_ACCEPTED = 'debtcon_objectives_accepted';
export const APPLICATION_APPROVED = 'application_approved';

export const NEW_SESSION = 'new_session_started';

export const QUOTE_READY = 'quote_ready';
export const QUOTE_READY_DISPLAYED = 'quote_ready_displayed';
export const VALID_QUOTE_DISPLAYED = 'valid_quote_displayed';

export const PRE_QUALIFIED_QUOTE_ACCEPTED = 'pre_qualified_quote_accepted';

export const QUICK_QUOTE_FORM_SUBMITTED = 'quick_quote_form_submitted';
export const QUICK_QUOTE_LOAN_PURPOSE_CHANGED = 'quick_quote_loan_purpose_changed';
export const QUICK_QUOTE_LOAN_AMOUNT_CHANGED = 'quick_quote_loan_amount_changed';
export const QUICK_QUOTE_FIRST_NAME_CHANGED = 'quick_quote_first_name_changed';
export const QUICK_QUOTE_MIDDLE_NAME_CHANGED = 'quick_quote_middle_name_changed';
export const QUICK_QUOTE_LAST_NAME_CHANGED = 'quick_quote_last_name_changed';
export const QUICK_QUOTE_EMAIL_CHANGED = 'quick_quote_email_changed';
export const QUICK_QUOTE_DATE_OF_BIRTH_CHANGED = 'quick_quote_date_of_birth_changed';
export const QUICK_QUOTE_ADDRESS_PLACE_ID_CHANGED = 'quick_quote_address_place_id_changed';
export const QUICK_QUOTE_UNIT_NUMBER_CHANGED = 'quick_quote_unit_number_changed';
export const QUICK_QUOTE_STREET_ADDRESS_CHANGED = 'quick_quote_street_address_changed';
export const QUICK_QUOTE_SUBURB_CHANGED = 'quick_quote_suburb_changed';
export const QUICK_QUOTE_STATE_CHANGED = 'quick_quote_state_changed';
export const QUICK_QUOTE_POSTCODE_CHANGED = 'quick_quote_postcode_changed';
export const QUICK_QUOTE_RELATIONSHIP_STATUS_CHANGED = 'quick_quote_relationship_status_changed';
export const QUICK_QUOTE_RESIDENCY_STATUS_CHANGED = 'quick_quote_residency_status_changed';
export const QUICK_QUOTE_LIVING_TYPE_CHANGED = 'quick_quote_living_type_changed';
export const QUICK_QUOTE_ACCOMMODATION_EXPENSE_AMOUNT_CHANGED = 'quick_quote_accommodation_expense_amount_changed';
export const QUICK_QUOTE_ACCOMMODATION_EXPENSE_FREQUENCY_CHANGED =
  'quick_quote_accommodation_expense_frequency_changed';
export const QUICK_QUOTE_NUMBER_OF_DEPENDANTS_CHANGED = 'quick_quote_number_of_dependants_changed';
export const QUICK_QUOTE_EMPLOYMENT_STATUS_CHANGED = 'quick_quote_employment_status_changed';
export const QUICK_QUOTE_INCOME_AMOUNT_CHANGED = 'quick_quote_income_amount_changed';
export const QUICK_QUOTE_INCOME_FREQUENCY_CHANGED = 'quick_quote_income_frequency_changed';
