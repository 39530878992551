import { useState } from 'react';
import { useAppSelector, useListUsersByRoleQuery } from '@harmoney/redux';
import { Button, Spinner } from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';
import { useDebouncedState } from '@mantine/hooks';
import { InternalUserRoleEnum } from '@prisma/client';
import classNames from 'classnames';

import { TaskDataTable } from './TaskDataTable';
import TaskFilters from './TaskFilters';

export function TaskPage() {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [filters, setFilters] = useDebouncedState<Record<string, string[]>>({}, 150);
  const token = useAppSelector((state) => state.accessToken.value);
  const { data: users, isLoading } = useListUsersByRoleQuery(
    { role: InternalUserRoleEnum.CREDIT_OFFICER },
    { skip: !token }
  );
  if (isLoading || !users) {
    return (
      <div className="align-center mt-32 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="bg-grey-1 flex min-h-dvh flex-row">
      <div
        className={classNames(
          'fixed flex flex-col py-8 border-r border-r-grey-2 bg-grey-1 h-screen transition-width duration-300',
          collapsed ? 'w-[2rem] px-4' : 'w-[15rem]',
          'pb-16'
        )}
      >
        <div className={classNames('px-6', collapsed ? 'hidden' : 'block', 'overflow-auto')}>
          <TaskFilters
            users={users}
            onFilterChanged={(_filterStatuses, stringFilters) => {
              setFilters(stringFilters);
            }}
          ></TaskFilters>
        </div>

        <Button
          onClick={() => setCollapsed(!collapsed)}
          variant="text"
          className="absolute top-[60px] right-[-20px] !text-grey-2"
        >
          <span className="border rounded-pill flex bg-grey-1">
            <Icon icon={`ic:round-chevron-${collapsed ? 'right' : 'left'}`} width={34} />
          </span>
        </Button>
      </div>

      <div className="flex-1 h-full transition-all px-4" style={{ marginLeft: collapsed ? '2rem' : '15rem' }}>
        <TaskDataTable users={users} filters={filters} />
      </div>
    </div>
  );
}
