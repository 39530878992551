import { TaskState } from '../camunda';

export const taskStatusMapper: Record<TaskState, string> = {
  [TaskState.Created]: 'To do',
  [TaskState.Completed]: 'Completed',
  [TaskState.Canceled]: 'Canceled',
  [TaskState.Failed]: 'Failed',
};

export const taskStatusPriorityMapper: Record<TaskState, number> = {
  [TaskState.Canceled]: 0,
  [TaskState.Completed]: 1,
  [TaskState.Failed]: 2,
  [TaskState.Created]: 3,
};
