import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useController, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { BaseFormFieldProps, FormField } from '../FormField';
import { HelpText } from '../HelpText';
import { IconV2 } from '../Icon';
import { Label } from '../Label';
import { ValidationMessage } from '../ValidationMessage';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

export type DatePickerProps = BaseFormFieldProps &
  Omit<ReactDatePickerProps, 'onChange'> & {
    name: string;
    label?: string;
    flexiblePopperPlacement?: boolean;
    textColor?: string;
    showDueDay?: boolean;
  };

export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
  (
    {
      className,
      label,
      name,
      helpText,
      selected,
      maxDate,
      minDate,
      disabled = false,
      readOnly = false,
      flexiblePopperPlacement = false,
      textColor = '',
      showDueDay = false,
      ...restProps
    }: DatePickerProps,
    forwardRef: React.Ref<HTMLDivElement>
  ) => {
    const inputRef = useRef<any>();
    const { control, formState, getFieldState } = useFormContext();
    const { isDirty, isTouched, invalid } = getFieldState(name, formState);
    const {
      field: { onChange, onBlur, value },
    } = useController({
      name,
      control,
    });
    const [internalSelectedDate, setInternalSelectedDate] = useState<Date | null>(selected || value);

    useEffect(() => {
      if (selected) {
        setInternalSelectedDate(selected);
      } else if (value) {
        setInternalSelectedDate(value);
      } else {
        setInternalSelectedDate(null);
      }
    }, [selected, value]);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.setOpen(true);
        inputRef.current.setOpen(false);
      }
    }, []);

    const handleCalendarOpen = () => {
      if (inputRef.current && !disabled) {
        inputRef.current.setOpen(true);
      }
    };

    const handleOnChange = (data: Date, event: React.SyntheticEvent<any, Event>) => {
      setInternalSelectedDate(data);
      onChange(data);
      event.stopPropagation();
    };

    return (
      <div className={className} ref={forwardRef}>
        {label && (
          <Label htmlFor={name} className="mb-2">
            {label}
          </Label>
        )}
        {helpText && <HelpText>{helpText}</HelpText>}
        <FormField
          isDirty={isDirty}
          isTouched={isTouched}
          isInvalid={invalid}
          disabled={disabled}
          readOnly={readOnly}
          onClick={handleCalendarOpen}
          className="justify-between"
        >
          <ReactDatePicker
            id={name}
            data-testid={name}
            showPopperArrow={false}
            selected={internalSelectedDate}
            dateFormat="dd/MM/yyyy"
            minDate={minDate}
            maxDate={maxDate}
            onBlur={onBlur}
            ref={inputRef}
            disabled={disabled}
            className={classNames(textColor ? textColor : '')}
            popperClassName={classNames(flexiblePopperPlacement ? '' : '!-left-4 !top-4 !pt-0')}
            {...restProps}
            onChange={handleOnChange}
            customInput={<PatternFormat format="##/##/####" allowEmptyFormatting={false} mask="_" />}
          />
          <div className="flex items-center">
            {showDueDay && (
              <span className="text-sm text-grey-4 mr-2">
                in {Math.abs(dayjs().diff(internalSelectedDate, 'days'))} days
              </span>
            )}
            <IconV2 icon="mdi:calendar-blank" className="text-grey-4" width={28} />
          </div>
        </FormField>
        <ValidationMessage name={name} />
      </div>
    );
  }
);
