export enum FeatureFlagEnum {
  MOCK_CENTRIX_NZ = 'mock_centrix_credit_pull_nz',
  MOCK_EQUIFAX_CREDIT_PULL_NZ = 'mock_equifax_credit_pull_nz',
  MOCK_ILLION_CREDIT_PULL_AU = 'mock_illion_credit_pull_au',
  MOCK_EQUIFAX_CREDIT_PULL_AU = 'mock_equifax_credit_pull_au',
  MOCK_IDV_NZ = 'mock_idv_nz',
  MOCK_IDV_AU = 'mock_idv_au',
  MOCK_BANK_STATEMENT_NZ = 'mock_bank_statement_nz',
  MOCK_BANK_STATEMENT_AU = 'mock_bank_statement_au',
}
