import { RepaymentFrequency } from '@harmoney/api-interfaces';
import { formatCurrency } from '@harmoney/utilities';
import { z } from 'zod';

export const validationMessages = {
  loanPurposeForUnutilisedLimit: 'Please select a loan purpose',
  loanAmountForUnutilisedLimit: (lowerLimit: number, upperLimit: number) => ({
    greaterThanLowerLimit: `Please enter an amount greater than ${formatCurrency(lowerLimit)}`,
    lessThanUpperLimit: `Please enter an amount less than ${formatCurrency(upperLimit)}`,
  }),
  repaymentFrequency: 'Please select a repayment frequency',
  termOption: 'Please select a term option',
};

export const defaultValues = {
  termInMonths: 84,
  repaymentFrequency: RepaymentFrequency.MONTHLY,
  loanPurposeForUnutilisedLimit: '',
};

export const createFormSchema = (lowerLimit: number, upperLimit: number) => {
  return z.object({
    loanPurposeForUnutilisedLimit: z.string().min(1, { message: validationMessages.loanPurposeForUnutilisedLimit }),
    loanAmountForUnutilisedLimit: z.coerce
      .number({
        invalid_type_error: validationMessages.loanAmountForUnutilisedLimit(lowerLimit, upperLimit)
          .greaterThanLowerLimit,
      })
      .gte(lowerLimit, {
        message: validationMessages.loanAmountForUnutilisedLimit(lowerLimit, upperLimit).greaterThanLowerLimit,
      })
      .lte(upperLimit, {
        message: validationMessages.loanAmountForUnutilisedLimit(lowerLimit, upperLimit).lessThanUpperLimit,
      }),
    repaymentFrequency: z.nativeEnum(RepaymentFrequency, {
      invalid_type_error: validationMessages.repaymentFrequency,
    }),
    termInMonths: z.coerce
      .number({
        invalid_type_error: validationMessages.termOption,
        required_error: validationMessages.termOption,
      })
      .refine((data) => [36, 60, 84].includes(data), { message: validationMessages.termOption }),
  });
};

// Example usage: This formSchema is for type reference only
const formSchema = createFormSchema(2000, 70000);

export type FormSchema = z.infer<typeof formSchema>;
