import { useEffect, useMemo, useState } from 'react';
import {
  DebtConsolidationDisbursementErrorStatus,
  DebtConsolidationInvalidLiabilityDto,
  DebtConsolidationLiabilityDisbursementValidationResponse,
  FORM_KEY,
} from '@harmoney/api-interfaces';
import { useBranch, useDisplayDebtConAffordableModal, useFriendlyURL } from '@harmoney/hooks';
import {
  repeatApiUtil,
  useAppDispatch,
  useGetPostApprovalConsolidatedDebtsQuery,
  useGetRefreshedLoansQuery,
  useGetVariablesQuery,
  useValidateDisbursementsMutation,
} from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Form, useForm } from '@harmoney/ui-design-system';
import { errors } from '@harmoney/ui-utils';
import { isHarmoneyDebt, isNonHarmoneyDebt, LiabilityProvider, parseNZBankAccount } from '@harmoney/utilities';
import { BranchEnum, PaymentTypeEnum, Prisma } from '@prisma/client';
import { z } from 'zod';

import { CommonProps } from '../../common-props';
import { LoanBalanceAffordableModal } from '../../loan-balance';

import { DebtPaymentDetail } from './DebtPaymentDetail/DebtPaymentDetail';
import { HarmoneyPaymentDetail } from './HarmoneyPaymentDetail/HarmoneyPaymentDetail';

const bsbSchema = z.object({
  bsbNumber: z.string(),
  bsbAccountNumber: z.string().regex(/^\d{4,9}$/, { message: 'Please enter 4-9 digit number' }),
  bsbReference: z.string().min(1, { message: errors.requiredField('Reference') }),
});
const bpaySchema = z.object({
  bpayBillerCode: z.string().regex(/^\d{4,6}$/, { message: 'Please enter a 4-6 digit number' }),
  bpayReference: z.string().regex(/^\d{2,20}$/, { message: 'Please enter a 2-20 digit number' }),
});

const nzPaymentDetailSchema = z.object({
  fullAccountNumber: z
    .string()
    .min(1, { message: 'Please enter an account number' })
    .min(18, { message: 'Account number too short. Please enter a 15 or 16 digit number.' })
    .max(19, { message: 'Account number too long. Please enter a 15 or 16 digit number.' }),
  code: z.string().min(1, { message: 'Please enter a code' }).max(12, { message: 'Maximum 12 characters' }),
  reference: z.string().min(1, { message: 'Please enter a reference' }).max(12, { message: 'Maximum 12 characters' }),
});

const baseFormSchema = z.array(
  z
    .discriminatedUnion('billerType', [
      z
        .object({
          billerType: z.literal(PaymentTypeEnum.BSB),
        })
        .merge(bsbSchema),
      z
        .object({
          billerType: z.literal(PaymentTypeEnum.BPAY),
        })
        .merge(bpaySchema),
    ])
    .refine(
      (val) => {
        const bpayBillerCode = val['bpayBillerCode'];
        if (val.billerType === PaymentTypeEnum.BPAY && (bpayBillerCode?.length < 4 || bpayBillerCode?.length > 6))
          return false;
        return true;
      },
      {
        message: 'Please enter 4-6 digit number',
        path: ['bpayBillerCode'],
      }
    )
    .refine(
      (val) => {
        const bpayReference = val['bpayReference'];
        if (val.billerType === PaymentTypeEnum.BPAY && (bpayReference?.length < 2 || bpayReference?.length > 20))
          return false;
        return true;
      },
      {
        message: 'Please enter 2-20 digit number',
        path: ['bpayReference'],
      }
    )
    .refine(
      (val) => {
        const bsbNumber = val['bsbNumber'];
        if (val.billerType === PaymentTypeEnum.BSB && (bsbNumber?.length < 7 || bsbNumber?.length > 7)) return false;
        return true;
      },
      {
        message: 'Please enter a valid 6 digit BSB number',
        path: ['bsbNumber'],
      }
    )
    .refine(
      (val) => {
        const bsbAccountNumber = val['bsbAccountNumber'];
        if (val.billerType === PaymentTypeEnum.BSB && (bsbAccountNumber?.length < 4 || bsbAccountNumber?.length > 9))
          return false;
        return true;
      },
      {
        message: 'Please enter a 4-9 digit number',
        path: ['bsbAccountNumber'],
      }
    )
);

const rootSchemaForAU = z.object({
  debts: baseFormSchema,
});

const rootSchemaForNZ = z.object({
  debts: z.array(nzPaymentDetailSchema),
});

export function DebtPayment({ taskId, taskFriendlyURL, completeTaskWithData }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const branch = useBranch();

  const { data: variables } = useGetVariablesQuery(taskId);
  const [failedVerifications, setFailedVerfications] = useState<DebtConsolidationInvalidLiabilityDto[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: refreshedLoanData } = useGetRefreshedLoansQuery(variables?.loanApplicationId?.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const dispatch = useAppDispatch();

  const [validateDisbursements] = useValidateDisbursementsMutation();

  const { data: consolidatedDebts } = useGetPostApprovalConsolidatedDebtsQuery(null, {
    refetchOnMountOrArgChange: true,
    skip: !refreshedLoanData,
  });

  const form = useForm({
    mode: 'onTouched',
    schema: branch === BranchEnum.AU ? rootSchemaForAU : rootSchemaForNZ,
    defaultValues: {
      debts: [],
    },
  });

  const { showLoanDetailsAffordableModal, setShowLoanDetailsAffordableModal } = useDisplayDebtConAffordableModal({
    refreshedLoanData,
    onUnaffordable: async () => await completeTaskWithData({ taskId, variables: { nextStep: 'decline' } }),
  });

  const resetFailedVerifications = () => setFailedVerfications([]);

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    resetFailedVerifications();
    const loanApplicationId = variables.loanApplicationId.toString();
    const disbursements: Prisma.DisbursementCreateManyInput[] = [];
    data.debts.map((d, i) => {
      if (d.fullAccountNumber) {
        const { bankCode, branchCode, accountNumber, accountSuffix } = parseNZBankAccount(d.fullAccountNumber);
        d['bankCode'] = bankCode;
        d['branchCode'] = branchCode;
        d['accountNumber'] = accountNumber;
        d['accountSuffix'] = accountSuffix;
      }

      const paymentType =
        d['billerType'] === PaymentTypeEnum.BSB ||
        (d['bankCode'] && d['branchCode'] && d['accountNumber'] && d['accountSuffix'])
          ? PaymentTypeEnum.directCredit
          : d['billerType'];
      const liability = consolidatedNonHarmoneyDebts[i];
      disbursements.push({
        loanApplicationId,
        liabilityId: liability.id,
        paymentType,
        amount: liability.outstandingBalance,
        bpayBillerCode: d['bpayBillerCode'],
        reference: d['bpayReference'] || d['bsbReference'] || d['reference'],
        bsb: d['bsbNumber'],
        accountNumber: d['bsbAccountNumber'] || d['accountNumber'],
        accountName: liability.provider,
        bankCode: d['bankCode'],
        branchCode: d['branchCode'],
        accountSuffix: d['accountSuffix'],
        code: d['code'],
      });
    });
    consolidatedHarmoneyDebts.forEach((debt) => {
      disbursements.push({
        loanApplicationId,
        liabilityId: debt.id,
        paymentType: PaymentTypeEnum.NppCreditBankAccount,
        amount: debt.outstandingBalance,
        accountName: LiabilityProvider.Harmoney,
        reference: debt.otherProvider,
      });
    });

    let validationResult: DebtConsolidationLiabilityDisbursementValidationResponse;
    try {
      validationResult = await validateDisbursements(disbursements).unwrap();
    } catch (error) {
      setFailedVerfications([
        ...failedVerifications,
        { liabilityId: '', status: DebtConsolidationDisbursementErrorStatus.HTTP_EXCEPTION },
      ]);
      setIsSubmitting(false);
      return;
    }

    if (!validationResult.success) {
      setFailedVerfications(validationResult.failedVerifications);
      setIsSubmitting(false);
      return;
    }

    await completeTaskWithData({
      taskId,
      formKey: FORM_KEY.DEBT_CONSOLIDATION_DEBT_PAYMENT_UPDATE,
      formData: { disbursements },
    }).unwrap();

    // Invalidate the RepeatEligibility tag to not display the debt con affordable modal again on the next component due to stale cache
    dispatch(repeatApiUtil.invalidateTags(['RepeatEligibility']));
  };

  const { consolidatedHarmoneyDebts, consolidatedNonHarmoneyDebts } = useMemo(() => {
    const consolidatedHarmoneyDebts = consolidatedDebts?.filter(isHarmoneyDebt);
    const consolidatedNonHarmoneyDebts = consolidatedDebts?.filter(isNonHarmoneyDebt);
    return { consolidatedHarmoneyDebts, consolidatedNonHarmoneyDebts };
  }, [consolidatedDebts]);

  const handleCloseLoanBalanceAffordableModal = () => setShowLoanDetailsAffordableModal(false);

  useEffect(() => console.log(failedVerifications), [failedVerifications]);

  return (
    <>
      <h1>
        Your <span className="text-primary">debt payments</span>
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        {consolidatedNonHarmoneyDebts && consolidatedNonHarmoneyDebts.length > 0 && (
          <DebtPaymentDetail failedVerifications={failedVerifications} debts={consolidatedNonHarmoneyDebts} />
        )}

        {consolidatedHarmoneyDebts && consolidatedHarmoneyDebts?.length > 0 && (
          <HarmoneyPaymentDetail debts={consolidatedHarmoneyDebts} />
        )}
        <Button
          alignIcon="end"
          isLoading={isSubmitting}
          disabled={!consolidatedDebts}
          type="submit"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          hasShadow
        >
          Continue
        </Button>
      </Form>
      {refreshedLoanData && refreshedLoanData.repaymentFrequency && (
        <LoanBalanceAffordableModal
          isOpen={showLoanDetailsAffordableModal}
          onModalOpen={handleCloseLoanBalanceAffordableModal}
          onContinue={handleCloseLoanBalanceAffordableModal}
          quoteDetails={refreshedLoanData}
        />
      )}
    </>
  );
}
