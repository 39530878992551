import React, { useEffect } from 'react';
import { DisbursalBankAccountDetail, UpdateDisbursalBankAccountDetailDto } from '@harmoney/api-interfaces';
import { useAppDispatch, useAppSelector } from '@harmoney/redux';
import { Card } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import type { User, UserProfile } from '@prisma/client';

import { setSelectedDisbursalBankAccountHistory } from '../../../../redux/slice/admin';
import { maskNormalizedBankAccount } from '../../../fulfilment/Payment/utils';
import { AuditSidePanel } from '../../common/AuditSidePanelDetails';

type ContentProp = {
  title: string;
  detail: DisbursalBankAccountDetail;
};

function BpayContent({ detail, title }: ContentProp) {
  if (!('bpayBillerCode' in detail)) return <></>;

  return (
    <>
      <strong>{title}</strong>
      <div className="flex flex-row justify-between">
        <span>BPAY biller code</span>
        <span>{detail.bpayBillerCode}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>BPAY reference</span>
        <span>{detail.reference}</span>
      </div>
    </>
  );
}

function BsbContent({ detail, title }: ContentProp) {
  if (!('accountBsb' in detail)) return <></>;
  return (
    <>
      <strong>{title}</strong>
      <div className="flex flex-row justify-between">
        <span>BSB number</span>
        <span>{detail.accountBsb}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Account number</span>
        <span>{detail.accountNumber}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Reference</span>
        <span>{detail.reference}</span>
      </div>
    </>
  );
}

function AccountNumberContent({ from, to }: UpdateDisbursalBankAccountDetailDto) {
  if (!('accountBsb' in from) || !('accountBsb' in to)) return <></>;
  return (
    <>
      <strong>Previous</strong>
      <div className="flex flex-row justify-between">
        <span>Account number</span>
        <div className="flex flex-col">
          <span className="font-medium">{from.bankName}</span>
          <span>{maskNormalizedBankAccount(from.accountBsb, from.accountNumber)}</span>
        </div>
      </div>
      <hr className="border-grey-2" />
      <strong>Previous</strong>
      <div className="flex flex-row justify-between">
        <span>Account number</span>
        <div className="flex flex-col">
          <span className="font-medium">{from.bankName}</span>
          <span>{maskNormalizedBankAccount(from.accountBsb, from.accountNumber)}</span>
        </div>
      </div>

      <hr className="border-grey-2" />
      <BsbContent title="Current" detail={to} />
    </>
  );
}

function DebtConDisbursalDetails({ from, to }: UpdateDisbursalBankAccountDetailDto) {
  const isDebtCon = !!from.debtConDisbursementId;
  const isFromBpay = 'bpayBillerCode' in from;
  const isToBpay = 'bpayBillerCode' in to;

  return (
    <>
      {isDebtCon && (
        <div className="flex flex-row justify-between">
          <span>Paid To</span>
          <span className="font-medium">{from.bankName || to.bankName}</span>
        </div>
      )}

      <div className="flex flex-row justify-between">
        <span>Type</span>
        <span className="font-medium">{from?.productName || '-'}</span>
      </div>
      <div className="flex flex-row justify-between">
        <span>Amount they will receive</span>
        <span className="font-medium">{formatCurrency(from?.amount ?? 0)}</span>
      </div>

      <hr className="border-grey-2" />
      {!isDebtCon ? (
        <AccountNumberContent from={from} to={to} />
      ) : (
        <>
          {isFromBpay ? <BpayContent title="Previous" detail={from} /> : <BsbContent title="Previous" detail={from} />}
          <hr className="border-grey-2" />
          {isToBpay ? <BpayContent title="Current" detail={to} /> : <BsbContent title="Current" detail={to} />}
        </>
      )}
    </>
  );
}

function PersonalLoanDetails({ from, to }: UpdateDisbursalBankAccountDetailDto) {
  if (!('accountBsb' in from) || !('accountBsb' in to)) return <></>;
  return (
    <>
      <div className="col-span-1">Previous disbursal account:</div>
      <div className="col-span-1 flex flex-col">
        <span className="font-medium">{from.bankName}</span>
        <small>
          {from.accountBsb}-{from.accountNumber}
        </small>
      </div>

      <div className="col-span-1">Updated disbursal account:</div>
      <div className="col-span-1 flex flex-col">
        <span className="font-medium">{to.bankName}</span>
        <small>
          {to.accountBsb}-{to.accountNumber}
        </small>
      </div>
    </>
  );
}

export default function DisbursalBankAccountHistorySidePanel() {
  const dispatch = useAppDispatch();
  const adminSlice = useAppSelector((state) => state.admin);
  const history = adminSlice.selectedDisbursalBankAccountHistory;

  useEffect(() => {
    return () => {
      dispatch(setSelectedDisbursalBankAccountHistory(undefined));
    };
  }, [dispatch]);

  if (!history) {
    dispatch(setSelectedDisbursalBankAccountHistory(undefined));
    return <></>;
  }

  const user = history.createdBy as User & UserProfile;
  let createdBy = ((user.firstName ?? '') + ' ' + (user.lastName ?? '')).trim();
  if (!createdBy) createdBy = user.email;

  const containsDebtCon = history.updates.some((update) => !!update.from.debtConDisbursementId);

  return (
    <AuditSidePanel
      createdAt={history.createdAt}
      createdBy={createdBy}
      note={history.note}
      headerTitle="Update disbursal account"
      onCancelClick={() => {
        dispatch(setSelectedDisbursalBankAccountHistory(undefined));
      }}
    >
      <div className="col-span-2 flex flex-col gap-2">
        {containsDebtCon ? (
          <>
            {history.updates.map(({ from, to }, idx) => (
              <Card key={idx}>
                <div className="flex flex-col gap-2">
                  <DebtConDisbursalDetails from={from} to={to} />
                </div>
              </Card>
            ))}
          </>
        ) : (
          <Card>
            <div className="flex flex-col gap-2">
              <PersonalLoanDetails from={history.updates[0].from} to={history.updates[0].to} />
            </div>
          </Card>
        )}
      </div>
    </AuditSidePanel>
  );
}
