import { BranchEnum } from '@prisma/client';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';

import { footerContent, headerContent, websiteAndContactContent } from '../../utils';

import { DecimalFormatter } from './loan-disclosure-template-v1';

interface docTemplateProps {
  data: LoanDisclosureVarationDetailDto;
  fonts?: any;
}

export interface LoanDisclosureVarationDetailDto {
  loanNumber: string;
  branch: BranchEnum;
  isVariation: boolean;
  isInterestChargeDifferent: boolean;
  isEachPaymentDifferent: boolean;
  isFinalPaymentDifferent: boolean;
  isNumberOfRepaymentsDifferent: boolean;
  isTotalPaymentDifferent: boolean;
  userProfileDetail: {
    lastName: string;
    firstName: string;
  };
  addressDetail: {
    streetNumber: string;
    streetName: string;
    suburb: string;
    state: string;
    postalCode: string;
  };
  variationDetail: {
    dateOfAdvance: string;
    totalInterestCharge: number;
    eachPaymentAmount: number;
    finalPaymentAmount: number;
    numberOfPayments: number;
    totalPaymentAmount: number;
    monthlyPaymentAmount: number;
    nextMonthlyPaymentDate: string;
  };
  disclosureDetail: {
    dateOfAdvance: string;
    totalInterestCharge: number;
    eachPaymentAmount: number;
    finalPaymentAmount: number;
    numberOfPayments: number;
    totalPaymentAmount: number;
  };
}

export const LoanDisclosureVariationTemplate = ({ data, fonts }: docTemplateProps) => {
  Font.register({
    family: 'GothamHTF-Bold',
    src: fonts?.gothamBold?.url,
  });
  Font.register({ family: 'GothamHTF-Book', src: fonts?.gothamBook?.url });

  const styles = StyleSheet.create({
    page: {
      paddingVertical: 20,
      paddingHorizontal: 25,
      paddingBottom: '90px',
    },
    headerLogo: {
      paddingBottom: '30px',
      width: '110px',
      height: 'auto',
    },
    rightAlign: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    titleText: {
      fontFamily: 'GothamHTF-Bold',
      fontSize: '14px',
      paddingTop: '30px',
      paddingBottom: '15px',
      display: 'flex',
      alignSelf: 'center',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '15px',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '5px',
    },
    listBullet: {
      width: '10px',
    },
    listText: {
      flex: '1px',
    },
    striped: {
      backgroundColor: '#f9f9f9',
    },
  });

  return (
    <Document>
      <Page style={[styles.page, { fontFamily: 'GothamHTF-Book', fontSize: '10px', lineHeight: 1.35 }]}>
        <View style={styles.rightAlign} fixed>
          <Image
            src="https://production-harmoney-cms-shared-assets.s3.ap-southeast-2.amazonaws.com/public/Logos/hmy-logo.png"
            style={styles.headerLogo}
          />
        </View>
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '15px' }}>
          {headerContent[data.branch]}
        </View>
        <Text style={{ marginTop: '15px' }}>{dayjs().format('DD MMMM YYYY')}</Text>
        <View style={{ marginTop: '15px' }}>
          <Text>
            {data.userProfileDetail.firstName} {data.userProfileDetail.lastName}
          </Text>
          <Text>
            {data.addressDetail.streetNumber} {data.addressDetail.streetName}
          </Text>
          <Text>{data.addressDetail.suburb}</Text>
          <Text>
            {data.addressDetail.state} {data.addressDetail.postalCode}
            {'\n'}
            {'\n'}
          </Text>
        </View>

        <Text>
          Dear {data.userProfileDetail.firstName} {data.userProfileDetail.lastName},
        </Text>

        {data.branch === BranchEnum.AU && (
          <View>
            <Text style={styles.titleText}>Variation due to different Settlement Date {data.loanNumber}</Text>
            <Text>
              We refer to your loan contract with Harmoney Australia Pty Ltd dated {data.disclosureDetail.dateOfAdvance}
              .{'\n'}
              {'\n'}
            </Text>
          </View>
        )}

        {data.branch === BranchEnum.NZ && (
          <View>
            <Text style={styles.titleText}>Variation due to different Date of Advance {data.loanNumber}</Text>
            <Text>
              We refer to your loan contract with Harmoney Services Limited dated {data.disclosureDetail.dateOfAdvance}.
              {'\n'}
              {'\n'}
            </Text>
          </View>
        )}

        {!data.isVariation && (
          <View>
            {data.branch === BranchEnum.AU && (
              <View>
                <Text>
                  We note that your Loan Agreement Details were prepared in the expectation that your Settlement Date
                  would be {data.disclosureDetail.dateOfAdvance}. Please note that your actual Settlement Date was&nbsp;
                  {data.variationDetail.dateOfAdvance}, however all other terms of your loan contract remain unchanged
                  and effective.
                  {'\n'}
                  {'\n'}
                </Text>
              </View>
            )}
            {data.branch === BranchEnum.NZ && (
              <View>
                <Text>
                  We note that your Loan Disclosure Statement was prepared in the expectation that your Date of Advance
                  would be {data.disclosureDetail.dateOfAdvance}. Please note that your actual Date of Advance was&nbsp;
                  {data.variationDetail.dateOfAdvance}, however all other terms of your loan contract remain unchanged
                  and effective.
                  {'\n'}
                  {'\n'}
                </Text>
              </View>
            )}
          </View>
        )}

        {data.isVariation && (
          <View>
            {data.branch === BranchEnum.AU && (
              <View>
                <Text>
                  We note that your Loan Agreement Details were prepared in the expectation that your Settlement Date
                  would be {data.disclosureDetail.dateOfAdvance}. Due to your actual Date of Advance being&nbsp;
                  {data.variationDetail.dateOfAdvance}, please note the following consequential changes to your loan
                  contract:
                  {'\n'}
                  {'\n'}
                </Text>
              </View>
            )}
            {data.branch === BranchEnum.NZ && (
              <View>
                <Text>
                  We note that your Loan Disclosure Statement was prepared in the expectation that your Date of Advance
                  would be {data.disclosureDetail.dateOfAdvance}. The loan contract provides that we may update your
                  repayment details if the actual Date of Advance occurs on a different date.
                  {'\n'}
                  {'\n'}
                  Due to your actual Date of Advance being {data.variationDetail.dateOfAdvance}, please note the
                  following consequential changes to your loan contract:
                  {'\n'}
                  {'\n'}
                </Text>
              </View>
            )}
            <View style={styles.list}>
              {data.isInterestChargeDifferent && (
                <View style={styles.listItem}>
                  <Text style={styles.listBullet}>•</Text>
                  <Text style={styles.listText}>
                    Your total interest charges will be{' '}
                    {DecimalFormatter.format(data.variationDetail.totalInterestCharge)} instead of{' '}
                    {DecimalFormatter.format(data.disclosureDetail.totalInterestCharge)}.
                  </Text>
                </View>
              )}
              {data.isEachPaymentDifferent && (
                <View style={styles.listItem}>
                  <Text style={styles.listBullet}>•</Text>
                  <Text style={styles.listText}>
                    The amount of each payment, except your final payment, will be{' '}
                    {DecimalFormatter.format(data.variationDetail.eachPaymentAmount)} instead of{' '}
                    {DecimalFormatter.format(data.disclosureDetail.eachPaymentAmount)}.
                  </Text>
                </View>
              )}
              {data.isFinalPaymentDifferent && (
                <View style={styles.listItem}>
                  <Text style={styles.listBullet}>•</Text>
                  <Text style={styles.listText}>
                    The amount of your final payment will be{' '}
                    {DecimalFormatter.format(data.variationDetail.finalPaymentAmount)} instead of{' '}
                    {DecimalFormatter.format(data.disclosureDetail.finalPaymentAmount)}.
                  </Text>
                </View>
              )}
              {data.isNumberOfRepaymentsDifferent && (
                <View style={styles.listItem}>
                  <Text style={styles.listBullet}>•</Text>
                  <Text style={styles.listText}>
                    Your total number of payments will be {data.variationDetail.numberOfPayments} instead of{' '}
                    {data.disclosureDetail.numberOfPayments}.
                  </Text>
                </View>
              )}
              {data.isTotalPaymentDifferent && (
                <View style={styles.listItem}>
                  <Text style={styles.listBullet}>•</Text>
                  <Text style={styles.listText}>
                    Your total amount of payments will be{' '}
                    {DecimalFormatter.format(data.variationDetail.totalPaymentAmount)} instead of{' '}
                    {DecimalFormatter.format(data.disclosureDetail.totalPaymentAmount)}.
                  </Text>
                </View>
              )}
            </View>
            <View>
              <Text>
                Consequently, we have updated your Direct Debit, and your next required monthly payment of{' '}
                {DecimalFormatter.format(data.variationDetail.monthlyPaymentAmount)} will be due for payment on{' '}
                {data.variationDetail.nextMonthlyPaymentDate}.{'\n'}
                {'\n'}
                Other than the changes set out in this letter, all other terms of your loan contract remain unchanged
                and effective.
                {'\n'}
                {'\n'}
              </Text>
            </View>
          </View>
        )}

        {data.branch === BranchEnum.AU && (
          <View>
            <Text>
              This letter constitutes variation disclosure for the purposes of the National Credit Code. Your loan
              details will also be updated in your account dashboard, which you can access at www.harmoney.com.au.
              {'\n'}
              {'\n'}
              If you have any further questions, please do not hesitate to contact us on 1300 042 766.
              {'\n'}
              {'\n'}
            </Text>
          </View>
        )}

        {data.branch === BranchEnum.NZ && (
          <View>
            <Text>
              This letter constitutes variation disclosure for the purposes of the Credit Contracts and Consumer Finance
              Act 2003. Your loan details will also be updated in your account dashboard, which you can access at
              {'\n'}
              www.harmoney.co.nz.
              {'\n'}
              {'\n'}
              If you have any further questions, please do not hesitate to contact us on 0800 427 666.
              {'\n'}
              {'\n'}
            </Text>
          </View>
        )}

        <Text>
          Yours faithfully,
          {'\n'}
          {'\n'}
        </Text>
        <Text>Harmoney</Text>
        <View style={{ position: 'absolute', bottom: 0, left: 0, paddingBottom: 20, paddingLeft: 40 }} fixed>
          <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '6px' }}>{websiteAndContactContent[data.branch]}</Text>
          <Text style={{ fontFamily: 'GothamHTF-Book', paddingTop: '5px', fontSize: '8px' }}>
            {footerContent[data.branch]}
          </Text>
        </View>
      </Page>
    </Document>
  );
};
