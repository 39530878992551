import { RepaymentFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';

export const getNextPaymentDate = (startDate: string, frequency: RepaymentFrequencyEnum) => {
  let nextPaymentDate: dayjs.Dayjs;

  switch (frequency) {
    case RepaymentFrequencyEnum.monthly:
      nextPaymentDate = dayjs(startDate).add(1, 'month');
      break;
    case RepaymentFrequencyEnum.weekly:
      nextPaymentDate = dayjs(startDate).add(1, 'week');
      break;
    case RepaymentFrequencyEnum.fortnightly:
      nextPaymentDate = dayjs(startDate).add(2, 'week');
      break;
    default:
      throw new Error('Invalid payment frequency');
  }

  return nextPaymentDate.format('YYYY-MM-DD');
};

/**
 * This method is used to get recurring payment Date for monthly, weekly and fortnightly
 * Incase of monthly frequency if the start date is Jan 31, the next payment date will be Feb 28 and 
 * next payment date will be Mar 31st instead of Mar 28th
 * check end date clause : Jan 31 + 1 month = Feb 28
    Feb 28 + 1 month = Mar 28 incorrect should be Mar 31
    Mar 28 + 1 month = Apr 28 incorrect should be Apr 30
 * @param startDate 
 * @param frequency 
 * @returns 
 */
export const getNextRecurringPaymentDate = (
  firstPaymentDate: string,
  recurringPaymentDate: string,
  frequency: RepaymentFrequencyEnum
) => {
  let nextRecurringPaymentDate: dayjs.Dayjs;

  switch (frequency) {
    case RepaymentFrequencyEnum.monthly:
      nextRecurringPaymentDate = nextMonthlyPaymentDate(recurringPaymentDate, firstPaymentDate);
      break;
    case RepaymentFrequencyEnum.weekly:
      nextRecurringPaymentDate = dayjs(recurringPaymentDate).add(1, 'week');
      break;
    case RepaymentFrequencyEnum.fortnightly:
      nextRecurringPaymentDate = dayjs(recurringPaymentDate).add(2, 'week');
      break;
    default:
      throw new Error('Invalid payment frequency');
  }

  return nextRecurringPaymentDate.format('YYYY-MM-DD');
};

export function nextMonthlyPaymentDate(currentDate: string, loanAcceptanceDate: string): dayjs.Dayjs {
  const acceptanceDate = dayjs(loanAcceptanceDate);
  const current = dayjs(currentDate);
  const nextMonth = current.add(1, 'month');
  const nextMonthDays = nextMonth.daysInMonth();

  let paymentDate;
  if (acceptanceDate.date() >= 1 && acceptanceDate.date() <= 28) {
    // If the acceptance date is between the 1st and 28th of any month
    paymentDate = nextMonth.date(acceptanceDate.date());
  } else if (acceptanceDate.date() >= 29 && acceptanceDate.date() <= 31) {
    // If the acceptance date is on the 29th, 30th, or 31st of any month
    if (nextMonthDays < acceptanceDate.date()) {
      // If the next month does not have that date, use the last day of the month
      paymentDate = nextMonth.date(nextMonthDays);
    } else {
      // Otherwise, use the same day in the following month
      paymentDate = nextMonth.date(acceptanceDate.date());
    }
  }

  return paymentDate;
}

// get next repayment date after payment arrangement expiry
export const getNextRepaymentDateAfterPaymentArrangementExpiry = (
  originalLatestPaymentDate: string,
  originalFrequency: RepaymentFrequencyEnum,
  paymentPlanExpiryDate: string
) => {
  let nextRepaymentDate: string = originalLatestPaymentDate;
  while (
    dayjs(nextRepaymentDate).isBefore(dayjs(paymentPlanExpiryDate)) ||
    dayjs(nextRepaymentDate).isSame(dayjs(paymentPlanExpiryDate))
  ) {
    nextRepaymentDate = getNextRecurringPaymentDate(originalLatestPaymentDate, nextRepaymentDate, originalFrequency);
  }
  return nextRepaymentDate;
};
