import { EmploymentType } from '@harmoney/api-interfaces';
import { EmploymentCodeEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { kebabCase } from 'lodash';

export const initialFormValues = ({ id, code }, isCustomerFacing = true) => {
  const commonValues = {
    networthSourceId: id,
    declaredAmount: null,
    frequency: '',
  };

  const commonValuesWithHiddenField = {
    networthSourceId: id,
    declaredAmount: null,
    frequency: '',
    hiddenValidationField: false,
  };

  const formConfig = {
    salary: {
      networthSourceId: id,
      employmentCode: !isCustomerFacing ? EmploymentCodeEnum.TRADE : '',
      employmentType: !isCustomerFacing ? EmploymentType.FULL_TIME : '',
      startEmploymentMonth: !isCustomerFacing ? dayjs().format('MM') : '',
      startEmploymentYear: !isCustomerFacing ? dayjs().format('YYYY') : '',
      declaredAmount: null,
      seasonalWorkingMonths: '',
      frequency: '',
      hasBonus: '',
      bonusAmountValue: 0,
      bonusAmountFrequency: '',
    },
    'self-employed': {
      selfEmployedType: '',
      ...commonValues,
    },
    'rental-income': {
      isRentalIncomeShared: '',
      ...commonValues,
    },
    benefit: {
      networthSourceId: id,
      benefitType: '',
      benefitName: '',
      declaredAmount: null,
      frequency: '',
    },
    'spousal-support': commonValuesWithHiddenField,
    pension: commonValuesWithHiddenField,
    'other-income-sources': {
      otherIncomeType: '',
      ...commonValues,
    },
    'interest-dividend': commonValuesWithHiddenField,
    'retirement-funds': commonValuesWithHiddenField,
    'student-payments': commonValuesWithHiddenField,
    'no-income': {
      networthSourceId: id,
    },
  };

  const defaultValue = {};

  const incomeFormValues = formConfig[kebabCase(code)];

  return incomeFormValues || defaultValue;
};
