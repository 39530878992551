import { useEffect } from 'react';
import { DisbursementDto } from '@harmoney/api-interfaces';
import { useBsbSearch } from '@harmoney/hooks';
import { ControlledInput, Spinner, useFormContext } from '@harmoney/ui-design-system';
import { accountNumberInputFormat, bsbInputFormat } from '@harmoney/utilities';
import { Icon } from '@iconify/react';

type Props = {
  disbursal: DisbursementDto;
};

export function BsbForm({ disbursal }: Props) {
  const { register, setValue, resetField, setError, clearErrors } = useFormContext();

  const { invalidBsb, isLoadingBsb, bsbBankDetails, setBsbInputValue } = useBsbSearch({
    onBankDetailsChanged(bankDetails) {
      if (!bankDetails?.bsb) {
        resetField(`updates.${disbursal.id}.newBankAccount.bankName`);
        return;
      }
      setValue(`updates.${disbursal.id}.newBankAccount.bankName`, bankDetails.bankCode);
    },
    onError(message) {
      const fieldName = `updates.${disbursal.id}.newBankAccount.accountBsb`;
      if (!message) clearErrors(fieldName);
      setError(fieldName, { message });
    },
  });

  useEffect(() => {
    setValue(`updates.${disbursal.id}.newBankAccount.type`, 'bsb');
  }, [disbursal.id]);

  return (
    <>
      <ControlledInput
        type="text"
        label="Account name"
        {...register(`updates.${disbursal.id}.newBankAccount.accountName`)}
      />
      <ControlledInput
        helpText="6 digit number"
        type="string"
        minLength={6}
        pattern={bsbInputFormat.source}
        label="BSB number"
        suffix={
          isLoadingBsb ? (
            <Spinner />
          ) : bsbBankDetails ? (
            bsbBankDetails?.bankCode
          ) : invalidBsb ? (
            <Icon fontSize="1.2rem" icon="mingcute:warning-line" />
          ) : (
            ''
          )
        }
        onInput={(e) => {
          setBsbInputValue((e.target as HTMLInputElement).value);
        }}
        {...register(`updates.${disbursal.id}.newBankAccount.accountBsb`)}
      />
      <ControlledInput
        helpText="4-9 digit number"
        type="text"
        minLength={4}
        pattern={accountNumberInputFormat.source}
        label="Account number"
        {...register(`updates.${disbursal.id}.newBankAccount.accountNumber`)}
      />
      <ControlledInput
        type="text"
        label="Reference"
        {...register(`updates.${disbursal.id}.newBankAccount.reference`)}
      />
    </>
  );
}
