import { FullStoryAnalytics } from './fullstory';
import { RudderAnalytics } from './rudderstack';
import { Metadata } from './metadata';
import { isDevelopment, isPreview, isProduction } from '../util';

type Options = 'Rudderstack' | 'GTM' | 'All';

interface SendToOption {
  to?: Options;
  gtmOptions?: KeyValuePair;
}

export interface EventAnalyticType {
  identify(userId: string, traits?: KeyValuePair, apiOptions?: KeyValuePair & SendToOption): void;
  page(category?: string, name?: string, properties?: KeyValuePair, apiOptions?: KeyValuePair & SendToOption): void;
  track(event: string, properties?: KeyValuePair, apiOptions?: KeyValuePair & SendToOption): void;
  alias(to: string, from?: string): void;
  group(groupId: string, traits?: KeyValuePair): void;
  reset(flag?: boolean): void;
}

export interface KeyValuePair {
  [key: string]: any;
}

/**
 * We utilise Inversion of Control for EventAnalytics class as the concrete implementation of RudderstackAnalytics
 * can probably move away in the future. And it also gives us flexibility to add another analytic tool with unified API.
 */
export class EventAnalytics implements EventAnalyticType {
  constructor(
    private rudderAnalytics = new RudderAnalytics(),
    private fullStoryAnalytics = new FullStoryAnalytics(),
    private metadata = new Metadata()
  ) {}

  get isLoaded() {
    return this.rudderAnalytics.isLoaded;
  }

  async load() {
    await this.rudderAnalytics.load();
  }

  // FullStory is a special case as it is loaded based on role so we need to load it separately
  // To test FullStory in non-production environments, you need to remove the condition `isDevelopment() || isPreview()`
  // And provide the full URL you are going to test to IT Ops team to whitelist it in FullStory
  loadFullStory() {
    if (isDevelopment() || isPreview()) return;
    this.fullStoryAnalytics.load();
  }

  private getDestination(apiOptions?: KeyValuePair & SendToOption): Options {
    if (apiOptions === undefined || apiOptions.to === undefined) {
      return 'All';
    } else {
      return apiOptions.to;
    }
  }

  identify(userId: string, traits?: KeyValuePair, apiOptions?: KeyValuePair & SendToOption) {
    const destination = this.getDestination(apiOptions);
    if (destination !== 'GTM') {
      this.rudderAnalytics.identify(userId, traits, apiOptions);
      this.fullStoryAnalytics.identify(userId, traits);
    }
    const gtmOptions = apiOptions?.gtmOptions;
    if (destination !== 'Rudderstack' && isProduction() && typeof window !== 'undefined') {
      window.dataLayer?.push({
        ...gtmOptions,
        userId,
        traits,
      });
    }
  }

  trackAnonymousUsers(displayName?: string, traits?: KeyValuePair) {
    this.fullStoryAnalytics.trackAnonymousUsers(displayName, traits);
  }

  page(category: string, name: string, properties?: KeyValuePair, apiOptions?: KeyValuePair & SendToOption): void {
    const destination = this.getDestination(apiOptions);
    const propertiesWithMetadata = { ...properties, ...this.metadata.get(), origin: 'S2' };
    if (destination !== 'GTM') {
      this.rudderAnalytics.page(category, name, propertiesWithMetadata);
    }
    if (destination !== 'Rudderstack' && isProduction() && typeof window !== 'undefined') {
      window.dataLayer?.push({
        category,
        name,
        ...properties,
        origin: 'S2',
      });
    }
  }

  track(event: string, properties: KeyValuePair = {}, apiOptions?: KeyValuePair & SendToOption) {
    const destination = this.getDestination(apiOptions);
    const propertiesWithMetadata = { ...properties, ...this.metadata.get(), origin: 'S2' };
    if (destination !== 'GTM') {
      this.rudderAnalytics.track(event, propertiesWithMetadata, apiOptions);
      this.fullStoryAnalytics.track(event, propertiesWithMetadata);
    }
    if (destination !== 'Rudderstack' && isProduction() && typeof window !== 'undefined') {
      window.dataLayer?.push({
        event,
        ...propertiesWithMetadata,
      });
    }
  }

  alias(to: string, from?: string): void {
    this.rudderAnalytics.alias(to, from);
  }

  group(groupId: string, traits?: KeyValuePair): void {
    this.rudderAnalytics.group(groupId, traits);
  }

  reset(flag?: boolean): void {
    this.rudderAnalytics.reset(flag);
  }
}

export const eventAnalytics = new EventAnalytics();
