import { Alert, ArrowCircleRightIcon, Button, Dialog, Label } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

import { PaymentArrangementSchedule } from './PaymentArrangementSchedule';
import { RepaymentPlanProps } from './RepaymentPlan';

interface PaymentArrangementModalProps {
  isConfirmModalOpen: boolean;
  setIsConfirmModalOpen: (isOpen: boolean) => void;
  onModalSubmit: () => void;
  isSubmitting: boolean;
  extraPaymentAmount: number;
  repaymentDetails: RepaymentPlanProps;
  noEndDate: boolean;
  isEdit?: boolean;
}

const getEditModalContent = () => ({
  title: 'Edit payment arrangement',
  description: 'Are you sure you want to edit the payment arrangement?',
  buttonLabel: 'Save',
});

const getSetModalContent = () => ({
  title: 'Set payment arrangement',
  description: 'Are you sure you want to setup a payment arrangement?',
  buttonLabel: 'Setup',
});

export const PaymentArrangementModal: React.FC<PaymentArrangementModalProps> = ({
  isConfirmModalOpen,
  setIsConfirmModalOpen,
  onModalSubmit,
  isSubmitting,
  repaymentDetails,
  noEndDate,
  extraPaymentAmount,
  isEdit,
}) => {
  const { title, description, buttonLabel } = isEdit ? getEditModalContent() : getSetModalContent();
  return (
    <Dialog
      open={isConfirmModalOpen}
      onOpenChange={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
      title={title}
      modal
    >
      <div className="p-4">
        <div className="text-left text-base font-medium">
          <Label className="mb-4">{description}</Label>
        </div>

        <PaymentArrangementSchedule repaymentDetails={repaymentDetails} hasEndDate={!noEndDate} />

        {!!extraPaymentAmount && extraPaymentAmount > 0 && (
          <Alert variant="warning" className="mb-6">
            This change may not meet the current contractual amount for the loan by {formatCurrency(extraPaymentAmount)}
            .
          </Alert>
        )}
        <div className="shrink-1 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse flex-wrap">
          <Button
            onClick={onModalSubmit}
            type="submit"
            variant="primary"
            className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            isLoading={isSubmitting}
            hasShadow
          >
            {buttonLabel}
          </Button>
          <Button
            onClick={() => setIsConfirmModalOpen(false)}
            variant="secondary"
            disabled={isSubmitting}
            className="!min-w-fit !max-w-fit"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
