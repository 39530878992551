import { useCallback, useEffect } from 'react';
import { UseFormWatch } from 'react-hook-form';
import {
  eventAnalytics,
  QUICK_QUOTE_ACCOMMODATION_EXPENSE_AMOUNT_CHANGED,
  QUICK_QUOTE_ACCOMMODATION_EXPENSE_FREQUENCY_CHANGED,
  QUICK_QUOTE_ADDRESS_PLACE_ID_CHANGED,
  QUICK_QUOTE_DATE_OF_BIRTH_CHANGED,
  QUICK_QUOTE_EMAIL_CHANGED,
  QUICK_QUOTE_EMPLOYMENT_STATUS_CHANGED,
  QUICK_QUOTE_FIRST_NAME_CHANGED,
  QUICK_QUOTE_INCOME_AMOUNT_CHANGED,
  QUICK_QUOTE_INCOME_FREQUENCY_CHANGED,
  QUICK_QUOTE_LAST_NAME_CHANGED,
  QUICK_QUOTE_LIVING_TYPE_CHANGED,
  QUICK_QUOTE_LOAN_AMOUNT_CHANGED,
  QUICK_QUOTE_LOAN_PURPOSE_CHANGED,
  QUICK_QUOTE_MIDDLE_NAME_CHANGED,
  QUICK_QUOTE_NUMBER_OF_DEPENDANTS_CHANGED,
  QUICK_QUOTE_POSTCODE_CHANGED,
  QUICK_QUOTE_RELATIONSHIP_STATUS_CHANGED,
  QUICK_QUOTE_RESIDENCY_STATUS_CHANGED,
  QUICK_QUOTE_STATE_CHANGED,
  QUICK_QUOTE_STREET_ADDRESS_CHANGED,
  QUICK_QUOTE_SUBURB_CHANGED,
  QUICK_QUOTE_UNIT_NUMBER_CHANGED,
} from '@harmoney/ui-app-shell';
import { useDebouncedCallback } from '@mantine/hooks';

import { FormSchemaType as QQFormSchema } from '../components/quick-quote';

export const useTrackQuickQuoteFormChangedEvents = (watch: UseFormWatch<QQFormSchema>, dirtyFields: any) => {
  const watchAmount = watch('amount');
  const watchLoanPurpose = watch('loanPurpose');
  const watchFirstName = watch('firstName');
  const watchMiddleName = watch('middleName');
  const watchLastName = watch('lastName');
  const watchEmail = watch('emailAddress');
  const watchDateOfBirth = watch('dateOfBirth');
  const watchAddressPlaceId = watch('address.placeId');
  const watchUnitNumber = watch('address.unitNumber');
  const watchStreetAddress = watch('address.streetAddress');
  const watchSuburb = watch('address.suburb');
  const watchState = watch('address.state');
  const watchPostcode = watch('address.postcode');
  const watchRelationshipStatus = watch('relationshipStatus');
  const watchResidencyStatus = watch('residencyStatus');
  const watchLivingType = watch('livingType');
  const watchAccommodationExpenseAmount = watch('accommodationExpense.amount');
  const watchAccommodationExpenseFrequency = watch('accommodationExpense.frequency');
  const watchNumberOfDependants = watch('numberOfDependants');
  const watchEmploymentStatus = watch('employmentStatus');
  const watchIncomeAmount = watch('income.amount');
  const watchIncomeFrequency = watch('income.frequency');

  const trackEvent = useCallback(
    (fieldName: string, event: string) => {
      const isFieldDirty = fieldName.split('.').reduce((acc, key) => acc?.[key], dirtyFields) === true;

      // Only fire .track() if the field has been changed manually by the user
      if (isFieldDirty) {
        eventAnalytics.track(event, {}, { integrations: { Intercom: false } });
      }
    },
    [dirtyFields]
  );

  const debouncedTrackEvent = useDebouncedCallback(trackEvent, 500);

  useEffect(() => {
    debouncedTrackEvent('amount', QUICK_QUOTE_LOAN_AMOUNT_CHANGED);
  }, [watchAmount, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('loanPurpose', QUICK_QUOTE_LOAN_PURPOSE_CHANGED);
  }, [watchLoanPurpose, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('firstName', QUICK_QUOTE_FIRST_NAME_CHANGED);
  }, [watchFirstName, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('middleName', QUICK_QUOTE_MIDDLE_NAME_CHANGED);
  }, [watchMiddleName, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('lastName', QUICK_QUOTE_LAST_NAME_CHANGED);
  }, [watchLastName, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('emailAddress', QUICK_QUOTE_EMAIL_CHANGED);
  }, [watchEmail, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('dateOfBirth', QUICK_QUOTE_DATE_OF_BIRTH_CHANGED);
  }, [watchDateOfBirth, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('address.placeId', QUICK_QUOTE_ADDRESS_PLACE_ID_CHANGED);
  }, [watchAddressPlaceId, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('address.unitNumber', QUICK_QUOTE_UNIT_NUMBER_CHANGED);
  }, [watchUnitNumber, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('address.streetAddress', QUICK_QUOTE_STREET_ADDRESS_CHANGED);
  }, [watchStreetAddress, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('address.suburb', QUICK_QUOTE_SUBURB_CHANGED);
  }, [watchSuburb, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('address.state', QUICK_QUOTE_STATE_CHANGED);
  }, [watchState, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('address.postcode', QUICK_QUOTE_POSTCODE_CHANGED);
  }, [watchPostcode, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('relationshipStatus', QUICK_QUOTE_RELATIONSHIP_STATUS_CHANGED);
  }, [watchRelationshipStatus, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('residencyStatus', QUICK_QUOTE_RESIDENCY_STATUS_CHANGED);
  }, [watchResidencyStatus, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('livingType', QUICK_QUOTE_LIVING_TYPE_CHANGED);
  }, [watchLivingType, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('accommodationExpense.amount', QUICK_QUOTE_ACCOMMODATION_EXPENSE_AMOUNT_CHANGED);
  }, [watchAccommodationExpenseAmount, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('accommodationExpense.frequency', QUICK_QUOTE_ACCOMMODATION_EXPENSE_FREQUENCY_CHANGED);
  }, [watchAccommodationExpenseFrequency, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('numberOfDependants', QUICK_QUOTE_NUMBER_OF_DEPENDANTS_CHANGED);
  }, [watchNumberOfDependants, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('employmentStatus', QUICK_QUOTE_EMPLOYMENT_STATUS_CHANGED);
  }, [watchEmploymentStatus, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('income.amount', QUICK_QUOTE_INCOME_AMOUNT_CHANGED);
  }, [watchIncomeAmount, debouncedTrackEvent]);

  useEffect(() => {
    debouncedTrackEvent('income.frequency', QUICK_QUOTE_INCOME_FREQUENCY_CHANGED);
  }, [watchIncomeFrequency, debouncedTrackEvent]);
};
