export const bankNameMapper: Record<string, string> = {
  anz: 'ANZ',
  anzdirect: 'ANZ Direct',
  anznz: 'ANZ New Zealand',
  anztransactivebb: 'ANZ Transactive Business Banking New Zealand',
  aotearoa: 'Aoteatoa Credit Union',
  asb: 'ASB',
  asbbb: 'ASB Business',
  auswide: 'Auswide Bank',
  bankaust: 'Bank Australia',
  banksa: 'BankSA',
  bankwest: 'Bankwest',
  bankwestbb: 'Bankwest Business',
  bendigo: 'Bendigo Bank',
  beyondbank: 'Beyond Bank',
  bnz: 'BNZ',
  bom: 'Bank of Melbourne',
  boq: 'Bank of Queensland',
  cba: 'Commonwealth Bank',
  defencebank: 'Defence Bank',
  greater: 'Greater Bank',
  heritage: 'Heritage',
  hume: 'Hume Bank',
  imb: 'IMB',
  ing: 'ING Direct',
  kiwi: 'Kiwi Bank',
  macquarie: 'Macquarie Bank',
  mystate: 'My State',
  nab: 'NAB',
  newcastle: 'Newcastle Permanent',
  peopleschoice: 'People’s Choice Credit Union',
  pnbank: 'P&N Bank',
  qccu: 'Queensland Country Credit Union',
  qtmb: 'RACQ Bank',
  regional: 'Regional Australia Bank',
  sccu: 'Southern Cross Credit Union',
  stgeorge: 'St.George',
  suncorp: 'Suncorp Bank',
  tmbank: 'Teachers Mutual Bank',
  tsbnz: 'TSB Bank',
  ubank: 'UBank',
  upbank: 'Up Bank',
  westpac: 'Westpac',
  westpacnz: 'Westpac New Zealand',
  woolworths: 'Woolworths Money',
  bank_of_custom: 'Bank of Custom',
  bank_of_statements: 'Bank of Statements',
};
