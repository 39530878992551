import { Html } from 'react-pdf-html';
import { Document, Font, Page, StyleSheet } from '@react-pdf/renderer';

import { EMPTY_HTML } from './constants';
import { customRenderers } from './utils';
interface LoanAgreementGeneralTermsTemplateProps {
  withHtml?: boolean;
  fonts?: any;
}

export const LoanAgreementGeneralTermsTemplateNZ = ({
  withHtml = true,
  fonts,
}: LoanAgreementGeneralTermsTemplateProps) => {
  Font.register({
    family: 'GothamHTF-Book',
    src: fonts?.gothamBook?.url,
  });
  Font.register({
    family: 'GothamHTF-Bold',
    src: fonts?.gothamBold?.url,
    fontWeight: 600,
  });
  Font.registerHyphenationCallback((word) => [word]);

  const paddingValue = withHtml ? 0 : 20;
  const fontSize = withHtml ? 12 : 14;

  const styles = StyleSheet.create({
    page: {
      padding: 35,
    },
  });

  const templateStyle = `<style>
      .logo {
        width: 150px;
        height: auto;
      }
      .address {
        width: 50%;
      }

      p {
        font-size: ${fontSize}px;
      }

      .loan-doc-container {
        ${withHtml ? "font-family: 'GothamHTF-Book';" : ''}
      }

      .loan-doc-container p {
        margin: 0 0 12px 0;
        padding: 0;
      }

      .address p {
        text-align: right !important;
      }

      h1.bold {
        font-size: 30px;
      }

      .bold,
      b {
        ${withHtml ? "font-family: 'GothamHTF-Bold';" : ''}
        font-weight: 600;
      }

      .text-center {
        text-align: center;
      }

      ol {
        list-style: auto;
        font-size: ${fontSize}px;
        padding-left: ${paddingValue}px;
        ${withHtml ? 'margin: 0;' : ''}
      }

      ol li {
        margin-top: 12px;
      }

      .ol-lr > li {
        list-style: lower-roman;
      }

      .ol-la > li {
        list-style: lower-alpha;
      }

      .ol-ld > li {
        list-style: decimal;
      }

      .ol-auto-la ol > li {
        list-style: decimal;
      }

      .ol-auto-la ol > li > ol > li {
        list-style: lower-alpha;
      }

      ul {
        max-width: 95%;
        padding-left: ${paddingValue}px;
        list-style: none;
      }

      .italic {
        font-style: italic;
      }

      @media screen and (max-width: 700px) {
        table {
            display: block;
        }
        .address,
        .logo {
          display: block;
          text-align: center;
          width: 100%;
        }
        ol,
        ul {
            max-width: 98%;
        }
      }
    </style>`;

  const template1 = `
    <div>
      <div class="logo">
        <img
          src="https://production-harmoney-cms-shared-assets.s3.ap-southeast-2.amazonaws.com/public/Logos/hmy-logo.png"
          alt="Company Logo"
        />
      </div>
      <br />
      <h1 class="bold">Loan Contract Terms</h1>
      <br />
      <p>Version: <span class="bold">NZ-S2-2025-1</span></p>
      <ol>
        <li>
          <p><span class="bold">Loan Contract</span><br /><span>The terms and conditions of your Loan Contract are set out in your Loan Disclosure Statement, together with the Vehicle Details Form (if applicable), and this Loan Contract Terms document. Your Loan Disclosure Statement prevails over this Loan Contract Terms document to the extent of any inconsistency.</span>
          </p>
        </li>

        <li>
          <p><span class="bold">Effective date</span><br /><span>Your Loan Contract comes into effect if, after you have been provided with your Loan Contract, you accept or confirm that you want to continue.</span>
          </p>
        </li>

        <li>
          <p><span class="bold">Borrower</span><br /><span>The Borrower under your Loan Contract is specified in your Loan Disclosure Statement. If more than one person is the Borrower:</span>
          </p>
          <ol class="ol-la">
            <li>
              <p>you are jointly and individually liable under your Loan Contract;</p>
            </li>
            <li>
              <p>we can accept instructions from any of you (without reference to any other
              person who is the Borrower) in respect of your Loan Contract; and</p>
            </li>
            <li>
              <p>each of you can agree on behalf of all of you to changes to your Loan
              Contract.</p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Participating Investors</span><br/><span>Any Participating Investors are anonymous to the Borrower, and you will not receive information about them. No Participating Investor is a party to your Loan Contract.</span>
          </p>
        </li>

        <li>
          <p><span class="bold">Agreement to lend</span><br /><span>Your Loan will be lent to you on and subject to the terms of your Loan Contract.</span>
          </p>
        </li>

        <li>
          <p><span class="bold">Secured Loans</span> </p>
          <ol class="ol-la">
            <li>
              <p>A Secured Loan may be available where you wish to use the Loan for the purchase of a vehicle. If so,
              the interest rates for Secured Loans (and unsecured loans) will be presented to you when you apply for your
              Loan.</p>
            </li>
            <li>
              <p>If you take out a Secured Loan, you must:</p>
              <ol class="ol-lr">
                <li>
                  <p>purchase a Vehicle within 60 days of the Date of Advance; and</p>
                </li>
                <li>
                  <p>satisfy the Secured Loan Conditions immediately upon
                  purchasing the Vehicle.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>If you do not satisfy clause 6(b) above, at the end of the 60-day period
              referred to in clause 6(b)(i):</p>
              <ol class="ol-lr">
                <li>
                  <p>the interest rate applicable to your Loan will change to the
                  annual unsecured interest rate (as set out in your Loan
                  Disclosure Statement); and</p>
                </li>
                <li>
                  <p>the required payments under your Loan will change.</p>
                </li>
              </ol>
              <p>We will notify you of the consequential changes to your payment schedule.</p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Security Interest</span> </p>
          <ol class="ol-la">
            <li>
              <p>Any security interest created under your Loan Contract continues at all
              times and applies to all present and future amounts you owe at any time to
              Harmoney Lender (including future advances for the purposes of sections
              71 and 72 of the PPSA).</p>
            </li>
            <li>
              <p>This security interest remains in full force until you have fully repaid all the
              money you owe Harmoney Lender, and Harmoney Lender releases you
              from the security interest. If you want to sell the Vehicle or you have repaid
              all the money you owe to Harmoney Lender, you can request a release
              from Harmoney Lender.</p>
            </li>
            <li>
              <p>In addition to this document, the Loan Disclosure Statement contains terms
              and conditions which apply to a Secured Loan.</p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Insurance</span> </p>
          <ol class="ol-la">
            <li>
              <p>You must renew and maintain the Insurance Policy throughout the term of
              the Loan, and at all times pay all insurance premiums under the insurance
              policy on time. Upon request by Harmoney Lender, you must provide
              Harmoney Lender with a copy of the current Insurance Policy and premium
              receipts.</p>
            </li>
            <li>
              <p>You must not do anything which would lead to the Insurance Policy being
              cancelled or adversely affected. Upon request by Harmoney Lender, you
              must provide Harmoney Lender with a certificate from your insurer showing
              this clause is being complied with.</p>
            </li>
            <li>
              <p>Any money you are entitled to under any insurance on the Vehicle is to be
              applied, at the option of Harmoney Lender, either in or towards rebuilding,
              repairing or replacing the Vehicle or meeting the liability insured against or
              in reduction of the amount you owe Harmoney Lender.</p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Verification statement</span><br /><span>You waive your right under section 148 of the PPSA to receive a copy of a verification statement in respect of any financing statement or financing change statement registered by Harmoney Lender in respect of any secured property.</span>
          </p>
        </li>

        <li>
          <p><span class="bold">Vehicle maintenance and compliance</span> </p>
          <ol class="ol-la">
            <li>
              <p>You must:</p>
              <ol class="ol-lr">
                <li>
                  <p>
                    comply with all legislation or other requirements affecting your
                    possession and use of the Vehicle, including obtaining and
                    keeping current all necessary registrations and licences relating
                    to your possession or use of the Vehicle;
                  </p>
                </li>
                <li>
                  <p>
                  immediately notify Harmoney Lender of any change of
                  identifying detail of the Vehicle (such as chassis or registration
                  number);
                  </p>
                </li>
                <li>
                  <p>
                  immediately notify Harmoney Lender in writing if you lose
                  possession of the Vehicle (e.g. if it is stolen, lost, taken from you
                  under a court order, destroyed or written off), and, if known,
                  advise Harmoney Lender of the Vehicle’s location;
                  </p>
                </li>
                <li>
                  <p>
                  upon request by Harmoney Lender, deposit all documents of title
                  constituting or evidencing ownership of the Vehicle;
                  </p>
                </li>
                <li>
                  <p>
                  promptly do everything reasonably requested by Harmoney
                  Lender to preserve and protect the value of the Vehicle and
                  protect and enforce Harmoney Lender’s title and rights to the
                  Vehicle; and
                  </p>
                </li>
                <li>
                  <p>
                  give Harmoney Lender 30 days prior notice if you intend to
                  change your name.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>You must not, without Harmoney Lender’s prior written consent:</p>
              <ol class="ol-lr">
                <li>
                  <p>
                  sell, lease, exchange, trade-in or otherwise dispose of the
                  Vehicle;
                  </p>
                </li>
                <li>
                  <p>
                  grant any security interest over the Vehicle (other than to
                  Harmoney Lender) or give anyone else rights in the Vehicle;
                  </p>
                </li>
                <li>
                  <p>
                  remove the Vehicle from New Zealand;
                  </p>
                </li>
                <li>
                  <p>
                  do anything which may cause or contribute to the deterioration in
                  value of the Vehicle; or
                  </p>
                </li>
                <li>
                  <p>
                  do, or fail to do, anything which could adversely affect Harmoney
                  Lender’s security interest in the Vehicle.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <p>
            <span class="bold">Date of Advance</span>
          </p>
          <p>
          The loan amount (specified in your Loan Disclosure Statement) is
          lent to you by Harmoney Lender on the Date of Advance. We may pay any part of the
          Loan to a third party in accordance with your instructions. In the unlikely event that
          the Date of Advance does not occur within 14 days of the Loan Contract coming into
          effect, then your Loan Contract will automatically be cancelled. If this happens,
          Harmoney Servicer will notify you of that fact.
          </p>
        </li>

        <li>
          <p>
            <span class="bold">Interest</span>
          </p>
          <p>
            Interest is charged and payable monthly in arrears as part of your required
            payments (as specified in your Loan Disclosure Statement).
          </p>
        </li>

        <li>
          <p>
            <span class="bold">Required Payments</span>
          </p>
          <p>
            You must pay to Harmoney Lender as and when due all
            required payments specified in your Loan Disclosure Statement, which comprise
            principal and interest. Each required payment will be direct debited from your
            Nominated Account on the date it is due (unless otherwise agreed). You may select a
            direct debit start date and frequency that suits you, provided that each required
            payment is paid in full by its due date. For the avoidance of doubt (and without
            limiting any other payment obligation under your Loan Contract) you must pay
            Harmoney Lender back in full the Loan together with all interest, fees, charges and
            other amounts payable by you under your Loan Contract, and no such amount may
            be outstanding after the final due date for a required payment specified in your Loan
            Disclosure Statement.
          </p>
        </li>

        <li>
          <p>
            <span class="bold">Fees and Charges</span>
          </p>
          <p>
            You must pay to Harmoney Lender all fees and charges
            (including default fees and charges) referred to in your Loan Disclosure Statement
            and in the Fees and Charges section set out below. Any such fees and charges will be direct debited from your
            Nominated Account on the date they are due (unless paid from, and forming part of,
            your Loan on the Date of Advance or otherwise agreed).
          </p>
        </li>

        <li>
          <p>
            <span class="bold">Early Repayment Option</span>
          </p>
          <p>
            You can prepay to Harmoney Lender your Loan or any
            portion of it early. If you choose to prepay the entire Loan, you must pay to Harmoney
            Lender the full outstanding amount of your Loan together with all interest, fees,
            charges and other amounts payable by you under your Loan Contract and accrued to
            the date of prepayment. If you choose at any time to make direct debit payments
            more frequently than the due date each month:
          </p>
          <ol class="ol-la">
            <li>
              <p>
              these are prepayments under this clause, which means that there is no
              formal variation to your required monthly payments and you can choose to
              change your direct debit frequency again at any time (provided that each
              required payment is paid in full by its due date); and
              </p>
            </li>
            <li>
              <p>
              like any prepayment, the total amount of the interest charges payable
              under your Loan Contract (and therefore the total amount of payments)
              may be less, because these prepayments reduce the unpaid balance of
              your Loan earlier each month.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Payment Provisions</span> </p>
          <ol class="ol-la">
            <li>
              <p>You must make every payment under your Loan Contract:</p>
              <ol class="ol-lr">
                <li>
                  <p>as and when due;</p>
                </li>
                <li>
                  <p>without set-off;</p>
                </li>
                <li>
                  <p>in cleared funds and in full, without any deduction or withholding,
                  except as may be required by law; and</p>
                </li>
                <li>
                  <p>to the Collections Account.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>Unless we have agreed with you otherwise, Harmoney Servicer will decide
              the order in which amounts we receive in connection with this Loan
              Contract (or any other loan agreement(s) you have entered into with us)
              will be applied to this Loan Contract (or any other loan agreement(s) you
              have entered into with us).</p>
            </li>
            <li>
              <p>You agree to a direct debit authority being established and maintained
              against your Nominated Account, on the Direct Debit Terms set out below, in relation to the amounts
              payable by you to Harmoney Lender under your Loan Contract. You may
              change your direct debit frequency at any time, provided that each required
              payment specified in your Loan Disclosure Statement is paid in full by its
              due date. You must ensure that your Nominated Account has sufficient
              funds at all relevant times to honour each direct debit payment. If any
              direct debit payment will not be made or honoured for any reason, you
              must make alternative arrangements to ensure that each required payment
              is made in full by its due date in accordance with these payment
              provisions.</p>
            </li>
            <li>
              <p>If any amount payable by you under your Loan Contract is not paid when
              due in accordance with this document, that amount will continue to
              constitute a debt due by you to Harmoney Lender until it is paid in full (see
              also clause 19 below).</p>
            </li>
            <li>
              <p>You agree to waive any right of set-off you may have against Harmoney
              Lender or Harmoney Servicer.</p>
            </li>
          </ol>
        </li>

        ${withHtml ? '<br />' : EMPTY_HTML}
        <li>
          <p><span class="bold">Tax Matters</span> </p>
          <ol class="ol-la">
            <li>
              <p>You will not be required to withhold or account to Inland Revenue for any
              RWT in relation to interest payments you make under your Loan. This is
              because Harmoney Lender holds an RWT Exemption Certificate.</p>
            </li>
            <li>
              <p>In certain circumstances where a Participating Investor is not resident in
              New Zealand for income tax purposes, you may be required to:</p>
              <ol class="ol-lr">
                <li>
                  <p>withhold and account to Inland Revenue for NRWT; or</p>
                </li>
                <li>
                  <p>pay AIL to Inland Revenue, in relation to interest payments you
                  make under your Loan.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>Harmoney Lender will meet all NRWT or AIL obligations on your behalf
              from the payments you make under your Loan Contract, immediately upon
              payment of the relevant interest into the Collections Account. For this
              purpose, you:</p>
              <ol class="ol-lr">
                <li>
                  <p>authorise Harmoney Lender to act on your behalf and to do all
                  things necessary (including, where required, completing Inland
                  Revenue registration forms and providing all necessary
                  information about you to Inland Revenue) to meet any NRWT or
                  AIL obligations on your interest payments; and</p>
                </li>
                <li>
                  <p>agree to provide Harmoney Lender with all necessary
                  information (including your IRD number and any other relevant
                  personal or tax-related information) for it to act effectively for the
                  purposes described above.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>You are not required to make any additional payment as a top-up for the
              NRWT or AIL deduction. To the extent allowable, Harmoney Lender will
              also undertake any required investment income reporting on your behalf.</p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Warranties</span><br /><span>You warrant to us that:</span></p>
          <ol class="ol-la">
            <li>
              <p>
              all information you provide to us is true, accurate, and complete, and there
              is no information which has not been provided which would impact on a
              decision to register you as a Borrower or to make a Loan available;
              </p>
            </li>
            <li>
              <p>
              you will provide us with updated information if any of your circumstances
              have changed;
              </p>
            </li>
            <li>
              <p>
              if your contact details change, you will immediately provide us with details
              of the change and update the dashboard with your revised information;
              </p>
            </li>
            <li>
              <p>
              where you have undertaken, confirmed or represented in the course of
              making your loan application that you will take any action or step (including
              but not limited to closing a credit account or other facility), you will perform
              that action or step promptly; and
              </p>
            </li>
            <li>
              <p>
              if you grant us a security interest over a Vehicle, you own the Vehicle free
              of other security interests and there are no undisclosed security interests
              over the Vehicle.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Default</span> </p>
          <ol class="ol-la">
            <li>
              <p>If you:</p>
              <ol class="ol-lr">
                <li>
                  <p>breach any term of this Loan Contract;</p>
                </li>
                <li>
                  <p>do not pay any money due under your Loan Contract on the due
                  date;</p>
                </li>
                <li>
                  <p>commit an act of bankruptcy (as defined in the Insolvency Act
                  2006); or</p>
                </li>
                <li>
                  <p>provided us with incorrect or fraudulent information,</p>
                </li>
              </ol>

              <p>we may take any of the steps described in clause 19(b) below.</p>

            </li>
            <li>
              <p>We, or any receiver, may:</p>
              <ol class="ol-lr">
                <li>
                  <p>by notice to you, declare all money outstanding under your Loan
                  Contract (including all accrued interest, fees, charges and other
                  amounts payable by you under your Loan Contract) immediately
                  due and payable. In that case, you must immediately pay that
                  outstanding money to Harmoney Lender;</p>
                </li>
                <li>
                  <p>exercise any collection, enforcement or other rights available to
                  us which may include taking appropriate recovery action (such
                  as appointing a collection agent, instituting legal proceedings, or
                  selling your Loan Contract to another person) and you must
                  promptly and fully comply with all requests and directions we
                  give you when we exercise these rights; and</p>
                </li>
                <li>
                  <p>where you have entered into a Secured Loan:</p>
                  <ol class="ol-la">
                    <li>
                      <p>immediately enforce our security interest in the
                      Vehicle, take possession of the Vehicle, sell the
                      Vehicle, and use the proceeds to repay the Loan;</p>
                    </li>
                    <li>
                      <p>appoint any person or persons to be a receiver of the
                      Vehicle (and without limiting or affecting, any other
                      powers and authorities conferred on a receiver) the
                      receiver has the power to do all things in relation to
                      the Vehicle as if the receiver had absolute ownership
                      of the Vehicle;</p>
                    </li>
                    <li>
                      <p>exercise all other rights conferred to Harmoney
                      Lender by law (including under Part 9 of the PPSA) in
                      relation to the Vehicle; and</p>
                    </li>
                    <li>
                      <p>in relation to a Vehicle:</p>
                      <ol class="ol-lr">
                        <li>
                          <p>sell all or any part of the Vehicle;</p>
                        </li>
                        <li>
                          <p>cancel an agreement for sale over the Vehicle,
                          and in that event, re-sell the relevant property
                          without being answerable for any reduction in
                          price; or</p>
                        </li>
                        <li>
                          <p>exercise any other incidental rights in connection
                          with the sale (whether under this Loan Contract
                          or by law, agreement or otherwise).</p>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>If Harmoney Lender or a receiver intends to exercise a right to sell the
              Vehicle, you must not do anything to delay, adversely affect or otherwise
              prevent a prompt sale of the Vehicle and transfer to the purchaser.</p>
            </li>
            <li>
              <p>This Loan Contract may be enforced regardless of whether Harmoney
              Lender has accepted a payment from you after the Default has occurred
              and without having to give notice to you or seek your consent. Harmoney
              Lender and any receiver is not responsible for any loss, or for any act or
              thing done or omitted, and is not obliged to enforce any right under any
              agreement for sale.</p>
            </li>
            <li>
              <p>In the event of a breach of your Loan Contract or on the enforcement of
              your Loan Contract, you must also pay to Harmoney Lender any applicable
              default fees and charges referred to in your Loan Disclosure Statement.
              Any such fees and charges will be direct debited from your Nominated
              Account on the date they are due (unless otherwise agreed).</p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Limitation of Liability</span> </p>
          <ol class="ol-la">
            <li>
              <p>
              If Harmoney Lender (referred to in this clause as the <span class="bold">Trustee</span>) has entered
              into your Loan Contract as bare trustee for Participating Investors, the
              Trustee holds all rights and powers vested in it under or in connection with
              your Loan Contract as bare trustee for the Participating Investors in
              accordance with their respective interests, on and subject to the terms of
              the Trustee’s appointment.
              </p>
            </li>
            <li>
              <p>
              Without limiting the above clause (a), no Relevant Person will be liable if it
              fails to comply with your Loan Contract due to events beyond its
              reasonable control (including due to any Participating Investors failing to
              fund your Loan). Nothing in this clause (b) or your Loan Contract limits any
              rights you may have at law.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Changes to Terms</span> </p>
          <ol class="ol-la">
            <li>
              <p>
              You authorise us to correct any minor errors in your Loan Contract
              (including inserting dates and Loan references), the amendment of which
              would not have a material effect on the Loan Contract.
              </p>
            </li>
            <li>
              <p>
              Subject to any applicable law, we may change any terms of your Loan
              Contract without your consent for one or more of the following reasons
              </p>
              <ol class="ol-lr">
                <li>
                  <p>
                  to reduce your obligations under your Loan Contract (including
                  by reducing the interest rate of your Loan), or to add benefits or
                  new features to your Loan and/or other products;
                  </p>
                </li>
                <li>
                  <p>
                  to comply with any change or anticipated changes in any
                  relevant law, code of practice or guidance, or to reflect any
                  decision of a court, ombudsman or regulator;
                  </p>
                </li>
                <li>
                  <p>
                  to reflect a change in our systems, procedures or circumstances
                  (including for security reasons); or
                  </p>
                </li>
                <li>
                  <p>
                  to make the terms of your Loan Contract clearer.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
              We may introduce a new type of fee under your Loan Contract, or amend
              any fee payable under your Loan Contract, but only in accordance with
              applicable law (including applicable lender responsibility principles and
              notice requirements) and only if (acting reasonably) we consider the
              introduction or amendment to be necessary or appropriate in all the
              circumstances. Harmoney Servicer will be entitled to process additional
              debit payments to cover any such fees.
              </p>
            </li>
            <li>
              <p>
              Subject to the above clauses (a) through (c), we will not otherwise, without
              your prior agreement, amend the interest rate applying to your Loan, the
              loan term, the loan amount, the amounts of any loan repayments, the loan
              repayment dates, or any other matter, except as expressly permitted in
              your Loan Contract.
              </p>
            </li>
          </ol>
        </li>

        ${withHtml ? '<br />' : EMPTY_HTML}
        <li>
          <p><span class="bold">Notices</span> </p>
          <ol class="ol-la">
            <li>
              <p>
              You must provide all notices to us in writing (including by email) using the
              contact details provided in your Loan Disclosure Statement or any updated
              details provided by Harmoney Servicer or on the Website at the relevant
              time.
              </p>
            </li>
            <li>
              <p>
              You consent to receiving all notices, documents and communications
              relating to your Loan (including ongoing disclosure information required by
              law) in electronic form and by electronic means, including to any electronic
              message address specified by you, or through the Website (including the
              dashboard).
              </p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Records</span> <br /><span>If your records and ours disagree regarding the effective date of the Loan Contract, the Date of Advance, or the date of any payment or system processing, our records will prevail in the absence of any evidence to the contrary.</span>
          </p>
        </li>

        <li>
          <p><span class="bold">Severability</span> <br /><span>The illegality, invalidity or unenforceability of any provision in your Loan Contract will not affect the legality, validity or enforceability of another provision.</span></p>
        </li>

        <li>
          <p><span class="bold">Privity</span> <br /><span>Each of Harmoney Lender and Harmoney Servicer can enforce its rights under your Loan Contract even if it has not signed your Loan Contract.</span></p>
        </li>

        <li>
          <p><span class="bold">Assignment</span> </p>
          <ol class="ol-la">
            <li>
              <p>
              You may not transfer or assign any of your rights or obligations under your
              Loan Contract to any person.
              </p>
            </li>
            <li>
              <p>
              Each of Harmoney Lender and Harmoney Servicer may transfer or assign
              all and any of its rights and/or obligations under your Loan Contract to any
              person, without requiring your consent.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <p><span class="bold">Governing Law</span> <br /><span>Your Loan Contract is governed by New Zealand law. The parties submit to the non-exclusive jurisdiction of the New Zealand courts in respect of all matters relating to your Loan Contract and your Loan.</span></p>
        </li>

        <li>
          <p><span class="bold">Definitions</span> <br /><span>In your Loan Contract, terms defined in your Loan Disclosure Statement have the same meanings where used in this document and: </span></p>
          <ul>
            <li>
              <p>
                <span class="bold">AIL</span>
                means approved issuer levy payable under part 6B of the Stamp and
                Cheque Duties Act 1971;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Borrower</span>
                means the person(s) specified as the borrower in your Loan
                Disclosure Statement;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Business Day</span>
                means a day other than a Saturday, Sunday, or statutory
                public holiday in New Zealand;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Collections Account</span>
                means the Harmoney Lender bank account into
                which (among other things) payments of principal and interest and other
                amounts payable by you under your Loan Contract must be paid;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Date of Advance</span>
                has the meaning given to that term in your Loan
                Disclosure Statement;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Harmoney Lender</span>
                has the meaning given to that term in your Loan
                Disclosure Statement;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Harmoney Servicer</span>
                has the meaning given to that term in your Loan
                Disclosure Statement;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Insurance Policy</span>
                means the insurance policy over the Vehicle for which
                you provide Harmoney Lender with a certificate of insurance in accordance
                with the Loan Disclosure Statement (in the section titled “Secured Loan
                Conditions”);
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Loan</span>
                means the total amount lent or to be lent by Harmoney Lender to you
                under your Loan Contract (whether credited to your bank account or
                otherwise applied for your benefit);
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Loan Contract</span>
                means the agreement between you and us in respect of your Loan,
                which comprises your Loan Disclosure Statement, the Vehicle Details Form (if
                applicable), and this Loan Contact Terms document;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Loan Disclosure Statement</span>
                means the loan disclosure statement
                provided to you in respect of your Loan;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Nominated Account</span>
                means the New Zealand bank account which is
                nominated by the Borrower from time to time as the account into which
                Loan advances are to be paid and from which amounts payable to
                Harmoney Lender are to be direct debited;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">NRWT</span>
                means non-resident withholding tax imposed under the NRWT
                rules in the Income Tax Act 2007;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Participating Investor</span>
                in relation to your Loan means (if applicable) each
                investor for whom Harmoney Lender as bare trustee holds your Loan;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">PPSA</span>
                means the Personal Property Securities Act 1999;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">financing statement, financing change statement, future advance,
                secured party, secured property,</span> and <span class="bold">verification statement</span>
                have the meanings given to them in, or in the context of, the PPSA;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Proceeds</span>
                means all money received directly or indirectly by you from the
                Vehicle (e.g. if you sell, rent out or trade the Vehicle);
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Relevant Person</span>
                means any of Harmoney Lender, Harmoney Servicer or
                any related company (as defined in in section 2(3) of the Companies Act
                1993) of those entities, and any employee, director, officer, agent, and
                contractor of those entities or such related company;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">RWT</span>
                means resident withholding tax imposed under the RWT rules in the
                Income Tax Act 2007;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">RWT Exemption Certificate</span>
                has the meaning given in section YA 1 of the
                Income Tax Act 2007;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Secured Loan</span>
                means a Loan for which the proceeds are to be used
                towards the purchase of a vehicle for which a security interest is to be
                granted to Harmoney Lender;
              </p>
            </li>
            ${withHtml ? '<br />' : EMPTY_HTML}
            <li>
              <p>
                <span class="bold">Secured Loan Conditions</span>
                means each of the conditions set out in your
                Loan Disclosure Statement that apply to a Secured Loan;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Vehicle</span>
                means all present and future right, title and interest (legal and
                equitable) in, all documents of title relating to, and all Proceeds of the
                specific vehicle to which this Loan Contract relates;
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Vehicle Details Form</span>
                means (in relation to a Secured Loan only) the form to be
                delivered in relation to a purchased vehicle containing the information set out in that
                form; and
              </p>
            </li>
            <li>
              <p>
                <span class="bold">Website</span>
                means the website at www.harmoney.co.nz, or such other
                website as Harmoney Servicer or a Relevant Person may from time to time
                operate.
              </p>
            </li>
          </ul>
        </li>

        <li>
          <p><span class="bold">Interpretation</span> <br /><span>In your Loan Contract:</span></p>
          <ol class="ol-la">
            <li>
              <p>
              headings are inserted for ease of reference only, and do not affect the
              interpretation of your Loan Contract;
              </p>
            </li>
            <li>
              <p>
              references to you or your means the Borrower;
              </p>
            </li>
            <li>
              <p>
              references to the singular include the plural, and vice versa;
              </p>
            </li>
            <li>
              <p>
              references to a person include an individual, company, corporation,
              partnership, firm, joint venture, association, trust, unincorporated body of
              persons, governmental or other regulatory body, authority or entity, in each
              case whether or not having a separate legal identity;
              </p>
            </li>
            <li>
              <p>
              references to any party (including Harmoney Lender and Harmoney
              Servicer) includes any permitted assignee or transferee; and any reference
              to Harmoney Servicer includes any back up servicer entity appointed to
              replace it;
              </p>
            </li>
            <li>
              <p>
              references to the liability of a person include references to its liability under
              any cause of action, whether in contract, tort, or equity or under any
              enactment;
              </p>
            </li>
            <li>
              <p>
              references to any document (however described) are references to that
              document as modified, novated, supplemented, varied or replaced from
              time to time and in any form, whether on paper or in an electronic form;
              and
              </p>
            </li>
            <li>
              <p>
              a reference to any legislation is a reference to that legislation as amended
              or replaced from time to time, and includes any regulations or legislative
              instrument made under it.
              </p>
            </li>
          </ol>
        </li>

      </ol>
    </div>
  `;

  const template2 = `
    ${withHtml ? EMPTY_HTML : '<br />'}
    <h2 class="bold">Direct Debit Terms</h2>
    <br />
    <p>
      In these terms, references to "Initiator" are references to Harmoney Servicer (or its successors
      or assigns) and references to the "Customer" are references to the Borrower.
    </p>
    <br />
    <p class="bold">Conditions of instruction to accept direct debits</p>
    <ol class="ol-ld">
      <li>
        <p>
          The Initiator:
        </p>
        <ol class="ol-ld">
          <li>
            <p>Will provide notice either:</p>
            <ol class="ol-la">
              <li>
                <p>in writing; or</p>
              </li>
              <li>
                <p>
                by electronic mail where the Customer has provided prior written consent to
                the Initiator.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
            Has agreed to give advance Notice of the net amount of each Direct Debit and the due
            date of the debiting at least 10 calendar days (but not more than 2 calendar months)
            before the date when the Direct Debit will be initiated.
            </p>
          </li>
          <li>
            <p>The advance notice will include the following message:</p>

            <p>
            “Unless advice to the contrary is received from you by (date*), the amount of
            $........... will be directly debited to your Bank account on (initiating date*).”
            </p>

            <p>
            *This date will be at least two (2) days prior to the initiating date to allow for
            amendment of Direct Debits.
            </p>

          </li>
          <li>
            <p>
            Alternatively, the Initiator undertakes to give notice to the Acceptor of the
            commencement date, frequency and amount at least 10 calendar days before the first
            Direct Debit is drawn (but no more than 2 calendar months).
            </p>
          </li>
          <li>
            <p>
            Where the Direct Debit System is used for the collection of payments which are
            regular as to frequency, but variable as to amounts, the Initiator undertakes to provide
            the Acceptor with a schedule detailing each payment amount and each payment date.
            </p>
          </li>
          <li>
            <p>In the event of any subsequent change to the frequency or amount of the Direct
            Debits, the Initiator has agreed to give advance notice of at least 30 days before the
            change comes into effect. This notice must be provided either:</p>
            <ol class="ol-la">
              <li>
                <p>in writing; or</p>
              </li>
              <li>
                <p>
                by electronic mail where the Customer has provided prior written consent to
                the Initiator.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>May initiate a Direct Debit on my/our account when authorisation is received from
            me/us in accordance with the terms and conditions agreed between me/us and the
            Initiator of each amount to be debited from my/our account.</p>
          </li>
          <li>
            <p>Notice will be sent of the net amount of each Direct Debit and the due date of debiting
            after receiving authorisation from me/us under clause 1.7 but no later than the date
            the Direct Debit will be initiated. This notice must be provided either:</p>
            <ol class="ol-la">
              <li>
                <p>in writing; or</p>
              </li>
              <li>
                <p>
                by any other means which provides a verifiable record of the initiated
                transaction and where the Customer has provided prior written consent to
                the Initiator.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>Where the notice is in writing it must include the following message:</p>

            <p>
            “The amount $....... was directly debited to your Bank account on (initiating
            date).”
            </p>

          </li>
          <li>
            <p>Where the notice is provided by other means:</p>
            <ol class="ol-la">
              <li>
                <p>the Initiator should hold prior written consent of those means of providing
                notice; and</p>
              </li>
              <li>
                <p>
                the notice should provide a verifiable record of the initiated transaction and
                include the amount and initiating date of that transaction.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>May, upon the relationship which gave rise to this Instruction being terminated, give
            notice to the Bank that no further Direct Debits are to be initiated under the Instruction.
            Upon receipt of such notice the Bank may terminate this Instruction as to future
            payments by notice in writing to me/us.</p>
          </li>
        </ol>
      </li>

      <li>
        <p>The Customer may:</p>
        <ol>
          <li>
            <p>
            At any time, terminate this Instruction as to future payments by giving written (or by
            the means previously agreed in writing) notice of termination to the Bank and to the
            Initiator.
            </p>
          </li>
          <li>
            <p>
            Stop payment of any Direct Debit to be initiated under this Instruction by the Initiator
            by giving written notice to the Bank prior to the Direct Debit being paid by the Bank.
            </p>
          </li>
          ${withHtml ? '<br />' : EMPTY_HTML}
          <li>
            <p>
            Where no advance notice is provided under clause 1.7 a variation to the amount
            agreed between the Initiator and the Customer from time to time to be Direct Debited
            had been made without notice being given in terms of clause 1.7 above, request the
            Bank to reverse or alter any such Direct Debit initiated by the Initiator by debiting the
            amount of the reversal or alteration of Direct Debit back to the Initiator through the
            Initiator’s Bank PROVIDED such request is made not more than 120 days from the
            date when the Direct Debit was debited to my/our account.
            </p>
          </li>
          <li>
            <p>
            Request the Bank to reverse any Direct Debits initiated by the Initiator under the
            Instructions by debiting the amount of the Direct Debits back to the Initiator through
            the Initiator’s Bank where the Initiator cannot produce a copy of the Instructions and/or
            Confirmation to me/us that I/we are reasonably satisfied demonstrate that I/we have
            authorised my/our bank to accept Direct Debits from the Initiator against my/our
            account PROVIDED the request is made not more than 9 months from the date when
            the first Direct Debit was debited to my/our account by the Initiator under the
            Instructions.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <p>The Customer acknowledges that:</p>
        <ol>
          <li>
            <p>
            This Instruction will remain in full force and effect in respect of all Direct Debits passed
            to my/our account in good faith notwithstanding my/our death, bankruptcy or other
            revocation of this Instruction until actual notice of such event is received by the Bank.
            </p>
          </li>
          <li>
            <p>
            In any event this Instruction is subject to any arrangement now or hereafter existing
            between me/us and the Bank in relation to my/our account.
            </p>
          </li>
          <li>
            <p>
            Any dispute as to the correctness or validity of an amount debited to my/our account
            shall not be the concern of the Bank except in so far as the Direct Debit has not been
            paid in accordance with this Instruction. Any other disputes lie between me/us and the
            Initiator.
            </p>
          </li>
          <li>
            <p>
            Where the Bank has used reasonable care and skill in acting in accordance with this
            Instruction, the Bank accepts no responsibility or liability in respect of:
            </p>
            <ol class="ol-la">
              <li>
                <p>
                  the accuracy of information about Direct Debits on Bank statements; and
                </p>
              </li>
              <li>
                <p>
                any variations between notices given by the Initiator and the amounts of
                Direct Debits.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
            The Bank is not responsible for, or under any liability in respect of the Initiator’s failure
            to give notice in accordance with clauses 1.1 to 1.7, nor for the non-receipt or late
            receipt of notice by me/us for any reason whatsoever. In any such situation the
            dispute lies between me/us and the Initiator.
            </p>
          </li>
          <li>
            <p>
            Where notice given by the Initiator in terms of clause 1.7 to the debtor responsible for
            the payment shall be effective. Any communication necessary because the debtor
            responsible for payment is a person other than me/us is a matter between me/us and
            the debtor concerned.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <p>The Bank may:</p>
        <ol>
          <li>
            <p>
            In its absolute discretion conclusively determine the order of priority of payment by it of
            any monies pursuant to this or any other Instruction, cheque or draft properly signed
            by me/us and given to or drawn on the Bank.
            </p>
          </li>
          <li>
            <p>
            At any time terminate this Instruction as to future payments by notice in writing to
            me/us.
            </p>
          </li>
          <li>
            <p>
            Charge its current fees for this service in force from time to time.
            </p>
          </li>
        </ol>
      </li>
    </ol>`;

  const template3 = `
    ${withHtml ? EMPTY_HTML : '<br />'}

    <h2 class="bold">Fees and charges</h2>

    <p>
    Borrowers will be charged no additional fees beyond the one-off establishment fee, except in
    the event of a breach of the loan contract or on the enforcement of the loan contract.
    </p>

    <p>
    Please note that fees and charges can be varied in accordance with the applicable loan
    contract.
    </p>

    <p><span class="bold">Establishment fee</span></p>

    <p>Borrowers are charged an up-front, one-off establishment fee for approved loans.</p>

    <p>
      The establishment fee covers the arranging costs incurred in connection with the loan
      application, including the processing and consideration of that application, documenting the
      loan, and administration around advancing the loan.
    </p>

    <p>
      The establishment fee is paid from the Borrower's loan on the date the loan is advanced. The
      amount of the fee is added to the approved loan amount and forms part of the principal amount
      of the loan (which is repaid, together with interest, by regular payments as specified in the loan
      contract).
    </p>

    <p>
      These establishment fees also apply to Top Ups.
    </p>

    <p><span class="bold">Dishonour fee</span></p>

    <p>
      In the case where a Borrower’s payment is dishonoured, a $15 fee may be charged. However,
      you will not be charged this fee if a direct debit payment for weekly or fortnightly payments is
      dishonoured, as long as you still pay your required monthly payment in full by the due date each
      month.
    </p>

    <p>
      The dishonour fee covers the costs of the additional administration required to re-process and
      arrange the payment as part of the collection process.
    </p>

    <p>
      Any dishonour fee will be charged to the Borrower and direct debited from the Borrower's
      nominated bank account on the Borrower's next payment due date.
    </p>

    <p><span class="bold">Overdue fee</span></p>

    <p>
      The overdue fee is charged if a payment is not made in full when due and the account goes into
      arrears. The fee payable is $20 on each of 6, 36, 66, 96 and 120 days after the payment due
      date, if the account remains in arrears.
    </p>

    <p>
      The overdue fee covers the costs of the additional administration required to manage the
      collection process for an overdue account, which includes arranging for a collection process in
      respect of the arrears amount, updating and maintaining records, and other necessary and
      incidental services to the collection process.
    </p>

    <p>
      The overdue fee is charged to the Borrower and direct debited from the Borrower's nominated
      bank account on the Borrower's next payment due date
    </p>

    <p><span class="bold">Collection and enforcement fees</span></p>

    <p>
      If collection or enforcement action is required against a Borrower, an amount that is no more
      than the legal and associated third party costs or expenses incurred in that collection or
      enforcement process (if any) will be charged to the Borrower.
    </p>

    <p>
      These fees cover the costs or expenses incurred when a third party is instructed to assist with
      recovery in respect of an overdue account.
    </p>

    <p>
      These fees are charged to the Borrower and direct debited from the Borrower's nominated bank
      account on the Borrower's next payment due date.
    </p>`;

  const template = (innerText: string) => `
    <html>
      ${templateStyle}
      <body>
        <div class="loan-doc-container">
          ${innerText}
        </div>
      </body>
    </html>
  `;

  if (withHtml) {
    return (
      <Document>
        <Page style={styles.page}>
          <Html renderers={customRenderers}>{template(template1)}</Html>
        </Page>
        <Page style={styles.page}>
          <Html>{template(template2)}</Html>
        </Page>
        <Page style={styles.page}>
          <Html>{template(template3)}</Html>
        </Page>
      </Document>
    );
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
        ${template(template1)}
        ${template(template2)}
        ${template(template3)}
        `,
      }}
    />
  );
};
