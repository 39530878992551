import { NetworthSourceDisplayNameEnum } from '@harmoney/api-interfaces';
import { AffordabilityModelEnum, Prisma } from '@prisma/client';

export interface AffordabilityModelDetailDto {
  model: AffordabilityModelEnum;
  umi: number;
  oldUMI: number | null;
  isSelected: boolean;
  isEligible: boolean;
  sharedExpenseResult?: Prisma.JsonValue;
  overview?: {
    totalIncome: number;
    totalExpense: number;
    buffer: number | null;
    totalDebtRepayment: number;
  };
  items?: {
    incomes: AffordabilityItemDto[];
    expenses: AffordabilityItemDto[];
    debts: AffordabilityItemDto[];
  };
}

export interface AffordabilityItemDto {
  source?: NetworthSourceDisplayNameEnum;
  isPrimarySource?: boolean;
  totalIncoming: number;
  totalOutgoing: number;
  finalSharedValue?: number;
  monthlyNetValues: MonthlyNetValueDto[];
}

interface MonthlyNetValueDto {
  id: SelectedSourceEnum | 'aiAnalysis';
  value: number;
  isShared?: boolean;
  isSelected?: boolean;
}

export interface IncomeAndExpenseSplitDto {
  income: number;
  accommodationExpenses: number;
  finalAccommodationExpenses: number;
  livingExpenses: number;
  finalLivingExpenses: number;
}

export enum SelectedSourceEnum {
  DECLARED = 'declared',
  BANK_STATEMENT = 'bankStatement',
  BENCHMARK = 'benchmark',
  CO_OVERRIDE = 'coOverride',
  MINIMUM_ACCEPTABLE_VALUE = 'minimumAcceptableValue',
  NONE = 'none',
}
