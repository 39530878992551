import { Card, Divider, IconV2, TextLink } from '@harmoney/ui-design-system';

export const QuickQuoteCreditFilePullUnsuccessful = () => {
  return (
    <>
      <h1 className="mt-8 font-bold">
        We are <span className="text-primary">unable to give you a quick quote</span> today
      </h1>
      <p>
        We appreciate you trying to get a quick quote with us, but based on the information provided we are unable to
        give you one today.
      </p>
      <Card className="relative mt-8 sm:mt-6 w-full !px-0 py-3">
        <div className="px-4">
          <p className="font-medium text-sm">Reasons we can&rsquo;t give you a quick quote</p>
        </div>
        <Divider className="text-grey-2 py-3" />
        <div className="px-4 py-1">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2">
              <IconV2 icon="hmy:credit-failed" className="size-6" />
              <span className="text-sm">Didn&rsquo;t meet our credit criteria</span>
            </div>
            <div className="flex flex-row gap-2">
              <IconV2 icon="hmy:document-magnifier" className="size-6" />
              <div className="flex flex-col text-sm">
                <span>An issue in your credit file</span>
                <TextLink
                  href="https://www.harmoney.com.au/how-can-i-check-my-credit-file"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  How can I check my credit file?
                </TextLink>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <p>Remember, timing is everything. If your financial situation changes don&rsquo;t hesitate to try again.</p>
    </>
  );
};
