import { errors } from '@harmoney/ui-utils';
import { LoanVariationPurposeEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { z } from 'zod';

export const getDirectDebitPlanDefaultValues = (recurringPaymentDate: Date, recurringPaymentFrequency: string) => ({
  purpose: '',
  otherPurpose: '',
  rescheduledDate: dayjs(recurringPaymentDate).toDate(),
  repaymentFrequency: recurringPaymentFrequency,
  notes: '',
});

export const directDebitPlanFormSchema = z
  .object({
    purpose: z.string().min(1, { message: 'Please select a purpose' }),
    otherPurpose: z.string().optional(),
    rescheduledDate: z.coerce.date(),
    repaymentFrequency: z.string().min(1, { message: 'Please select a repayment frequency' }),
    notes: z.string().min(1, { message: 'Please add a note' }),
  })
  .superRefine((data, { addIssue }) => {
    if (typeof data === 'undefined') {
      throw new Error('Data is undefined');
    }
    if (/^\s*$/g.test(data.notes)) {
      addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Notes can not be empty',
        path: ['note'],
      });
    }
    if (data.purpose === LoanVariationPurposeEnum.OTHER && !data.otherPurpose) {
      addIssue({
        code: z.ZodIssueCode.custom,
        message: errors.requiredField('Other Purpose'),
        path: ['otherPurpose'],
      });
    }
    if (data.purpose === LoanVariationPurposeEnum.OTHER && /^\s*$/g.test(data.otherPurpose)) {
      addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Other Purpose can not be empty',
        path: ['otherPurpose'],
      });
    }
  });
