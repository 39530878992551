import { errors } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { LoanVariationPurposeEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { z } from 'zod';

const getDefaultValues = (additionalFields = {}) => ({
  purpose: '',
  otherPurpose: '',
  notes: '',
  ...additionalFields,
});

export const commonValidations = (data, addIssue) => {
  if (/^\s*$/g.test(data.notes)) {
    addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Notes cannot be empty',
      path: ['notes'],
    });
  }
  if (data.purpose === LoanVariationPurposeEnum.OTHER) {
    if (!data.otherPurpose) {
      addIssue({
        code: z.ZodIssueCode.custom,
        message: errors.requiredField('Other Purpose'),
        path: ['otherPurpose'],
      });
    }
    if (/^\s*$/g.test(data.otherPurpose)) {
      addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Other Purpose cannot be empty',
        path: ['otherPurpose'],
      });
    }
  }
};

export const getSuspendDirectDebitDefaultValues = () =>
  getDefaultValues({
    effectiveDate: null,
    resumeDate: null,
    isPermanentSuspension: false,
  });

export const createSuspendDirectDebitFormSchema = () =>
  z
    .object({
      purpose: z.string().min(1, { message: 'Please select a purposes' }),
      otherPurpose: z.string().optional(),
      effectiveDate: z.coerce.date().min(dayjs().toDate(), { message: 'Please select a date' }),
      resumeDate: z.coerce.date().nullable().optional(),
      isPermanentSuspension: z.boolean().optional(),
      notes: z.string().min(1, { message: 'Please add a note' }),
    })
    .superRefine((data, ctx) => {
      commonValidations(data, ctx.addIssue);
      if (!data.isPermanentSuspension && !data.resumeDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select either Permanent Suspension or Resume Date',
          path: ['isPermanentSuspension'],
        });
      }
      if (data.resumeDate && data.effectiveDate >= data.resumeDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Resume Date should be after Effective Date',
          path: ['resumeDate'],
        });
      }
    });

export const extraPaymentInitialValues = { paymentAmount: null, schedulePaymentDate: null };

export const getScheduleExtraPaymentDefaultValues = () =>
  getDefaultValues({ extraPayments: [extraPaymentInitialValues] });

const extraPaymentsSchema = (
  payOffAmount: number,
  currentOneOffPaymentDates: string[],
  currentPendingOneOffPaymentAmount: number
) =>
  z.object({
    paymentAmount: z.coerce
      .number({ invalid_type_error: errors.defaultValidAmount })
      .gte(1, { message: errors.defaultValidAmount })
      .lte(payOffAmount, { message: `The extra payment can’t exceed ${formatCurrency(payOffAmount)}` })
      .refine(
        (data) => {
          const totalPaymentAmount = data + currentPendingOneOffPaymentAmount;
          if (totalPaymentAmount > payOffAmount) {
            return false;
          }
          return true;
        },
        {
          message: `The extra payment can’t exceed ${formatCurrency(payOffAmount)}`,
        }
      ),
    schedulePaymentDate: z.coerce
      .date()
      .nullable()
      .refine(
        (data) => {
          if (data === null) {
            return false;
          }
          return true;
        },
        { message: 'Please select a date' }
      )
      .refine(
        (data) => {
          if (currentOneOffPaymentDates?.includes(dayjs(data).format('YYYY-MM-DD'))) {
            return false;
          }
          return true;
        },
        {
          message: 'Looks like you already have a payment scheduled for this date! Please choose a different date.',
        }
      ),
  });

export const createScheduleExtraPaymentFormSchema = (
  payOffAmount: number,
  currentOneOffPaymentDates: string[],
  currentPendingOneOffPaymentAmount: number
) =>
  z
    .object({
      purpose: z.string().min(1, { message: 'Please select a purpose' }),
      otherPurpose: z.string().optional(),
      notes: z.string().min(1, { message: 'Please add a note' }),
      extraPayments: z
        .array(extraPaymentsSchema(payOffAmount, currentOneOffPaymentDates, currentPendingOneOffPaymentAmount))
        .min(1, { message: 'Please add at least one extra payment schedule' }),
    })
    .superRefine((data, ctx) => {
      commonValidations(data, ctx.addIssue);
      const totalPaymentAmount =
        data.extraPayments.reduce((sum, payment) => sum + payment.paymentAmount, 0) + currentPendingOneOffPaymentAmount;

      if (data.extraPayments.length > 1 && totalPaymentAmount > payOffAmount) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `The extra payments can’t exceed ${formatCurrency(payOffAmount)}`,
          path: ['extraPayments'],
        });
      }
      const dateOccurrences: { [key: string]: number } = {};

      data.extraPayments.forEach((payment, index) => {
        const formattedDate = dayjs(payment.schedulePaymentDate).format('YYYY-MM-DD');
        if (dateOccurrences[formattedDate]) {
          dateOccurrences[formattedDate] += 1;
        } else {
          dateOccurrences[formattedDate] = 1;
        }
        if (dateOccurrences[formattedDate] > 1) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Looks like you already have a payment scheduled for this date! Please choose a different date.',
            path: ['extraPayments', index, 'schedulePaymentDate'],
          });
        }
      });
    });
