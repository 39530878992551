import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoanApplicationDto } from '@harmoney/api-interfaces';
import { useBranch, useQuoteData } from '@harmoney/hooks';
import { useGetQuoteOptionsQuery, useGetVariablesQuery } from '@harmoney/redux';
import { eventAnalytics, QUOTE_READY, QUOTE_READY_DISPLAYED } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, ClockLightningIcon } from '@harmoney/ui-design-system';
import { formatCurrency, isPersonalLoan } from '@harmoney/utilities';
import { BranchEnum, RepaymentFrequencyEnum } from '@prisma/client';

import { CommonProps } from '../../common-props';
import { TimeDisplayByBranch } from '../shared';
import { calculateEstablishmentFee } from '../shared/utils';

import 'swiper/css';

interface QuoteReadyGenericProps extends CommonProps {
  loanApplicationData: LoanApplicationDto;
  quoteRepaymentHeroExperimentFeb25Value?: string;
}

export function QuoteReadyGeneric({
  loanApplicationData,
  quoteRepaymentHeroExperimentFeb25Value,
  loanProductData,
  taskId,
  completeTaskWithData,
}: QuoteReadyGenericProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: variables } = useGetVariablesQuery(taskId);
  const { quoteData } = useQuoteData(taskId);
  const branch = useBranch();

  const { requestedAmountEstablishmentFee, approvedAmountEstablishmentFee } = useMemo(() => {
    const requestedAmount = loanApplicationData?.requestedAmount;
    const approvedAmount = loanApplicationData?.quotePresentedAmount;
    const requestedAmountEstablishmentFee = calculateEstablishmentFee(requestedAmount, quoteData);
    const approvedAmountEstablishmentFee = calculateEstablishmentFee(approvedAmount, quoteData);
    return { requestedAmountEstablishmentFee, approvedAmountEstablishmentFee };
  }, [loanApplicationData?.quotePresentedAmount, loanApplicationData?.requestedAmount, quoteData]);

  const { data: requestedAmountQuoteData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: loanApplicationData?.requestedAmount,
      establishmentFee: requestedAmountEstablishmentFee,
    },
    {
      skip: !variables || !loanApplicationData?.requestedAmount || !requestedAmountEstablishmentFee,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: approvedAmountQuoteData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: loanApplicationData?.quotePresentedAmount,
      establishmentFee: approvedAmountEstablishmentFee,
    },
    {
      skip: !variables || !loanApplicationData?.quotePresentedAmount || !approvedAmountEstablishmentFee,
      refetchOnMountOrArgChange: true,
    }
  );

  const weeklyRepaymentRequested = requestedAmountQuoteData?.find(
    (option) => option.repaymentFrequency === RepaymentFrequencyEnum.weekly && option.termInMonths === 84
  );

  const weeklyRepaymentPresented = approvedAmountQuoteData?.find(
    (option) => option.repaymentFrequency === RepaymentFrequencyEnum.weekly && option.termInMonths === 84
  );

  const isVariantB = quoteRepaymentHeroExperimentFeb25Value && quoteRepaymentHeroExperimentFeb25Value === 'B';
  const eventProperties: { [key: string]: string | number } = useMemo(() => {
    return {};
  }, []);

  const amountsComparison = useCallback(() => {
    if (loanApplicationData?.quotePresentedAmount > loanApplicationData?.requestedAmount) return 'higher';
    if (loanApplicationData?.quotePresentedAmount < loanApplicationData?.requestedAmount) return 'lower';
    return 'equal';
  }, [loanApplicationData?.quotePresentedAmount, loanApplicationData?.requestedAmount]);

  useEffect(() => {
    if (branch === BranchEnum.AU && quoteRepaymentHeroExperimentFeb25Value) {
      eventProperties['amounts_comparison'] = amountsComparison();
      eventProperties['quote_repayment_hero_experiment_feb25'] =
        `quote-repayment-hero-variant-${quoteRepaymentHeroExperimentFeb25Value}`;
    }
  }, [amountsComparison, branch, eventProperties, quoteRepaymentHeroExperimentFeb25Value]);

  useEffect(() => {
    eventAnalytics.track(QUOTE_READY_DISPLAYED, {
      taskid_str: taskId,
      ...eventProperties,
    });
  }, [eventProperties, taskId]);

  const handleContinueClick = async () => {
    setIsSubmitting(true);

    await completeTaskWithData({
      taskId,
    });

    eventAnalytics.track(QUOTE_READY, {
      taskid_str: taskId,
      ...eventProperties,
    });
  };
  return (
    <>
      <Card>
        <h1>
          {isVariantB && branch === BranchEnum.AU ? (
            <>
              <span className="text-primary">Congratulations,</span> here&rsquo;s your quote
            </>
          ) : (
            <>
              Congratulations, here&rsquo;s your <span className="text-primary">quote</span>
            </>
          )}
        </h1>
        {loanProductData &&
          loanApplicationData &&
          (isVariantB && branch === BranchEnum.AU ? (
            <>
              <div className="grid grid-cols-2">
                <div>
                  <p className="text-sm mb-0">Borrow</p>
                  <p className="text-h1">{formatCurrency(loanApplicationData?.requestedAmount)}</p>
                </div>
                <div>
                  <p className="text-sm mb-0">You&rsquo;ll pay about</p>
                  <p className="text-h1">
                    {formatCurrency(Math.floor(weeklyRepaymentRequested?.repaymentAmount))}{' '}
                    <span className="text-h2">{weeklyRepaymentRequested?.repaymentFrequency}</span>
                  </p>
                </div>
              </div>
              <p className="text-sm mt-4 mb-4">
                {weeklyRepaymentRequested?.termInMonths / 12} year term |{' '}
                {loanApplicationData?.finalInterestRate as unknown as number}% fixed interest p.a.
              </p>
              {loanApplicationData?.quotePresentedAmount > loanApplicationData?.requestedAmount && (
                <>
                  <hr className="border-t border-grey-2 -ml-6 -mr-6" />
                  <p className="text-sm pt-4">
                    You can increase your loan up to{' '}
                    <span className="font-medium">{formatCurrency(loanApplicationData?.quotePresentedAmount)}</span>{' '}
                    with a weekly payment of{' '}
                    <span className="font-medium">
                      {formatCurrency(Math.floor(weeklyRepaymentPresented?.repaymentAmount))}
                    </span>
                    .
                  </p>
                </>
              )}
            </>
          ) : (
            <>
              <p className="mb-2">
                Fixed interest rate of{' '}
                <span className="font-medium">{loanApplicationData?.finalInterestRate as unknown as number}% p.a.</span>
              </p>
              {
                <p className="mb-0">
                  Borrow up to{' '}
                  <span className="font-medium">{formatCurrency(loanApplicationData?.quotePresentedAmount)}</span>
                </p>
              }
            </>
          ))}
      </Card>
      {!isVariantB && (
        <Card className="flex items-center !p-4">
          <span className="mr-2 flex">
            <ClockLightningIcon />
          </span>
          <span className="text-sm">
            Hit continue and{' '}
            {isPersonalLoan(loanProductData?.name)
              ? 'the money could be in your account'
              : 'your debts could be paid off'}{' '}
            <TimeDisplayByBranch />
          </span>
        </Card>
      )}
      <div className="mb-6 text-center">
        <Button
          onClick={handleContinueClick}
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </>
  );
}
