import { useCallback, useEffect, useState } from 'react';
import {
  UpdateDirectDebitPlanDto,
  ValidateDirectDebitPlanResponse,
  VaultPaymentInformationDto,
} from '@harmoney/api-interfaces';
import { useGetUserByIdQuery, useValidateDirectDebitPlanMutation } from '@harmoney/redux';
import {
  Alert,
  ControlledInput,
  DatePicker,
  Form,
  Label,
  PaymentCard,
  Select,
  Textarea,
  TextLink,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { frequencyOptionsWithoutDefault } from '@harmoney/ui-utils';
import { convertFromRepaymentMonthlyAmount, formatCurrency, getNextPaymentDate } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { InboxType, LoanVariationPurposeEnum, RepaymentFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { CommonProps } from '../../../../common-props';
import { loanVariationPurposeOptions } from '../../LoanVariation/loan-variation-options';
import { PaymentPlanFormFooter } from '../PaymentPlanFormFooter';
import { PaymentPlanFormHeader } from '../PaymentPlanFormHeader';
import { ChangeSummaryTypeEnum } from '../util';

import { directDebitPlanFormSchema, getDirectDebitPlanDefaultValues } from './form-config';
import { UpdateDirectDebitPlanModal } from './UpdateDirectDebitPlanModal';

dayjs.extend(isSameOrBefore);

interface UpdateDirectDebitPlanProps extends CommonProps {
  paymentInfo: VaultPaymentInformationDto;
  loanApplicationId: string;
  spokeId: string;
  userId: string;
  redirectToMakeExtraPayment: () => void;
}

const formatDate = (date: string | Date) => dayjs(date).format('YYYY-MM-DD');

export const hasFirstRepaymentDatePassed = (firstPaymentDate: Date) => dayjs().isAfter(firstPaymentDate);

const getCurrentRepaymentDate = (firstPaymentDate: Date, recurringPaymentDate: Date) => {
  return hasFirstRepaymentDatePassed(firstPaymentDate) ? recurringPaymentDate : firstPaymentDate;
};

const getInitialRepaymentDetails = (paymentInfo: VaultPaymentInformationDto) => {
  const firstPayment = {
    title: 'First payment',
    amount: paymentInfo?.firstPaymentAmount,
    frequency: paymentInfo?.recurringPaymentFrequency,
    date: paymentInfo?.firstPaymentDate,
    icon: <Icon icon="mdi:calendar-today" className="text-grey-4" width={24} />,
  };
  const recurringPayment = {
    title: 'Recurring payment',
    amount: paymentInfo?.recurringPaymentAmount,
    frequency: RepaymentFrequencyEnum[paymentInfo?.recurringPaymentFrequency],
    date: paymentInfo?.recurringPaymentDate,
    icon: <Icon icon="mdi:calendar-month" className="text-grey-4" width={24} />,
  };

  return hasFirstRepaymentDatePassed(paymentInfo?.firstPaymentDate)
    ? { recurringPayment }
    : { firstPayment, recurringPayment };
};

const surfaceNotificationToCustomer = (preferredName: string) => {
  const message = `Hey ${preferredName}, just to let you know your direct debit plan has been updated.`;
  return {
    content: message,
    inboxType: InboxType.Message,
  };
};

export const UpdateDirectDebitPlan = ({
  loanApplicationId,
  taskId,
  completeTaskWithData,
  paymentInfo,
  spokeId,
  userId,
  redirectToMakeExtraPayment,
}: UpdateDirectDebitPlanProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [showMakeExtraPayment, setShowMakeExtraPayment] = useState<boolean>(false);
  const [extraPaymentAmount, setExtraPaymentAmount] = useState<number>(0);
  const [repaymentDetails, setRepaymentDetails] = useState(getInitialRepaymentDetails(paymentInfo));

  const [validateDirectDebitPlan] = useValidateDirectDebitPlanMutation();
  const { data: userData } = useGetUserByIdQuery(userId as string, { skip: !userId });

  const form = useForm({
    mode: 'onTouched',
    schema: directDebitPlanFormSchema,
    defaultValues: getDirectDebitPlanDefaultValues(
      getCurrentRepaymentDate(paymentInfo?.firstPaymentDate, paymentInfo?.recurringPaymentDate),
      paymentInfo?.recurringPaymentFrequency
    ),
  });

  const { register, watch, getValues } = form;

  useEffect(() => {
    handleRepaymentScheduleChange();
  }, []);

  const updateRepaymentDetails = (
    rescheduledDate: string,
    frequency: RepaymentFrequencyEnum,
    repaymentAmount: number
  ) => {
    if (!hasFirstRepaymentDatePassed(paymentInfo.firstPaymentDate)) {
      setRepaymentDetails((prevDetails) => ({
        ...prevDetails,
        firstPayment: {
          ...prevDetails.firstPayment,
          frequency: RepaymentFrequencyEnum[frequency],
          amount: repaymentAmount,
          date: dayjs(rescheduledDate).toDate(),
        },
        recurringPayment: {
          ...prevDetails.recurringPayment,
          amount: repaymentAmount,
          frequency: RepaymentFrequencyEnum[frequency],
          date: dayjs(getNextPaymentDate(rescheduledDate, frequency)).toDate(),
        },
      }));
    } else {
      setRepaymentDetails((prevDetails) => ({
        ...prevDetails,
        recurringPayment: {
          ...prevDetails.recurringPayment,
          amount: repaymentAmount,
          frequency: RepaymentFrequencyEnum[frequency],
          date: dayjs(rescheduledDate).toDate(),
        },
      }));
    }
  };

  const handleRepaymentScheduleChange = async () => {
    const rescheduledDate = getValues('rescheduledDate');
    const frequency = RepaymentFrequencyEnum[getValues('repaymentFrequency')];
    const repaymentAmount = convertFromRepaymentMonthlyAmount(paymentInfo?.monthlyContractualPaymentAmount, frequency);

    if (repaymentAmount) updateRepaymentDetails(formatDate(rescheduledDate), frequency, repaymentAmount);

    const result: ValidateDirectDebitPlanResponse = await validateDirectDebitPlan({
      loanApplicationId,
      paymentFrequency: RepaymentFrequencyEnum[frequency],
      paymentDate: formatDate(rescheduledDate),
    }).unwrap();

    if (!result.isValid) {
      setShowMakeExtraPayment(true);
      setExtraPaymentAmount(result.pendingPaymentAmount);
    } else {
      setShowMakeExtraPayment(false);
    }
  };

  const handleSubmit = () => setIsConfirmModalOpen(true);

  function generateUUID(): string {
    const randomHex = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

    return `${randomHex()}${randomHex()}-${randomHex()}-${randomHex()}-${randomHex()}-${randomHex()}${randomHex()}${randomHex()}`;
  }

  const onModalSubmit = useCallback(() => {
    setIsSubmitting(true);
    const formData = form.getValues();
    const data: UpdateDirectDebitPlanDto = {
      entityId: loanApplicationId,
      spokeId,
      userId,
      purpose: LoanVariationPurposeEnum[formData.purpose],
      otherPurpose: formData.otherPurpose,
      paymentFrequency: RepaymentFrequencyEnum[formData.repaymentFrequency],
      paymentDate: formatDate(formData.rescheduledDate),
      note: formData.notes,
      transactionId: generateUUID(),
    };

    const notification = surfaceNotificationToCustomer(userData?.preferredName);

    completeTaskWithData({
      taskId,
      variables: { loanApplicationId, notification, ...data },
    });
  }, [form, loanApplicationId, spokeId, userId, userData, completeTaskWithData, taskId]);

  return (
    <>
      <Form form={form} onSubmit={handleSubmit}>
        <PaymentPlanFormHeader
          type={ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN}
          headerTitle="Update direct debit plan"
        />
        <div className="px-4">
          <Select {...register('purpose')} label="Purpose" options={loanVariationPurposeOptions} className="mb-6" />
          {watch('purpose') === LoanVariationPurposeEnum.OTHER && (
            <>
              <Label htmlFor="otherPurpose" className="mb-2">
                Specify the purpose
              </Label>
              <ControlledInput {...register('otherPurpose')} className="mb-6" type="text" />
            </>
          )}
          <DatePicker
            className="mb-8"
            {...register('rescheduledDate', { onChange: handleRepaymentScheduleChange })}
            label="Reschedule recurring direct debit payment"
            placeholderText={dayjs(
              getCurrentRepaymentDate(paymentInfo?.firstPaymentDate, paymentInfo?.recurringPaymentDate)
            ).format('YYYY-MM-DD')}
            minDate={dayjs().add(1, 'day').toDate()}
            flexiblePopperPlacement
            onKeyDown={(e) => e.preventDefault()}
          />
          <ToggleGroup
            {...register('repaymentFrequency', { onChange: handleRepaymentScheduleChange })}
            options={frequencyOptionsWithoutDefault}
            className="sm:min-w-full mb-8"
            label="Payment frequency"
          />
          {showMakeExtraPayment ? (
            <Alert variant="warning" className="mb-6">
              You will have to{' '}
              <TextLink
                href=""
                onClick={(event) => {
                  event.preventDefault();
                  redirectToMakeExtraPayment();
                }}
              >
                schedule an extra payment
              </TextLink>{' '}
              for {formatCurrency(extraPaymentAmount)} before proceeding to update the direct debit plan.
            </Alert>
          ) : (
            <>
              {repaymentDetails?.firstPayment && (
                <PaymentCard
                  title={repaymentDetails.firstPayment.title}
                  amount={repaymentDetails.firstPayment.amount}
                  frequency={repaymentDetails.firstPayment.frequency as 'one-off'}
                  date={`From ${dayjs(repaymentDetails.firstPayment.date).format('ddd, DD MMMM YYYY')} onwards`}
                  icon={repaymentDetails.firstPayment.icon}
                  className="mb-4"
                />
              )}
              {repaymentDetails?.recurringPayment && (
                <PaymentCard
                  title={repaymentDetails.recurringPayment.title}
                  amount={repaymentDetails.recurringPayment.amount}
                  frequency={repaymentDetails.recurringPayment.frequency}
                  date={`From ${dayjs(repaymentDetails.recurringPayment.date).format('ddd, DD MMMM YYYY')} onwards`}
                  icon={repaymentDetails.recurringPayment.icon}
                />
              )}
            </>
          )}
          <Textarea {...register('notes')} label="Notes" placeholder="Add a note" className="mt-8" />
          <PaymentPlanFormFooter
            type={ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN}
            buttonText="Update"
            isSubmitting={isSubmitting}
            isDisabled={showMakeExtraPayment}
          />
        </div>
      </Form>

      <UpdateDirectDebitPlanModal
        isConfirmModalOpen={isConfirmModalOpen}
        setIsConfirmModalOpen={setIsConfirmModalOpen}
        onModalSubmit={onModalSubmit}
        isSubmitting={isSubmitting}
        directDebitPlan={{
          paymentDate: dayjs(getValues('rescheduledDate')).format('DD/MM/YYYY'),
          paymentFrequency: getValues('repaymentFrequency') as RepaymentFrequencyEnum,
        }}
      />
    </>
  );
};
