import { errors } from '@harmoney/ui-utils';
import dayjs from 'dayjs';
import { z } from 'zod';

export const defaultFormValues = {
  purpose: '',
  otherPurpose: '',
  startDate: null,
  endDate: null,
  noEndDate: false,
  numberOfPayments: undefined,
  paymentAmount: undefined,
  paymentFrequency: '',
  note: '',
};

export const paymentArrangementFormSchema = z
  .object({
    startDate: z.coerce
      .date({
        invalid_type_error: errors.invalid('First payment date'),
        required_error: errors.requiredField('First payment date'),
      })
      .min(dayjs().toDate(), { message: 'Please select first payment date' }),

    endDate: z.coerce.date().nullable().optional(),

    noEndDate: z.boolean().optional(),
    numberOfPayments: z.coerce
      .number({
        invalid_type_error: errors.invalid('number of payments'),
      })
      .optional(),

    paymentAmount: z.coerce
      .number({ invalid_type_error: errors.defaultValidAmount })
      .gte(1, { message: errors.defaultValidAmount }),
    paymentFrequency: z
      .string({
        message: errors.requiredField('Frequency'),
      })
      .min(1, { message: errors.defaultRequiredFrequency }),

    note: z.string().min(1, { message: 'Please add a note' }),
  })

  .superRefine((data, ctx) => {
    if (data.noEndDate === false && data.endDate === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please select either end date or no end date ',
        path: ['noEndDate'],
      });
    }

    if (data.startDate && data.endDate && dayjs(data.startDate).isAfter(data.endDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'End date must be after start date',
        path: ['endDate'],
      });

      if (!data.numberOfPayments || data.numberOfPayments <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Number of payments must be greater than 0',
          path: ['numberOfPayments'],
        });
      }
    }
  });
