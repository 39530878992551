import { z } from 'zod';

export enum UtmKeyEnum {
  SOURCE = 'utm_source',
  MEDIUM = 'utm_medium',
  CAMPAIGN = 'utm_campaign',
  CONTENT = 'utm_content',
  TERM = 'utm_term',
  GCLID = 'hmy_gclid',
}

export const utmKeyMapper = {
  [UtmKeyEnum.SOURCE]: 'utmSource',
  [UtmKeyEnum.MEDIUM]: 'utmMedium',
  [UtmKeyEnum.CAMPAIGN]: 'utmCampaign',
  [UtmKeyEnum.CONTENT]: 'utmContent',
  [UtmKeyEnum.TERM]: 'utmTerm',
  [UtmKeyEnum.GCLID]: 'gclid',
};

export const createUtmsSchema = z.array(
  z.object({
    name: z.nativeEnum(UtmKeyEnum),
    value: z.string().min(1),
  })
);

export type CreateUtmsRequestPayload = z.infer<typeof createUtmsSchema>;
