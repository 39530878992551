import { FieldArrayWithId } from 'react-hook-form';
import { IIncomeOverrideReasonFields, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { Badge, CommonOptionProps, CurrencyWithFrequency } from '@harmoney/ui-design-system';
import { CurrencyFormatter, retirementFundNameByBranch, valueOrNA } from '@harmoney/ui-utils';
import { BranchEnum, IncomeAndExpense, NetworthSource } from '@prisma/client';
import { Entry } from 'contentful';
import dayjs from 'dayjs';
import { capitalize, startCase } from 'lodash';

import { Field } from '../../shared';
import { NonSharedOverrideIncomeFormSchemaType, OverrideForm } from '../Override/OverrideForm';

const PARTNER_INCOME_CODE = 'partner_income';

type IncomeItemProps = {
  income: IncomeAndExpense & { networthSource: NetworthSource };
  overrideReasons?: Entry<IIncomeOverrideReasonFields>[];
  displayOverrideForm: boolean;
  fields: FieldArrayWithId<NonSharedOverrideIncomeFormSchemaType>[];
  branch: BranchEnum;
};

export const IncomeItem = ({ income, overrideReasons, displayOverrideForm, fields, branch }: IncomeItemProps) => {
  const overrideOptions: CommonOptionProps[] = overrideReasons
    ? [
        { label: 'Select an option', value: '' },
        ...overrideReasons.map((reason) => ({
          label: reason.fields.reason,
          value: reason.sys.id,
        })),
        { label: 'Other', value: 'other' },
      ]
    : [];

  const overrideCategoryName =
    income.networthSourceId === NetworthSourceEnum.INCOME_RETIREMENT_FUNDS_ID
      ? retirementFundNameByBranch[branch]
      : income.networthSource.name;
  return (
    <>
      <div className="p-4 space-y-2">
        {income?.createdBy && <Badge label="Added by Credit Officer" variant="secondary" className="self-end" />}
        <Field>
          <span>Income category:</span>
          <span className="font-medium first-letter:capitalize">{valueOrNA(overrideCategoryName)}</span>
        </Field>
        {income?.networthSource.id !== NetworthSourceEnum.INCOME_NO_ID && (
          <>
            {income?.employmentCode && (
              <Field>
                <span>Work code:</span>
                <span>{valueOrNA(capitalize(income?.employmentCode))}</span>
              </Field>
            )}
            {(income?.employmentType || income?.selfEmploymentType) && (
              <Field>
                <span>Work type:</span>
                {income?.networthSource.id === NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID ? (
                  <span>{valueOrNA(startCase(income?.selfEmploymentType))}</span>
                ) : (
                  <span>{valueOrNA(startCase(income?.employmentType))}</span>
                )}
              </Field>
            )}
            {income?.otherIncomeType && (
              <Field>
                <span>Other work type:</span>
                <span>{valueOrNA(income?.otherIncomeType)}</span>
              </Field>
            )}
            {income?.benefitType && (
              <Field>
                <span>Benefit Type:</span>
                <span>{valueOrNA(income?.benefitType)}</span>
              </Field>
            )}
            {income?.benefitName && (
              <Field>
                <span>Benefit Name:</span>
                <span>{valueOrNA(income?.benefitName)}</span>
              </Field>
            )}
            {income?.seasonalWorkingMonths && (
              <Field>
                <span>Seasonal working months:</span>
                <span>{valueOrNA(income?.seasonalWorkingMonths)}</span>
              </Field>
            )}
            {income?.employmentStartDate && (
              <Field>
                <span>Start date:</span>
                <span>
                  {income?.employmentStartDate ? dayjs(income?.employmentStartDate).format('MMM YYYY') : 'N/A'}
                </span>
              </Field>
            )}
            {income?.declaredAmount && (
              <Field>
                <span>Customer declared amount:</span>
                <span>
                  {income?.declaredAmount > 0 ? (
                    <CurrencyWithFrequency amount={income.declaredAmount} frequency={income.frequency} />
                  ) : (
                    `$0`
                  )}
                </span>
              </Field>
            )}
            {income?.payType && (
              <Field>
                <span>Type:</span>
                <span>{capitalize(income?.payType)} pay</span>
              </Field>
            )}
            {income?.isRentalIncomeShared && (
              <Field>
                <span>Income shared:</span>
                <span>
                  {(income.networthSource.id === NetworthSourceEnum.INCOME_RENT_ID && income.isRentalIncomeShared) ||
                    'N/A'}
                </span>
              </Field>
            )}
            {!displayOverrideForm && (
              <>
                {income?.updatedBy && (
                  <div className="pb-2">
                    <Badge label="Overwritten by credit officer" variant="secondary" />
                  </div>
                )}
                {income?.creditOfficerFrequency && (
                  <Field>
                    <span>
                      {income.networthSource.code === PARTNER_INCOME_CODE ? 'Net partner income:' : 'Override amount:'}
                    </span>
                    {income?.creditOfficerAmount > 0 ? (
                      <CurrencyWithFrequency
                        amount={income?.creditOfficerAmount}
                        frequency={income?.creditOfficerFrequency}
                      />
                    ) : (
                      CurrencyFormatter.format(income?.creditOfficerAmount)
                    )}
                  </Field>
                )}
                {income?.overrideReason && (
                  <Field>
                    <span>Override reason:</span>
                    <span className="first-letter:capitalize">
                      {valueOrNA(
                        income?.overrideReason.includes(' - ')
                          ? income?.overrideReason.split(' - ')[1]
                          : income?.overrideReason
                      )}
                    </span>
                  </Field>
                )}
              </>
            )}
          </>
        )}
        {displayOverrideForm && (
          <OverrideForm
            field={fields.find((field) => field?.itemId === income.id)}
            index={fields.findIndex((field) => field?.itemId === income.id)}
            overrideOptions={overrideOptions}
            type="income"
            amountLabel={income.networthSource.code === PARTNER_INCOME_CODE ? 'Net partner income:' : undefined}
          />
        )}
      </div>
    </>
  );
};
