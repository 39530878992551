import { LoanApplicationStatusEnum, LoanArrearsStatusEnum, VaultLoanAccountStateEnum } from '@prisma/client';

export const isCancelled = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return !!(
    loanApplicationStatus === LoanApplicationStatusEnum.quote_cancelled ||
    loanApplicationStatus === LoanApplicationStatusEnum.application_cancelled
  );
};

export const isExpired = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return !!(
    loanApplicationStatus === LoanApplicationStatusEnum.quote_expired ||
    loanApplicationStatus === LoanApplicationStatusEnum.application_expired
  );
};

export const isUnsuccessful = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return !!(
    loanApplicationStatus === LoanApplicationStatusEnum.quote_unsuccessful ||
    loanApplicationStatus === LoanApplicationStatusEnum.application_unsuccessful
  );
};

export const isApplicationSuccessful = (loanApplicationStatus: LoanApplicationStatusEnum) => {
  if (!loanApplicationStatus) return false;
  return loanApplicationStatus === LoanApplicationStatusEnum.application_successful;
};

export const isLoanArrearsPaidOff = (
  loanApplicationStatus: LoanApplicationStatusEnum,
  loanArrearsStatus: LoanArrearsStatusEnum
) => {
  if (!loanApplicationStatus) return false;
  if (!loanArrearsStatus) return false;
  if (loanApplicationStatus !== LoanApplicationStatusEnum.application_successful) return false;
  return loanArrearsStatus === LoanArrearsStatusEnum.PAID_OFF;
};

export const isLoanArrearsCancelled = (loanArrearsStatus: LoanArrearsStatusEnum) => {
  if (!loanArrearsStatus) return false;
  return loanArrearsStatus === LoanArrearsStatusEnum.CANCELED;
};

export const isVaultLoanStatePaidOff = (vaultLoanState: VaultLoanAccountStateEnum) => {
  if (!vaultLoanState) return false;
  if (vaultLoanState === VaultLoanAccountStateEnum.PAID_OFF) return true;
  else return false;
};

export const isVaultLoanStateCancelled = (vaultLoanState: VaultLoanAccountStateEnum) => {
  if (!vaultLoanState) return false;
  if (vaultLoanState === VaultLoanAccountStateEnum.CANCELLED) return true;
  else return false;
};

export const isVaultLoanStateWrittenOff = (vaultLoanState: VaultLoanAccountStateEnum) => {
  if (!vaultLoanState) return false;
  if (vaultLoanState === VaultLoanAccountStateEnum.WRITTEN_OFF) return true;
  else return false;
};

export const isHistorical = (
  loanApplicationStatus: LoanApplicationStatusEnum,
  loanArrearsStatus: LoanArrearsStatusEnum,
  vaultLoanState: VaultLoanAccountStateEnum
) => {
  if (!loanApplicationStatus) return false;
  return !!(
    isUnsuccessful(loanApplicationStatus) ||
    isCancelled(loanApplicationStatus) ||
    isExpired(loanApplicationStatus) ||
    isLoanArrearsPaidOff(loanApplicationStatus, loanArrearsStatus) ||
    isLoanArrearsCancelled(loanArrearsStatus) ||
    isVaultLoanStatePaidOff(vaultLoanState) ||
    isVaultLoanStateCancelled(vaultLoanState)
  );
};
