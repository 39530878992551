import Image from 'next/image';
import { useState } from 'react';
import { useFriendlyURL, useQuoteData } from '@harmoney/hooks';
import { useGetLoanApplicationQuery } from '@harmoney/redux';
import { eventAnalytics, QUOTE_READY } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, ClockLightningIcon, Spinner } from '@harmoney/ui-design-system';
import { formatCurrency, isPersonalLoan } from '@harmoney/utilities';
import { Player } from '@lottiefiles/react-lottie-player';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CommonProps } from '../../common-props';
import { TimeDisplayByBranch } from '../shared';

import 'swiper/css';

const cards = [
  {
    imageSrc: '/assets/images/repayment-fees.svg',
    text: 'No early repayment fees',
  },
  {
    imageSrc: '/assets/images/money-in-minutes.svg',
    text: 'Money in minutes',
  },
  {
    imageSrc: '/assets/images/fair-rates.svg',
    text: 'Fair rates',
  },
];

export function QuoteReady({ loanProductData, taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const { quoteData, variables, isLoading: isQuoteDataLoading } = useQuoteData(taskId);
  const { data: loanApplicationData, isLoading: isLoanApplicationLoading } = useGetLoanApplicationQuery(
    variables?.loanApplicationId?.toString(),
    {
      skip: !variables?.loanApplicationId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  useFriendlyURL(taskFriendlyURL);

  const handleContinueClick = async () => {
    setIsSubmitting(true);
    await completeTask({ taskId });
    eventAnalytics.track(QUOTE_READY, {
      taskid_str: taskId,
    });
  };

  if (isQuoteDataLoading || isLoanApplicationLoading) {
    return <Spinner size="large" />;
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full bg-gradient-to-b from-secondary-lighter-2 to-gradient-grey h-[20rem]"></div>

      <div className="relative mt-12">
        <Player
          autoplay
          loop
          controls={false}
          src="https://lottie.host/74413bf1-c7cd-40aa-9fb3-903a936fd756/K6ArNCmwIK.json"
          style={{ position: 'absolute', width: '100%', top: '-100px' }}
        />
      </div>

      <div className="relative">
        <Card>
          <h1>
            Congratulations, here&rsquo;s your <span className="text-primary">quote</span>
          </h1>
          {quoteData && loanProductData && loanApplicationData && (
            <>
              <p className="mb-2">
                Fixed interest rate of {loanApplicationData?.finalInterestRate as unknown as number}%
              </p>
              {isPersonalLoan(loanProductData?.name) ? (
                <p className="mb-0">Borrow up to {formatCurrency(quoteData.maximumBorrowingLimit)}</p>
              ) : (
                <p className="mb-0">You can consolidate up to {formatCurrency(quoteData.maximumBorrowingLimit)}</p>
              )}
            </>
          )}
        </Card>

        <Card className="flex items-center !p-4">
          <span className="mr-2 flex">
            <ClockLightningIcon />
          </span>
          <span className="text-sm">
            Hit continue and{' '}
            {isPersonalLoan(loanProductData?.name)
              ? 'the money could be in your account'
              : 'your debts could be paid off'}{' '}
            <TimeDisplayByBranch />
          </span>
        </Card>

        <div className="mb-6 text-center">
          <Button
            onClick={handleContinueClick}
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            isLoading={isSubmitting}
          >
            Continue
          </Button>
        </div>

        <h3 className="font-body text-base font-medium">Why Harmoney</h3>
        <div className="md:hidden">
          <Swiper slidesPerView={2} className="md:hidden">
            {cards.map(({ imageSrc, text }, index) => (
              <SwiperSlide
                key={index}
                className="shadow-drop mr-4 flex flex-col items-center justify-center rounded-lg bg-white p-2 "
              >
                <Image src={imageSrc} alt={text} width={200} height={200} quality={100} />
                <h4 className="text-h5 my-2 text-center !font-medium">{text}</h4>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <ul className="hidden grid-cols-2 gap-4 md:grid md:grid-cols-3">
          {cards.map(({ imageSrc, text }, index) => (
            <li key={index} className="shadow-drop flex flex-col items-center justify-center rounded-lg bg-white p-2">
              <Image src={imageSrc} alt={text} width={200} height={200} quality={100} />
              <h4 className="my-2 text-center text-sm !font-medium">{text}</h4>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
