import { GeocodeAddressInfoDto, PlacesAutocompleteSuggestionsDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const mapsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlaceAutocompleteSuggestions: builder.query<PlacesAutocompleteSuggestionsDto[], string>({
      query: (query) => ({
        url: `/maps/autocomplete?searchText=${query}`,
        method: 'GET',
      }),
    }),
    getGeocodeInformation: builder.query<GeocodeAddressInfoDto, string>({
      query: (placeId) => ({
        url: `/maps/geocode?placeId=${placeId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetPlaceAutocompleteSuggestionsQuery, useLazyGetGeocodeInformationQuery } = mapsApi;
