import { CollapsibleCard, Divider } from '@harmoney/ui-design-system';
import { valueOrNA } from '@harmoney/ui-utils';
import { formatNumber } from '@harmoney/utilities';
import {
  AffordabilityModelEnum,
  AssetAndLiability,
  BranchEnum,
  FinancialProfile,
  IncomeAndExpense,
  NetworthSource,
  Prisma,
} from '@prisma/client';
import { get } from 'lodash';

import { Field } from '../customer-hub';
import { DebtsTable } from '../customer-hub/Affordability/DebtsTable';
import { ExpensesTable } from '../customer-hub/Affordability/ExpensesTable';
import { IncomeTable } from '../customer-hub/Affordability/IncomeTable';
import PartnerShareExpensesDetails from '../customer-hub/Affordability/PartnerShareExpensesDetails';

interface AffordabilityDetailsProps {
  rootData: Prisma.JsonValue;
  financialProfileData: FinancialProfile & {
    asset_and_liability: (AssetAndLiability & {
      networthSource: NetworthSource;
    })[];
    income_and_expense: (IncomeAndExpense & {
      networthSource: NetworthSource;
    })[];
  };
}

export type RowData = {
  title: string;
  declared?: string;
  bankStatement?: string;
  benchmark?: string;
  acceptedValue: string;
};

export const AffordabilityDetailsForSimulation = ({ rootData, financialProfileData }: AffordabilityDetailsProps) => {
  const checkIfUserEligibleForSharedExpense = (model: AffordabilityModelEnum) => {
    if (model === AffordabilityModelEnum.hLoanWithSharedExpense) {
      return (
        rootData[`sharedExpenseResultForHLoan`]?.['sharedAccommodationExpenseEligibility']?.['isEligible'] ||
        rootData[`sharedExpenseResultForHLoan`]?.['sharedLivingExpenseEligibility']?.['isEligible'] ||
        rootData[`sharedExpenseResultForHLoan`]?.['sharedMortgageExpenseEligibility']?.['isEligible']
      );
    }

    if (model === AffordabilityModelEnum.hMoneyWithSharedExpense) {
      return (
        rootData[`sharedExpenseResultForHMoney`]?.['sharedAccommodationExpenseEligibility']?.['isEligible'] ||
        rootData[`sharedExpenseResultForHMoney`]?.['sharedLivingExpenseEligibility']?.['isEligible'] ||
        rootData[`sharedExpenseResultForHMoney`]?.['sharedMortgageExpenseEligibility']?.['isEligible']
      );
    }
  };

  const renderUmi = (key: string) => {
    return (
      <div className="flex flex-col">
        {rootData && (
          <span className={`${(rootData?.[key] as number) < 0 ? 'text-error' : ''}`}>
            {formatNumber(get(rootData, key) as number)}
          </span>
        )}
        {rootData && (
          <span className="text-sm text-grey-3">Original value: {formatNumber(get(rootData, key) as number)}</span>
        )}
      </div>
    );
  };

  const calculateMaxRatio = ({
    affordabilityData,
    ratio1Key,
    ratio2Key,
    minRatioKey,
  }: {
    affordabilityData: Prisma.JsonValue;
    ratio1Key: string;
    ratio2Key: string;
    minRatioKey: string;
  }) => {
    if (
      !affordabilityData[ratio1Key] ||
      !affordabilityData[ratio2Key] ||
      !affordabilityData['sharedExpenseInput']['configuration'][minRatioKey]
    )
      return 'N/A';
    const maxRatio = Math.max(affordabilityData[ratio1Key], affordabilityData[ratio2Key]);
    const finalRatio = maxRatio < affordabilityData[minRatioKey] ? affordabilityData[minRatioKey] : maxRatio;
    return `${finalRatio.toFixed(2)}%`;
  };

  const branch = rootData?.['degreesOfFreedom']?.['branch'];

  return (
    <CollapsibleCard title="Affordability" className="mb-6" defaultOpen={false}>
      <div className="flex flex-col gap-2 py-4">
        <div className="grid grid-cols-2 px-4">
          {rootData ? (
            <Field className="space-x-4">
              <span className="font-medium">Affordability Model Used</span>
              <span>{rootData?.['selectedOffer']}</span>
            </Field>
          ) : (
            <div className="space-y-1">
              <Field className="space-x-4">
                <span className="font-medium">Affordability Assessment Model Used</span>
                <span>{rootData['degreesOfFreedom']['affordabilityModel']?.assessmentProcessId}</span>
              </Field>
              <Field className="space-x-4">
                <span className="font-medium">Affordability Calculation Model Used</span>
                <span>{rootData['degreesOfFreedom']['affordabilityModel']?.calculationProcessId}</span>
              </Field>
            </div>
          )}
          {rootData ? (
            <div className="space-y-1">
              {rootData?.['umiHLoan'] && (
                <Field className="space-x-4">
                  <span>HLoan UMI</span>
                  {renderUmi('umiHLoan')}
                </Field>
              )}
              {rootData?.['umiHMoney'] && (
                <Field className="space-x-4">
                  <span>HMoney UMI</span>
                  {renderUmi('umiHMoney')}
                </Field>
              )}
              <Field className="space-x-4">
                <span>HLoan with shared expense UMI</span>
                {rootData &&
                rootData?.['sharedExpenseResultForHLoan'] &&
                checkIfUserEligibleForSharedExpense(AffordabilityModelEnum.hLoanWithSharedExpense) ? (
                  renderUmi('sharedExpenseResultForHLoan.umi')
                ) : (
                  <span className="font-normal">N/A</span>
                )}
              </Field>
              <Field className="space-x-4">
                <span>HMoney with shared expense UMI</span>
                {rootData &&
                rootData?.['sharedExpenseResultForHMoney'] &&
                checkIfUserEligibleForSharedExpense(AffordabilityModelEnum.hMoneyWithSharedExpense) ? (
                  renderUmi('sharedExpenseResultForHMoney.umi')
                ) : (
                  <span className="font-normal">N/A</span>
                )}
              </Field>
            </div>
          ) : (
            <div>
              <span className="mx-4 grid grid-cols-4">
                <span className="col-span-1 font-medium">UMI</span>
                <span className={`col-span-3 ${(rootData['umi'] as number) < 0 ? 'text-error' : ''}`}>
                  {formatNumber(rootData['umi'] as number)}
                </span>
              </span>
            </div>
          )}
        </div>
        <Divider className="text-grey-2 px-4 my-2" />
        {branch === BranchEnum.NZ ? (
          <PartnerShareExpensesDetails affordabilityData={rootData} />
        ) : (
          <div className="px-4">
            <p className="mb-4 font-medium">Shared contribution (%)</p>
            <div className="grid grid-cols-2 odd:border-r-grey-1">
              <div className="space-y-1">
                <Field className="space-x-4">
                  <span>Income Split</span>
                  <span>
                    {(rootData['declaredToHouseholdIncomeRatio'] as number)
                      ? `${(rootData['declaredToHouseholdIncomeRatio'] as number).toFixed(2)}%`
                      : 'N/A'}
                  </span>
                </Field>
                <Field className="space-x-4">
                  <span>Accommodation Expenses Split</span>
                  <span>
                    {(rootData['sharedAccommodationExpenseRatio'] as number)
                      ? `${(rootData['sharedAccommodationExpenseRatio'] as number).toFixed(2)}%`
                      : 'N/A'}
                  </span>
                </Field>
                <Field className="space-x-4">
                  <span>Final Accommodation Expenses Split</span>
                  <span>
                    {calculateMaxRatio({
                      affordabilityData: rootData,
                      ratio1Key: 'declaredToHouseholdIncomeRatio',
                      ratio2Key: 'sharedAccommodationExpenseRatio',
                      minRatioKey: 'minIncomeAccommodationExpenseRatio',
                    })}
                  </span>
                </Field>
              </div>
              <div className="space-y-1">
                <Field className="space-x-4">
                  <span>Primary Income Source</span>
                  <span>{valueOrNA(rootData?.['primaryIncomeSource']?.['source'])}</span>
                </Field>
                <Field className="space-x-4">
                  <span>Living Expenses Split</span>
                  <span>
                    {(rootData['sharedLivingExpenseRatio'] as number)
                      ? `${(rootData['sharedLivingExpenseRatio'] as number).toFixed(2)}%`
                      : 'N/A'}
                  </span>
                </Field>
                <Field className="space-x-4">
                  <span>Final Living Expenses Split</span>
                  <span>
                    {calculateMaxRatio({
                      affordabilityData: rootData,
                      ratio1Key: 'declaredToHouseholdIncomeRatio',
                      ratio2Key: 'sharedLivingExpenseRatio',
                      minRatioKey: 'minIncomeLivingExpenseRatio',
                    })}
                  </span>
                </Field>
              </div>
            </div>
          </div>
        )}

        <Divider className="text-grey-2 px-4 my-2" />
        <div className="px-4 space-y-4">
          <span className="text-xs italic">*All values monthly</span>
          <IncomeTable affordabilityData={rootData} rootData={rootData} />
          <ExpensesTable affordabilityData={rootData} rootData={rootData} />
          <DebtsTable affordabilityData={rootData} financialProfileData={financialProfileData} />
        </div>
      </div>
    </CollapsibleCard>
  );
};
