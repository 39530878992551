import React from 'react';
import classNames from 'classnames';

interface PercentageSliderProps {
  value: number;
  onChange: (value: number) => void;
  step?: number;
  className?: string;
  thumbClassName?: string;
}

export const Slider: React.FC<PercentageSliderProps> = ({ value, onChange, step = 10, className, thumbClassName }) => {
  const ticks = Array.from({ length: 100 / step + 1 }, (_, i) => i * step);
  const skipInterval = 2; // Set to 1 to allow no text skipping
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    onChange(newValue);
  };
  const thumbSize = 16 + (value / 100) * 32;

  return (
    <div className={classNames('relative w-full', className)}>
      {/* Slider */}
      <div className="relative w-full h-1">
        <div className="absolute w-full h-full bg-grey-2 rounded-full">
          <div className="absolute h-full bg-primary rounded-full" style={{ width: `${value}%` }} />
        </div>
        <span
          role="img"
          aria-label="rocket"
          className="absolute top-1/2 -translate-y-1/2 select-none pointer-events-none"
          style={{
            left: `${value}%`,
            transform: `translate(-50%, -50%) scale(${thumbSize / 16})`,
          }}
        >
          🚀
        </span>
        <input
          type="range"
          min="0"
          max="100"
          step={step}
          value={value}
          onChange={handleChange}
          className={classNames(
            'absolute w-full h-1 appearance-none bg-transparent cursor-pointer',
            '[&::-webkit-slider-thumb]:appearance-none',
            '[&::-webkit-slider-thumb]:size-6',
            '[&::-webkit-slider-thumb]:opacity-0',
            thumbClassName
          )}
        />
      </div>
      {/* Ticks */}
      <span
        className="mt-1 flex w-full items-center justify-between gap-1 px-2.5 text-xs font-medium"
        aria-hidden="true"
      >
        {ticks.map((tick, i) => (
          <span key={i} className="flex w-0 flex-col items-center justify-center gap-2">
            <span className={classNames('bg-black/70 h-1', i % skipInterval !== 0 && 'h-0.5')} />
            <span className={classNames(i % skipInterval !== 0 && 'opacity-0')}>{tick}%</span>
          </span>
        ))}
      </span>
    </div>
  );
};
