import { useEffect, useState } from 'react';
import { AdjustmentTypeEnum, loanVariationPurposeMapper, SpokeName } from '@harmoney/api-interfaces';
import {
  useAppSelector,
  useCancelDirectDebitSuspensionMutation,
  useCancelOneOffPaymentMutation,
  useGetAllSpokesQuery,
  useLazyGetUserByIdQuery,
  useLazyGetUserProfileForAdminQuery,
  useRemovePaymentArrangementMutation,
} from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Badge,
  Button,
  Dialog,
  Divider,
  Label,
  PaymentCard,
  Spinner,
} from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { dayjsUTCToSydney, formatCurrency, formatFrequency } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { PaymentStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { capitalize } from 'lodash';

import { PaymentArrangementSchedule } from './PaymentArrangement/PaymentArrangementSchedule';
import { RepaymentPlan, RepaymentPlanProps } from './PaymentArrangement/RepaymentPlan';
import { PaymentPlanFormHeaderWithoutForm } from './PaymentPlanFormHeaderWithoutForm';
import { hasFirstRepaymentDatePassed } from './UpdateDirectDebitPlan';
import {
  ChangeSummaryTypeEnum,
  isAllPaymentsCompleted,
  isAllPaymentsDeleted,
  isDDSuspensionDeleted,
  isSomePaymentsDeleted,
  PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR,
} from './util';

dayjs.extend(isSameOrAfter);

interface ChangeSummaryDataProps {
  loanApplicationId: string;
  isSummaryDataLoading?: boolean;
  summaryData: any;
  committedBy?: string;
  updatedBy?: string;
  onSuccessfulRemoval: (type: string) => void;
  cancelAccessPermission: (spokeName: SpokeName) => boolean;
  handleEditWriteOff: () => void;
  handleEditPaymentArrangement: () => void;
}
export const ChangeSummaryData = ({
  summaryData,
  committedBy,
  updatedBy,
  isSummaryDataLoading = false,
  loanApplicationId,
  onSuccessfulRemoval,
  cancelAccessPermission,
  handleEditWriteOff,
  handleEditPaymentArrangement,
}: ChangeSummaryDataProps) => {
  const token = useAppSelector((state) => state?.accessToken?.value);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [updatedByNames, setUpdatedByNames] = useState<{ [key: number]: string }>({});
  const [isPaymentArrangementRemoved, setIsPaymentArrangementRemoved] = useState<boolean>(false);
  const [isPaymentArrangementEdited, setIsPaymentArrangementEdited] = useState<boolean>(false);
  const [createdByName, setCreatedByName] = useState<string | null>(null);
  const [hasEndDate, setHasEndDate] = useState<boolean>(false);

  const [repaymentSchedule, setRepaymentSchedule] = useState<RepaymentPlanProps>(null);

  const [removeSuspension] = useCancelDirectDebitSuspensionMutation();

  const [removeOneOffPayment] = useCancelOneOffPaymentMutation();

  const [removePaymentArrangement] = useRemovePaymentArrangementMutation();

  const [userData] = useLazyGetUserByIdQuery();

  const [userProfile] = useLazyGetUserProfileForAdminQuery();

  const { data: allSpokesData } = useGetAllSpokesQuery(null, {
    skip: !token,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  useEffect(() => {
    const fetchUpdatedByNames = async () => {
      const names = await Promise.all(
        summaryData?.editWriteOff.map(async (writeOff, index) => {
          const updatedByFullName = await getEmailIdAndUserName(writeOff.updatedBy);
          return { index, updatedByFullName };
        })
      );

      const updatedNamesMap = names.reduce(
        (acc, { index, updatedByFullName }) => {
          acc[index] = updatedByFullName;
          return acc;
        },
        {} as { [key: number]: string }
      );

      setUpdatedByNames(updatedNamesMap);
    };

    const fetchCreatedByName = async () => {
      if (summaryData?.createdBy) {
        const createdByFullName = await getEmailIdAndUserName(summaryData.createdBy);
        setCreatedByName(createdByFullName);
      }
    };

    const fetchRepaymentSchedule = () => {
      summaryData.noEndDate = !summaryData?.endDate;
      setHasEndDate(!!summaryData?.endDate);

      const repaymentScheduleData = RepaymentPlan(summaryData, summaryData?.paymentInfo);
      setRepaymentSchedule(repaymentScheduleData);
    };

    if (summaryData?.editWriteOff?.length > 0) {
      fetchUpdatedByNames();
    }
    if (summaryData && summaryData?.type === ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT) {
      fetchRepaymentSchedule();

      if (!summaryData?.isEnabled) {
        setIsPaymentArrangementRemoved(true);
      }
      if (
        summaryData?.isMasterRecord &&
        summaryData?.isEnabled &&
        dayjs(summaryData?.createdAt).isBefore(summaryData?.updatedAt)
      ) {
        setIsPaymentArrangementEdited(true);
      }
    }
    if (summaryData) {
      fetchCreatedByName();
    }
  }, [summaryData]);

  const hasAccessPermission = (spokeName: SpokeName) => {
    return allSpokesData?.some((spoke) => spoke.name === spokeName);
  };

  const handleSubmit = () => {
    setIsConfirmModalOpen(true);
  };

  const onModalSubmit = async (type: ChangeSummaryTypeEnum) => {
    setIsSubmitting(true);
    switch (type) {
      case ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT:
        await removeSuspension(loanApplicationId);
        break;
      case ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT:
        await removePaymentArrangement(loanApplicationId);
        break;

      case ChangeSummaryTypeEnum.ONE_OFF_PAYMENT:
        await removeOneOffPayment({
          loanApplicationId,
          scheduleIds: summaryData?.schedules
            ?.filter((schedule) => {
              return (
                dayjs(schedule?.scheduledAt).isSameOrAfter(dayjs(), 'day') && schedule.status === PaymentStatusEnum.new
              );
            })
            .map((schedule) => schedule.id as string),
        });
        break;
    }
    onSuccessfulRemoval(type);
  };

  const getModalTitle = (type: ChangeSummaryTypeEnum) => {
    switch (type) {
      case ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT:
        return 'Remove direct debit suspension';
      case ChangeSummaryTypeEnum.ONE_OFF_PAYMENT:
        return 'Delete scheduled extra payment';
      case ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT:
        return 'Remove payment arrangement';
    }
  };

  const isDeleted = isDDSuspensionDeleted(summaryData) || isAllPaymentsDeleted(summaryData);

  const getBadgeData = (status: PaymentStatusEnum): { label: string; variant: 'action' | 'success' } => {
    switch (status) {
      case PaymentStatusEnum.cancelled:
        return { variant: 'action', label: 'Deleted' };
      case PaymentStatusEnum.dishonored:
        return { variant: 'action', label: 'Dishonored' };
      case PaymentStatusEnum.error:
        return { variant: 'action', label: 'Error' };
      case PaymentStatusEnum.new:
        return { variant: 'success', label: 'New' };
      case PaymentStatusEnum.processing:
        return { variant: 'success', label: 'Processing' };
      case PaymentStatusEnum.success:
        return { variant: 'success', label: 'Completed' };
    }
  };

  const getDeleteButtonLabel = (type: ChangeSummaryTypeEnum) => {
    if (type === ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT) {
      return 'Remove Suspension';
    } else if (type === ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT) {
      return 'Remove';
    } else {
      return 'Delete';
    }
  };

  const getOverAllStatusBadgeData = (): { label: string; variant: 'action' | 'success' } => {
    if (isAllPaymentsDeleted(summaryData)) {
      return { variant: 'action', label: 'Deleted' };
    }
    if (isAllPaymentsCompleted(summaryData)) {
      return { variant: 'success', label: 'Completed' };
    }
  };

  if (isSummaryDataLoading) {
    return <Spinner size="small" />;
  }

  const cancelledAt = dayjs(summaryData?.cancelledAt);
  const deletedAt = cancelledAt.isValid()
    ? `${cancelledAt.format(DATE_FORMAT)} at ${cancelledAt.format('h:mm a')}`
    : '';

  const getCommittedAt = () => {
    switch (summaryData?.type) {
      case ChangeSummaryTypeEnum.WRITE_OFF:
        if (summaryData?.editWriteOff?.length > 0) {
          return `${dayjs(summaryData?.editWriteOff[0]?.updatedAt).format(DATE_FORMAT)} at ${dayjs(summaryData?.editWriteOff[0]?.updatedAt).format('h:mm a')}`;
        }
        return `${dayjs(summaryData?.createdAt).format(DATE_FORMAT)} at ${dayjs(summaryData?.createdAt).format('h:mm a')}`;
      case ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT:
        if (isPaymentArrangementEdited) {
          return `${dayjs(summaryData?.updatedAt).format(DATE_FORMAT)} at ${dayjs(summaryData?.updatedAt).format('h:mm a')}`;
        }
        return `${dayjs(summaryData?.createdAt).format(DATE_FORMAT)} at ${dayjs(summaryData?.createdAt).format('h:mm a')}`;
      default:
        return `${dayjs(summaryData?.createdAt).format(DATE_FORMAT)} at ${dayjs(summaryData?.createdAt).format('h:mm a')}`;
    }
  };

  const getCommittedBy = () => {
    switch (summaryData?.type) {
      case ChangeSummaryTypeEnum.WRITE_OFF:
        if (summaryData?.editWriteOff?.length > 0) {
          return updatedByNames[0];
        }
        return committedBy;
      default:
        return committedBy;
    }
  };

  const getNotes = () => {
    switch (summaryData?.type) {
      case ChangeSummaryTypeEnum.WRITE_OFF:
        if (summaryData?.editWriteOff?.length > 0) {
          return summaryData?.editWriteOff[0]?.note;
        }
        return summaryData?.note;
      default:
        return summaryData?.note;
    }
  };

  const getEmailIdAndUserName = async (userId: string) => {
    const user = await userData(userId).unwrap();
    const userProfileData = await userProfile(userId).unwrap();

    const { firstName, middleName, lastName } = userProfileData;
    const fullNameParts = [firstName, middleName, lastName].filter(
      (part) => typeof part === 'string' && part.trim() !== ''
    );
    if (fullNameParts?.length > 0) {
      return fullNameParts.join(' ');
    } else {
      return user?.email;
    }
  };

  const isPurposeVisible = () => {
    return (
      summaryData?.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT ||
      summaryData?.type === ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT ||
      summaryData?.type === ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN
    );
  };
  return (
    <>
      <PaymentPlanFormHeaderWithoutForm
        headerTitle={
          summaryData?.type === ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN
            ? 'Update direct debit plan'
            : summaryData.type
        }
      />
      <div className="px-4">
        {summaryData?.type === ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT && isDDSuspensionDeleted(summaryData) && (
          <>
            <p className="text-h3 pt-8 font-semibold">Suspension removed</p>
            <span className="grid grid-cols-2 py-4">
              <span>Removed on:</span>
              <span className="font-medium">{`${dayjs(summaryData?.updatedAt).format(DATE_FORMAT)} at ${dayjs(summaryData?.updatedAt).format('h:mm a')}`}</span>
            </span>
            <span className="grid grid-cols-2 py-4 border-b-[1px] border-b-grey-2">
              <span>Removed by:</span>
              <span className="font-medium">{updatedBy}</span>
            </span>
          </>
        )}
        {summaryData?.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT &&
          (isAllPaymentsDeleted(summaryData) || isAllPaymentsCompleted(summaryData)) && (
            <Badge variant={getOverAllStatusBadgeData().variant} label={getOverAllStatusBadgeData().label} />
          )}

        {summaryData?.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT && isSomePaymentsDeleted(summaryData) && (
          <>
            <span className="grid grid-cols-2 py-4">
              <span>Deleted on:</span>
              <span className="font-medium">{deletedAt}</span>
            </span>
            <span className="grid grid-cols-2 py-4 border-b-[1px] border-b-grey-2">
              <span>Deleted by:</span>
              <span className="font-medium">{updatedBy}</span>
            </span>
          </>
        )}

        <span className={isDeleted ? '!text-grey-4' : ''}>
          {isPurposeVisible() && (
            <span className="grid grid-cols-2 py-4">
              <span>Purpose:</span>
              <span className="font-medium">{loanVariationPurposeMapper[summaryData?.purpose]}</span>
            </span>
          )}
          {summaryData?.otherPurpose && (
            <span className="grid grid-cols-2 py-4">
              <span>Other purpose:</span>
              <span className="font-medium">{summaryData?.otherPurpose}</span>
            </span>
          )}
          {summaryData?.type === ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT && (
            <>
              <span className="font-medium grid grid-col-1 py-4">
                All direct debits will be suspended during this period.
              </span>
              <span className="grid grid-cols-2 py-2">
                <span>Pause direct debit from:</span>
                <span className="font-medium">{dayjsUTCToSydney(summaryData?.effectiveDate).format(DATE_FORMAT)}</span>
              </span>
              <span className="grid grid-cols-2 py-4">
                <span>Resume direct debit on:</span>
                <span className="font-medium">
                  {dayjs(summaryData?.expiryDate).year() === PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR
                    ? 'Paused permanently'
                    : dayjsUTCToSydney(summaryData?.expiryDate).add(1, 'day').format(DATE_FORMAT)}
                </span>
              </span>
            </>
          )}
          {summaryData?.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT &&
            summaryData?.schedules?.map((schedule, index) => (
              <div key={index} className="border-1 rounded-lg border border-grey-2 p-6 mb-6">
                <span className="grid grid-cols-2 py-4">
                  <span>Payment amount:</span>
                  <span className="font-medium">{formatCurrency(schedule?.amount)}</span>
                </span>
                <span className="grid grid-cols-2 py-4">
                  <span>Schedule payment on:</span>
                  <span className="font-medium">{dayjsUTCToSydney(schedule?.scheduledAt).format(DATE_FORMAT)}</span>
                </span>
                <Badge
                  variant={getBadgeData(schedule?.status).variant}
                  className="mt-4"
                  label={getBadgeData(schedule?.status).label}
                />
              </div>
            ))}
          {summaryData?.type === ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN && (
            <>
              <span className="grid grid-cols-2 py-4">
                <span>Rescheduled recurring direct debit payment:</span>
                <span className="font-medium">{dayjsUTCToSydney(summaryData?.paymentDate).format(DATE_FORMAT)}</span>
              </span>
              <span className="grid grid-cols-2 py-4">
                <span>Payment frequency:</span>
                {summaryData?.paymentFrequency && (
                  <span className="font-medium">{capitalize(formatFrequency(summaryData?.paymentFrequency))}</span>
                )}
              </span>

              {!hasFirstRepaymentDatePassed(summaryData?.firstRepaymentDate) && (
                <PaymentCard
                  title="First payment"
                  amount={summaryData?.firstRepaymentAmount}
                  frequency={summaryData?.paymentFrequency}
                  date={`From ${dayjsUTCToSydney(summaryData?.firstRepaymentDate)?.format('ddd, DD MMMM YYYY')} onwards`}
                  icon={<Icon icon="mdi:calendar-today" className="text-grey-4" width={24} />}
                  className="mb-4"
                />
              )}
              <PaymentCard
                title="Recurring payment"
                amount={summaryData?.recurringRepaymentAmount}
                frequency={summaryData?.paymentFrequency}
                date={`From ${dayjsUTCToSydney(summaryData?.recurringRepaymentDate).format('ddd, D MMMM')} onwards`}
                icon={<Icon icon="mdi:calendar-month" className="text-grey-4" width={24} />}
              />
            </>
          )}

          {summaryData?.type === ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT && (
            <>
              {isPaymentArrangementRemoved && (
                <>
                  <Badge variant="action" label="Removed" />
                  <span className="grid grid-cols-2 py-4">
                    <span>Removed on:</span>
                    <span className="font-medium">
                      {dayjs(summaryData?.updatedAt).format(DATE_FORMAT)} at{' '}
                      {dayjs(summaryData?.updatedAt).format('h:mm a')}
                    </span>
                  </span>
                  <span className="grid grid-cols-2 py-4">
                    <span>Removed by:</span>
                    <span className="font-medium">{updatedBy}</span>
                  </span>
                  <Divider className="text-grey-2" />
                </>
              )}

              <PaymentArrangementSchedule repaymentDetails={repaymentSchedule} hasEndDate={hasEndDate} />
            </>
          )}

          {summaryData?.type === ChangeSummaryTypeEnum.WRITE_OFF && (
            <>
              <span className="grid grid-cols-2 py-4">
                <span>Write off amount:</span>
                <span className="font-medium">{formatCurrency(summaryData?.amount)}</span>
              </span>
              <span className="grid grid-cols-2 py-4">
                <span>Write off reason:</span>
                <span className="font-medium">
                  {summaryData?.editWriteOff?.length > 0 ? summaryData?.editWriteOff[0].reason : summaryData?.reason}
                </span>
              </span>
            </>
          )}
          {summaryData?.type === ChangeSummaryTypeEnum.ADJUSTMENT && (
            <>
              <span className="grid grid-cols-2 py-4">
                <span>Adjustment type:</span>
                <span className="font-medium">Initiate {summaryData?.adjustmentType}</span>
              </span>
              {summaryData?.paymentKey && (
                <span className="grid grid-cols-2 py-4">
                  <span>Payment key:</span>
                  <span className="font-medium">{summaryData?.paymentKey}</span>
                </span>
              )}
              <span className="grid grid-cols-2 py-4">
                <span>{capitalize(summaryData?.adjustmentType)} amount:</span>
                <span className="font-medium">{formatCurrency(summaryData?.amount, 2)}</span>
              </span>
              {summaryData?.adjustmentType === (AdjustmentTypeEnum.REVERSAL as string) && (
                <span className="grid grid-cols-2 py-4">
                  <span>Reason:</span>
                  <span className="font-medium">{summaryData?.reversalReason}</span>
                </span>
              )}
              <span className="grid grid-cols-2 py-4">
                <span>Reference:</span>
                <span className="font-medium">{summaryData?.reference}</span>
              </span>
            </>
          )}
          {summaryData?.type !== ChangeSummaryTypeEnum.ADJUSTMENT && (
            <span className="grid grid-cols-2 py-4">
              <span>Notes:</span>
              <span className="font-medium">{getNotes()}</span>
            </span>
          )}
          <span className="grid grid-cols-2 py-4">
            <span>{summaryData?.editWriteOff?.length > 0 ? 'Edited on:' : 'Committed on:'}</span>
            <span className="font-medium">{getCommittedAt()}</span>
          </span>
          <span className="grid grid-cols-2 py-4">
            <span>{summaryData?.editWriteOff?.length > 0 ? 'Edited by:' : 'Committed by:'}</span>
            <span className="font-medium">{getCommittedBy()}</span>
          </span>
        </span>
        {summaryData?.type === ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT &&
          summaryData?.isMasterRecord &&
          summaryData?.isEnabled && (
            <>
              <Button
                variant="tertiary"
                size="medium"
                className="my-4 !min-w-fit"
                disabled={!hasAccessPermission(SpokeName.PAYMENT_ARRANGEMENT)}
                onClick={handleEditPaymentArrangement}
              >
                Edit
              </Button>
              <Button
                variant="tertiary"
                size="medium"
                className="my-4 !min-w-fit"
                type="submit"
                isLoading={isSubmitting}
                disabled={!hasAccessPermission(SpokeName.PAYMENT_ARRANGEMENT)}
                onClick={handleSubmit}
              >
                Remove
              </Button>
            </>
          )}
        {summaryData?.type === ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT && !isDDSuspensionDeleted(summaryData) && (
          <Button
            variant="tertiary"
            size="medium"
            className="my-4 !min-w-fit"
            onClick={handleSubmit}
            disabled={cancelAccessPermission(SpokeName.DIRECT_DEBIT_SUSPENSION)}
          >
            Remove suspension
          </Button>
        )}
        {summaryData?.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT &&
          !isAllPaymentsDeleted(summaryData) &&
          !isAllPaymentsCompleted(summaryData) &&
          summaryData?.schedules.some(
            (schedule) =>
              dayjs(schedule?.scheduledAt).isSameOrAfter(dayjs(), 'day') && schedule.status === PaymentStatusEnum.new
          ) && (
            <Button
              variant="tertiary"
              size="medium"
              className="my-4 !min-w-fit"
              onClick={handleSubmit}
              disabled={cancelAccessPermission(SpokeName.EXTRA_PAYMENT_SCHEDULER)}
            >
              Delete scheduled extra payment
            </Button>
          )}
        {summaryData?.type === ChangeSummaryTypeEnum.WRITE_OFF && (
          <Button
            variant="outline-secondary"
            size="medium"
            className="my-4 !min-w-fit"
            onClick={handleEditWriteOff}
            disabled={cancelAccessPermission(SpokeName.EDIT_WRITE_OFF_LOAN)}
          >
            Edit
          </Button>
        )}
        {summaryData?.editWriteOff?.length > 0 && (
          <div className="border-t-[1px] border-b-grey-2 mt-4 text-grey-3">
            {summaryData?.editWriteOff.slice(1).map((writeOff, index) => (
              <div key={index} className="py-4 border-b-[1px] border-b-grey-2 mb-4">
                <span className="grid grid-cols-2 py-4">
                  <span>Write off reason:</span>
                  <span className="font-medium">{writeOff.reason}</span>
                </span>
                <span className="grid grid-cols-2 py-4">
                  <span>Notes:</span>
                  <span className="font-medium">{writeOff.note}</span>
                </span>
                <span className="grid grid-cols-2 py-4">
                  <span>Edited on:</span>
                  <span className="font-medium">{`${dayjs(writeOff.updatedAt).format(DATE_FORMAT)} at ${dayjs(writeOff.updatedAt).format('h:mm a')}`}</span>
                </span>
                <span className="grid grid-cols-2 py-4">
                  <span>Edited by:</span>
                  <span className="font-medium">{updatedByNames[index + 1]}</span>
                </span>
              </div>
            ))}
            <span className="grid grid-cols-2 py-4">
              <span>Write off reason:</span>
              <span className="font-medium">{summaryData?.reason}</span>
            </span>
            <span className="grid grid-cols-2 py-4">
              <span>Notes:</span>
              <span className="font-medium">{summaryData?.note}</span>
            </span>
            <span className="grid grid-cols-2 py-4">
              <span>Committed on:</span>
              <span className="font-medium">{`${dayjs(summaryData?.createdAt).format(DATE_FORMAT)} at ${dayjs(summaryData?.createdAt).format('h:mm a')}`}</span>
            </span>
            <span className="grid grid-cols-2 py-4">
              <span>Committed by:</span>
              <span className="font-medium">{createdByName}</span>
            </span>
          </div>
        )}
      </div>
      <Dialog
        open={isConfirmModalOpen}
        onOpenChange={() => {
          setIsConfirmModalOpen(!isConfirmModalOpen);
        }}
        title={getModalTitle(summaryData?.type)}
        modal
        showCloseButton={!isSubmitting}
      >
        {summaryData?.type === ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT && (
          <>
            {dayjs(summaryData?.expiryDate).year() === PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR ? (
              <p className="p-4">
                Are you sure you want to remove the permanent suspension of direct debits from{' '}
                <span className="font-medium">{dayjsUTCToSydney(summaryData?.effectiveDate).format(DATE_FORMAT)}</span>?
              </p>
            ) : (
              <p className="p-4">
                Are you sure you want to remove the suspension of direct debits from{' '}
                <span className="font-medium">{dayjsUTCToSydney(summaryData?.effectiveDate).format(DATE_FORMAT)}</span>{' '}
                to{' '}
                <span className="font-medium">
                  {dayjsUTCToSydney(summaryData?.expiryDate).add(1, 'day').format(DATE_FORMAT)}
                </span>
                ?
              </p>
            )}
          </>
        )}
        {summaryData?.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT && (
          <p className="p-4">
            {`Are you sure you want to delete the scheduled extra payment${summaryData?.schedules?.length === 1 ? ' ' : 's '}below:`}
            {summaryData?.schedules
              ?.filter((payment) => {
                return (
                  dayjs(payment?.scheduledAt).isSameOrAfter(dayjs(), 'day') && payment.status === PaymentStatusEnum.new
                );
              })
              .map((payment, index) => (
                <span key={index} className="flex justify-between mt-4">
                  <span className="font-medium">{formatCurrency(payment?.amount)}</span>
                  <span>Scheduled for {dayjs(payment?.scheduledAt).format(DATE_FORMAT)}</span>
                </span>
              ))}
          </p>
        )}

        {summaryData?.type === ChangeSummaryTypeEnum.PAYMENT_ARRANGEMENT && (
          <div className="p-4">
            <div className="text-left text-base font-medium">
              <Label className="mb-4">Are you sure you want to remove a payment arrangement?</Label>
            </div>

            <PaymentArrangementSchedule repaymentDetails={repaymentSchedule} hasEndDate={hasEndDate} />
          </div>
        )}

        <div className="shrink-1 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse p-4 flex-wrap">
          <Button
            onClick={() => onModalSubmit(summaryData?.type)}
            type="submit"
            variant="primary"
            className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            isLoading={isSubmitting}
            hasShadow
          >
            {getDeleteButtonLabel(summaryData?.type)}
          </Button>
          <Button
            onClick={() => setIsConfirmModalOpen(false)}
            variant="secondary"
            className="!min-w-fit !max-w-fit"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </>
  );
};
