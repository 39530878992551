import {
  DDSuspensionDto,
  DirectDebitPlanParamsDto,
  PaymentArrangementDto,
  RepaymentScheduleDto,
  ValidateDirectDebitPlanResponse,
} from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const paymentPlanApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRepaymentScheduleData: builder.query<RepaymentScheduleDto, string>({
      query: (loanApplicationId) => ({
        url: `/payment-plan/${loanApplicationId}`,
        method: 'GET',
      }),
    }),

    cancelDirectDebitSuspension: builder.mutation<void, string>({
      query: (loanApplicationId) => ({
        url: `/payment-plan/${loanApplicationId}/directDebitSuspension`,
        method: 'DELETE',
      }),
    }),
    cancelOneOffPayment: builder.mutation<void, { loanApplicationId: string; scheduleIds: string[] }>({
      query: ({ loanApplicationId, scheduleIds }) => ({
        url: `/payment-plan/${loanApplicationId}/oneoff`,
        method: 'DELETE',
        body: scheduleIds,
      }),
    }),
    validateDirectDebitPlan: builder.mutation<
      ValidateDirectDebitPlanResponse,
      { loanApplicationId: string } & DirectDebitPlanParamsDto
    >({
      query: ({ loanApplicationId, paymentFrequency, paymentDate, paymentAmount }) => ({
        url: `/payment-plan/${loanApplicationId}/direct-debit-plan/validate`,
        method: 'POST',
        body: {
          paymentFrequency,
          paymentDate,
          paymentAmount,
        },
      }),
    }),

    getDDSuspensionDetails: builder.query<DDSuspensionDto, string>({
      query: (loanApplicationId) => ({
        url: `/payment-plan/${loanApplicationId}/dd-suspension`,
        method: 'GET',
      }),
    }),

    getAllPaymentArrangements: builder.query<PaymentArrangementDto[], string>({
      query: (loanApplicationId) => ({
        url: `/payment-plan/${loanApplicationId}/payment-arrangement/all`,
        method: 'GET',
      }),
    }),

    getPaymentArrangement: builder.query<PaymentArrangementDto, string>({
      query: (loanApplicationId) => ({
        url: `/payment-plan/${loanApplicationId}/payment-arrangement`,
        method: 'GET',
      }),
    }),

    removePaymentArrangement: builder.mutation<void, string>({
      query: (loanApplicationId) => ({
        url: `/payment-plan/${loanApplicationId}/payment-arrangement`,
        method: 'DELETE',
      }),
    }),

    checkIfDDsuspensionIsSet: builder.query<boolean, string>({
      query: (loanApplicationId) => ({
        url: `/payment-plan/${loanApplicationId}/dd-suspension/is-enabled`,
        method: 'GET',
      }),
    }),

    checkIfPaymentArrangementIsSet: builder.query<boolean, string>({
      query: (loanApplicationId) => ({
        url: `/payment-plan/${loanApplicationId}/payment-arrangement/is-enabled`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetRepaymentScheduleDataQuery,
  useGetAllPaymentArrangementsQuery,
  useCancelDirectDebitSuspensionMutation,
  useCancelOneOffPaymentMutation,
  useValidateDirectDebitPlanMutation,
  useGetPaymentArrangementQuery,
  useRemovePaymentArrangementMutation,
  useCheckIfDDsuspensionIsSetQuery,
  useCheckIfPaymentArrangementIsSetQuery,
  useGetDDSuspensionDetailsQuery,
} = paymentPlanApi;
