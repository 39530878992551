import { HtmlStyle } from 'react-pdf-html';
import { Text, View } from '@react-pdf/renderer';
export const orderedAlpha = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'n',
  'm',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const lowerAlpha = ['lower-alpha', 'lower-latin'];
export const upperAlpha = ['upper-alpha', 'upper-latin'];

export function toRoman(num: number) {
  let result = '';
  const conversationMap = new Map<number, string>([
    [1000, 'M'],
    [900, 'CM'],
    [500, 'D'],
    [400, 'CD'],
    [100, 'C'],
    [90, 'XC'],
    [50, 'L'],
    [40, 'XL'],
    [10, 'X'],
    [9, 'IX'],
    [5, 'V'],
    [4, 'IV'],
    [1, 'I'],
  ]);
  conversationMap.forEach((roman, decimal) => {
    let quotient: bigint = BigInt(num) / BigInt(decimal);
    num = num % decimal;
    while (quotient--) {
      result += roman;
    }
  });
  return result;
}

// override the `li` implementation from https://github.com/danomatic/react-pdf-html/blob/main/src/renderers.tsx
export const customRenderers = {
  li: ({ element, stylesheets, style, children, ...props }: any) => {
    const list = element.closest('ol, ul');
    const ordered = list?.tag === 'ol' || element.parentNode.tag === 'ol';
    const listStyle =
      list?.style?.reduce((combined: any, listStyle: any) => Object.assign(combined, listStyle), {} as HtmlStyle) || {};

    const itemStyle = element.style.reduce(
      (combined: any, itemStyle: any) => Object.assign(combined, itemStyle),
      {} as HtmlStyle
    );
    const listStyleType =
      itemStyle.listStyleType || itemStyle.listStyle || listStyle.listStyleType || listStyle.listStyle || '';

    let bullet;

    if (listStyleType.includes('none')) {
      bullet = false;
    } else if (ordered) {
      const currentIndex = element.indexOfType;
      const start = parseInt(element.parentNode.attributes.start, 10);
      const offset = isNaN(start) ? 0 : start - 1; // keep it zero based for later

      let updatedIndex = currentIndex + offset;
      let currentElement = element;

      let sibling = currentElement;
      do {
        currentElement = sibling;
        sibling = currentElement.previousElementSibling;

        if (!currentElement) {
          break;
        }
        if (currentElement.tag !== 'li') {
          // skip all other element types because they do not belong in a list
          continue;
        }
        const startValue = parseInt(currentElement.attributes.value, 10);

        if (!isNaN(startValue)) {
          updatedIndex = startValue + (currentIndex - currentElement.indexOfType) - 1;
          break;
        }
      } while (sibling);

      if (lowerAlpha.includes(listStyleType)) {
        bullet = `(${orderedAlpha[updatedIndex].toLowerCase()})`;
      } else if (listStyleType === 'lower-roman') {
        bullet = `(${toRoman(element.indexOfType + 1).toLowerCase()})`;
      } else {
        bullet = `${updatedIndex + 1}.`;
      }
    } else {
      bullet = '•';
    }

    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {bullet && (
          <View
            style={{
              width: 30,
              textAlign: 'right',
              flexShrink: 0,
              flexGrow: 0,
              paddingRight: 5,
            }}
          >
            <Text>{bullet}</Text>
          </View>
        )}
        <View
          style={{
            textAlign: 'left',
            flexGrow: 1,
            flexBasis: 1,
          }}
        >
          {children}
        </View>
      </View>
    );
  },
};
