import { useMemo, useState } from 'react';
import { DisbursementDto } from '@harmoney/api-interfaces';
import { ArrowCircleRightIcon, Button, Card, Dialog } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { LoanPurpose } from '@prisma/client';

import { maskNormalizedBankAccount } from '../../../fulfilment/Payment/utils';

import { UpdateDebtConDisbursalBankAccountSchema } from './update-disbursal-debt-con-form-config';

type Props = {
  data: UpdateDebtConDisbursalBankAccountSchema;
  shouldMaskPersonalDisbursalAccount?: boolean;
  disbursals: DisbursementDto[];
  loanPurpose: LoanPurpose;
  onSubmit: () => void | Promise<void>;
  onClose?: () => void | Promise<void>;
};

function DebtConUpdateItem({
  disbursal,
  update,
}: {
  disbursal: DisbursementDto;
  update: UpdateDebtConDisbursalBankAccountSchema['updates'][string];
}) {
  const networthSourceName = useMemo(() => {
    return disbursal?.networthSource?.name.charAt(0).toUpperCase() + disbursal?.networthSource?.name.slice(1);
  }, [disbursal?.networthSource?.name]);

  const bpay = 'bpayBillerCode' in update.newBankAccount ? update.newBankAccount.bpayBillerCode : undefined;
  const bsbAccount =
    'accountBsb' in update.newBankAccount
      ? { accountBsb: update.newBankAccount.accountBsb, accountNumber: update.newBankAccount.accountNumber }
      : undefined;

  return (
    <Card>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <span>Paid to</span>
          <strong>{disbursal.paidTo}</strong>
        </div>
        <div className="flex flex-row justify-between">
          <span>Type</span>
          <strong>{networthSourceName}</strong>
        </div>
        <div className="flex flex-row justify-between">
          <span>Amount they will receive</span>
          <strong>{formatCurrency(disbursal.amount ?? 0)}</strong>
        </div>
        {bpay ? (
          <>
            <div className="flex flex-row justify-between">
              <span>BPAY biller code</span>
              <strong>{bpay}</strong>
            </div>
            <div className="flex flex-row justify-between">
              <span>BPAY reference</span>
              <strong>{update.newBankAccount.reference}</strong>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row justify-between">
              <span>BSB number</span>
              <strong>{bsbAccount?.accountBsb}</strong>
            </div>
            <div className="flex flex-row justify-between">
              <span>Account number</span>
              <strong>{bsbAccount?.accountNumber}</strong>
            </div>
            <div className="flex flex-row justify-between">
              <span>Reference</span>
              <strong>{update.newBankAccount.reference}</strong>
            </div>
          </>
        )}
      </div>
    </Card>
  );
}

function PersonalLoanUpdateItem({
  disbursal,
  update,
  shouldMask,
  loanPurpose,
}: {
  disbursal: DisbursementDto;
  loanPurpose: LoanPurpose;
  update: UpdateDebtConDisbursalBankAccountSchema['updates'][string];
  shouldMask?: boolean;
}) {
  const bsbAccount =
    'accountBsb' in update.newBankAccount
      ? { accountBsb: update.newBankAccount.accountBsb, accountNumber: update.newBankAccount.accountNumber }
      : undefined;

  const bankName = 'bankName' in update.newBankAccount ? update.newBankAccount.bankName : undefined;

  return (
    <Card>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <span>Paid to</span>
          <div className="flex flex-col text-end">
            <strong>{bankName}</strong>
            <span>
              {shouldMask
                ? maskNormalizedBankAccount(bsbAccount?.accountBsb, bsbAccount?.accountNumber)
                : `${bsbAccount?.accountBsb}-${bsbAccount?.accountNumber}`}
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <span>Type</span>
          <strong>{loanPurpose.displayName}</strong>
        </div>
        <div className="flex flex-row justify-between">
          <span>Amount they will receive</span>
          <strong>{formatCurrency(disbursal.amount ?? 0)}</strong>
        </div>
      </div>
    </Card>
  );
}

export function UpdateDebtConDisbursalBankAccountModal({
  data,
  onSubmit,
  onClose: _onClose,
  shouldMaskPersonalDisbursalAccount,
  disbursals,
  loanPurpose,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const mappedDisbursals = useMemo(() => {
    return disbursals
      .map((disbursal) => {
        const update = data.updates[disbursal.id];
        if (!update) return;
        if (disbursal.isDebtCon) return <DebtConUpdateItem disbursal={disbursal} update={update} key={disbursal.id} />;
        return (
          <PersonalLoanUpdateItem
            loanPurpose={loanPurpose}
            disbursal={disbursal}
            update={update}
            key={disbursal.id}
            shouldMask={shouldMaskPersonalDisbursalAccount}
          />
        );
      })
      .filter((v) => !!v);
  }, [data.updates, disbursals, loanPurpose, shouldMaskPersonalDisbursalAccount]);

  function onClose() {
    setIsClosed(true);
    _onClose?.();
  }

  return (
    <Dialog
      open={!isClosed}
      onOpenChange={onClose}
      modal
      showCloseButton
      title="Update disbursal account"
      contentClassName="max-h-full z-[100] overflow-auto"
    >
      <div className="flex flex-col gap-1 p-4">
        <strong>Are you sure you want to update these disbursal accounts?</strong>
        {mappedDisbursals}

        <div className="inline-flex flex-row justify-center items-end gap-4">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            alignIcon="end"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            icon={<ArrowCircleRightIcon size="large" />}
            onClick={async () => {
              setIsSubmitting(true);
              await onSubmit();
              setIsSubmitting(false);
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
