import { useEffect, useState } from 'react';
import { CreateUtmsRequestPayload, UtmKeyEnum } from '@harmoney/api-interfaces';
import { isEmpty } from 'lodash';

import { useCookie } from './use-cookie';

export const useUtm = () => {
  const getCookie = useCookie();
  const [utms, setUtms] = useState<CreateUtmsRequestPayload | undefined>(undefined);

  useEffect(() => {
    if (getCookie && utms === undefined) {
      const utmKeys = [
        UtmKeyEnum.CAMPAIGN,
        UtmKeyEnum.SOURCE,
        UtmKeyEnum.MEDIUM,
        UtmKeyEnum.TERM,
        UtmKeyEnum.CONTENT,
        UtmKeyEnum.GCLID,
      ];
      
      const resultArray = utmKeys
        .map(key => {
          const value = getCookie(key);
          return !isEmpty(value) ? { name: key, value } : null;
        })
        .filter(Boolean);
      
      setUtms(resultArray.length > 0 ? resultArray : []);
    }
  }, [getCookie]);

  return utms;
};
