import { LoanVariationFlag, PaymentArrangementDto, VaultAccountStatus } from '@harmoney/api-interfaces';
import { PaymentStatusEnum, UpdateDirectDebitPlan, VaultLoanAccountStateEnum } from '@prisma/client';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export const contractualStartDate = dayjs().add(1, 'day').toDate();
export const contractualEndDate = dayjs().add(60, 'day').toDate();

export const loanStatusBadgeVariant = {
  [VaultAccountStatus.ACCOUNT_STATUS_CANCELLED]: 'tertiary',
  [VaultAccountStatus.ACCOUNT_STATUS_CLOSED]: 'tertiary',
  [VaultAccountStatus.ACCOUNT_STATUS_OPEN]: 'success',
  [VaultAccountStatus.ACCOUNT_STATUS_PENDING]: 'warning',
  [VaultAccountStatus.ACCOUNT_STATUS_PENDING_CLOSURE]: 'warning',
  [VaultAccountStatus.ACCOUNT_STATUS_UNKNOWN]: 'tertiary',
};

export const vaultLoanAccountStateMapper: Record<VaultLoanAccountStateEnum, string> = {
  [VaultLoanAccountStateEnum.PENDING]: 'Pending',
  [VaultLoanAccountStateEnum.ACTIVE]: 'Active',
  [VaultLoanAccountStateEnum.PAID_OFF]: 'Paid-off',
  [VaultLoanAccountStateEnum.CANCELLED]: 'Cancelled',
  [VaultLoanAccountStateEnum.WRITTEN_OFF]: 'Written-off',
  [VaultLoanAccountStateEnum.PARTIAL_WRITTEN_OFF]: 'Partial Written-off',
};

export const loanStateBadgeVariant = {
  Active: 'success',
  'Written-off': 'action',
  Pending: 'warning',
  Cancelled: 'tertiary',
  'Paid-off': 'tertiary',
  'Partial Written-off': 'action',
};

export const vaultFlags = [LoanVariationFlag.INTEREST_ACCRUED_PAUSE_FLAG, LoanVariationFlag.REPAYMENT_HOLIDAY_FLAG];

export const getFlagLinkText = (flagName: LoanVariationFlag) => {
  return vaultFlags.includes(flagName) ? 'variation history' : 'change summary';
};

export const smoothScrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const downloadCSV = (csvData, fileName) => {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.csv`;
  link.click();
  window.URL.revokeObjectURL(link.href);
};

export const createScheduleExtraPaymentFlags = (oneOffPayments) => {
  if (!oneOffPayments) return [];
  return oneOffPayments
    .filter((data) => {
      return data.schedules.some(
        (schedule) =>
          dayjs(schedule?.scheduledAt).isSameOrAfter(dayjs(), 'day') && schedule?.status !== PaymentStatusEnum.cancelled
      );
    })
    .map((data) => {
      const validSchedules = data.schedules.filter(
        (schedule) =>
          dayjs(schedule?.scheduledAt).isSameOrAfter(dayjs(), 'day') &&
          (schedule?.status === PaymentStatusEnum.new || schedule?.status === PaymentStatusEnum.processing)
      );
      const firstValidSchedule = validSchedules[0];

      const flag = {
        flagName: LoanVariationFlag.SCHEDULE_EXTRA_PAYMENT_FLAG,
        scheduledAt: firstValidSchedule?.scheduledAt,
        amount: firstValidSchedule?.amount,
      };

      if (validSchedules?.length > 1) {
        flag['numberOfOtherPayments'] = validSchedules.length - 1;
      }

      return flag;
    });
};

export const createUpdatedDirectDebitFlags = (directDebitPlans: UpdateDirectDebitPlan[]) => {
  if (directDebitPlans?.length === 0) return [];

  const validDirectDebitPlans = directDebitPlans?.filter((plan) => {
    return dayjs(plan?.paymentDate).isSameOrAfter(dayjs(), 'day');
  });

  const firstValidDirectDebitPlan = validDirectDebitPlans[0];

  const flag = {
    flagName: LoanVariationFlag.UPDATE_DIRECT_DEBIT_FLAG,
    paymentDate: firstValidDirectDebitPlan?.paymentDate,
    paymentFrequency: firstValidDirectDebitPlan?.paymentFrequency,
    amount: firstValidDirectDebitPlan?.recurringRepaymentAmount,
  };

  return flag;
};

export const createPaymentArrangementFlags = (paymentArrangements: PaymentArrangementDto[]) => {
  if (paymentArrangements?.length === 0) return [];

  // Filter out payment arrangements that are in the past

  const latestPaymentArrangement = paymentArrangements
    ?.filter((plan) => {
      if (plan?.endDate) return plan.isEnabled && dayjs(plan?.endDate).isSameOrAfter(dayjs(), 'day');
      else {
        return plan.isEnabled;
      }
    })
    .toSorted((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];

  if (!latestPaymentArrangement) return [];

  const flag = {
    flagName: LoanVariationFlag.PAYMENT_ARRANGEMENT_FLAG,
    startDate: latestPaymentArrangement?.startDate,
    endDate: latestPaymentArrangement?.endDate,
    numberOfPayments: latestPaymentArrangement?.numberOfPayments,
    paymentFrequency: latestPaymentArrangement?.paymentFrequency,
    amount: latestPaymentArrangement?.paymentAmount,
  };

  return flag;
};
