import { useEffect, useState } from 'react';
import { useAppSelector, useGetBasicUserQuery } from '@harmoney/redux';
import { BranchEnum } from '@prisma/client';

import { useBranchFromUrl } from './use-branch-from-url';

export const useBranch = () => {
  const [branch, setBranch] = useState<BranchEnum>(null);
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const { data: user, isLoading } = useGetBasicUserQuery(null, { skip: !accessToken });
  const branchFromUrl = useBranchFromUrl();

  useEffect(() => {
    if (!isLoading && user) {
      setBranch(user.branch);
    } else {
      setBranch(branchFromUrl);
    }
  }, [isLoading, user, branchFromUrl]);

  return branch;
};
