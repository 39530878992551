import { useEffect, useState } from 'react';
import { EmailCheckStatusEnum } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import { useGetUserQuery, useGetVariablesQuery, useLazyCheckEmailQuery, useUpdateEmailMutation } from '@harmoney/redux';
import {
  Alert,
  ArrowCircleRightIcon,
  Button,
  Card,
  ControlledInput,
  Form,
  Label,
  useForm,
} from '@harmoney/ui-design-system';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

const errorMessage = {
  VALIDATION_ERROR: 'It looks like the email you entered is incorrect. Please double-check and try again.',
  SERVER_ERROR: 'We had some trouble saving your email address. Please try again.',
  INVALID_EMAIL: 'Something went wrong. If you require assistance, please feel free to reach out to us.',
};

const formSchema = z.object({
  email: z.string().email({ message: errorMessage.VALIDATION_ERROR }),
});

export function EmailChange({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  const [checkEmail] = useLazyCheckEmailQuery();
  const [changeEmail] = useUpdateEmailMutation();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: user } = useGetUserQuery();
  const auth0ErrorPresent = variables?.auth0ServiceFailure;
  const [isCancelling, setIsCancelling] = useState(false);

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: {
      email: '',
    },
  });
  useFriendlyURL(taskFriendlyURL);

  useEffect(() => {
    reset();
    if (auth0ErrorPresent) {
      form.setValue('email', user?.changeProcessEmail ? String(user?.changeProcessEmail) : '');
    }
  }, [variables]);

  const {
    register,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
    setError,
  } = form;

  const handleSubmit = async (formData) => {
    const trimmedEmail = formData.email.trim();
    const { error, data } = await checkEmail({ email: trimmedEmail }, true);
    const serverError = error as { status: number };

    if (error) {
      const message = serverError.status === 400 ? errorMessage.VALIDATION_ERROR : errorMessage.SERVER_ERROR;
      setError('email', { message });
      return;
    }

    if (data?.status === EmailCheckStatusEnum.NEW_USER && !data?.accountStatus) {
      const result = await changeEmail({ changeProcessEmail: trimmedEmail }).unwrap();
      if (!result.success) {
        setError('email', { message: errorMessage.SERVER_ERROR });
      }
      return;
    }

    setError('email', {
      message: errorMessage.INVALID_EMAIL,
    });
  };

  const handleCancel = async () => {
    if (isCancelling) return;
    setIsCancelling(true);
    await completeTaskWithData({
      taskId,
      variables: { auth0ServiceFailure: false, emailStatus: 'Cancel', isEmailToBeUpdated: false },
    });
  };

  return (
    <div className="flex flex-col">
      {auth0ErrorPresent && <Alert variant="error">{errorMessage.SERVER_ERROR}</Alert>}

      <h1 className="mt-4 mb-0">
        Your <span className="text-primary">email address</span>
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        <Card className="mt-6 !p-4">
          <Label className="flex flex-col font-medium mb-2 ">Email address</Label>
          <ControlledInput {...register('email')} type="email" />
        </Card>

        <div className="flex flex-col justify-start items-center ">
          <Button
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            type="submit"
            isLoading={isSubmitting || isSubmitSuccessful}
            disabled={isCancelling}
            hasShadow
          >
            Save
          </Button>

          <Button
            size="large"
            variant="tertiary"
            className="!min-w-fit mt-1"
            disabled={isSubmitting || isSubmitSuccessful || isCancelling}
            isLoading={isCancelling}
            onClick={() => {
              reset();
              handleCancel();
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}
