import { AdjustmentTypeEnum } from '@harmoney/api-interfaces';
import { errors } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import { z } from 'zod';

export const adjustmentInitialValues = {
  adjustmentType: '',
  paymentKey: '',
  amount: null,
  reference: '',
  reversalReason: '',
};

export const createAdjustmentFormSchema = (totalPrincipalPaid: number, paymentKeyPrincipal: number) => {
  return z
    .object({
      adjustmentType: z.string().min(1, { message: 'Please select a reason' }),
      paymentKey: z.string().optional(),
      amount: z.coerce
        .number({ invalid_type_error: errors.defaultValidAmount })
        .gte(0.01, { message: 'Please enter an amount greater than or equal to 0.01' }),
      reference: z.string().min(1, { message: 'Please enter a reference' }),
      reversalReason: z.string().optional(),
    })
    .superRefine((data, { addIssue }) => {
      const amountErrorMessage = `The amount can’t exceed the principal paid, which is ${formatCurrency(totalPrincipalPaid, 2)}.`;

      if (data.adjustmentType === AdjustmentTypeEnum.REVERSAL && !data.reversalReason) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: errors.requiredField('Reason'),
          path: ['reversalReason'],
        });
      }

      if (data.paymentKey) {
        if (paymentKeyPrincipal > totalPrincipalPaid) {
          addIssue({
            code: z.ZodIssueCode.custom,
            message: amountErrorMessage,
            path: ['amount'],
          });
        }
      }

      if (!data.paymentKey && data.amount > totalPrincipalPaid) {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: amountErrorMessage,
          path: ['amount'],
        });
      }
    });
};
