import { PaymentStatusEnum, PaymentTypeEnum } from '@prisma/client';

export function checkPaymentIfFailed(txn: { status: PaymentStatusEnum }) {
  return txn.status === PaymentStatusEnum.error || txn.status === PaymentStatusEnum.dishonored;
}

export function filterDisbursableTransactions<
  T extends {
    paymentType?: PaymentTypeEnum;
    status?: PaymentStatusEnum;
    id?: string;
    accountName?: string;
    disbursalBankDetails?: { accountName: string };
    paidTo?: string;
  },
>(payments: T[]): T[] {
  return payments.filter((txn) => {
    const accountName = txn.disbursalBankDetails?.accountName ?? txn.accountName ?? txn.paidTo ?? '';
    return (
      accountName.trim().toLowerCase() !== 'harmoney' &&
      (txn.paymentType === PaymentTypeEnum.NppCreditBankAccount ||
        txn.paymentType === PaymentTypeEnum.directCredit ||
        txn.paymentType === PaymentTypeEnum.BPAY)
    );
  });
}

export function filterDisbursablePersonalLoans<
  T extends {
    paymentType?: PaymentTypeEnum;
    status?: PaymentStatusEnum;
    id?: string;
    isDebtCon?: boolean;
    accountName?: string;
  },
>(payments: T[], disbursements?: { paymentTransactionId: string; id: string }[]): T[] {
  return filterDisbursableTransactions(payments).filter((txn) => {
    const notBpay = txn.paymentType !== PaymentTypeEnum.BPAY;
    if (!disbursements) return notBpay && !txn.isDebtCon;
    const disbursement = disbursements.find((disbursement) => disbursement.paymentTransactionId === txn.id);
    return notBpay && !disbursement;
  });
}

export function filterDisbursableDebtCons<
  T extends {
    paymentType?: PaymentTypeEnum;
    status?: PaymentStatusEnum;
    id?: string;
    isDebtCon?: boolean;
    accountName?: string;
    disbursalBankDetails?: { accountName: string };
  },
>(payments: T[], disbursements?: { paymentTransactionId: string; id: string; accountName?: string }[]): T[] {
  return filterDisbursableTransactions(payments).filter((txn) => {
    if (!disbursements) return !!txn.isDebtCon;
    const disbursement = disbursements.find((disbursement) => disbursement.paymentTransactionId === txn.id);
    return !!disbursement?.id;
  });
}

export function checkIfAllowDisbursalBankChange(
  payments: {
    paymentType?: PaymentTypeEnum;
    status?: PaymentStatusEnum;
    id?: string;
    isDebtCon?: boolean;
    accountName?: string;
  }[],
  disbursements?: { paymentTransactionId: string; id: string; accountName?: string }[]
) {
  const filteredDebtConDisbursals = filterDisbursableDebtCons(payments, disbursements);
  const filteredPersonalLoanDisbursals = filterDisbursablePersonalLoans(payments, disbursements);

  return {
    allowPersonalLoanChange:
      filteredPersonalLoanDisbursals?.every(checkPaymentIfFailed) && !!filteredPersonalLoanDisbursals.length,
    allowDebtConChange: filteredDebtConDisbursals?.some(checkPaymentIfFailed) && !!filteredDebtConDisbursals.length,
  };
}
