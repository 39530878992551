import { useMemo, useState } from 'react';
import { BankAccountDto, DisbursementDto, FORM_KEY } from '@harmoney/api-interfaces';
import { useCompleteTaskWithDataMutation, useUpdateDisbursalBankAccountMutation } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Form, Textarea, useForm } from '@harmoney/ui-design-system';
import { LoanPurpose } from '@prisma/client';

import { ADD_ANOTHER_BANK_ACCOUNT } from '../../../fulfilment/Payment/components';
import { Header } from '../PaymentPlan/Header';

import DisbursedAccount from './components/DisbursedAccount';
import {
  updateDebtConDisbursalBankAccountDefault,
  UpdateDebtConDisbursalBankAccountSchema,
  updateDebtConDisbursalBankAccountSchema,
} from './update-disbursal-debt-con-form-config';
import { UpdateDebtConDisbursalBankAccountModal } from './UpdateDebtConDisbursalBankAccountModal';
import UpdateSingleDebtConDisbursalBankAccount from './UpdateSingleDebtConDisbursalBankAccount';
import UpdateSinglePersonalLoanDisbursalBankAccount from './UpdateSinglePersonalLoanDisbursalBankAccount';

type Props = {
  disbursals: DisbursementDto[];
  successfulDisbursals?: DisbursementDto[];
  loanApplicationId: string;
  taskId: string;
  userId: string;
  loanPurpose: LoanPurpose;
  fromBankAccount?: BankAccountDto;

  onSuccess?: () => void | Promise<void> | unknown;
  onCancel?: () => void | Promise<void> | unknown;
  onFail?: (err: any) => void | Promise<void> | unknown;
};

export default function UpdateDisbursalDebtConBankAccount({
  onCancel,
  disbursals,
  successfulDisbursals,
  loanApplicationId,
  taskId,
  userId,
  loanPurpose,
  fromBankAccount,
  onFail,
  onSuccess,
}: Props) {
  const [modalData, setModalData] = useState<UpdateDebtConDisbursalBankAccountSchema>();
  const [suppliedOwnership, setSuppliedOwnership] = useState(true);
  const [asNewBankAccount, setAsNewBankAccount] = useState(true);

  const [updateDisbursal] = useUpdateDisbursalBankAccountMutation();
  const [completeTaskWithData] = useCompleteTaskWithDataMutation();

  const form = useForm({
    mode: 'onChange',
    schema: updateDebtConDisbursalBankAccountSchema,
    defaultValues: updateDebtConDisbursalBankAccountDefault,
  });

  const {
    register,
    getValues,
    formState: { isSubmitting, isValid },
  } = form;

  const debtConDisbursalComponents =
    disbursals
      ?.filter((disbursal) => !!disbursal.isDebtCon)
      .map((disbursal) => {
        return <UpdateSingleDebtConDisbursalBankAccount key={disbursal.id} disbursal={disbursal} />;
      }) ?? [];

  const personalLoan = useMemo(() => {
    return disbursals?.find((disbursal) => !disbursal.isDebtCon);
  }, [disbursals]);

  const allowSave = useMemo(() => {
    return isValid && suppliedOwnership;
  }, [isValid, suppliedOwnership]);

  function handleSubmit() {
    setModalData(getValues());
  }

  async function onModalSubmit() {
    const value = getValues();
    try {
      const res = await updateDisbursal({
        loanApplicationId,
        data: {
          note: value.notes,
          updates: Object.values(value.updates)
            .filter((v) => !!v)
            .map(({ fromBankAccount: from, newBankAccount: to }) => ({ from, to })) as never,
        },
      });
      if ('error' in res) throw res.error;

      await completeTaskWithData({
        taskId,
        formKey: FORM_KEY.DISBURSAL_BANK_ACCOUNT_UPDATE,
        formData: {
          userId,
          taskId,
        },
      });
      onSuccess?.();
    } catch (err) {
      form.setError('root', err);
      onFail?.(err);
    } finally {
      setModalData(undefined);
    }
  }

  return (
    <>
      <Form form={form} onSubmit={handleSubmit} className="mr-8 pb-16 flex flex-col gap-4">
        <Header
          headerTitle="Update disbursal account"
          onCancelClick={() => {
            onCancel?.();
          }}
        />

        {!!debtConDisbursalComponents.length && debtConDisbursalComponents}
        {personalLoan && (
          <UpdateSinglePersonalLoanDisbursalBankAccount
            userId={userId}
            fromBankAccount={fromBankAccount}
            payment={personalLoan}
            loanPurpose={loanPurpose}
            onSuppliedOwnershipChanged={setSuppliedOwnership}
            onNewBankAccountSelected={(v) => setAsNewBankAccount(v === ADD_ANOTHER_BANK_ACCOUNT)}
          />
        )}
        {successfulDisbursals &&
          successfulDisbursals.map((disbursal) => <DisbursedAccount disbursal={disbursal} key={disbursal.id} />)}

        <Textarea {...register('notes')} label="Notes" placeholder="Add a note" />

        <div className="flex justify-end items-start gap-6 self-stretch">
          <Button variant="tertiary" onClick={onCancel} size="medium" className="!min-w-fit" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            size="medium"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="medium" />}
            className="sm:min-w-fit"
            disabled={!allowSave}
            isLoading={isSubmitting}
          >
            Update
          </Button>
        </div>
      </Form>

      {!!modalData && (
        <UpdateDebtConDisbursalBankAccountModal
          data={modalData}
          onSubmit={onModalSubmit}
          disbursals={disbursals}
          loanPurpose={loanPurpose}
          shouldMaskPersonalDisbursalAccount={!asNewBankAccount}
          onClose={() => {
            setModalData(undefined);
          }}
        />
      )}
    </>
  );
}
