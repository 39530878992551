import { NextStepsList } from '@harmoney/components';
import { useAuth } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button, Card, Divider } from '@harmoney/ui-design-system';

interface Props {
  email: string;
}

export const QuickQuoteAffordabilityUnsuccessful = ({ email }: Props) => {
  const { login } = useAuth();

  const handleClick = async () => {
    await login({
      authorizationParams: {
        login_hint: email,
        screen_hint: 'signup',
      },
      appState: { returnTo: `${window.location.origin}/` },
    });
  };

  return (
    <>
      <h1 className="mt-6 font-bold">
        Just need a little more <span className="text-primary">information</span>!
      </h1>
      <Card className="relative mt-8 sm:mt-6 w-full !px-0">
        <div className="px-4 py-1">
          <p className="text-sm">With the information provided we could not give you a quick quote just yet.</p>
          <p className="text-sm">
            Simply complete our full form, and we&rsquo;ll gain a better understanding of your finances to provide you
            with a personalised and accurate quote tailored to you.
          </p>
        </div>
        <Divider className="text-grey-2 py-4" />
        <div className="px-6 py-1">
          <div className="flex flex-col gap-4">
            <NextStepsList />
          </div>
        </div>
      </Card>
      <div className="flex justify-center">
        <Button
          variant="primary"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          type="submit"
          onClick={handleClick}
        >
          Sign Up
        </Button>
      </div>
    </>
  );
};
