import { getDayjsInTimezoneByBranch } from '@harmoney/utilities';
import { BranchEnum } from '@prisma/client';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { FooterTemplate } from './footer-template';

interface ObligationMetLetterTemplateProps {
  fonts?: any;
  branch: BranchEnum;
  data: {
    loanNumber: string;
    email: string;
    firstName: string;
    fullName: string;
    address: {
      streetNumber: string;
      streetName: string;
      suburb: string;
      state: string;
      postalCode: string;
    };
  };
}

export const ObligationMetLetterTemplate = ({ fonts, branch, data }: ObligationMetLetterTemplateProps) => {
  const logo = 'https://assets.harmoney.com/logos/hmy-logo-wide-498x114.png';
  Font.register({
    family: 'GothamHTF-Book',
    src: fonts?.gothamBook?.url,
    fontSize: '10px',
  });

  Font.register({
    family: 'GothamHTF-Bold',
    src: fonts?.gothamBold?.url,
    fontWeight: 600,
  });

  Font.registerHyphenationCallback((word) => [word]);

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'GothamHTF-Book',
      fontSize: '10px',
      lineHeight: 1.35,
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      paddingVertical: 20,
      paddingHorizontal: 25,
      backgroundColor: '#f5f5f5',
      justifyContent: 'flex-start',
    },
    content: {
      flexGrow: 1,
      backgroundColor: '#ffffff',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '10px',
    },

    section: {
      margin: 10,
    },

    logo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    headerLogo: {
      paddingBottom: '15px',
      width: '110px',
      height: 'auto',
      backgroundColor: '#f5f5f5',
    },
    newLine: {
      marginTop: 10,
    },
    textSmallBold: {
      fontFamily: 'GothamHTF-Bold',
      fontSize: '10px',
    },
    textLargeBold: {
      fontFamily: 'GothamHTF-Bold',
      fontSize: '14px',
    },
  });

  return (
    <Document>
      <Page style={[styles.page]} wrap>
        <View style={styles.logo}>
          <Image src={logo} style={styles.headerLogo} />
        </View>
        <View style={styles.content}>
          <View style={styles.section}>
            <Text>{getDayjsInTimezoneByBranch(branch).format('DD MMMM YYYY')}</Text>
          </View>
          <View style={styles.section}>
            <Text>{data.fullName}</Text>
            <Text>
              {data.address.streetNumber} {data.address.streetName}
            </Text>
            <Text>{data.address.suburb}</Text>
            <Text>
              {data.address.state} {data.address.postalCode}
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.textLargeBold}>Dear {data.firstName},</Text>
            <Text style={styles.newLine}>
              We are pleased to confirm that we have received your final payment on your loan {data.loanNumber}, and the
              loan has now been fully paid off. You have met all of your obligations under the loan contract.
            </Text>
            <Text style={styles.newLine}>
              Thank you for choosing Harmoney. It has been a pleasure having you as a customer.
            </Text>
            <Text style={styles.newLine}>
              Please don’t hesitate to let us know if we can be of assistance in the future.
            </Text>
          </View>

          <View style={styles.section}>
            <Text>Regards,</Text>
            <Text style={[styles.newLine, styles.textSmallBold]}>The Harmoney Team</Text>
          </View>
        </View>
        <FooterTemplate branch={branch} />
      </Page>
    </Document>
  );
};
