import { useCallback, useEffect, useState } from 'react';
import { MonoovaBSBValidationOutputDto } from '@harmoney/api-interfaces';
import { useLazyGetBankDetailsFromBsbQuery } from '@harmoney/redux';
import { isValidBsb, transformBsb } from '@harmoney/utilities';

type Props = {
  onBankDetailsChanged?: (bankDetails?: MonoovaBSBValidationOutputDto) => void | Promise<void>;
  onError?: (message: string) => void | Promise<void>;
};

export function useBsbSearch({ onBankDetailsChanged, onError }: Props) {
  const [isLoadingBsb, setIsLoadingBsb] = useState(false);
  const [bsbBankDetails, setBsbBankDetails] = useState<MonoovaBSBValidationOutputDto>();
  const [invalidBsb, setInvalidBsb] = useState(false);
  const [bsbInputValue, setBsbInputValue] = useState('');
  const [debouncedBsbValue, setDebouncedBsbValue] = useState('');

  const [getBankDetailsTrigger] = useLazyGetBankDetailsFromBsbQuery();

  const getBankDetails = useCallback(
    async (bsb: string) => {
      setInvalidBsb(undefined);
      bsb = transformBsb(bsb);
      const currentBsb = transformBsb(bsbBankDetails?.bsb ?? '');

      const bsbIsValid = isValidBsb(bsb);
      if (!bsbIsValid) setBsbBankDetails(undefined);
      if (!bsbIsValid || currentBsb === bsb) return;
      setIsLoadingBsb(true);

      try {
        const currentBankDetails = await getBankDetailsTrigger(bsb);
        if (currentBankDetails.error) throw currentBankDetails.error;
        setBsbBankDetails(currentBankDetails.data);
        onBankDetailsChanged?.(currentBankDetails.data);
      } catch (err) {
        onError?.(err?.data?.message?.message ?? '');
        setInvalidBsb(!!err);
      }

      setIsLoadingBsb(false);
    },
    [bsbBankDetails?.bsb]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedBsbValue(bsbInputValue);
      clearTimeout(handler);
    }, 250);

    return () => clearTimeout(handler);
  }, [bsbInputValue]);

  useEffect(() => {
    getBankDetails(debouncedBsbValue);
  }, [debouncedBsbValue, getBankDetails]);

  return {
    isLoadingBsb,
    setIsLoadingBsb,
    bsbBankDetails,
    setBsbBankDetails,
    bsbInputValue,
    setBsbInputValue,
    debouncedBsbValue,
    setDebouncedBsbValue,
    invalidBsb,
    setInvalidBsb,
  };
}
