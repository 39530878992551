import { numberInputFormat } from '@harmoney/utilities';
import { z } from 'zod';

import { ADD_ANOTHER_BANK_ACCOUNT } from '../../../fulfilment/Payment/components';
import { zBankAccount } from '../UpdateDisbursalBankAccount/update-disbursal-form-config';

function zBpayAccount() {
  return z.object({
    type: z.literal('bpay').default('bpay'),
    bpayBillerCode: z
      .string()
      .min(1, { message: 'Please input the BPAY biller code' })
      .max(10, { message: 'Only 10 digits are allowed for the BPAY biller code' })
      .refine((v) => numberInputFormat.test(v), { message: 'BPAY biller code should only contain numbers and spaces' }),
    reference: z
      .string()
      .min(1, { message: 'Please input the reference number' })
      .max(20, { message: 'Only 10 digits are allowed for the BPAY referece number' }),
  });
}

function zBankAccountRef() {
  return zBankAccount().merge(
    z.object({
      type: z.literal('bsb').default('bsb'),
      reference: z.string().optional(),
    })
  );
}

export const updateDebtConDisbursalBankAccountDefault = {} satisfies UpdateDebtConDisbursalBankAccountSchema;

export const updateDebtConDisbursalBankAccountSchema = z.object({
  notes: z.string().min(1, { message: 'Please add a note' }),

  updates: z
    .record(
      z
        .object({
          fromBankAccount: z.intersection(
            z.union([zBpayAccount(), zBankAccountRef()]),
            z.object({ paymentId: z.string() })
          ),
          newBankAccount: z.union([
            zBpayAccount(),
            zBankAccountRef().merge(
              z.object({
                updateDisbursalToBankAccount: z.union([z.literal(ADD_ANOTHER_BANK_ACCOUNT), z.string()]).optional(),
                hasSuppliedProofOfAccountOwnership: z
                  .boolean()
                  .refine((val) => val, {
                    message: 'Please verify the customer-provided information before updating the bank account.',
                  })
                  .optional(),
              })
            ),
          ]),
        })
        .optional()
    )
    .refine((v) => !!Object.keys(v ?? {})?.length),
});

export type UpdateDebtConDisbursalBankAccountSchema = z.infer<typeof updateDebtConDisbursalBankAccountSchema>;
