import { BranchEnum } from '@prisma/client';
import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';

import {
  AU_CONTACT,
  AU_FOOTER_TEXT_DISCLAIMER,
  AU_FOOTER_TEXT_INFO,
  AU_OFFICE_ADDRESS,
  AU_WEBSITE,
  NZ_CONTACT,
  NZ_FOOTER_DISCLAIMER,
  NZ_FOOTER_INFO,
  NZ_OFFICE_ADDRESS,
  NZ_WEBSITE,
} from '../../utils';

const AUFooterTemplate = (styles: any) => {
  return (
    <>
      <Text style={styles.address}>{AU_OFFICE_ADDRESS}</Text>
      <Text style={styles.link}>
        <Link style={styles.linkText} src={AU_WEBSITE}>
          Our website
        </Link>{' '}
        |{' '}
        <Link style={styles.linkText} src={AU_CONTACT}>
          Contact us
        </Link>
      </Text>

      <View style={styles.content}>
        <Text style={styles.info}>{AU_FOOTER_TEXT_INFO}</Text>
        <Text style={styles.disclaimer}>
          <Text style={styles.bold}>Disclaimer: </Text>
          <Text>{AU_FOOTER_TEXT_DISCLAIMER}</Text>
        </Text>
      </View>
    </>
  );
};

const NZFooterTemplate = (styles: any) => {
  return (
    <>
      <Text style={styles.address}>{NZ_OFFICE_ADDRESS}</Text>
      <Text style={styles.link}>
        <Link style={styles.linkText} src={NZ_WEBSITE}>
          Our website
        </Link>{' '}
        |{' '}
        <Link style={styles.linkText} src={NZ_CONTACT}>
          Contact us
        </Link>
      </Text>
      <View style={styles.content}>
        <Text style={styles.info}>{NZ_FOOTER_INFO}</Text>
        <Text style={styles.disclaimer}>
          <Text style={styles.bold}>Disclaimer: </Text>
          <Text>{NZ_FOOTER_DISCLAIMER}</Text>
        </Text>
      </View>
    </>
  );
};
interface FooterTemplateProps {
  branch: BranchEnum;
}

export const FooterTemplate = ({ branch }: FooterTemplateProps) => {
  const footerStyles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 'auto',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 25,
      paddingRight: 25,
      backgroundColor: '#141414',
      fontFamily: 'GothamHTF-Book',
    },
    address: {
      fontSize: '8px',
      textAlign: 'center',
      color: 'white',
    },
    link: {
      fontSize: '6px',
      textAlign: 'center',
      marginTop: '5px',
      borderBottom: '1px solid #ccc',
      color: 'white',
      paddingBottom: '10px',
    },
    content: {
      textAlign: 'justify',
      color: 'white',
    },
    info: {
      marginTop: '10px',
      fontSize: '8px',
    },
    disclaimer: {
      marginTop: '10px',
      fontSize: '6px',
    },
    bold: {
      fontFamily: 'GothamHTF-Bold',
    },
    linkText: {
      color: 'white',
    },
  });

  return (
    <View style={footerStyles.container}>
      {branch === BranchEnum.AU ? AUFooterTemplate(footerStyles) : NZFooterTemplate(footerStyles)}
    </View>
  );
};
