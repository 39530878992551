import { useCallback, useEffect, useMemo, useState } from 'react';
import { UncontrolledCheckbox } from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';
import classNames from 'classnames';

export type SideFiltersProps = {
  filters: Array<FilterGroup>;
  onFilterChanged?: (statuses: FilterStatuses) => void | Promise<void>;
};

export type FilterGroup = {
  id: string;
  label: string;
  filters: Array<Filter>;

  hasAllTrigger?: boolean;
  foldable?: boolean;
  defaultOpened?: boolean;
};

export type Filter = {
  id: string;
  label: string;
  defaultChecked?: boolean;
};

export type FilterStatus = {
  groupId: string;
  filters: Record<
    string,
    {
      filterId: string;
      checked: boolean;
    }
  >;
};

export type FilterStatuses = Record<string, FilterStatus>;

export default function SideFilters({ filters, onFilterChanged }: SideFiltersProps) {
  const [filterStatuses, setFilterStatuses] = useState(convertFilterToFilterStatus(filters));
  const [filterToggles, setFilterToggles] = useState(convertFilterToToggleGroups(filters));

  const allCheckboxStates = useMemo(() => {
    const values = Object.values(filterStatuses)
      .map((filterStatus) => {
        return Object.values(filterStatus.filters).map((filter) => filter.checked);
      })
      .flat();

    return values;
  }, [filterStatuses]);
  const allChecked = useMemo(
    () => allCheckboxStates.every((v) => v) && !!allCheckboxStates.length,
    [allCheckboxStates]
  );
  const indeterminate = useMemo(() => allCheckboxStates.some((v) => v) && !allChecked, [allChecked, allCheckboxStates]);

  useEffect(() => {
    setFilterStatuses(convertFilterToFilterStatus(filters));
  }, [filters]);

  const getChecked = useCallback(
    (groupId: string, filterId: string) => {
      const filterStatus = filterStatuses[groupId]?.filters[filterId];
      if (!filterStatus) return false;
      return filterStatus.checked;
    },
    [filterStatuses]
  );

  const getGroupChecked = useCallback(
    (groupId: string) => {
      const filters = filterStatuses[groupId]?.filters;
      if (!filters) return [false];
      return Object.values(filters).map((v) => v.checked);
    },
    [filterStatuses]
  );
  const getGroupAllChecked = useCallback(
    (groupId: string, values?: boolean[]) => {
      values = values ?? getGroupChecked(groupId);
      return values.every((v) => v) && !!values.length;
    },
    [getGroupChecked]
  );
  const getGroupIndeterminate = useCallback(
    (groupId: string) => {
      const values = getGroupChecked(groupId);
      return !getGroupAllChecked(groupId, values) && values.some((v) => v);
    },
    [getGroupAllChecked, getGroupChecked]
  );

  const handleSelectAllGroup = useCallback(
    (groupId: string) => {
      const filters = filterStatuses[groupId]?.filters;
      if (!filters) return;

      const toggleAllToTrue = getGroupIndeterminate(groupId) ? true : !getGroupAllChecked(groupId);
      const statuses = toggleFilterStatuses(filterStatuses, toggleAllToTrue, groupId);
      setFilterStatuses(statuses);
      onFilterChanged?.(statuses);
    },
    [filterStatuses, getGroupAllChecked, getGroupIndeterminate, onFilterChanged]
  );

  const handleChanged = useCallback(
    (groupId: string, filterId: string, checked: boolean) => {
      const filterStatus = filterStatuses[groupId]?.filters[filterId];
      if (!filterStatus) return;
      const newFilter = {
        ...filterStatuses,
        [groupId]: {
          ...filterStatuses[groupId],
          filters: {
            ...filterStatuses[groupId].filters,
            [filterId]: {
              ...filterStatus,
              checked,
            },
          },
        },
      };
      setFilterStatuses(newFilter);
      onFilterChanged?.(newFilter);
    },
    [filterStatuses, onFilterChanged]
  );

  const handleSelectAll = useCallback(() => {
    const toggleAllToTrue = indeterminate ? true : !allChecked;
    const statuses = toggleFilterStatuses(filterStatuses, toggleAllToTrue);
    setFilterStatuses(statuses);
    onFilterChanged?.(statuses);
  }, [allChecked, filterStatuses, indeterminate, onFilterChanged]);

  const isGroupOpen = useCallback(
    (id: string) => {
      return !!filterToggles[id];
    },
    [filterToggles]
  );

  const toggleGroupOpen = useCallback(
    (id: string) => {
      setFilterToggles({ ...filterToggles, [id]: !filterToggles[id] });
    },
    [filterToggles]
  );

  return (
    <div>
      <h2>Filters</h2>
      <UncontrolledCheckbox
        name="all"
        label="Select all / Clear all"
        alignLabel="right"
        className="font-medium text-sm mb-4"
        checked={indeterminate ? 'indeterminate' : allChecked}
        onCheckedChange={handleSelectAll}
        checkIconSize="tiny"
        iconWidth={16}
      />

      {filters.map((group) => {
        const isOpen = isGroupOpen(group.id);
        return (
          <div key={group.id} className="mb-4">
            <div
              className={classNames(
                'flex flex-row justify-between items-center',
                group.foldable ? 'hover:cursor-pointer' : ''
              )}
              onClick={() => toggleGroupOpen(group.id)}
            >
              <span className="text-base font-medium">{group.label}</span>
              {group.foldable && (
                <Icon
                  fontSize={20}
                  icon={isOpen ? 'ic:baseline-keyboard-arrow-up' : 'ic:baseline-keyboard-arrow-down'}
                ></Icon>
              )}
            </div>
            {(!group.foldable || (group.foldable && isOpen)) && (
              <div className="flex flex-col gap-1 mt-3">
                {group.hasAllTrigger && (
                  <div className="flex flex-row items-center justify-start gap-x-4 mb-2">
                    <UncontrolledCheckbox
                      name={group.id + '::all'}
                      label="All"
                      alignLabel="right"
                      className="text-sm"
                      checked={getGroupIndeterminate(group.id) ? 'indeterminate' : getGroupAllChecked(group.id)}
                      onCheckedChange={() => handleSelectAllGroup(group.id)}
                      checkIconSize="tiny"
                      iconWidth={16}
                    />
                  </div>
                )}
                {group.filters.map((filter) => {
                  return (
                    <div key={filter.id} className="flex flex-row items-center justify-start gap-x-4 mb-2">
                      <UncontrolledCheckbox
                        name={filter.id}
                        label={filter.label}
                        checked={getChecked(group.id, filter.id)}
                        onCheckedChange={($checked) => {
                          const checked = typeof $checked === 'boolean' ? $checked : false;
                          handleChanged(group.id, filter.id, checked);
                        }}
                        className="text-sm max-w-full overflow-hidden whitespace-nowrap"
                        checkboxClassName="flex-shrink-0"
                        checkIconSize="tiny"
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export function convertFilterToFilterStatus(filterGroups: SideFiltersProps['filters']): FilterStatuses {
  return filterGroups.reduce((acc, group) => {
    const innerFilters = group.filters.reduce(
      (acc, curr) => {
        if (curr.id in acc) return acc;
        return {
          ...acc,
          [curr.id]: {
            filterId: curr.id,
            checked: curr.defaultChecked,
          } satisfies FilterStatus['filters'][string],
        };
      },
      {} as FilterStatus['filters']
    );

    return {
      ...acc,
      [group.id]: {
        filters: innerFilters,
        groupId: group.id,
      } satisfies FilterStatus,
    };
  }, {} as FilterStatuses);
}

function convertFilterToToggleGroups(
  filterGroups: SideFiltersProps['filters'],
  initAllOpen = false
): Record<string, boolean> {
  return filterGroups.reduce(
    (acc, curr) => {
      if (curr.id in acc) return acc;
      return { ...acc, [curr.id]: curr.defaultOpened || initAllOpen };
    },
    {} as Record<string, boolean>
  );
}

function toggleFilterStatuses(filters: FilterStatuses, to?: boolean, groupKeyToCheck?: string) {
  return Object.entries(filters).reduce((acc, [groupKey, curr]) => {
    return {
      ...acc,
      [groupKey]: {
        ...curr,
        filters: Object.entries(curr.filters).reduce(
          (acc, [filterKey, curr]) => {
            return {
              ...acc,
              [filterKey]: {
                ...curr,
                ...((!groupKeyToCheck || groupKeyToCheck === groupKey) && {
                  checked: to !== undefined ? to : !curr.checked,
                }),
              },
            };
          },
          {} as FilterStatus['filters']
        ),
      },
    };
  }, {} as FilterStatuses);
}

export function convertToStringIds(filterStatuses: FilterStatuses, invertedCheck = false) {
  return Object.entries(filterStatuses).reduce(
    (acc, [key, curr]) => {
      return {
        ...acc,
        [key]: Object.values(curr.filters)
          .filter((filter) => (invertedCheck ? !filter.checked : filter.checked))
          .map((filter) => filter.filterId),
      };
    },
    {} as Record<string, string[]>
  );
}
