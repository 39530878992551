import { isPartnered } from '@harmoney/ui-utils';
import { LoanProductName } from '@harmoney/utilities';
import { BranchEnum, RelationshipStatusEnum } from '@prisma/client';
import { z } from 'zod';

import { debtConsolidationRootSchema } from './debt-con-config';
import { rootSchema } from './personal-loan-config';

export const getFormSchemaByProductNameAndRelationshipStatus = (
  productName: LoanProductName,
  relationshipStatus: RelationshipStatusEnum,
  branch: BranchEnum
) => {
  const useSharedMortgage = isPartnered(relationshipStatus);
  switch (productName) {
    case LoanProductName.DEBT_CONSOLIDATION:
      return debtConsolidationRootSchema(useSharedMortgage, branch);
    case LoanProductName.PERSONAL_LOAN:
      return rootSchema(useSharedMortgage, branch);
    default:
      break;
  }
};

export type FormSchema = z.infer<ReturnType<typeof getFormSchemaByProductNameAndRelationshipStatus>>;
