import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreateInternalUserInstanceMutation } from '@harmoney/redux';
import { entityType } from '@prisma/client';

import { RootState } from '../redux/store';

import { useInternalTaskListener } from './use-internal-task-listener';

export const useJourney = (journeyId: string, spokeId: string, entityId: string, entityType: entityType) => {
  const router = useRouter();
  const userId = useSelector((state: RootState) => state.userId.value);
  const [userInstanceCreated, setUserInstanceCreated] = useState({} as Record<string, boolean>);
  const [instanceId, setInstanceId] = useState({} as Record<string, string>);
  const [createInternalUserInstance] = useCreateInternalUserInstanceMutation();

  useInternalTaskListener(journeyId, userId, instanceId[spokeId]);

  const startJourney = useCallback(async () => {
    if (!userId || !journeyId || userInstanceCreated[spokeId]) return;
    try {
      if (userInstanceCreated[spokeId]) return;
      setUserInstanceCreated({ ...userInstanceCreated, [spokeId]: true });
      const { id } = await createInternalUserInstance({
        userId,
        processId: journeyId as string,
        spokeVariables: { spokeId: spokeId as string, entityId: entityId as string, entityType: entityType as string },
      }).unwrap();
      setInstanceId({ ...instanceId, [spokeId]: id });
    } catch (e) {
      alert('Fail to start the application, please try later.');
      router.replace('/admin');
    }
  }, [userId, journeyId, userInstanceCreated, spokeId, createInternalUserInstance, entityId, instanceId, router]);

  return { startJourney };
};
