export const bsbDbFormat = /^\d{3}-\d{3}$/g;
export const bsbInputFormat = /^(\d\s*){3}-?(\d\s*){3}$/;
export const accountNumberInputFormat = /^(\d\s*){3}-?(\d\s*){1,6}$/;
export const numberInputFormat = /^(\d\s*)+$/;

export function isValidBsb(bsb: string) {
  return bsbInputFormat.test(bsb);
}

export function transformBsb(bsb: string) {
  bsb = bsb.trim();
  bsb = normalizeBsbOrAccountNumber(bsb);
  if (bsbDbFormat.test(bsb)) return bsb;
  return bsb.slice(0, 3) + '-' + bsb.slice(3);
}

export function normalizeBsbOrAccountNumber(bsbOrAccountNumber: string) {
  return bsbOrAccountNumber.replace(/[\s-]/g, '');
}
