import {
  AssignTaskByUserDto,
  PaginatedQueryInput,
  PaginatedResult,
  TaskListDetailsDto,
} from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const tasklistApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTasks: builder.query<PaginatedResult<TaskListDetailsDto>, PaginatedQueryInput>({
      query: (params) => {
        return {
          url: `/tasks-list/all`,
          method: 'GET',
          params,
        };
      },
    }),

    getUnassignedTasks: builder.query<TaskListDetailsDto[], { group: string }>({
      query: ({ group }) => ({
        url: `/tasks-list/unassigned-task`,
        method: 'GET',
        params: { group },
      }),
      providesTags: ['InternalTask'],
    }),

    getUserTasks: builder.query<TaskListDetailsDto[], { group: string }>({
      query: ({ group }) => ({
        url: `/tasks-list/my-tasks`,
        method: 'GET',
        params: { group },
      }),
      providesTags: ['InternalTask'],
    }),

    findTaskById: builder.query<TaskListDetailsDto, string>({
      query: (taskId) => ({
        url: `/tasks-list/${taskId}`,
        method: 'GET',
      }),
      providesTags: ['InternalTask'],
    }),

    assignTaskByUser: builder.mutation<TaskListDetailsDto, { taskId: string; data: AssignTaskByUserDto }>({
      query: ({ taskId, data }) => ({
        url: `/tasks-list/assign/${taskId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['InternalTask'],
    }),

    unassignTaskByUser: builder.mutation<TaskListDetailsDto, { taskId: string }>({
      query: ({ taskId }) => ({
        url: `/tasks-list/unassign/${taskId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['InternalTask'],
    }),

    findIncompleteInternalTasks: builder.query<{ [key: string]: string }, string>({
      query: (loanApplicationId) => ({
        url: `/tasks-list/tasks`,
        method: 'GET',
        params: { loanApplicationId },
      }),
      providesTags: ['InternalTask'],
    }),
  }),
});

export const {
  useGetAllTasksQuery,
  useGetUnassignedTasksQuery,
  useGetUserTasksQuery,
  useFindTaskByIdQuery,
  useAssignTaskByUserMutation,
  useUnassignTaskByUserMutation,
  useFindIncompleteInternalTasksQuery,
} = tasklistApi;
