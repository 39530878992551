import { useEffect, useState } from 'react';
import { AdjustmentTypeEnum, VaultLoanInformationDto } from '@harmoney/api-interfaces';
import { useBranch } from '@harmoney/hooks';
import { useLazyGetParentAndChildTransactionsQuery } from '@harmoney/redux';
import {
  Alert,
  ArrowCircleRightIcon,
  Button,
  ControlledInput,
  DecimalAmountInput,
  Dialog,
  Form,
  Label,
  Select,
  Textarea,
  useForm,
} from '@harmoney/ui-design-system';
import { formatCurrency, getDayjsInTimezoneByBranch } from '@harmoney/utilities';
import { PaymentPurposeEnum, PaymentTransaction } from '@prisma/client';
import { capitalize } from 'lodash';

import { CommonProps } from '../../../common-props';
import { PaymentPlanFormFooter } from '../PaymentPlan/PaymentPlanFormFooter';
import { PaymentPlanFormHeader } from '../PaymentPlan/PaymentPlanFormHeader';
import { ChangeSummaryTypeEnum } from '../PaymentPlan/util';

import { adjustmentTypeOptions, reversalReasonOptions } from './adjustment-options';
import { adjustmentInitialValues, createAdjustmentFormSchema } from './form-config';

interface AdjustmentsProps extends CommonProps {
  applicationId: string;
  loanInformation: VaultLoanInformationDto;
  vaultId: string;
  onErrorAlert?: (value: boolean) => void;
}

export const Adjustments = ({
  loanInformation,
  applicationId,
  taskId,
  completeTaskWithData,
  vaultId,
  onErrorAlert,
}: AdjustmentsProps) => {
  const [trigger] = useLazyGetParentAndChildTransactionsQuery();

  const [paymentTransactions, setPaymentTransactions] = useState<{
    parentTransaction: PaymentTransaction;
    childTransactions: PaymentTransaction[];
  } | null>(null);
  const [paymentTransactionFetchError, setPaymentTransactionFetchError] = useState<any>(null);
  const [transactionBreakdown, setTransactionBreakdown] = useState({
    principalPaid: 0,
    interestPaid: 0,
    penalitiesPaid: 0,
  });
  const [totalPrincipalPaid, setTotalPrincipalPaid] = useState<number>(0);
  const [paymentKeyPrincipal, setPaymentKeyPrincipal] = useState<number>(0);
  const [paymentKeyError, setPaymentKeyError] = useState<string>('');
  const [amountManuallySet, setAmountManuallySet] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [initialTaskId, setInitialTaskId] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const branch = useBranch();

  const form = useForm({
    mode: 'onTouched',
    schema: createAdjustmentFormSchema(totalPrincipalPaid, paymentKeyPrincipal),
    defaultValues: adjustmentInitialValues,
  });

  const { watch, register, setValue, clearErrors, getValues } = form;

  const watchForm = watch();

  useEffect(() => {
    if (loanInformation) {
      const pendingPrincipal =
        loanInformation?.principal +
        loanInformation?.principalDue +
        loanInformation?.principalOverDue +
        loanInformation?.principalWrittenOff;
      const calculatedTotalPrincipalPaid = loanInformation?.loanAmountTotalWithFee - pendingPrincipal;
      setTotalPrincipalPaid(Math.round(calculatedTotalPrincipalPaid * 100) / 100);
    }
  }, [loanInformation]);

  useEffect(() => {
    if (watchForm.paymentKey && paymentTransactions?.childTransactions) {
      const principal = paymentTransactions.childTransactions.find(
        (payment) => payment.purpose === PaymentPurposeEnum.warehouse_principal
      )?.amount;
      setPaymentKeyPrincipal(principal || 0);
    }
  }, [paymentTransactions?.childTransactions, watchForm.paymentKey]);

  useEffect(() => {
    const fetchPaymentTransactions = async () => {
      if (watchForm.paymentKey) {
        try {
          const res = await trigger(watchForm.paymentKey).unwrap();
          if (res.parentTransaction === null) {
            setPaymentKeyError('Please enter a valid payment key.');
          } else if (res.parentTransaction.loanApplicationId !== applicationId) {
            setPaymentKeyError('This payment key is used for another loan. Please try a different one.');
          } else {
            setPaymentTransactions(res);
            setPaymentKeyError('');
          }
        } catch (error) {
          setPaymentTransactionFetchError(error);
        }
      } else {
        setPaymentKeyError('');
        setPaymentTransactions(null);
      }
    };

    fetchPaymentTransactions();
  }, [applicationId, trigger, watchForm.paymentKey]);

  useEffect(() => {
    if (paymentTransactions?.childTransactions) {
      const { childTransactions } = paymentTransactions;
      const principalPaid =
        childTransactions.find((payment) => payment.purpose === PaymentPurposeEnum.warehouse_principal)?.amount || 0;
      const interestPaid =
        childTransactions.find((payment) => payment.purpose === PaymentPurposeEnum.warehouse_interest)?.amount || 0;
      const penalitiesPaid =
        childTransactions.find((payment) => payment.purpose === PaymentPurposeEnum.warehouse_penalties)?.amount || 0;

      setTransactionBreakdown({ principalPaid, interestPaid, penalitiesPaid });
    }
  }, [paymentTransactions?.childTransactions]);

  useEffect(() => {
    if (watchForm.paymentKey && paymentTransactions?.parentTransaction?.amount && !paymentKeyError) {
      clearErrors('amount');
      setValue('amount', paymentTransactions.parentTransaction.amount);
    } else if (!watchForm.paymentKey) {
      setValue('amount', null);
    }
  }, [paymentTransactions?.parentTransaction?.amount, watchForm.paymentKey, paymentKeyError]);

  useEffect(() => {
    if (!watchForm.amount) {
      setAmountManuallySet(false);
    }
  }, [watchForm.amount]);

  useEffect(() => {
    if (initialTaskId && initialTaskId !== taskId) {
      setIsSubmitting(false);
      setShowAlert(true);
      onErrorAlert(true);
    }
  }, [taskId]);

  const handleSubmit = () => {
    setInitialTaskId(taskId);
    setIsConfirmModalOpen(true);
  };

  const onModalSubmit = () => {
    setIsSubmitting(true);
    const formData = getValues();
    const adjustmentData = {
      amount: formData.amount,
      reference: formData.reference,
      vaultId,
      adjustmentType: watchForm.adjustmentType,
      paymentKey: watchForm.paymentKey || null,
      totalPrincipalPaid,
      reversalReason: watchForm.reversalReason || null,
    };

    completeTaskWithData({
      taskId,
      variables: { adjustmentData },
    });
    setIsConfirmModalOpen(false);
  };

  const getAmountLabel = () => {
    const labels = {
      [AdjustmentTypeEnum.REFUND]: 'Amount to be refunded',
      [AdjustmentTypeEnum.REVERSAL]: 'Amount to be reversed',
      [AdjustmentTypeEnum.INTEREST_ADJUSTMENT]: 'Interest amount to be adjusted',
      default: 'Amount',
    };
    return labels[watchForm.adjustmentType] || labels.default;
  };

  const getPaidAmountAndType = () => {
    const paidAmounts = {
      [AdjustmentTypeEnum.REFUND]: { type: 'Total principal paid:', amount: formatCurrency(totalPrincipalPaid, 2) },
      [AdjustmentTypeEnum.REVERSAL]: { type: 'Total principal paid:', amount: formatCurrency(totalPrincipalPaid, 2) },
      [AdjustmentTypeEnum.INTEREST_ADJUSTMENT]: { type: 'Total interest accrued:', amount: '$1000' },
    };
    return paidAmounts[watchForm.adjustmentType] || null;
  };

  const getButtonText = () => {
    const buttonTexts = {
      [AdjustmentTypeEnum.REFUND]: 'Initiate Refund',
      [AdjustmentTypeEnum.REVERSAL]: 'Initiate reversal',
      [AdjustmentTypeEnum.INTEREST_ADJUSTMENT]: 'Initiate adjustment',
      default: 'Update',
    };
    return buttonTexts[watchForm.adjustmentType] || buttonTexts.default;
  };

  const getDialogTitle = () => {
    const dialogTitles = {
      [AdjustmentTypeEnum.REFUND]: 'Initiate refund',
      [AdjustmentTypeEnum.REVERSAL]: 'Initiate principal reversal',
      [AdjustmentTypeEnum.INTEREST_ADJUSTMENT]: 'Initiate interest adjustment',
      default: 'Adjustment',
    };
    return dialogTitles[watchForm.adjustmentType] || dialogTitles.default;
  };

  const getAmountType = () => {
    const amountTypes = {
      [AdjustmentTypeEnum.REFUND]: 'amount to be refunded',
      [AdjustmentTypeEnum.REVERSAL]: 'amount to be reversed',
      [AdjustmentTypeEnum.INTEREST_ADJUSTMENT]: 'interest amount to be adjusted',
      default: '',
    };
    return amountTypes[watchForm.adjustmentType] || amountTypes.default;
  };

  return (
    <>
      <Form form={form} onSubmit={handleSubmit}>
        <PaymentPlanFormHeader
          headerTitle="Adjustments"
          type={ChangeSummaryTypeEnum.ADJUSTMENT}
          closeVisible={!isSubmitting}
        />
        <div className="px-4">
          <Select label="Adjustment type" {...register('adjustmentType')} options={adjustmentTypeOptions} />
          {watchForm.adjustmentType && watchForm.adjustmentType !== AdjustmentTypeEnum.INTEREST_ADJUSTMENT && (
            <>
              <p className="font-medium py-8 mb-0">
                Please provide your payment key (if you have one) or the {getAmountType()}.
              </p>
              <Label>
                Payment key <span className="text-grey-4">(Optional)</span>
              </Label>
              <ControlledInput
                {...register('paymentKey')}
                disabled={amountManuallySet}
                displayErrorMessage={paymentKeyError ?? ''}
                isInvalid={!!paymentKeyError}
              />
              {watchForm.paymentKey &&
                paymentTransactions &&
                (paymentTransactionFetchError ? (
                  <Alert variant="error" className="mt-8">
                    <p>Failed to fetch payment transactions.</p>
                  </Alert>
                ) : (
                  !paymentKeyError &&
                  (paymentTransactions?.childTransactions.length > 0 ? (
                    <>
                      <p className="grid grid-cols-2 gap-4 pt-4">
                        <span>Principal:</span>
                        <span>{formatCurrency(transactionBreakdown.principalPaid, 2)}</span>
                      </p>
                      <p className="grid grid-cols-2 gap-4">
                        <span>Interest:</span>
                        <span>{formatCurrency(transactionBreakdown.interestPaid, 2)}</span>
                      </p>
                      <p className="grid grid-cols-2 gap-4">
                        <span>Other:</span>
                        <span>{formatCurrency(transactionBreakdown.penalitiesPaid, 2)}</span>
                      </p>
                      <p className="grid grid-cols-2 gap-4">
                        <span>Transaction date:</span>
                        <span>
                          {getDayjsInTimezoneByBranch(
                            branch,
                            paymentTransactions?.childTransactions[0]?.createdAt
                          )?.format('DD/MM/YYYY')}
                        </span>
                      </p>
                      <p className="grid grid-cols-2 gap-4">
                        <span>Cleared Date:</span>
                        <span>
                          {getDayjsInTimezoneByBranch(
                            branch,
                            paymentTransactions.childTransactions[0].completedAt ??
                              paymentTransactions.childTransactions[0].processingAt
                          )?.format('DD/MM/YYYY')}
                        </span>
                      </p>
                    </>
                  ) : (
                    <Alert variant="error" className="mt-8">
                      <p>No successful breakdown found for this payment key. Please contact Engineering team</p>
                    </Alert>
                  ))
                ))}
            </>
          )}
          {watchForm.adjustmentType && (
            <>
              <DecimalAmountInput
                label={getAmountLabel()}
                {...register('amount')}
                className="pt-8"
                disabled={!!watchForm.paymentKey}
                onInput={() => setAmountManuallySet(true)}
              />
              {watchForm.adjustmentType === AdjustmentTypeEnum.REVERSAL && (
                <Select
                  label="Reason"
                  {...register('reversalReason')}
                  options={reversalReasonOptions}
                  className="pt-8"
                />
              )}
              <p className="grid grid-cols-2 gap-4 pt-8">
                <span>{getPaidAmountAndType()?.type}</span>
                <span>{getPaidAmountAndType()?.amount}</span>
              </p>
              <Textarea label="Reference" {...register('reference')} className="pt-8" />
            </>
          )}
          {watchForm.adjustmentType && watchForm.adjustmentType !== AdjustmentTypeEnum.INTEREST_ADJUSTMENT && (
            <Alert variant="warning" className="mt-8">
              <p>Please transfer the funds immediately after this to complete the transaction.</p>
            </Alert>
          )}
          {showAlert && (
            <Alert
              variant="error"
              title="Failed to apply this adjustment to ThoughtMachine due to technical issues"
              className="mt-4"
            />
          )}
          <PaymentPlanFormFooter
            buttonText={getButtonText()}
            type={ChangeSummaryTypeEnum.ADJUSTMENT}
            isSubmitting={isSubmitting}
            isCancelDisabled={isSubmitting}
          />
        </div>
      </Form>
      <Dialog
        open={isConfirmModalOpen}
        onOpenChange={() => {
          setIsConfirmModalOpen(!isConfirmModalOpen);
        }}
        title={getDialogTitle()}
        modal
        showCloseButton={!isSubmitting}
      >
        <p className="px-4 pt-4 font-medium">Are you sure you want to initiate this {watchForm.adjustmentType}?</p>
        <div className="px-4 grid grid-cols-2">
          <p>{getAmountLabel()}</p>
          <p className="font-medium">{formatCurrency(watchForm.amount, 2)}</p>
        </div>
        {watchForm.adjustmentType === AdjustmentTypeEnum.REVERSAL && (
          <div className="px-4 grid grid-cols-2">
            <p>Reason:</p>
            <p className="font-medium">{capitalize(watchForm.reversalReason.replace(/_/g, ' ').toLowerCase())}</p>
          </div>
        )}
        <div className="px-4 grid grid-cols-2">
          <p>Reference:</p>
          <p className="font-medium">{watchForm.reference}</p>
        </div>
        {watchForm.adjustmentType !== AdjustmentTypeEnum.INTEREST_ADJUSTMENT && (
          <Alert variant="warning" className="mx-4">
            <p>Please transfer the funds immediately after this to complete the transaction.</p>
          </Alert>
        )}
        <div className="shrink-1 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse p-4 flex-wrap">
          <Button
            onClick={onModalSubmit}
            type="submit"
            variant="primary"
            className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            isLoading={isSubmitting}
            hasShadow
          >
            Confirm
          </Button>
          <Button
            onClick={() => setIsConfirmModalOpen(false)}
            variant="secondary"
            className="!min-w-fit !max-w-fit"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    </>
  );
};
