import {
  ApiObject,
  ApiOptions,
  RudderAnalyticsPreloader,
  RudderAnalytics as RudderStackAnalytics,
} from '@rudderstack/analytics-js';
import { isDevelopment, isPreview } from '../../util';
import { EventAnalyticType, KeyValuePair } from '../event-analytics';

declare global {
  interface Window {
    rudderanalytics: RudderAnalyticsPreloader | RudderStackAnalytics | undefined;
  }
}

export interface RudderAnalyticsType extends EventAnalyticType {
  load(writeKey: string, dataPlaneUrl: string, loadOptions: KeyValuePair): void;
}

/**
 * This is the concrete implementation of Rudderstack API.
 * We simplify Rudderstack API to fit our needs and add a layer of error handler for better monitoring.
 * Reference: https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/
 */
export class RudderAnalytics {
  get isLoaded() {
    return typeof window !== 'undefined' && !!window.rudderanalytics;
  }

  async load() {
    if (this.isLoaded) return;
    let configUrl = window.location.origin.includes('secure')
      ? window.location.origin.replace('secure', 'events-api')
      : 'https://events-api.harmoneylabs.com.au';

    const { RudderAnalytics: RudderStackAnalytics } = await import('@rudderstack/analytics-js');

    window.rudderanalytics = new RudderStackAnalytics();

    let rudderstackDataplaneUrl = window.location.origin.includes('secure')
      ? window.location.origin.replace('secure', 'events-data')
      : 'https://events-data.harmoneylabs.com.au';

    if (isDevelopment() || isPreview()) {
      rudderstackDataplaneUrl = 'https://harmoneysihx.dataplane.rudderstack.com';
      configUrl = 'https://api.rudderstack.com';
    }

    window.rudderanalytics.load(process.env['NEXT_PUBLIC_RUDDER_UI_WRITE_KEY'] || '', rudderstackDataplaneUrl, {
      integrations: { All: true },
      configUrl,
      storage: {
        encryption: {
          version: 'legacy',
        },
        migrate: false,
      },
      plugins: ['DeviceModeDestinations', 'NativeDestinationQueue', 'StorageEncryptionLegacy', 'XhrQueue'],
    });
  }

  identify(userId: string, traits?: KeyValuePair, apiOptions?: KeyValuePair) {
    if (!this.isLoaded) {
      console.error(`[RudderAnalytics.identify]: Rudderstack is not loaded.`);
      return;
    }

    try {
      window.rudderanalytics?.identify(userId, traits, apiOptions);
    } catch (error) {
      console.error(`[RudderAnalytics.identify]: ${error}`);
    }
  }

  page(category: string, name: string, properties?: ApiObject, options?: ApiOptions) {
    if (!this.isLoaded) {
      console.error(`[RudderAnalytics.page]: Rudderstack is not loaded.`);
      return;
    }

    try {
      if (window.rudderanalytics instanceof RudderStackAnalytics) {
        window.rudderanalytics?.page(category, name, properties, options);
      }
    } catch (error) {
      console.error(`[RudderAnalytics.page]: ${error}`);
    }
  }

  track(event: string, properties?: KeyValuePair, options?: ApiOptions) {
    if (!this.isLoaded) {
      console.error(`[RudderAnalytics.track]: Rudderstack is not loaded.`);
      return;
    }

    try {
      window.rudderanalytics?.track(event, properties, options);
    } catch (error) {
      console.error(`[RudderAnalytics.track]: ${error}`);
    }
  }

  alias(to: string, from?: string) {
    if (!this.isLoaded) {
      console.error(`[RudderAnalytics.alias]: Rudderstack is not loaded.`);
      return;
    }

    try {
      window.rudderanalytics?.alias(to, from);
    } catch (error) {
      console.error(`[RudderAnalytics.alias]: ${error}`);
    }
  }

  group(groupId: string, traits?: KeyValuePair) {
    if (!this.isLoaded) {
      console.error(`[RudderAnalytics.group]: Rudderstack is not loaded.`);
      return;
    }

    try {
      window.rudderanalytics?.group(groupId, traits);
    } catch (error) {
      console.error(`[RudderAnalytics.group]: ${error}`);
    }
  }

  reset(anonymousIdRemoved?: boolean) {
    if (!this.isLoaded) {
      console.error(`[RudderAnalytics.reset]: Rudderstack is not loaded.`);
      return;
    }

    try {
      window.rudderanalytics?.reset(anonymousIdRemoved);
    } catch (error) {
      console.error(`[RudderAnalytics.reset]: ${error}`);
    }
  }
}
