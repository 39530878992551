import { useEffect } from 'react';
import { DisbursementDto } from '@harmoney/api-interfaces';
import { ControlledInput, useFormContext } from '@harmoney/ui-design-system';
import { numberInputFormat } from '@harmoney/utilities';

type Props = {
  disbursal: DisbursementDto;
};

export function BpayForm({ disbursal }: Props) {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue(`updates.${disbursal.id}.newBankAccount.type`, 'bpay');
  }, [disbursal.id]);

  return (
    <>
      <ControlledInput
        type="text"
        pattern={numberInputFormat.source}
        label="BPAY biller code"
        {...register(`updates.${disbursal.id}.newBankAccount.bpayBillerCode`)}
      />
      <ControlledInput
        type="text"
        label="Reference"
        {...register(`updates.${disbursal.id}.newBankAccount.reference`)}
      />
    </>
  );
}
