import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import {
  BankAccountDetails,
  BankAccountDto,
  DirectDebitHistory,
  FORM_KEY,
  MonoovaBSBValidationOutputDto,
  VaultLoanViewDto,
} from '@harmoney/api-interfaces';
import {
  LoanApplicationQueryResponse,
  useAppSelector,
  useCompleteTaskWithDataMutation,
  useGetAllLoanApplicationsByUserIdQuery,
  useGetDDBankAccountsQuery,
  useLazyGetRepaymentDetailByUserIdQuery,
  useUpdateDDBankAccountMutation,
} from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Form, Textarea, useForm } from '@harmoney/ui-design-system';
import { joinTextAsCommaSeparatedAndAnd, normalizeBsbOrAccountNumber, transformBsb } from '@harmoney/utilities';
import { z } from 'zod';

import { BankAccountSelector } from '../../../fulfilment/Payment/components';
import { normalizeBankAccount } from '../../../fulfilment/Payment/utils';
import { Header } from '../PaymentPlan/Header';

import { LoanSelector, NewBankAccountSection } from './components';
import { defaultValuesForUpdateDirectDebitBankAccount, formSchemaForUpdateDirectDebitBankAccount } from './form-config';
import DirectDebitUpdateConfirmModal from './UpdateDDConfirmModal';

type Props = {
  userId: string;
  taskId: string;
  loans: (VaultLoanViewDto & LoanApplicationQueryResponse)[];
  onSuccess?: () => void | Promise<void> | unknown;
  onCancel?: () => void | Promise<void> | unknown;
  onFail?: (err: any) => void | Promise<void> | unknown;
};

const ADD_ANOTHER_BANK_ACCOUNT = 'ADD_ANOTHER_BANK_ACCOUNT' as const;

function mapBankAccountToDto(bankAccount: BankAccountDetails): BankAccountDetails {
  const { accountBsb, accountNumber, bankName, bankSlug, loanApplicationId, loanId, productName, accountName } =
    bankAccount;
  return {
    accountBsb,
    accountName,
    accountNumber,
    bankName,
    bankSlug,
    loanApplicationId,
    loanId,
    productName,
  };
}

function extractBankDetails(bankAccounts: BankAccountDto[], bankAccount: string) {
  const matchedBankAccount = bankAccounts.find(
    ({ accountNumber, bsb }) => bankAccount === normalizeBankAccount(bsb, accountNumber)
  );

  return {
    accountBsb: matchedBankAccount.bsb,
    accountNumber: matchedBankAccount.accountNumber,
    accountName: matchedBankAccount.accountHolder || matchedBankAccount.accountName,
    bankName: matchedBankAccount.bankName,
    bankSlug: matchedBankAccount.bankSlug,
  } satisfies BankAccountDetails;
}

export default function UpdateDDBankAccount({ userId, onCancel, loans, taskId, onSuccess, onFail }: Props) {
  const [loanBankAccountMap, setLoanBankAccountMap] = useState<Record<string, BankAccountDto>>({});
  const [modalData, setModalData] = useState<DirectDebitHistory & { shouldMask?: boolean }>();
  const [isActionDisabled, setIsActionDisabled] = useState(false);
  const [bankDetails, setBankDetails] = useState<MonoovaBSBValidationOutputDto>();

  const token = useAppSelector((state) => state?.accessToken?.value);
  const { data: bankAccounts } = useGetDDBankAccountsQuery(userId, { skip: !userId || !token });
  const { data: loanApplications } = useGetAllLoanApplicationsByUserIdQuery(userId, { skip: !userId || !token });

  const [repaymentQueryTrigger] = useLazyGetRepaymentDetailByUserIdQuery();
  const [updateDD] = useUpdateDDBankAccountMutation();
  const [completeTaskWithData] = useCompleteTaskWithDataMutation();

  const form = useForm({
    mode: 'onChange',
    schema: formSchemaForUpdateDirectDebitBankAccount(),
    defaultValues: defaultValuesForUpdateDirectDebitBankAccount,
  });
  const {
    register,
    clearErrors,
    formState: { isSubmitting, isValid },
    setValue,
  } = form;
  const selectedBankAccount = useWatch({
    name: 'updateDirectDebitToBankAccount',
    control: form.control,
  });
  const selectedLoanKeys = useWatch({
    name: 'selectedLoans',
    control: form.control,
  });

  const loanApplicationsMap = useMemo(() => {
    return (loanApplications ?? []).reduce(
      (acc, curr) => {
        return { ...acc, [curr.id]: curr };
      },
      {} as Record<string, (typeof loanApplications)[number]>
    );
  }, [loanApplications]);

  const getLoanBankAccountsMap = useCallback(
    async (loans: Props['loans']) => {
      const loanBankAccounts = await Promise.all(
        loans.map(async (loan) => {
          const { repaymentInfo } = loanApplicationsMap[loan.id];
          const { data: repaymentResponse } = await repaymentQueryTrigger({ loanApplicationId: loan.id, userId });
          const primaryBankAccount = repaymentResponse?.bankAccounts?.find(
            (bankAccount) =>
              normalizeBsbOrAccountNumber(bankAccount.bsb) === normalizeBsbOrAccountNumber(repaymentInfo?.bsb) &&
              bankAccount.accountNumber === repaymentInfo?.accountNumber
          );
          return {
            accountHolder: primaryBankAccount?.accountHolder,
            accountName: repaymentInfo?.accountName ?? primaryBankAccount?.accountName, // Fallback to primary account name
            bankNumber: primaryBankAccount?.bankNumber ?? repaymentInfo?.bankCode,
            branchNumber: repaymentInfo?.branchCode ?? null,
            accountNumber: primaryBankAccount?.accountNumber ?? repaymentInfo?.accountNumber,
            suffix: repaymentInfo?.accountSuffix ?? null,
            bankName: primaryBankAccount?.bankName ?? repaymentInfo?.bankName ?? repaymentInfo.bankCode,
            bankLogoUrl: primaryBankAccount?.bankLogoUrl,
            bsb: repaymentInfo?.bsb ?? primaryBankAccount?.bsb,
            bankSlug: primaryBankAccount?.bankSlug ?? repaymentInfo?.bankSlug,
            loanId: loan.businessKey,
          };
        })
      );

      const mappedLoanBankAccounts = loanBankAccounts.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.loanId]: curr,
          };
        },
        {} as typeof loanBankAccountMap
      );
      setLoanBankAccountMap(mappedLoanBankAccounts);
    },
    [loanApplicationsMap, repaymentQueryTrigger, userId]
  );

  useEffect(() => {
    if (selectedBankAccount === ADD_ANOTHER_BANK_ACCOUNT) return;
    setValue('newBankAccount', undefined);
  }, [selectedBankAccount, setValue]);

  useEffect(() => {
    getLoanBankAccountsMap(loans);
  }, [getLoanBankAccountsMap, loans]);

  useEffect(() => {
    const hasAnySelected = Object.values(selectedLoanKeys || {}).some((isChecked) => isChecked);
    if (hasAnySelected) clearErrors('selectedLoans');
  }, [selectedLoanKeys, clearErrors]);

  const loanKeysWithSameBankAccounts = useMemo(() => {
    const sameBankAccountsList = Object.entries(selectedLoanKeys)
      .filter(([loanKey, isChecked]) => {
        const primaryAccount = loanBankAccountMap[loanKey];
        const normalizedBankAccount = `${transformBsb(primaryAccount.bsb)}-${normalizeBsbOrAccountNumber(primaryAccount.accountNumber)}`;
        if (primaryAccount && normalizedBankAccount === selectedBankAccount && isChecked) return !!loanKey;
        return false;
      })
      .map(([loanKey]) => loanKey);

    setIsActionDisabled(!!sameBankAccountsList.length);

    return new Set(sameBankAccountsList);
  }, [loanBankAccountMap, selectedBankAccount, selectedLoanKeys]);

  function handleSubmit(data: z.infer<ReturnType<typeof formSchemaForUpdateDirectDebitBankAccount>>) {
    const selectedBankAccount = data.updateDirectDebitToBankAccount;
    const notes = data.notes;

    const selectedLoanIds = new Set(
      Object.entries(data.selectedLoans)
        .filter(([, isChecked]) => isChecked)
        .map(([loanKey]) => loanKey)
    );

    const selectedLoans = loans.filter((loan) => selectedLoanIds.has(loan.businessKey));

    let updateDirectDebitTo: BankAccountDetails;
    if (selectedBankAccount === ADD_ANOTHER_BANK_ACCOUNT) {
      updateDirectDebitTo = {
        accountBsb: data.newBankAccount.accountBsb,
        accountNumber: data.newBankAccount.accountNumber,
        accountName: data.newBankAccount.accountName,
        bankName: bankDetails?.bankCode,
        bankSlug: '',
      };
    } else {
      updateDirectDebitTo = extractBankDetails(bankAccounts, selectedBankAccount);
    }

    const modalData = {
      note: notes,
      createdAt: new Date(),
      createdBy: '',
      shouldMask: selectedBankAccount !== ADD_ANOTHER_BANK_ACCOUNT,
      from: selectedLoans.map((loan) => {
        const {
          bsb: accountBsb,
          accountNumber,
          bankName,
          bankSlug,
          accountName,
          accountHolder,
        } = loanBankAccountMap[loan.businessKey];
        return {
          accountBsb,
          accountNumber,
          bankName,
          bankSlug,
          accountName: accountHolder ?? accountName,
          loanApplicationId: loan.id,
          loanId: loan.businessKey,
          productName: loan.loanProduct.name,
        };
      }),
      id: undefined as never,
      to: updateDirectDebitTo,
    };

    setModalData(modalData);
  }

  async function onModalSubmit() {
    try {
      const res = await updateDD({
        customerId: userId,
        data: {
          note: modalData.note,
          to: mapBankAccountToDto(modalData.to),
          from: modalData.from.map(mapBankAccountToDto),
        },
      });
      if ('error' in res) throw res.error;

      await completeTaskWithData({
        taskId,
        formKey: FORM_KEY.DIRECT_DEBIT_BANK_ACCOUNT_UPDATE,
        formData: {
          userId,
          taskId,
        },
      });
      onSuccess?.();
    } catch (err) {
      form.setError('root', err);
      onFail?.(err);
    } finally {
      setModalData(undefined);
    }
  }

  return (
    <div className="mr-8">
      <Header headerTitle="Update direct debit account" onCancelClick={onCancel} />

      <Form form={form} onSubmit={handleSubmit} className="grid grid-cols-1 gap-8">
        {Array.isArray(loans) && loans.length > 0 ? (
          <LoanSelector loans={loans} register={register} loanBankAccountMap={loanBankAccountMap} />
        ) : null}

        {!!bankAccounts?.length && (
          <div>
            <BankAccountSelector
              maskBankDetails
              bankAccountType="updateDirectDebitToBankAccount"
              bankAccounts={bankAccounts}
            />
            {!!loanKeysWithSameBankAccounts.size && (
              <span role="alert" aria-live="assertive" className="leading-sm text-error mt-1 block text-sm">
                {`The direct debit account for your loan(s) ${joinTextAsCommaSeparatedAndAnd([
                  ...loanKeysWithSameBankAccounts,
                ])} is the same as the account you've chosen. Please choose a different account.`}
              </span>
            )}
          </div>
        )}

        {selectedBankAccount === ADD_ANOTHER_BANK_ACCOUNT && (
          <NewBankAccountSection onBankDetailsChanged={setBankDetails} />
        )}

        {/* Notes */}
        <Textarea {...register('notes')} label="Notes" placeholder="Add a note" />

        {/* Action buttons */}
        <div className="flex justify-end items-start gap-6 self-stretch">
          <Button variant="tertiary" onClick={onCancel} size="medium" className="!min-w-fit" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            size="medium"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="medium" />}
            className="sm:min-w-fit"
            disabled={!isValid || isActionDisabled}
            isLoading={isSubmitting}
          >
            Update
          </Button>
        </div>
      </Form>

      {modalData && (
        <DirectDebitUpdateConfirmModal
          onSubmit={onModalSubmit}
          onClose={() => setModalData(undefined)}
          {...modalData}
        />
      )}
    </div>
  );
}
