import { Fragment } from 'react';
import { accountTypeToDebtType } from '@harmoney/api-interfaces';
import { ExtendedPreQualifiedQuote } from '@harmoney/redux';
import { Card } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { currencyOrDash, dayjsSydney } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import classNames from 'classnames';
import { lowerCase } from 'lodash';

import { Field } from './shared';

interface PreQualifiedQuoteFinancialProfileDetailsProps {
  preQualifiedQuote: ExtendedPreQualifiedQuote;
}

export const PreQualifiedQuoteFinancialProfileDetails = ({
  preQualifiedQuote,
}: PreQualifiedQuoteFinancialProfileDetailsProps) => {
  const { calculatedMortgageRepayment, isMortgageFoundInCreditFile } = preQualifiedQuote?.variables?.debt || {};
  const { benchmarkValue, monthlyLivingExpense } = preQualifiedQuote?.variables || {};
  const rentalBenchmark = preQualifiedQuote?.variables?.rentalBenchmark;
  const liabilities = preQualifiedQuote?.liabilities || [];

  const housingExpenses = {
    monthlyHousingExpense: {
      label: 'Declared housing expenses',
      value: preQualifiedQuote.monthlyHousingExpense,
    },
    ...(isMortgageFoundInCreditFile
      ? {
          mortgageMAV: {
            label: 'Mortgage MAV',
            value: calculatedMortgageRepayment,
          },
        }
      : {
          rentalBenchmark: {
            label: 'Rental benchmark',
            value: rentalBenchmark,
          },
        }),
    minAccommodationExpense: {
      label: 'Min. accommodation expense',
      value: 650,
    },
  };

  const selectedHousingExpense = (() => {
    const expensesToCheck = isMortgageFoundInCreditFile
      ? ['minAccommodationExpense', 'mortgageMAV', 'monthlyHousingExpense']
      : ['minAccommodationExpense', 'rentalBenchmark', 'monthlyHousingExpense'];

    const sortedExpenses = expensesToCheck.map((expense) => housingExpenses[expense]).sort((a, b) => b.value - a.value);
    return sortedExpenses[0];
  })();

  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <div className="flex flex-col gap-2">
          <Card className="shadow-none border border-grey-2 !p-0 !mb-0 h-fit">
            <div className="p-4 space-y-2">
              <Field className="!pb-0">
                <h4 className="font-semibold mb-0">Income</h4>
                <span className="text-grey-4 !font-normal">Annual</span>
              </Field>
              <hr className="border-grey-2 !my-4" />
              <Field className="!pb-1">
                <span>Work code:</span>
                <span>{preQualifiedQuote.employmentCode || 'N/A'}</span>
              </Field>
              <Field className="!pb-1">
                <span>Gross annual income:</span>
                <span>${preQualifiedQuote.grossAnnualIncome?.toLocaleString() || 'N/A'}</span>
              </Field>
            </div>
          </Card>
          <Card className="shadow-none border border-grey-2 !p-0 !mb-0 h-fit">
            <div className="p-4 space-y-2">
              <Field className="!pb-0">
                <h4 className="font-semibold mb-0">Expenses</h4>
                <span className="text-grey-4 !font-normal">Monthly</span>
              </Field>
              <hr className="border-grey-2 !my-4" />
              <Field className="!pb-1">
                <span>Expense type:</span>
                <span>Living expense</span>
              </Field>
              <Field className="!pb-1">
                <span>Living expenses HEM Benchmark:</span>
                <span>{currencyOrDash(monthlyLivingExpense ?? (benchmarkValue * 52) / 12)}</span>
              </Field>
              <hr className="border-grey-2 !my-4" />
              {Object.values(housingExpenses).map((expense) => (
                <Field
                  key={expense.label}
                  className={classNames('!pb-1', {
                    hidden:
                      expense.label === housingExpenses.minAccommodationExpense.label &&
                      selectedHousingExpense.label !== housingExpenses.minAccommodationExpense.label,
                  })}
                >
                  <span>{expense.label}:</span>
                  <span>
                    {currencyOrDash(expense.value)}
                    {selectedHousingExpense.label === expense.label && (
                      <Icon
                        icon="material-symbols:check-circle-outline"
                        className="text-success align-middle ml-2 -mt-1"
                        width={18}
                      />
                    )}
                  </span>
                </Field>
              ))}
            </div>
          </Card>
        </div>

        <div className="flex flex-col gap-2 col-start-2">
          {liabilities.length > 0 && (
            <Card className="shadow-none border border-grey-2 !p-0 !mb-0 h-fit">
              <div className="p-4 pb-3">
                <Field className="!pb-0">
                  <h4 className="font-semibold mb-0">Debts</h4>
                  <span className="text-grey-4 !font-normal">From credit file</span>
                </Field>
                <hr className="border-grey-2 !my-4" />
                {liabilities.map((liability, index) => (
                  <Fragment key={liability.bureauAccountId}>
                    <Field className="!pb-2">
                      <span>Debt type:</span>
                      <span className="first-letter:capitalize">
                        {lowerCase(
                          Object.keys(accountTypeToDebtType).find((debtType) =>
                            accountTypeToDebtType[debtType].includes(liability.accountType)
                          )
                        )}{' '}
                        ({liability.accountType})
                      </span>
                    </Field>
                    <Field className="!pb-2">
                      <span>Provider:</span>
                      <span>{liability.organisation}</span>
                    </Field>
                    {liability.accountOpenDate && (
                      <Field className="!pb-2">
                        <span>Opened on:</span>
                        <span>{dayjsSydney(liability.accountOpenDate).format(DATE_FORMAT)}</span>
                      </Field>
                    )}
                    {liability.termOfLoan > 0 && (
                      <Field className="!pb-2">
                        <span>Term of loan:</span>
                        <span>{liability.termOfLoan} months</span>
                      </Field>
                    )}
                    {liability.accountType.startsWith('C') && (
                      <Field className="!pb-2">
                        <span>Credit limit:</span>
                        <span>{currencyOrDash(liability.creditLimit as number)}</span>
                      </Field>
                    )}
                    {liabilities.length - 1 !== index && <hr className="border-grey-2 !mb-4 !mt-3" />}
                  </Fragment>
                ))}
              </div>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};
