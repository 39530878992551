import { LEGAL_DISCLAIMER_LINKS } from '@harmoney/api-interfaces';
import { useBranch } from '@harmoney/hooks';

export const PreQualifiedDisclaimer = () => {
  const branch = useBranch();

  return (
    <p className="text-sm">
      By continuing, you confirm that you have read, understand and consent to the{' '}
      <a href={LEGAL_DISCLAIMER_LINKS[branch]?.websiteTermsOfUse} target="_blank" rel="noopener noreferrer">
        Website Terms of Use
      </a>
      ,{' '}
      <a
        href={LEGAL_DISCLAIMER_LINKS[branch]?.electronicCommunicationsConsent}
        target="_blank"
        rel="noopener noreferrer"
      >
        Electronic Communications Consent
      </a>{' '}
      and{' '}
      <a href={LEGAL_DISCLAIMER_LINKS[branch]?.privacyPolicy} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      .
    </p>
  );
};
