export const getBenefitsList = () => {
  return [
    {
      icon: 'hmy:timer-gradient',
      title: 'Breeze through the process in minutes.',
    },
    {
      icon: 'hmy:money-gradient',
      title: 'Get almost instant approvals.',
    },
    {
      icon: 'hmy:block-gradient',
      title: 'Get your money in minutes not days.',
    },
  ];
};
