import { Slide, toast } from 'react-toastify';
import { debtSaleStatusMapper } from '@harmoney/api-interfaces';
import { DebtSaleStatusEnum } from '@prisma/client';

import { vaultLoanAccountStateMapper } from '../admin';

export const downloadPdf = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `StatementOfAccount.pdf`);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export enum DownloadError {
  SOA_ERROR = 'There was a problem downloading your statement of account. Please reach out to our customer service and we will send it to you directly.',
  POQ_ERROR = 'There was a problem downloading your pay off quote.',
  OML_ERROR = 'There was a problem downloading obligation met letter.',
  POQ_NOTIFICATION = 'Error: This loan has a Direct Debit scheduled for today or a pending payment. The Pay off Quote will need to be generated manually.',
  OML_NOTIFICATION = 'Error: The Obligation Met Letter will not be generated as this loan is not paid-off.',
}

export const handleDownloadError = (error: DownloadError) => {
  toast.error(`${error}`, {
    position: 'bottom-center',
    autoClose: false,
    closeButton: true,
    draggable: false,
    className: 'border bg-error-fill border-error !p-4 !mb-4 !mx-2 rounded-lg',
    bodyClassName: 'bg-error-fill !m-0 text-grey-5 text-sm',
    transition: Slide,
  });
};

type BadgeProps = { label: string; variant: 'success' | 'tertiary' };

export const getBadgeProps = (
  loanState: string,
  isDebtSold?: boolean,
  debtSale?: { status: DebtSaleStatusEnum }
): BadgeProps => {
  if (isDebtSold) {
    return { label: debtSaleStatusMapper[DebtSaleStatusEnum.debt_sold], variant: 'tertiary' };
  }

  if (!debtSale && loanState === vaultLoanAccountStateMapper.ACTIVE) return { label: 'Active', variant: 'success' };

  switch (loanState) {
    case vaultLoanAccountStateMapper.ACTIVE:
      return { label: 'Active', variant: 'success' };
    case vaultLoanAccountStateMapper.CANCELLED:
      return { label: 'Cancelled', variant: 'tertiary' };
    case vaultLoanAccountStateMapper.PAID_OFF:
      return { label: 'Paid off', variant: 'success' };
    case vaultLoanAccountStateMapper.WRITTEN_OFF:
      return { label: 'Active', variant: 'success' };
    case vaultLoanAccountStateMapper.PARTIAL_WRITTEN_OFF:
      return { label: 'Active', variant: 'success' };
    default:
      return { label: 'Active', variant: 'success' };
  }
};
