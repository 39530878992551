import { BranchRecord } from '@harmoney/api-interfaces';
import { isProduction } from '@harmoney/ui-app-shell';
import { otherLivingSituationOptions, primaryLivingSituationOptions } from '@harmoney/ui-utils';
import { BranchEnum, EmploymentCodeEnum } from '@prisma/client';

const quickQuoteLivingSituationOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  ...primaryLivingSituationOptions,
  ...otherLivingSituationOptions.slice(1),
];

const quickQuoteEmploymentStatusOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: 'Professional (eg. doctor, solicitor)',
    value: EmploymentCodeEnum.PROFESSIONAL,
  },
  {
    label: 'Office-based employment',
    value: EmploymentCodeEnum.OFFICE,
  },
  {
    label: 'Trade-qualified employment',
    value: EmploymentCodeEnum.TRADE,
  },
  {
    label: 'Self-employed',
    value: EmploymentCodeEnum.SELF_EMPLOYED,
  },
  {
    label: 'Superannuation',
    value: EmploymentCodeEnum.RETIRED_SUPERANNUATION,
  },
  {
    label: 'Benefit',
    value: EmploymentCodeEnum.BENEFIT_UNEMPLOYMENT,
  },
  {
    label: 'Student',
    value: EmploymentCodeEnum.STUDENT,
  },
  {
    label: 'Other',
    value: EmploymentCodeEnum.OTHERS,
  },
];

const loanProductOptions = [
  {
    label: 'Personal loan',
    value: 'personal-loan',
  },
  {
    label: 'Debt consolidation',
    value: 'debt-consolidation',
  },
];

const addressStates = {
  [BranchEnum.AU]: [
    {
      name: 'Australian Capital Territory',
      value: 'Australian Capital Territory',
    },
    {
      name: 'New South Wales',
      value: 'New South Wales',
    },
    {
      name: 'Northern Territory',
      value: 'Northern Territory',
    },
    {
      name: 'Queensland',
      value: 'Queensland',
    },
    {
      name: 'South Australia',
      value: 'South Australia',
    },
    {
      name: 'Tasmania',
      value: 'Tasmania',
    },
    {
      name: 'Victoria',
      value: 'Victoria',
    },
    {
      name: 'Western Australia',
      value: 'Western Australia',
    },
  ],
  [BranchEnum.NZ]: [],
};

const websiteUrl: BranchRecord<string> = {
  AU: isProduction() ? 'https://secure.harmoney.com.au' : 'https://secure.harmoneylabs.com.au',
  NZ: isProduction() ? 'https://secure.harmoney.co.nz' : 'https://secure.harmoneylabs.co.nz',
};

export {
  addressStates,
  loanProductOptions,
  quickQuoteEmploymentStatusOptions,
  quickQuoteLivingSituationOptions,
  websiteUrl,
};
