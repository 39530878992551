import { DisbursementDto } from '@harmoney/api-interfaces';
import { PaymentTypeEnum } from '@prisma/client';

type Props = {
  disbursal: DisbursementDto;
};

export function CurrentBankDetails({ disbursal }: Props) {
  return (
    <>
      {disbursal.paymentType === PaymentTypeEnum.BPAY ? (
        <>
          <div className="flex flex-row justify-between">
            <span>Current BPAY biller code</span>
            <span>{disbursal.disbursalBankDetails.bpayBillerCode}</span>
          </div>
          <div className="flex flex-row justify-between">
            <span>Current BPAY reference</span>
            <span>{disbursal.disbursalBankDetails.reference}</span>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-row justify-between">
            <span>BSB number</span>
            <span>{disbursal.disbursalBankDetails.bsb}</span>
          </div>
          <div className="flex flex-row justify-between">
            <span>Account number</span>
            <span>{disbursal.disbursalBankDetails.accountNumber}</span>
          </div>
          <div className="flex flex-row justify-between">
            <span>Reference</span>
            <span>{disbursal.disbursalBankDetails.reference}</span>
          </div>
        </>
      )}
    </>
  );
}
