// Please sort it alphabetically in FORM_KEY enum AND form.service.ts switch case
export enum FORM_KEY {
  ASSET_UPDATE = 'ASSET_UPDATE',
  BANK_STATEMENT_REFERENCE_RETRIED_UPDATE = 'BANK_STATEMENT_REFERENCE_RETRIED_UPDATE',
  DEBT_CONSOLIDATION_DEBT_PAYMENT_UPDATE = 'DEBT_CONSOLIDATION_DEBT_PAYMENT_UPDATE',
  DEBT_CONSOLIDATION_USER_SELECTION_UPDATE = 'DEBT_CONSOLIDATION_USER_SELECTION_UPDATE',
  DISBURSEMENT_DECISION = 'DISBURSEMENT_DECISION',
  CREDIT_AUDIT_DECISION = 'CREDIT_AUDIT',
  DEBT_UPDATE = 'DEBT_UPDATE',
  DIRECT_DEBIT_BANK_ACCOUNT_UPDATE = 'DIRECT_DEBIT_BANK_ACCOUNT_UPDATE',
  DISBURSAL_BANK_ACCOUNT_UPDATE = 'DISBURSAL_BANK_ACCOUNT_UPDATE',
  HOUSEHOLD_UPDATE = 'HOUSEHOLD_UPDATE',
  INCOME_UPDATE = 'INCOME_UPDATE',
  INCOME_AND_EXPENSE_UPDATE = 'INCOME_AND_EXPENSE_UPDATE',
  LOAN_APPLICATION_SUBMIT = 'LOAN_APPLICATION_SUBMIT',
  LOAN_APPLICATION_AND_LOAN_PURPOSE_UPDATE = 'LOAN_APPLICATION_AND_LOAN_PURPOSE_UPDATE',
  LOAN_AGREEMENT_ACCEPT = 'LOAN_AGREEMENT_ACCEPT',
  LOAN_DISCLOSURE_ACCEPT = 'LOAN_DISCLOSURE_ACCEPT',
  LOAN_PURPOSE_AMOUNT_UPDATE = 'LOAN_PURPOSE_AMOUNT_UPDATE',
  PAYMENT_UPDATE = 'PAYMENT_UPDATE',
  PAYMENT_ARRANGEMENT = 'PAYMENT_ARRANGEMENT',
  USER_INFORMATION_UPDATE = 'USER_INFORMATION_UPDATE',
  USER_PHONE_NUMBER_UPDATE = 'USER_PHONE_NUMBER_UPDATE',
}

export interface CompleteTaskDto {
  taskId: string;
  variables?: { [key: string]: string | number | boolean | object };
  formKey?: FORM_KEY;
  formData?: { [key: string]: string | number | boolean | object };
  isInternalTask?: boolean;
}
