import { useMemo } from 'react';
import { ProcessId } from '@harmoney/api-interfaces';
import { useAppSelector, useGetAllUserInstancesByUserIdApplicationIdAndProcessIdsQuery } from '@harmoney/redux';
import { CollapsibleCard, Divider } from '@harmoney/ui-design-system';
import { truncateToTwoDecimalPlaces, valueOrNA } from '@harmoney/ui-utils';
import {
  AffordabilityModelEnum,
  AssetAndLiability,
  BranchEnum,
  FinancialProfile,
  IncomeAndExpense,
  NetworthSource,
  Prisma,
} from '@prisma/client';
import { get } from 'lodash';

import { DebtsTable } from './Affordability/DebtsTable';
import { ExpensesTable } from './Affordability/ExpensesTable';
import { IncomeTable } from './Affordability/IncomeTable';
import PartnerShareExpensesDetails from './Affordability/PartnerShareExpensesDetails';
import { Field } from './shared';
interface AffordabilityDetailsProps {
  affordabilityData: Prisma.JsonValue;
  rootData: Prisma.JsonValue;
  financialProfileData: FinancialProfile & {
    asset_and_liability: (AssetAndLiability & {
      networthSource: NetworthSource;
    })[];
    income_and_expense: (IncomeAndExpense & {
      networthSource: NetworthSource;
    })[];
  };
}

export type RowData = {
  title: string;
  declared?: string;
  bankStatement?: string;
  benchmark?: string;
  acceptedValue: string;
};

export const AffordabilityDetails = ({
  affordabilityData,
  rootData,
  financialProfileData,
}: AffordabilityDetailsProps) => {
  const userId = affordabilityData['userId'];
  const branch = affordabilityData['degreesOfFreedom']['branch'];
  const applicationId = affordabilityData['loanApplicationId'];
  const token = useAppSelector((state) => state.accessToken.value);
  const { data: userInstanceDataForOffers, isLoading } = useGetAllUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: [
        ProcessId.COMPARE_OFFERS,
        ProcessId.COMPARE_OFFERS_V2,
        ProcessId.COMPARE_OFFERS_V3,
        ProcessId.COMPARE_OFFERS_V4,
        ProcessId.COMPARE_OFFERS_V5,
        ProcessId.COMPARE_OFFERS_V6,
      ],
    },
    { skip: !token || !applicationId || !userId }
  );

  const latestOfferData = useMemo(() => {
    if (!isLoading && userInstanceDataForOffers?.length > 0) {
      return userInstanceDataForOffers.reduce((prev, current) => (prev.createdAt > current.createdAt ? prev : current))
        .variables;
    }
  }, [userInstanceDataForOffers, isLoading]);

  const originalOfferData = useMemo(() => {
    if (!isLoading && userInstanceDataForOffers && userInstanceDataForOffers.length > 1) {
      return userInstanceDataForOffers.reduce((prev, current) => (prev.createdAt < current.createdAt ? prev : current))
        .variables;
    }
  }, [userInstanceDataForOffers, isLoading]);

  const checkIfUserEligibleForSharedExpense = (model: AffordabilityModelEnum) => {
    if (model === AffordabilityModelEnum.hLoanWithSharedExpense) {
      return (
        latestOfferData[`sharedExpenseResultForHLoan`]?.['sharedAccommodationExpenseEligibility']?.['isEligible'] ||
        latestOfferData[`sharedExpenseResultForHLoan`]?.['sharedLivingExpenseEligibility']?.['isEligible'] ||
        latestOfferData[`sharedExpenseResultForHLoan`]?.['sharedMortgageExpenseEligibility']?.['isEligible']
      );
    }

    if (model === AffordabilityModelEnum.hMoneyWithSharedExpense) {
      return (
        latestOfferData[`sharedExpenseResultForHMoney`]?.['sharedAccommodationExpenseEligibility']?.['isEligible'] ||
        latestOfferData[`sharedExpenseResultForHMoney`]?.['sharedLivingExpenseEligibility']?.['isEligible'] ||
        latestOfferData[`sharedExpenseResultForHMoney`]?.['sharedMortgageExpenseEligibility']?.['isEligible']
      );
    }
  };

  const renderUmi = (key: string) => {
    return (
      <div className="flex flex-col">
        {latestOfferData && (
          <span className={`${(latestOfferData?.[key] as number) < 0 ? 'text-error' : ''}`}>
            {truncateToTwoDecimalPlaces(get(latestOfferData, key) as number)}
          </span>
        )}
        {originalOfferData && (
          <span className="text-sm text-grey-3">
            Original value: {truncateToTwoDecimalPlaces(get(originalOfferData, key) as number)}
          </span>
        )}
      </div>
    );
  };

  const calculateMaxRatio = ({
    affordabilityData,
    ratio1Key,
    ratio2Key,
    minRatioKey,
  }: {
    affordabilityData: Prisma.JsonValue;
    ratio1Key: string;
    ratio2Key: string;
    minRatioKey: string;
  }) => {
    if (
      !affordabilityData[ratio1Key] ||
      !affordabilityData[ratio2Key] ||
      !affordabilityData['sharedExpenseInput']['configuration'][minRatioKey]
    )
      return 'N/A';
    const maxRatio = Math.max(affordabilityData[ratio1Key], affordabilityData[ratio2Key]);
    const finalRatio = maxRatio < affordabilityData[minRatioKey] ? affordabilityData[minRatioKey] : maxRatio;
    return `${finalRatio.toFixed(2)}%`;
  };

  return (
    <CollapsibleCard title="Affordability" className="mb-6" defaultOpen={false}>
      <div className="flex flex-col gap-2 py-4">
        <div className="grid grid-cols-2 px-4">
          {latestOfferData ? (
            <Field className="space-x-4">
              <span className="font-medium">Affordability Model Used</span>
              <span>{latestOfferData?.['selectedOffer']}</span>
            </Field>
          ) : (
            <div className="space-y-1">
              <Field className="space-x-4">
                <span className="font-medium">Affordability Assessment Model Used</span>
                <span>{affordabilityData['degreesOfFreedom']['affordabilityModel']?.assessmentProcessId}</span>
              </Field>
              <Field className="space-x-4">
                <span className="font-medium">Affordability Calculation Model Used</span>
                <span>{affordabilityData['degreesOfFreedom']['affordabilityModel']?.calculationProcessId}</span>
              </Field>
            </div>
          )}
          {latestOfferData ? (
            <div className="space-y-1">
              {latestOfferData?.['umiHLoan'] && (
                <Field className="space-x-4">
                  <span>HLoan UMI</span>
                  {renderUmi('umiHLoan')}
                </Field>
              )}
              {latestOfferData?.['umiHMoney'] && (
                <Field className="space-x-4">
                  <span>HMoney UMI</span>
                  {renderUmi('umiHMoney')}
                </Field>
              )}
              <Field className="space-x-4">
                <span>HLoan with shared expense UMI</span>
                {latestOfferData &&
                latestOfferData?.['sharedExpenseResultForHLoan'] &&
                checkIfUserEligibleForSharedExpense(AffordabilityModelEnum.hLoanWithSharedExpense) ? (
                  renderUmi('sharedExpenseResultForHLoan.umi')
                ) : (
                  <span className="font-normal">N/A</span>
                )}
              </Field>
              <Field className="space-x-4">
                <span>HMoney with shared expense UMI</span>
                {latestOfferData &&
                latestOfferData?.['sharedExpenseResultForHMoney'] &&
                checkIfUserEligibleForSharedExpense(AffordabilityModelEnum.hMoneyWithSharedExpense) ? (
                  renderUmi('sharedExpenseResultForHMoney.umi')
                ) : (
                  <span className="font-normal">N/A</span>
                )}
              </Field>
            </div>
          ) : (
            <div>
              <span className="mx-4 grid grid-cols-4">
                <span className="col-span-1 font-medium">UMI</span>
                <span className={`col-span-3 ${(affordabilityData['umi'] as number) < 0 ? 'text-error' : ''}`}>
                  {truncateToTwoDecimalPlaces(affordabilityData['umi'] as number)}
                </span>
              </span>
            </div>
          )}
        </div>
        <Divider className="text-grey-2 px-4 my-2" />
        {branch === BranchEnum.NZ ? (
          <PartnerShareExpensesDetails affordabilityData={affordabilityData} />
        ) : (
          <div className="px-4">
            <p className="mb-4 font-medium">Shared contribution (%)</p>
            <div className="grid grid-cols-2 odd:border-r-grey-1">
              <div className="space-y-1">
                <Field className="space-x-4">
                  <span>Income Split</span>
                  <span>
                    {(affordabilityData['declaredToHouseholdIncomeRatio'] as number)
                      ? `${(affordabilityData['declaredToHouseholdIncomeRatio'] as number).toFixed(2)}%`
                      : 'N/A'}
                  </span>
                </Field>
                <Field className="space-x-4">
                  <span>Accommodation Expenses Split</span>
                  <span>
                    {(affordabilityData['sharedAccommodationExpenseRatio'] as number)
                      ? `${(affordabilityData['sharedAccommodationExpenseRatio'] as number).toFixed(2)}%`
                      : 'N/A'}
                  </span>
                </Field>
                <Field className="space-x-4">
                  <span>Final Accommodation Expenses Split</span>
                  <span>
                    {calculateMaxRatio({
                      affordabilityData,
                      ratio1Key: 'declaredToHouseholdIncomeRatio',
                      ratio2Key: 'sharedAccommodationExpenseRatio',
                      minRatioKey: 'minIncomeAccommodationExpenseRatio',
                    })}
                  </span>
                </Field>
              </div>
              <div className="space-y-1">
                <Field className="space-x-4">
                  <span>Primary Income Source</span>
                  <span>{valueOrNA(affordabilityData?.['primaryIncomeSource']?.['source'])}</span>
                </Field>
                <Field className="space-x-4">
                  <span>Living Expenses Split</span>
                  <span>
                    {(affordabilityData['sharedLivingExpenseRatio'] as number)
                      ? `${(affordabilityData['sharedLivingExpenseRatio'] as number).toFixed(2)}%`
                      : 'N/A'}
                  </span>
                </Field>
                <Field className="space-x-4">
                  <span>Final Living Expenses Split</span>
                  <span>
                    {calculateMaxRatio({
                      affordabilityData,
                      ratio1Key: 'declaredToHouseholdIncomeRatio',
                      ratio2Key: 'sharedLivingExpenseRatio',
                      minRatioKey: 'minIncomeLivingExpenseRatio',
                    })}
                  </span>
                </Field>
              </div>
            </div>
          </div>
        )}

        <Divider className="text-grey-2 px-4 my-2" />
        <div className="px-4 space-y-4">
          <span className="text-xs italic">*All values monthly</span>
          <IncomeTable affordabilityData={affordabilityData} rootData={rootData} />
          <ExpensesTable affordabilityData={affordabilityData} rootData={rootData} />
          <DebtsTable affordabilityData={affordabilityData} financialProfileData={financialProfileData} />
        </div>
      </div>
    </CollapsibleCard>
  );
};
