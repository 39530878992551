import { NetworthSourceDto, NetworthSourceEnum } from '@harmoney/api-interfaces';

export const getIncomeDetailsByNetWorthSourceId = (
  incomeTypes: NetworthSourceDto[],
  networthSourceId: NetworthSourceEnum
): NetworthSourceDto => {
  return incomeTypes.find((income) => income.id === Number(networthSourceId));
};

export const networthSourceOrderMap = {
  [NetworthSourceEnum.INCOME_SALARY_WAGES_ID]: 20,
  [NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID]: 19,
  [NetworthSourceEnum.INCOME_RENT_ID]: 18,
  [NetworthSourceEnum.INCOME_BENEFIT_ID]: 17,
  [NetworthSourceEnum.INCOME_SPOUSAL_SUPPORT_ID]: 16,
  [NetworthSourceEnum.INCOME_PENSION_ID]: 15,
  [NetworthSourceEnum.INCOME_INTEREST_DIVIDEND_ID]: 14,
  [NetworthSourceEnum.INCOME_RETIREMENT_FUNDS_ID]: 13,
  [NetworthSourceEnum.INCOME_STUDENT_PAYMENTS_ID]: 12,
  [NetworthSourceEnum.INCOME_OTHER_ID]: 11,
  [NetworthSourceEnum.INCOME_NO_ID]: 10,
};

export const correctOrderOfIncomeTypes = (
  incomeTypes: NetworthSourceDto[],
  orderMap: { [key: number]: number }
): NetworthSourceDto[] => {
  return [...incomeTypes].sort((a, b) => {
    const orderA = orderMap[a.id] ?? a.id;
    const orderB = orderMap[b.id] ?? b.id;
    return orderB - orderA;
  });
};
