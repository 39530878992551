import { PreQualifiedQuoteDetails } from '@harmoney/api-interfaces';
import { Badge, Card, Divider } from '@harmoney/ui-design-system';
import { DATE_FORMAT_WITH_MONTH } from '@harmoney/ui-utils';
import { dayjsSydney, formatCurrency } from '@harmoney/utilities';
import { RepaymentFrequencyEnum } from '@prisma/client';

interface QuickQuoteCardProps {
  quoteDetails: PreQualifiedQuoteDetails;
}

function getWeeklyRepayment(monthlyRepayment: number) {
  return formatCurrency(Math.floor((monthlyRepayment * 12) / 52));
}

function getLoanTermInYears(loanTermInMonths: number) {
  return loanTermInMonths / 12;
}

export const QuickQuoteCard = ({ quoteDetails }: QuickQuoteCardProps) => {
  const { interestRate, loanAmount, loanTerm, monthlyRepayment, quoteExpiryDate } = quoteDetails;
  return (
    <Card className="relative w-full !px-0 !py-4 !mb-6">
      <Badge label="Approximate" className="px-1 py-0 ml-4 text-xs item-center justify-center" variant="tertiary" />
      <div className="flex flex-row items-start justify-between p-4">
        <div className="flex flex-col flex-1 items-start">
          <span className="text-sm">Loan amount</span>
          <span className="font-bold text-2xl">{formatCurrency(loanAmount)}</span>
        </div>
        <div className="flex flex-col flex-1 items-start">
          <span className="text-sm">You&rsquo;ll pay about</span>
          <span className="font-bold text-2xl">
            {getWeeklyRepayment(monthlyRepayment)}{' '}
            <span className="font-bold text-xl">{RepaymentFrequencyEnum.weekly}</span>
          </span>
          <div className="text-xs text-balance flex flex-col gap-0 ">On a {getLoanTermInYears(loanTerm)} year term</div>
        </div>
      </div>
      <Divider className="text-grey-2" />
      <div className="p-4 pb-0 py-4 flex flex-col flex-1 justify-center gap-2">
        <Badge
          label={<span>Guaranteed until {dayjsSydney(quoteExpiryDate).format(DATE_FORMAT_WITH_MONTH)}</span>}
          className="px-1 py-0 text-xs"
        />
        <span className="text-sm">{interestRate}% fixed interest p.a</span>
      </div>
    </Card>
  );
};
