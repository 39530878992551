import { useAuth } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button, Card, IconV2 } from '@harmoney/ui-design-system';

import { getBenefitsList } from '../pre-qualified-quote/utils';

interface Props {
  email: string;
}

const NextStepsList = () => {
  const benefits = getBenefitsList();
  return (
    <>
      <span className="text-sm font-medium">Your next steps:</span>
      <div className="flex flex-col gap-1">
        {benefits.map((benefit) => (
          <div key={benefit.title} className="flex flex-row gap-2 items-center">
            <IconV2 icon={benefit.icon} className="size-8" />
            <span className="text-xs font-normal">{benefit.title}</span>
          </div>
        ))}
      </div>
    </>
  );
};

const QuickQuoteBenefitsCard = ({ email }: Props) => {
  const { login } = useAuth();

  const handleClick = async () => {
    await login({
      authorizationParams: {
        login_hint: email,
        screen_hint: 'signup',
      },
      appState: { returnTo: `${window.location.origin}/` },
    });
  };

  return (
    <Card className="relative w-full py-10 flex flex-col flex-1 gap-4">
      <span className="text-sm font-medium">Complete our form to finalise your quote.</span>
      <NextStepsList />
      <Button
        className="w-full"
        variant="primary"
        alignIcon="end"
        isFullWidth
        icon={<ArrowCircleRightIcon size="large" />}
        type="submit"
        onClick={handleClick}
      >
        Sign Up
      </Button>
    </Card>
  );
};

export { NextStepsList, QuickQuoteBenefitsCard };
