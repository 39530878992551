import React from 'react';
import { LoanApplicationQueryResponse, useAppSelector, useGetDebtSalePurchaserQuery } from '@harmoney/redux';
import { Badge, Divider } from '@harmoney/ui-design-system';
import {
  formatCurrency,
  isLoanArrearsCancelled,
  isLoanArrearsPaidOff,
  isVaultLoanStateWrittenOff,
} from '@harmoney/utilities';
import {
  DebtSaleStatusEnum,
  LoanApplicationStatusEnum,
  LoanArrearsStatusEnum,
  VaultLoanAccountStateEnum,
} from '@prisma/client';
import dayjs from 'dayjs';

import { DebtSoldAlert } from '../../loan';

type Props = {
  application: LoanApplicationQueryResponse;
};

const getBadgeProps = (
  application: LoanApplicationQueryResponse
): { label: string; variant: 'tertiary' | 'success' } => {
  const { status, loanArrearsStatus } = application;
  if (isLoanArrearsPaidOff(status, loanArrearsStatus) || application.loanState === VaultLoanAccountStateEnum.PAID_OFF)
    return { label: 'Paid off loan', variant: 'success' };
  if (isLoanArrearsCancelled(loanArrearsStatus) || application.loanState === VaultLoanAccountStateEnum.CANCELLED)
    return { label: 'Cancelled loan', variant: 'tertiary' };
  if (isVaultLoanStateWrittenOff(application.loanState)) {
    return { label: 'Written off loan', variant: 'tertiary' };
  }
  switch (status) {
    case 'quote_expired':
      return { label: 'Expired quote', variant: 'tertiary' };
    case 'quote_cancelled':
      return { label: 'Cancelled quote', variant: 'tertiary' };
    case 'quote_unsuccessful':
      return { label: 'Unsuccessful quote', variant: 'tertiary' };
    case 'application_expired':
      return { label: 'Expired application', variant: 'tertiary' };
    case 'application_cancelled':
      return { label: 'Cancelled application', variant: 'tertiary' };
    case 'application_unsuccessful':
      return { label: 'Unsuccessful application', variant: 'tertiary' };
    default:
      return { label: '', variant: 'tertiary' };
  }
};

const getAmountProps = (application: LoanApplicationQueryResponse) => {
  const { status, loanArrearsStatus } = application;
  if (
    status === LoanApplicationStatusEnum.application_successful ||
    loanArrearsStatus === LoanArrearsStatusEnum.DEBT_SOLD
  ) {
    return { label: 'Loan amount', value: application.fundedAmount + application.establishmentFee };
  } else {
    return { label: 'Requested amount', value: application.requestedAmount };
  }
};

export const HistoryLoanDetails = ({ application }: Props) => {
  const accessToken = useAppSelector((state) => state.accessToken.value);
  const badge = getBadgeProps(application);
  const amount = getAmountProps(application);
  const { data: debtSale } = useGetDebtSalePurchaserQuery(application?.id, {
    skip: !application?.id || !accessToken,
  });

  const isDebtSold =
    application?.loanArrearsStatus === LoanArrearsStatusEnum.DEBT_SOLD &&
    debtSale?.status === DebtSaleStatusEnum.debt_sold;

  return (
    <div key={application?.id} className="flex flex-col gap-4 p-4">
      {isDebtSold ? (
        <DebtSoldAlert purchaser={debtSale?.purchaser} />
      ) : (
        <Badge label={badge.label} variant={badge.variant} />
      )}
      <div className="flex flex-row justify-between text-sm">
        <span>Loan purpose</span>
        <span className="font-medium">
          {application?.loanApplicationPurposes?.[0]?.loanPurpose?.displayName || '-'}
        </span>
      </div>
      <Divider className="text-grey-1 !m-0" />
      <div className="flex flex-row justify-between text-sm">
        <span>{amount?.label}</span>
        <span className="font-medium">{formatCurrency(amount?.value)}</span>
      </div>
      <Divider className="text-grey-1 !m-0" />
      <div className="flex flex-row justify-between text-sm">
        <span>Start date</span>
        <span className="font-medium">{dayjs(application.createdAt).format('DD MMMM YYYY')}</span>
      </div>
    </div>
  );
};
