import { BranchRecord } from '@harmoney/api-interfaces';
import { AU_FOOTER_TEXT_INFO, NZ_FOOTER_INFO } from '@harmoney/ui-design-system';
import { BranchEnum } from '@prisma/client';

interface FooterProps {
  branch: BranchEnum;
}

export const Footer = ({ branch }: FooterProps) => {
  const footerContent: BranchRecord<string> = {
    [BranchEnum.AU]: AU_FOOTER_TEXT_INFO,
    [BranchEnum.NZ]: NZ_FOOTER_INFO,
  };

  return (
    <footer className="top-full bottom-0 bg-inherit p-4 mx-auto max-w-xl my-auto">
      <p className="text-xs text-center text-grey-4">{footerContent[branch]}</p>
    </footer>
  );
};
