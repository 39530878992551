import { ResidentialStatusEnum } from '@prisma/client';

export const residentialStatusMapper: Record<ResidentialStatusEnum, string> = {
  [ResidentialStatusEnum.HOME_OWNER]: 'home owner',
  [ResidentialStatusEnum.RENTING]: 'renting',
  [ResidentialStatusEnum.BOARDER]: 'boarder',
  [ResidentialStatusEnum.LIVE_WITH_PARENTS]: 'live with parents',
  [ResidentialStatusEnum.GOVERNMENT_HOUSING]: 'government housing',
  [ResidentialStatusEnum.COMPANY_HOUSING]: 'company housing',
  [ResidentialStatusEnum.OTHER]: 'other',
};

export const reverseResidentialStatusMapper: Record<string, ResidentialStatusEnum> = {
  'home owner': ResidentialStatusEnum.HOME_OWNER,
  renting: ResidentialStatusEnum.RENTING,
  boarder: ResidentialStatusEnum.BOARDER,
  'live with parents': ResidentialStatusEnum.LIVE_WITH_PARENTS,
  'government housing': ResidentialStatusEnum.GOVERNMENT_HOUSING,
  'company housing': ResidentialStatusEnum.COMPANY_HOUSING,
};
