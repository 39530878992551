import { useMemo } from 'react';
import { DisbursementDto } from '@harmoney/api-interfaces';
import { Card } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

type Props = {
  disbursal: DisbursementDto;
};

export default function DisbursedAccount({ disbursal }: Props) {
  const networthSourceName = useMemo(() => {
    return disbursal?.networthSource?.name.charAt(0).toUpperCase() + disbursal?.networthSource?.name.slice(1);
  }, [disbursal?.networthSource?.name]);

  return (
    <Card>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <span>Paid to</span>
          <strong>{disbursal.paidTo}</strong>
        </div>
        <div className="flex flex-row justify-between">
          <span>Type</span>
          <strong>{networthSourceName}</strong>
        </div>
        <div className="flex flex-row justify-between">
          <span>Amount disbursed</span>
          <strong>{formatCurrency(disbursal.amount ?? 0)}</strong>
        </div>
      </div>
    </Card>
  );
}
