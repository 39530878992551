import React, { useMemo } from 'react';
import { PreQualifiedQuoteData } from '@harmoney/api-interfaces';
import { LoanApplicationQueryResponse, UserInstanceQueryResponse } from '@harmoney/redux';
import { Divider, getIconComponent } from '@harmoney/ui-design-system';
import {
  convertMonthsToYears,
  FrequencyFormatter,
  isApplication,
  isApplicationInProgress,
  isFromPQ,
  isPQFromClearScore,
  isPQFromHarmoney,
  isQuoteInProgress,
  isQuoteInReview,
  isQuoteOffered,
  isQuoteOfferedAndDebtConConsolidated,
  isValidApplication,
} from '@harmoney/ui-utils';
import { formatCurrency, isApplicationSuccessful, isDebtCon, isPersonalLoan } from '@harmoney/utilities';
import classNames from 'classnames';
import { kebabCase } from 'lodash';

enum InterestRateMessagesEnum {
  PENDING = 'Pending',
  COMPLETE_QUOTE = 'Complete your quote to get your interest rate.',
}

enum AmountMessageEnum {
  COMPLETE_QUOTE = 'Complete your quote to see how much you could consolidate.',
}

type AmountLabelDisplayType = 'Amount requested' | 'Borrow up to' | 'Amount' | 'Consolidate up to';

const AmountAndInterestRateDetails = ({
  showDetails,
  amountConfig,
  interestRateConfig,
}: {
  showDetails: boolean;
  amountConfig: {
    label: string;
    value: number | AmountMessageEnum;
    show: boolean;
  };
  interestRateConfig: {
    label: string;
    value: number | InterestRateMessagesEnum;
    show: boolean;
  };
}) => {
  if (!showDetails) return null;

  return (
    <>
      {(amountConfig.show || interestRateConfig.show) && <Divider className="text-grey-1 my-4" />}
      <div className="ml-4 grid grid-cols-2 w-full">
        {amountConfig.show && (
          <div className="flex flex-col w-full">
            <span>{amountConfig.label}</span>
            {Object.values(AmountMessageEnum).includes(amountConfig.value as AmountMessageEnum) ? (
              <span className="text-grey-4 text-sm font-light">{amountConfig.value}</span>
            ) : (
              <span className="text-xl font-medium">{formatCurrency(amountConfig.value as number)}</span>
            )}
          </div>
        )}
        {interestRateConfig.show && (
          <div
            className={classNames('flex flex-col w-full', {
              'ml-6': amountConfig.show,
            })}
          >
            <span>{interestRateConfig.label}</span>
            {Object.values(InterestRateMessagesEnum).includes(interestRateConfig.value as InterestRateMessagesEnum) ? (
              <span className="text-grey-4 text-sm font-light">{interestRateConfig.value}</span>
            ) : (
              <span className="text-xl font-medium">{interestRateConfig.value}%</span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

type Props = {
  loanApplication: LoanApplicationQueryResponse;
  preQualifiedQuoteData?: PreQualifiedQuoteData;
  userInstances?: UserInstanceQueryResponse;
  interestRate?: number;
};

export const LoanDetails = ({ loanApplication, userInstances, preQualifiedQuoteData, interestRate }: Props) => {
  const amountToDisplay: {
    display: AmountLabelDisplayType;
    amount: number | AmountMessageEnum.COMPLETE_QUOTE;
  } = useMemo(() => {
    if (!loanApplication) return { display: 'Amount requested', amount: 0 };
    if (
      isPQFromHarmoney(loanApplication) &&
      isDebtCon(loanApplication?.loanProduct?.name) &&
      isQuoteInProgress(loanApplication?.status)
    )
      return { display: 'Amount', amount: AmountMessageEnum.COMPLETE_QUOTE };
    if (isApplication(loanApplication?.status) && loanApplication?.fundedAmount)
      return {
        display: 'Amount',
        amount: loanApplication?.fundedAmount + loanApplication?.establishmentFee,
      };
    if (userInstances && isQuoteOfferedAndDebtConConsolidated(loanApplication, userInstances)) {
      return {
        display: 'Amount',
        amount: loanApplication?.fundedAmount,
      };
    }
    if (isQuoteOffered(loanApplication?.status) && loanApplication?.maximumBorrowingLimit) {
      return {
        display: isDebtCon(loanApplication?.loanProduct?.name) ? 'Consolidate up to' : 'Borrow up to',
        amount: loanApplication?.quotePresentedAmount || loanApplication?.maximumBorrowingLimit,
      };
    }
    if (isQuoteOffered(loanApplication?.status) && loanApplication?.originalLoanApplicationId) {
      return {
        display: 'Borrow up to',
        amount: loanApplication?.quotePresentedAmount,
      };
    }
    return {
      display: 'Amount requested',
      amount: loanApplication?.requestedAmount,
    };
  }, [loanApplication, userInstances]);

  const loanApplicationPurposeName = loanApplication?.loanApplicationPurposes?.[0]?.loanPurpose?.name;
  const loanApplicationCustomName = loanApplication?.displayName;
  const loanProductName = loanApplication?.loanProduct?.name;

  const isQuoteStatusFinalized =
    !isQuoteInReview(loanApplication?.status) && !isQuoteInProgress(loanApplication?.status);

  const isLoanFromPQ = isFromPQ(loanApplication);

  return (
    <div className="border-b-grey-2 border-b p-4">
      {loanApplicationPurposeName && (
        <>
          <div className="flex flex-row items-center space-x-2">
            <span className="flex items-center font-medium">
              {loanApplicationPurposeName && getIconComponent(kebabCase(loanApplicationPurposeName), 'secondary')}
              <span className="ml-2 flex flex-col">
                {isPersonalLoan(loanProductName) && <span className="font-normal text-sm">{loanProductName}</span>}
                <span
                  className={classNames({
                    'text-sm font-normal': Boolean(loanApplicationCustomName),
                  })}
                >
                  {loanApplication?.loanApplicationPurposes
                    ?.map((purpose) => purpose?.loanPurpose?.displayName)
                    .join(' + ')}
                </span>
                {loanApplicationCustomName && <span>{loanApplication?.displayName}</span>}
                {isApplicationSuccessful(loanApplication?.status) && (
                  <span style={{ color: 'grey', fontSize: 'regular' }}>{loanApplication.businessKey}</span>
                )}
              </span>
            </span>
          </div>
        </>
      )}
      <AmountAndInterestRateDetails
        showDetails={
          isLoanFromPQ ||
          isPersonalLoan(loanApplication?.loanProduct?.name) ||
          (isDebtCon(loanApplication?.loanProduct?.name) &&
            (isValidApplication(loanApplication?.status) || isQuoteOffered(loanApplication.status)))
        }
        amountConfig={{
          label: amountToDisplay.display,
          value: amountToDisplay.amount,
          show: (() => {
            /* ClearScore PQ FLOW */
            if (isPQFromClearScore(loanApplication)) {
              return (
                isPersonalLoan(loanApplication?.loanProduct?.name) ||
                (isDebtCon(loanApplication?.loanProduct?.name) && isQuoteStatusFinalized)
              );
            }

            /* NON ClearScore PQ FLOW */
            return true;
          })(),
        }}
        interestRateConfig={{
          label: 'Interest rate',
          value: (() => {
            /* ClearScore PQ FLOW */
            if (isPQFromClearScore(loanApplication)) {
              const isRatioA = preQualifiedQuoteData?.pqInterestRateExperiment === 'A';
              const shouldUseInterestRateValue =
                isQuoteOffered(loanApplication?.status) ||
                isApplicationSuccessful(loanApplication?.status) ||
                isApplicationInProgress(loanApplication?.status);

              if (isRatioA || shouldUseInterestRateValue) return interestRate;
              return isQuoteInReview(loanApplication?.status)
                ? InterestRateMessagesEnum.PENDING
                : InterestRateMessagesEnum.COMPLETE_QUOTE;
            }

            /* NON ClearScore PQ FLOW */
            if (interestRate && !isQuoteInReview(loanApplication?.status)) return interestRate;

            return isQuoteInReview(loanApplication?.status)
              ? InterestRateMessagesEnum.PENDING
              : InterestRateMessagesEnum.COMPLETE_QUOTE;
          })(),
          show: (() => {
            /* ClearScore PQ FLOW */
            const isRatioA = preQualifiedQuoteData?.pqInterestRateExperiment === 'A';
            if (isPQFromClearScore(loanApplication) && !isRatioA) {
              if (isDebtCon(loanApplication?.loanProduct?.name)) return isQuoteStatusFinalized;
              return true;
            }

            /* NON ClearScore PQ FLOW */
            return true;
          })(),
        }}
      />
      {isValidApplication(loanApplication?.status) && (
        <>
          <Divider className="text-grey-1 my-4" />
          <div className="ml-4 grid grid-cols-2">
            <div className="col-span-1 flex flex-col items-stretch justify-start space-y-0">
              <span>Repayment</span>
              {loanApplication?.repaymentInfo?.recurringRepaymentAmount &&
                !isNaN(loanApplication?.repaymentInfo?.recurringRepaymentAmount) && (
                  <span className="text-xl font-medium">
                    {formatCurrency(loanApplication?.repaymentInfo?.recurringRepaymentAmount)}
                    <span className="text-sm font-normal">
                      /{FrequencyFormatter(loanApplication?.repaymentInfo?.frequency)}
                    </span>
                  </span>
                )}
            </div>
            <div className="col-span-1 flex flex-col items-stretch justify-center space-y-0 ml-6">
              <span>Term</span>
              <span className="text-xl font-medium">{convertMonthsToYears(loanApplication?.termInMonths)}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
