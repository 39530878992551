import Select, { components, GroupBase, Props } from 'react-select';
import clsx from 'classnames';
import Fuse from 'fuse.js';

import { useFormContext } from '../Form';
import { HelpText } from '../HelpText';
import { IconV2 } from '../Icon';
import { ValidationMessage } from '../ValidationMessage';

export interface AutocompleteProps {
  /**
   * Callback triggered when an option is selected
   */
  onSelectSuggestion: (value: any) => any;
  /**
   * Placeholder for the autocomplete input
   */
  placeholder?: string;
  /**
   * Help text for the autocomplete input
   */
  helpText?: string;
  /**
   * Icon to be displayed on the right-hand side of the autocomplete
   */
  icon?: string;
  /**
   * Icon color
   */
  iconColor?: string;
  /**
   * Boolean to render whether autocomplete is disabled or not
   */
  disabled?: boolean;
}

const controlStyles = {
  base: 'font-body leading-base text-grey-5 text-base border-grey-2 rounded-lg border bg-white !outline-0 p-2',
  focus: 'focus:border-secondary active:border-secondary focus-within:border-secondary',
  nonFocus: 'border-grey-2',
};
const placeholderStyles = 'text-grey-4';
const selectInputStyles = 'mx-0 my-2';
const valueContainerStyles = 'mx-2';
const indicatorsContainerStyles = 'p-2 gap-2';
const menuStyles = 'mt-1 border-none bg-white rounded-lg empty:invisible shadow-drop z-20';
const clearIndicatorStyles = 'text-grey-4';
const noOptionsMessageStyles = 'text-grey-4 p-4 bg-white rounded-lg';
const loadingMessageStyles = 'text-grey-4 p-4 bg-white rounded-lg';
const optionStyles = {
  base: 'p-4 flex cursor-pointer items-center justify-start border-b border-grey-2 last:border-none hover:bg-secondary-lighter-3 first:rounded-tl-lg first:rounded-tr-lg last:rounded-bl-lg last:rounded-br-lg',
  focus: 'bg-secondary-lighter-3',
};

export const Autocomplete = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  value,
  onInputChange,
  onSelectSuggestion,
  options = [],
  placeholder,
  helpText,
  name = '',
  icon,
  iconColor,
  disabled,
  ...restProps
}: Props<Option, IsMulti, Group> & AutocompleteProps) => {
  const { getFieldState, formState } = useFormContext();
  const { invalid } = getFieldState(name, formState);

  return (
    <div className={clsx('relative', restProps.className)}>
      {helpText && <HelpText>{helpText}</HelpText>}
      <Select
        {...restProps}
        isDisabled={disabled}
        className="rounded-lg"
        classNames={{
          control: ({ isFocused }) =>
            clsx(isFocused ? controlStyles.focus : controlStyles.nonFocus, controlStyles.base, {
              '!border-error hover:border-error': invalid,
            }),
          placeholder: () => placeholderStyles,
          input: () =>
            clsx(selectInputStyles, {
              '!border-error hover:border-error': invalid,
            }),
          valueContainer: () => valueContainerStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          option: ({ isFocused }) => clsx(isFocused && optionStyles.focus, optionStyles.base),
          menu: () => menuStyles,
          noOptionsMessage: () => noOptionsMessageStyles,
          loadingMessage: () => loadingMessageStyles,
        }}
        components={{
          DropdownIndicator: (props: any) => (
            <components.DropdownIndicator {...props}>
              <IconV2 icon={icon || ''} width={24} height={24} color={iconColor ?? '#8E8E8E'} />
            </components.DropdownIndicator>
          ),
        }}
        filterOption={(option, inputValue) => {
          // implement fuzzy match to options provided
          const fuse = new Fuse([option], {
            keys: ['label'],
            threshold: 0.3,
            findAllMatches: true,
          });

          const results = fuse.search(inputValue);
          return results.length > 0;
        }}
        onChange={onSelectSuggestion}
        onInputChange={onInputChange}
        options={options}
        placeholder={placeholder}
        unstyled
        value={value}
      />
      <ValidationMessage name={name} />
    </div>
  );
};
