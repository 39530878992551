import type {
  BankAccountDto,
  DirectDebitHistory,
  DisbursalBankAccountHistory,
  UpdateBankAccountDto,
  UpdateDisbursalBankAccountDto,
} from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const updateBankAccountApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDDBankAccountHistory: builder.query<DirectDebitHistory[], string>({
      query: (customerId) => ({
        url: `/update-bank-account/${customerId}/history`,
      }),
    }),
    updateDDBankAccount: builder.mutation<undefined, { data: UpdateBankAccountDto; customerId: string }>({
      query: ({ customerId, data }) => ({
        url: `/update-bank-account/${customerId}`,
        body: data,
        method: 'PATCH',
      }),
    }),
    getDDBankAccounts: builder.query<BankAccountDto[], string>({
      query: (customerId) => ({
        url: `/update-bank-account/${customerId}`,
        method: 'GET',
      }),
    }),

    updateDisbursalBankAccount: builder.mutation<
      undefined,
      { data: UpdateDisbursalBankAccountDto; loanApplicationId: string }
    >({
      query: ({ loanApplicationId, data }) => ({
        url: `/update-bank-account/disbursal/${loanApplicationId}`,
        body: data,
        method: 'PATCH',
      }),
    }),
    getDisbursalBankAccounts: builder.query<BankAccountDto[], string>({
      query: (customerId) => ({
        url: `/update-bank-account/disbursal/${customerId}`,
        method: 'GET',
      }),
    }),
    getDisbursalBankAccountHistory: builder.query<DisbursalBankAccountHistory[], string>({
      query: (loanApplicationId) => ({
        url: `/update-bank-account/${loanApplicationId}/history`,
      }),
    }),
  }),
});

export const {
  useGetDDBankAccountHistoryQuery,
  useUpdateDDBankAccountMutation,
  useGetDDBankAccountsQuery,
  useUpdateDisbursalBankAccountMutation,
  useGetDisbursalBankAccountsQuery,
  useGetDisbursalBankAccountHistoryQuery,
} = updateBankAccountApi;
