import { useRouter } from 'next/router';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  accountTypeToDebtType,
  FORM_KEY,
  Liability,
  NetworthSourceDto,
  NetworthSourceEnum,
  NetworthSourceKind,
} from '@harmoney/api-interfaces';
import { FinancialConfirmationSectionEnum, useFinancialConfirm, useFriendlyURL } from '@harmoney/hooks';
import {
  useAppDispatch,
  useAppSelector,
  useGetCreditFileLiabilitiesQuery,
  useGetLiabilitiesQuery,
  useGetLoanProductByTaskIdQuery,
  useGetNetworthSourcesQuery,
  useGetRefreshedLoansQuery,
  useGetUndisclosedDebtsQuery,
  useGetUserQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_DEBTS_PROVIDED, isProduction } from '@harmoney/ui-app-shell';
import {
  Alert,
  ArrowCircleRightIcon,
  Button,
  CollapsibleHeader,
  Divider,
  Form,
  IconV2,
  Label,
  useForm,
} from '@harmoney/ui-design-system';
import { capitalizeTitle } from '@harmoney/ui-utils';
import {
  convertStringToBoolean,
  formatCurrency,
  isBooleanStringTrue,
  isDebtCon,
  isHarmoneyStellare2DebtInCreditFile,
  isNonHarmoneyDebt,
  LiabilityProvider,
  LoanProductName,
} from '@harmoney/utilities';
import { DevTool } from '@hookform/devtools';
import { useScrollIntoView } from '@mantine/hooks';
import { AssetAndLiability, AssetAndLiabilityRecordOriginEnum, NetworthSource } from '@prisma/client';
import { isArray, isBoolean, isEmpty, lowerCase, map, omit } from 'lodash';

import { setDebtFormData } from '../../../redux/slice/debt-form';
import { CommonProps } from '../../common-props';

import { DebtItem } from './DebtItem/DebtItem';
import { BlockSingleHmyLoanDebtConModal } from './BlockSingleHmyLoanDebtConModal';
import { FormSchema, getFormSchemaByProductNameAndRelationshipStatus } from './form-config';
import { HarmoneyDebtCard } from './HarmoneyDebtCard';
import { initialDebtFormValues } from './initialDebtFormValues';
import {
  convertBooleanToString,
  DebtCode,
  DebtTypeIcon,
  NETWORTH_CODE_CAR_LOAN,
  sortedDebtCode,
  transformSubmittedData,
} from './util';

const NETWORTH_CODE_NO_DEBT = 'no_debt';

export function UpdateDebt({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const router = useRouter();
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ offset: 100 });
  const [elementId, setElementId] = useState(null);
  const userId = useAppSelector((state) => state.userId.value);
  const { data: user } = useGetUserQuery();
  const debtFormData = useAppSelector((state) => state.debtForm.data);
  const dispatch = useAppDispatch();
  const { confirmSection } = useFinancialConfirm();
  const [isSubmitting, setIsSubmitting] = useState({ update: false, cancel: false });
  const [isBlockSingleHmyLoanDebtConModalOpen, setIsBlockSingleHmyLoanDebtConModalOpen] = useState(false);

  const relationshipStatus = user?.relationshipStatus;

  const { data: variables } = useGetVariablesQuery(taskId, { skip: !taskId });

  const { data: debtTypes, isLoading } = useGetNetworthSourcesQuery(NetworthSourceKind.LIABILITY);
  const { data: loanProduct, isLoading: isLoanProductInfoLoading } = useGetLoanProductByTaskIdQuery(taskId, {
    refetchOnMountOrArgChange: true,
  });
  const { data: refreshedLoans } = useGetRefreshedLoansQuery(variables?.loanApplicationId?.toString(), {
    skip: !variables?.loanApplicationId || !isDebtCon(loanProduct?.name),
    refetchOnMountOrArgChange: true,
  });
  const {
    data: consolidatedLiabilities,
    isSuccess,
    isLoading: isPrefillLoading,
  } = useGetCreditFileLiabilitiesQuery(variables?.financialProfileId as string, {
    skip: !variables?.financialProfileId,
  });

  const loanApplicationId = variables?.loanApplicationId?.toString();
  const { data: undisclosedDebts, isLoading: isUndisclosedDebtsLoading } = useGetUndisclosedDebtsQuery(
    loanApplicationId,
    { skip: !loanApplicationId }
  );

  const { data: existingLiabilities, refetch: refetchLiabilities } = useGetLiabilitiesQuery(
    variables?.financialProfileId as string,
    {
      skip: !variables?.financialProfileId || (isDebtCon(loanProduct?.name) && !refreshedLoans),
      refetchOnMountOrArgChange: true,
    }
  );

  const [existingHarmoneyLoans, existingOtherLoans] = useMemo(() => {
    if (existingLiabilities === undefined) return [[], []];
    const existingHarmoneyLoans = existingLiabilities.filter(
      (liability) =>
        liability.networthSourceId === NetworthSourceEnum.LIABILITY_PERSONAL_LOAN_ID &&
        liability.provider.startsWith(LiabilityProvider.Harmoney)
    );
    const existingOtherLoans = existingLiabilities.filter(isNonHarmoneyDebt).map((liability) => {
      return convertBooleanToString(liability);
    });

    return [existingHarmoneyLoans, existingOtherLoans];
  }, [existingLiabilities]);

  const [primaryDebtTypes, setPrimaryDebtTypes] = useState<NetworthSourceDto[]>([]);
  const [secondaryDebtTypes, setSecondaryDebtTypes] = useState<(NetworthSourceDto & { isDisabled: boolean })[]>([]);
  const [otherDebtOpen, setOtherDebtOpen] = useState(false);
  const [noDebtData, setNoDebtData] = useState(null);
  const [formTouched, setFormTouched] = useState(false);
  const [disableCollapsible, setDisableCollapsible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [expandCollapsible, setExpandCollapsible] = useState<boolean[]>([false, false, false, false]);
  const debtConsolidationControl = ['mortgage', 'buy_now_pay_later', 'no_debt', 'other_debts'];
  const [debtConErrorMessage, setDebtConErrorMessage] = useState<string>('');

  const initialDebts = useMemo(() => {
    if (debtFormData) {
      return JSON.parse(debtFormData);
    }
    if (isEmpty(consolidatedLiabilities?.liability) && isEmpty(existingOtherLoans)) {
      return {};
    } else {
      let debts = {};
      if (isSuccess) {
        (consolidatedLiabilities?.liability as unknown as Liability[])?.map((liability: Liability) => {
          // skip harmoney Stellare2 debts prefill
          if (isHarmoneyStellare2DebtInCreditFile(liability.organisation, liability.bureauAccountId)) {
            return;
          }
          const debtType = Object.keys(accountTypeToDebtType).find((debtType) =>
            accountTypeToDebtType[debtType].includes(liability.accountType)
          );
          if (!debts[debtType]) {
            debts[debtType] = [];
          }
          let providerSuffix = '';
          switch (liability.accountType) {
            case 'PF':
              providerSuffix = ' (Fixed)';
              break;
            case 'PR':
              providerSuffix = ' (Revolving)';
              break;
            default:
              break;
          }

          // include creditLimit as property only for credit card
          const debt = () => {
            const returnObj = {
              provider: `${liability.organisation}${providerSuffix}`,
              networthSourceId: debtTypes?.find((debt) => debt.code === debtType).id,
              recordOrigin: AssetAndLiabilityRecordOriginEnum.prefill,
              creditFileReference: `${liability?.accountType}_${liability?.bureauAccountId}`,
              paidOff: isBoolean(liability.paidOff) ? liability.paidOff.toString() : undefined,
              interestRate: liability.interestRate,
            };
            if (debtType === 'credit_card') {
              return {
                ...returnObj,
                creditLimit: +liability.creditLimit > 0 ? liability.creditLimit : undefined,
                interestRate: undefined,
                outstandingBalance: null,
                paysOutstandingBalance: null,
              };
            }
            if (debtType === 'mortgage') {
              return {
                ...returnObj,
                isJointMortgageFromCreditFile: isBoolean(liability?.isJointMortgage) ? liability.isJointMortgage : null,
              };
            }
            return returnObj;
          };

          return debts[debtType].push({
            ...debt(),
            elementId: `${debtType}-${debts[debtType].length}`,
          });
        });
      }
      if (!isEmpty(undisclosedDebts)) {
        const debtType = NETWORTH_CODE_CAR_LOAN;
        if (!debts[debtType]) {
          debts[debtType] = [];
        }
        undisclosedDebts.map((debt) => {
          debts[debtType].push({
            provider: debt.provider,
            networthSourceId: debtTypes?.find((debt) => debt.code === debtType).id,
            recordOrigin: AssetAndLiabilityRecordOriginEnum.prefill,
            isFromJointBankAccount: debt.isFromJointBankAccount,
            paidOff: undefined,
            ownership: undefined,
            bankStatementAmount: debt.bankStatementAmount,
            bankStatementRepaymentAmount: debt.bankStatementRepaymentAmount,
            bankStatementRepaymentFrequency: debt.bankStatementRepaymentFrequency,
            bankReference: debt.bankReference,
          });
        });
      }
      if (existingOtherLoans) {
        // liabilities from table consolidated_liabilities already inserted into asset_and_liability table
        if (
          existingOtherLoans.some((liability) => liability.recordOrigin === AssetAndLiabilityRecordOriginEnum.prefill)
        ) {
          debts = {};
        }
        existingOtherLoans.forEach((liability) => {
          const debtType = liability.networthSource.code;
          if (!debts[debtType]) {
            debts[debtType] = [];
          }
          const copyLiability = {
            ...liability,
            elementId: `${debtType}-${debts[debtType].length}`,
            paidOff: liability.paidOff === null ? 'false' : liability.paidOff.toString(),
            interestRate: Number(liability.interestRate) || null,
            isJointMortgageFromCreditFile:
              liability.isJointMortgageFromCreditFile !== null
                ? convertStringToBoolean(liability.isJointMortgageFromCreditFile)
                : null,
          };
          debts[debtType].push(
            isEmpty(copyLiability.creditFileReference) ? omit(copyLiability, ['creditFileReference']) : copyLiability
          );
        });
        return debts;
      }
    }
  }, [consolidatedLiabilities?.liability, isSuccess, debtTypes, debtFormData, existingOtherLoans, undisclosedDebts]);

  const isInitialDebtsPrefill = useMemo(() => {
    if (!isEmpty(initialDebts)) {
      return Object.values(initialDebts).some(
        (arr) => isArray(arr) && arr.some((item) => item.recordOrigin === 'prefill' || item.recordOrigin === 'manual')
      );
    }
  }, [initialDebts]);

  const form = useForm({
    mode: 'onSubmit',
    schema: getFormSchemaByProductNameAndRelationshipStatus(loanProduct?.name as LoanProductName, relationshipStatus),
    defaultValues: {
      debts: initialDebts,
    },
  });
  const {
    register,
    watch,
    setValue,
    getValues,
    reset,
    resetField,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (!isEmpty(errors)) {
      console.error('errors', errors);
    }
  }, [errors]);

  useMemo(() => {
    let debtTypesToUse = debtTypes;
    const hasDebtsFromCreditFile = (consolidatedLiabilities?.liability as unknown as Liability[])?.length > 0;
    if (isDebtCon(loanProduct?.name) || existingHarmoneyLoans?.length > 0 || hasDebtsFromCreditFile) {
      debtTypesToUse = debtTypes?.filter((debt) => debt.code !== NETWORTH_CODE_NO_DEBT);
    }
    if (!isEmpty(initialDebts)) {
      const sortedInitialDebts = Object.keys(initialDebts)
        .map((debtType) => debtTypesToUse?.find((debt) => debt.code === debtType))
        .sort((a, b) => a.id - b.id);
      reset({ debts: initialDebts });

      const flattenInitialDebts = Object.values(initialDebts).flatMap((debt) => debt as any);
      if (flattenInitialDebts?.length > 0) {
        setPrimaryDebtTypes(sortedInitialDebts);
        setSecondaryDebtTypes(
          map(debtTypes, (obj) => ({
            ...obj,
            isDisabled: false,
          })).filter((debt) => debt.code !== NETWORTH_CODE_NO_DEBT)
        );
      } else {
        setPrimaryDebtTypes([]);
        setSecondaryDebtTypes(map(debtTypesToUse, (obj) => ({ ...obj, isDisabled: false })));
      }
    } else {
      setPrimaryDebtTypes([]);
      setSecondaryDebtTypes(map(debtTypesToUse, (obj) => ({ ...obj, isDisabled: false })));
    }
    setExpandCollapsible((prevState) => {
      if (isEmpty(initialDebts)) {
        return prevState.map(() => false);
      } else {
        if (isInitialDebtsPrefill) {
          return Array.from(
            { length: Object.keys(initialDebts).length },
            (_, i) => i < Object.keys(initialDebts).length
          );
        } else {
          return prevState.map((_, i) => (initialDebts[debtTypesToUse[i]?.code] ? true : false));
        }
      }
    });
    if (initialDebts?.[NETWORTH_CODE_NO_DEBT]?.length > 0) {
      setDisableCollapsible(true);
    }
  }, [debtTypes, initialDebts, reset, loanProduct?.name, isInitialDebtsPrefill, existingHarmoneyLoans]);

  useEffect(() => {
    if (refreshedLoans?.hasChanged && existingLiabilities) {
      refetchLiabilities();
    }
  }, [existingLiabilities, refetchLiabilities, refreshedLoans]);

  const watchForm = watch();
  const watchDebts = watchForm.debts;

  const storedFormData = useRef({});
  const [tmpExpandState, setTmpExpandState] = useState<boolean[]>([]);

  const onToggle = useCallback(
    (item: NetworthSourceDto, index: number, noDebtIndex: number) => {
      setFormTouched(false);

      // set collapsible state to track the expandible div's
      setExpandCollapsible((prevState) => {
        let newState;

        if (index === noDebtIndex) {
          if (expandCollapsible.some((item) => item === true && item !== expandCollapsible[index])) {
            setTmpExpandState(prevState);
          }

          newState = prevState[index] === true ? tmpExpandState : prevState.map(() => false);

          newState[index] = !prevState[index];
          if (newState[index]) {
            storedFormData.current = watchDebts;
            reset({ debts: {} });
          } else {
            newState[index] = tmpExpandState[index];
            reset({ debts: storedFormData.current });
          }
          return newState;
        } else {
          if (prevState[index]) {
            resetField(`debts.${item.code as DebtCode}`);
          }
          newState = [...prevState];
          newState[index] = !prevState[index];
        }
        return newState;
      });

      const { id, code } = item;

      if (code === NETWORTH_CODE_NO_DEBT) {
        setOtherDebtOpen(false);
        setDisableCollapsible(!disableCollapsible);

        if (!isEmpty(watchDebts)) {
          storedFormData.current = watchDebts;
          reset({ debts: {} });
        }
        setNoDebtData((prevState) =>
          isEmpty(prevState)
            ? {
                [`debts.${code}`]: [
                  initialDebtFormValues({ id, code, relationshipStatus, loanProduct: loanProduct?.name }),
                ],
              }
            : null
        );
        if (
          tmpExpandState.filter((_, i) => index !== i).some((item) => item === true) &&
          !Object.values(storedFormData.current).some((arr) => isArray(arr) && arr.length > 0)
        ) {
          reset({ debts: storedFormData.current });
          setNoDebtData(null);
        }
      } else {
        if (expandCollapsible[index]) {
          const debts = watchDebts;
          delete debts[code as DebtCode];
          reset({ debts });
        } else {
          const elementId = `${code}-0`;
          setValue(`debts.${code as DebtCode}`, [
            {
              ...initialDebtFormValues({ id, code, relationshipStatus, loanProduct: loanProduct?.name }),
              elementId,
            },
          ]);
          setElementId(elementId);
        }
      }
    },
    [
      disableCollapsible,
      expandCollapsible,
      reset,
      resetField,
      setValue,
      relationshipStatus,
      loanProduct,
      storedFormData,
      watchDebts,
      tmpExpandState,
    ]
  );

  const handleCancel = async () => {
    try {
      setIsSubmitting({ update: false, cancel: true });
      await completeTaskWithData({
        taskId,
      });
    } catch (error) {
      console.error(`Failed to complete task [taskId: ${taskId}], error: ${error}`);
      setIsSubmitting({ update: false, cancel: false });
    }
  };

  const handleSubmit = async (data: FormSchema) => {
    try {
      data['debts'] = isEmpty(data['debts']) ? noDebtData || watchForm.debts : data['debts'];

      if (isDebtCon(loanProduct?.name)) {
        const doesUserOnlyHaveOneHarmoneyLoan = checkIfS2FoundOnlyOneHarmoneyLoanForUser(
          data.debts,
          existingHarmoneyLoans
        );

        const willUserRemainWithOneHarmoneyLoan = checkIfUserWillOnlyHaveOneHarmoneyLoanLeft(
          data.debts,
          existingHarmoneyLoans,
          loanProduct?.maximumLoanAmount
        );

        if (doesUserOnlyHaveOneHarmoneyLoan || willUserRemainWithOneHarmoneyLoan) {
          setFormData(data);
          setIsBlockSingleHmyLoanDebtConModalOpen(true);
          return;
        }
      }

      const noDebtToConsolidate = isDebtCon(loanProduct?.name)
        ? checkingIfNoDebtToConsolidate(data.debts, existingHarmoneyLoans, loanProduct?.maximumLoanAmount)
        : false;
      const isDebtConAmountUnderMinimum = isDebtCon(loanProduct?.name)
        ? checkIfDebtsAmountIsUnderMinBorrowingLimit(
            data.debts,
            existingHarmoneyLoans,
            loanProduct?.minimumLoanAmount,
            loanProduct?.maximumLoanAmount
          )
        : false;

      if (isDebtCon(loanProduct?.name) && (isEmpty(data.debts) || noDebtToConsolidate || isDebtConAmountUnderMinimum)) {
        setIsSubmitting({ update: false, cancel: false });
        setFormTouched(true);
        if (noDebtToConsolidate) {
          setDebtConErrorMessage(
            `None of your debts are eligible for consolidation. Currently, we do not consolidate: <ul class='list-disc pl-6'><li>Mortgage</li><li>Buy now pay later</li><li>Unspecified debt type</li><li>Credit cards where outstanding balance is paid off each month</li><li>Debts over ${formatCurrency(loanProduct?.maximumLoanAmount)}</li></ul>`
          );
        } else if (isDebtConAmountUnderMinimum) {
          setDebtConErrorMessage(
            `For a debt consolidation loan, your eligible debts need to add up to the minimum loan amount of ${formatCurrency(loanProduct?.minimumLoanAmount)}.`
          );
        }
        return;
      } else if (!isDebtCon(loanProduct?.name) && isEmpty(data.debts) && existingHarmoneyLoans?.length === 0) {
        setIsSubmitting({ update: false, cancel: false });
        setFormTouched(true);
        return;
      } else {
        setFormTouched(false);
      }

      setIsSubmitting({ update: true, cancel: false });

      const transformedData = transformSubmittedData(data, existingOtherLoans as { id: string }[]);
      await completeTaskWithData({
        taskId,
        formKey: FORM_KEY.DEBT_UPDATE,
        formData: { liabilities: transformedData },
      });

      onDebtsUpdateComplete();
    } catch (error) {
      console.error(`Failed to complete task [taskId: ${taskId}], error: ${error}`);
      setIsSubmitting({ update: false, cancel: false });
    }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      dispatch(setDebtFormData(''));
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  const handleAddItem = (item) => {
    const allItems = watchDebts[item.code];
    const { id, code } = item;

    if (!allItems) {
      const debtIndex = primaryDebtTypes.findIndex((debt) => debt.code === item.code);
      const noDebtIndex = primaryDebtTypes.findIndex((debt) => debt.code === NETWORTH_CODE_NO_DEBT);
      onToggle(item, debtIndex, noDebtIndex);
      return;
    }

    const elementId = `${item.code}-${watchDebts[item.code].length}`;
    const newItems = [
      ...allItems,
      {
        ...initialDebtFormValues({ id, code, relationshipStatus, loanProduct: loanProduct.name }),
        recordOrigin: 'manual',
        elementId,
      },
    ];
    setElementId(elementId);

    setValue(`debts.${item.code as DebtCode}`, newItems);
  };

  const handleRemoveItem = (code: DebtCode, index: number) => {
    const debtItems = getValues(`debts.${code}`);
    const updatedDebtItems = (debtItems as any).filter((_, i) => i !== index);
    resetField(`debts.${code}`);
    setValue(`debts.${code}`, updatedDebtItems as any);
  };

  const splitDebts = ({ insertIndex, debt }) => {
    if (insertIndex < 0) {
      insertIndex = 0;
    }
    const primaryDebts = primaryDebtTypes.slice(0);
    primaryDebts.splice(insertIndex, 0, debt);
    setPrimaryDebtTypes(primaryDebts);
    onToggle(debt, insertIndex, insertIndex + 1);
  };

  // On add other debts to primary assets
  const handleAddOtherDebts = (debt: NetworthSourceDto) => {
    if (!primaryDebtTypes.map((item) => item.code).includes(debt.code)) {
      setOtherDebtOpen(!otherDebtOpen);
      if (isEmpty(initialDebts)) {
        splitDebts({
          insertIndex: isDebtCon(loanProduct?.name) ? primaryDebtTypes.length : primaryDebtTypes.length - 1,
          debt,
        });
      } else {
        splitDebts({ insertIndex: primaryDebtTypes.length, debt });
      }
      return;
    }
    handleAddItem(debt);
    setOtherDebtOpen(!otherDebtOpen);
  };

  const handleChangeLoanPurposeToPersonalLoanSubmit = async (
    loanPurposeData: {
      id: string;
      answers: {
        questionId: string;
        optionId: string;
      }[];
    }[]
  ) => {
    const transformedData = transformSubmittedData(formData, existingOtherLoans as { id: string }[]);

    await completeTaskWithData({
      taskId,
      formKey: FORM_KEY.DEBT_UPDATE,
      formData: {
        liabilities: transformedData,
      },
      variables: {
        didLoanPurposeChange: true,
        loanProductName: LoanProductName.DEBT_CONSOLIDATION,
        loanPurposes: loanPurposeData,
      },
    });

    onDebtsUpdateComplete();
  };

  const onDebtsUpdateComplete = () => {
    eventAnalytics.track(FINANCIALPROFILE_DEBTS_PROVIDED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    confirmSection(FinancialConfirmationSectionEnum.Debts);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      if (!isEmpty(watchDebts) && !noDebtData) {
        dispatch(setDebtFormData(JSON.stringify(watchDebts)));
      }
      if (noDebtData) {
        dispatch(setDebtFormData(''));
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, watchDebts, noDebtData]);

  useEffect(() => {
    if (targetRef) {
      scrollIntoView();
    }
  }, [scrollIntoView, targetRef, elementId]);

  useEffect(() => {
    if (!isEmpty(errors) && !isEmpty(errors?.debts)) {
      const errorFields = Object.keys(errors.debts);
      const firstErrorField = sortedDebtCode.find((code) => errorFields.includes(code));
      const index = errors.debts[firstErrorField]?.findIndex(
        (item) => item && typeof item === 'object' && Object.keys(item).length > 0
      );
      setElementId(`${firstErrorField}-${index}`);
      scrollIntoView();
    }
  }, [errors, scrollIntoView]);

  if (isLoading || isPrefillLoading || isLoanProductInfoLoading || isUndisclosedDebtsLoading) return null;

  return (
    <>
      <h1>
        {isInitialDebtsPrefill || isDebtCon(loanProduct?.name)
          ? 'Can you tell us about your '
          : 'Are you currently managing any '}
        <span className="text-primary">debts</span>?
      </h1>
      {((isInitialDebtsPrefill && !isDebtCon(loanProduct?.name)) || undisclosedDebts) && (
        <Alert variant="info">
          <span>These are the debts we found on your credit file/bank statement.</span>
        </Alert>
      )}
      {isDebtCon(loanProduct?.name) && (
        <Alert variant="info">
          <span>
            Make sure the information you provide is precise as this will influence your consolidation amount.
          </span>
        </Alert>
      )}
      <Form form={form} onSubmit={handleSubmit}>
        {primaryDebtTypes
          ?.filter((item) => watchDebts?.[item?.code]?.length !== 0)
          .map((debt, debtIndex) => (
            <Fragment key={debt?.id}>
              <CollapsibleHeader
                valid={
                  isDebtCon(loanProduct?.name) && debtConsolidationControl.includes(debt?.code) ? false : formTouched
                }
                checkbox={false}
                title={debt?.name}
                code={debt?.code}
                disabledActive={
                  initialDebts?.[debt?.code]?.length > 0 &&
                  initialDebts?.[debt?.code]?.some((item) => item.recordOrigin === 'prefill')
                }
                disabled={debt?.code === NETWORTH_CODE_NO_DEBT ? false : disableCollapsible}
                onCollapseChange={() =>
                  onToggle(
                    debt,
                    debtIndex,
                    isInitialDebtsPrefill || isDebtCon(loanProduct?.name) || existingHarmoneyLoans?.length > 0
                      ? -1
                      : primaryDebtTypes.length - 1
                  )
                }
                withIconify={DebtTypeIcon[debt?.code]}
                open={
                  expandCollapsible[debtIndex] ||
                  (initialDebts[debt?.code] && isInitialDebtsPrefill && isEmpty(noDebtData))
                }
              >
                {isDebtCon(loanProduct?.name) && debtConsolidationControl.includes(debt?.code) && (
                  <DebtConAlert code={debt?.code} />
                )}
                {watchDebts?.[debt?.code]?.map((item, index) => (
                  <div key={debt?.code + index} ref={item.elementId === elementId ? targetRef : null}>
                    <DebtItem
                      formData={watchDebts}
                      register={register}
                      removeItem={handleRemoveItem}
                      index={index}
                      code={debt?.code}
                      item={item}
                      loanProduct={loanProduct}
                      relationshipStatus={relationshipStatus}
                    />
                    {debt.code === NETWORTH_CODE_NO_DEBT ||
                      (watchDebts[debt?.code].length - 1 === index && (
                        <div className="text-center mx-4 mb-3">
                          <Button
                            onClick={() => handleAddItem(debt)}
                            alignIcon="start"
                            size="medium"
                            variant="outline-secondary"
                            key={`add_another_${debt?.code}`}
                            className="!text-sm"
                          >
                            + Another {debt?.name}
                          </Button>
                        </div>
                      ))}
                  </div>
                ))}
              </CollapsibleHeader>
            </Fragment>
          ))}

        {existingHarmoneyLoans && existingHarmoneyLoans?.length > 0 && (
          <HarmoneyDebtCard liabilities={existingHarmoneyLoans} isDebtCon={isDebtCon(loanProduct?.name)} />
        )}
        {/* Select other debts options */}
        <CollapsibleHeader
          onCollapseChange={() => setOtherDebtOpen(!otherDebtOpen)}
          chevron
          disabled={disableCollapsible}
          valid={formTouched}
          title={isEmpty(initialDebts) ? 'More debts' : 'Do you have any other debts?'}
          withIconify="ic:outline-format-list-bulleted"
          open={otherDebtOpen}
        >
          <>
            {secondaryDebtTypes?.map((debt, index) => (
              <div key={`debt_${debt.id}`}>
                <button
                  type="button"
                  onClick={() => handleAddOtherDebts(debt)}
                  className={`hover:bg-grey-1 flex w-full cursor-pointer items-center justify-between space-x-4 bg-white p-4 ${
                    debt.isDisabled ? 'cursor-not-allowed hover:bg-white' : ''
                  }`}
                  disabled={debt.isDisabled}
                >
                  <div
                    key={`debt-${debt.id}`}
                    className={`flex cursor-pointer items-center space-x-4 ${
                      debt.isDisabled ? 'cursor-not-allowed' : ''
                    }`}
                  >
                    <IconV2
                      icon={DebtTypeIcon[debt.code]}
                      width={24}
                      className={debt.isDisabled ? 'text-grey-2' : 'text-grey-3'}
                    />
                    <Label className={`${debt.isDisabled ? '!text-grey-2 cursor-not-allowed' : ''}`}>
                      {capitalizeTitle(debt.name)}
                    </Label>
                    <Divider className="text-grey-2 m-0 p-0" />
                  </div>
                </button>
                {index !== secondaryDebtTypes.length - 1 && <Divider className="text-grey-2 my-0" />}
              </div>
            ))}
          </>
        </CollapsibleHeader>
        {/* Select other debts options */}
        {formTouched && !isDebtCon(loanProduct?.name) && <p className="text-error">Please select an option</p>}
        {formTouched && isDebtCon(loanProduct?.name) && (
          <Alert variant="error">
            <p className="text-sm" dangerouslySetInnerHTML={{ __html: debtConErrorMessage }}></p>
          </Alert>
        )}

        <div className="flex flex-col gap-2 items-center">
          <Button
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            type="submit"
            className="mt-6"
            hasShadow
            isLoading={isSubmitting.update}
            disabled={isSubmitting.cancel}
          >
            Save
          </Button>
          <Button
            variant="tertiary"
            onClick={handleCancel}
            isLoading={isSubmitting.cancel}
            disabled={isSubmitting.update}
          >
            Cancel
          </Button>
        </div>
      </Form>
      {!isProduction() && (
        <DevTool
          control={form.control}
          placement="bottom-left"
          styles={{
            button: { width: '50px', height: '50px' },
            panel: { zIndex: 10000, overflow: 'visible', height: '765px', width: '230px' },
          }}
        />
      )}
      <BlockSingleHmyLoanDebtConModal
        isOpen={isBlockSingleHmyLoanDebtConModalOpen}
        closeModal={() => setIsBlockSingleHmyLoanDebtConModalOpen(false)}
        onChangeLoanPurposeSubmit={handleChangeLoanPurposeToPersonalLoanSubmit}
      />
    </>
  );
}

const DebtConAlert = ({ code }) => {
  if (code === 'mortgage' || code === 'buy_now_pay_later') {
    return (
      <Alert variant="info" className="mb-4 mx-4" key={`alert_provide_${code}`}>
        <p className="text-sm">{`We don't provide debt consolidation for ${lowerCase(code)}.`}</p>
      </Alert>
    );
  }
  if (code === 'other_debts') {
    return (
      <Alert variant="info" className="mb-4 mx-4" key={`alert_provide_${code}`}>
        <p className="text-sm">
          We currently only offer debt consolidation for specific debt types. Any debts listed here won&apos;t be
          available for consolidation.
        </p>
      </Alert>
    );
  }
  return null;
};

function getDebtsToConsolidate(debts: FormSchema['debts'], maxBorrowingLimit: number) {
  const debtConsolidationControl = ['mortgage', 'buy_now_pay_later', 'other_debts'];
  const debtsToConsolidate = [];
  if (debts) {
    Object.entries(debts).forEach(([key, value]) => {
      if (!debtConsolidationControl.includes(key)) {
        (
          value as unknown as FormSchema['debts'][
            | 'credit_card'
            | 'personal_loan'
            | 'overdraft'
            | 'car_loan'
            | 'line_of_credit']
        )
          .filter((item) => !isBooleanStringTrue(item.paidOff))
          .filter((item) => (item as unknown as any).outstandingBalance <= maxBorrowingLimit)
          .filter(
            (item) =>
              item.networthSourceId !== NetworthSourceEnum.LIABILITY_CREDIT_CARD_ID ||
              (item.networthSourceId === NetworthSourceEnum.LIABILITY_CREDIT_CARD_ID &&
                !isBooleanStringTrue(item.paysOutstandingBalance))
          )
          .map((item) => {
            debtsToConsolidate.push(item);
          });
      }
    });
  }
  return debtsToConsolidate;
}

function checkingIfNoDebtToConsolidate(
  debts: FormSchema['debts'],
  existingHarmoneyLoans: object[],
  maxBorrowingLimit: number
) {
  let noDebtToConsolidate = true;
  const debtsToConsolidate = getDebtsToConsolidate(debts, maxBorrowingLimit);

  if (debtsToConsolidate.length > 0) {
    noDebtToConsolidate = false;
  }
  if (existingHarmoneyLoans?.length > 0) {
    noDebtToConsolidate = false;
  }
  return noDebtToConsolidate;
}

function checkIfDebtsAmountIsUnderMinBorrowingLimit(
  debts: FormSchema['debts'],
  harmoneyLoans: (Partial<AssetAndLiability> & {
    networthSource: Partial<NetworthSource>;
  })[],
  minBorrowingLimit: number,
  maxBorrowingLimit: number
) {
  const debtsToConsolidate = getDebtsToConsolidate(debts, maxBorrowingLimit);
  const harmoneyLoanAmount = harmoneyLoans.reduce((acc, item) => acc + item.outstandingBalance, 0);
  const totalDebtsAmount = debtsToConsolidate.reduce((acc, item) => acc + item.outstandingBalance, 0);
  return totalDebtsAmount + harmoneyLoanAmount < minBorrowingLimit;
}

function checkIfS2FoundOnlyOneHarmoneyLoanForUser(debts: FormSchema['debts'], existingHarmoneyLoans: object[]) {
  const hasOnlyASingleHmyLoan = existingHarmoneyLoans.length === 1;
  const hasEmptyDebts = isEmpty(debts) || (debts && Object.values(debts).every((val) => val?.length === 0));

  if (hasEmptyDebts && hasOnlyASingleHmyLoan) {
    return true;
  }

  return false;
}

function checkIfUserWillOnlyHaveOneHarmoneyLoanLeft(
  debts: FormSchema['debts'],
  existingHarmoneyLoans: object[],
  maxBorrowingLimit: number
) {
  const hasOnlyASingleHmyLoan = existingHarmoneyLoans.length === 1;
  const debtsToConsolidate = getDebtsToConsolidate(debts, maxBorrowingLimit);

  if (hasOnlyASingleHmyLoan && debtsToConsolidate.length === 0) {
    return true;
  }

  return false;
}
