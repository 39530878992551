import { VaultLoanViewDto } from '@harmoney/api-interfaces';
import { getBadgeProps, LoanCard } from '@harmoney/components';
import { useBranchContent } from '@harmoney/hooks';
import { LoanApplicationQueryResponse } from '@harmoney/redux';
import { Card } from '@harmoney/ui-design-system';
import { Button } from '@harmoney/ui-design-system';
import { IntercomActionEnum, openChatWindow } from '@harmoney/ui-utils';
import { LoanArrearsStatusEnum } from '@prisma/client';

type LoanProps = {
  title: string;
  loans: (VaultLoanViewDto & LoanApplicationQueryResponse)[];
};

const LiveChat = () => {
  return (
    <Button
      as="a"
      variant="link"
      className="text-secondary border-none text-sm h-0 !min-h-0 !important"
      onClick={() => openChatWindow(IntercomActionEnum.SHOW)}
    >
      live chat
    </Button>
  );
};

const MessageSection = ({ customerServiceContactNumber }) => (
  <div className="flex flex-col p-2 ">
    <p className="text-sm">
      <strong>Need info on a closed loan not listed?</strong>
      <br></br>
      Get your loan details, statement of accounts and more - easily! Just call
      {customerServiceContactNumber ? (
        <>
          {' '}
          at <span className="text-secondary"> {customerServiceContactNumber}</span>
        </>
      ) : (
        <></>
      )}{' '}
      or use our <LiveChat />.
    </p>
  </div>
);

const isDebtSold = (loan: VaultLoanViewDto & LoanApplicationQueryResponse) => {
  return loan?.loanArrearsStatus === LoanArrearsStatusEnum.DEBT_SOLD;
};

export const Loans = ({ title, loans }: LoanProps) => {
  const { branchContent } = useBranchContent();

  return (
    <>
      <Card className="flex flex-col items-stretch !p-0 md:px-16 ">
        <div className="border-b-grey-2 flex flex-row justify-between border-b p-4">
          <span className="flex-col font-medium">{title}</span>
        </div>
        <div className="flex flex-col gap-y-2 p-4">
          {!loans || loans.length === 0 ? (
            title.toLowerCase().includes('active') ? (
              <div className="text-grey-4">No {title.toLowerCase()}</div>
            ) : (
              ''
            )
          ) : (
            loans.map((loan) => {
              const badge = getBadgeProps(loan.loanInformation.loanState, isDebtSold(loan));

              return (
                <LoanCard
                  key={loan?.id}
                  loanApplicationId={loan?.id}
                  loanBusinessKey={loan?.businessKey}
                  loanDisplayName={loan?.displayName}
                  loanPurpose={loan?.loanApplicationPurposes
                    .map((loanApplicationPurpose) => loanApplicationPurpose?.loanPurpose?.displayName)
                    .join(', ')}
                  badgeLabel={badge.label}
                  badgeVariant={badge.variant}
                />
              );
            })
          )}
          {title.toLowerCase().includes('closed') && (
            <MessageSection customerServiceContactNumber={branchContent?.customerServiceContactNumber} />
          )}
        </div>
      </Card>
    </>
  );
};
