import { DebtSaleStatusEnum } from '@prisma/client';

export const debtSaleStatusMapper: Record<DebtSaleStatusEnum, string> = {
  [DebtSaleStatusEnum.debt_sold]: 'Debt Sold',
  [DebtSaleStatusEnum.processing]: 'Debt sale in progress',
  [DebtSaleStatusEnum.cancelled]: null,
  [DebtSaleStatusEnum.purchased_back]: null,
};

export const debtSaleStatusBadgeVariantMapper: Record<DebtSaleStatusEnum, 'action' | 'warning' | null> = {
  [DebtSaleStatusEnum.debt_sold]: 'action',
  [DebtSaleStatusEnum.processing]: 'warning',
  [DebtSaleStatusEnum.cancelled]: null,
  [DebtSaleStatusEnum.purchased_back]: null,
};
