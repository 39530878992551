import {
  Frequency,
  InterestRateAdjustmentRecordDTO,
  LoanApplicationCount,
  LoanApplicationDto,
  LoanApplicationType,
  PaginatedQueryInput,
  PaginatedResult,
  PublishMessageDto,
  PublishMessageResponse,
  Referral,
  ResponseResult,
  UnutilisedLimitEligibilityDto,
} from '@harmoney/api-interfaces';
import {
  BankStatementOperation,
  BankStatementReference,
  CreditAudit,
  DebtConsolidationBenefitEnum,
  LoanApplication,
  LoanApplicationPurpose,
  LoanApplicationPurposeAnswer,
  LoanApplicationStatusEnum,
  LoanProduct,
  LoanPurpose,
  LoanPurposeQuestion,
  LoanPurposeQuestionOption,
  PaymentTransaction,
  Prisma,
  RepaymentInfo,
  ResidencyStatus,
} from '@prisma/client';
import { Decimal } from '@prisma/client/runtime/library';

import { emptySplitApi } from './empty-api';

export type LoanApplicationQueryResponse = LoanApplication & {
  loanApplicationPurposes: (LoanApplicationPurpose & {
    loanPurpose: LoanPurpose;
    loanApplicationPurposeAnswers: (LoanApplicationPurposeAnswer & {
      loanPurposeQuestion: LoanPurposeQuestion;
      loanPurposeQuestionOption: LoanPurposeQuestionOption;
    })[];
  })[];
  loanProduct: LoanProduct;
  scorecardOperations: {
    interestRate?: number;
  }[];
  repaymentInfo: RepaymentInfo;
  maximumBorrowingLimit?: number;
  customerInterestRate?: Decimal;
  finalInterestRate?: Decimal;
  originalLoanApplicationBusinessKey?: string;
  paymentTransactions?: PaymentTransaction[];
  isConsolidated: boolean;
  preQualifiedQuoteReferral?: Referral;
  preQualifiedQuoteId?: string;
};

export type LoanApplicationAdminQueryResponse = LoanApplicationQueryResponse & {
  user: Prisma.UserGetPayload<{ include: { userProfile: { select: { firstName: true; lastName: true } } } }>;
  creditAudit: CreditAudit[];
};

const loanApplicationApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getLoanApplication: builder.query<LoanApplicationDto & { bankStatementReferences: any[] }, string>({
      query: (applicationId) => ({
        url: `/loan-applications/${applicationId}`,
        method: 'GET',
      }),
      providesTags: ['Application'],
    }),
    getLoanApplicationById: builder.query<LoanApplicationQueryResponse, string>({
      query: (applicationId) => ({
        url: `/loan-applications`,
        method: 'GET',
        params: {
          applicationId,
        },
      }),
      providesTags: ['Application'],
    }),
    getAllLoanApplicationsByUserId: builder.query<
      (LoanApplicationQueryResponse & { unutilisedLimitEligibility: UnutilisedLimitEligibilityDto })[],
      string
    >({
      query: (userId) => ({
        url: '/loan-applications/admin-all',
        method: 'GET',
        params: {
          userId,
        },
      }),
    }),
    getAllApplicationsByIdpIdentifier: builder.query<
      (LoanApplicationQueryResponse & {
        paymentTransactions: Pick<PaymentTransaction, 'status' | 'completedAt' | 'processingAt'>[];
        unutilisedLimitEligibility: { isEligible: boolean; reason: string };
      })[],
      string
    >({
      query: () => ({
        url: '/loan-applications/loanApplications',
        method: 'GET',
      }),
      providesTags: ['Application'],
    }),
    getAllApplications: builder.query<PaginatedResult<LoanApplicationAdminQueryResponse>, PaginatedQueryInput>({
      query: (params) => ({
        url: '/loan-applications/admin-list',
        method: 'GET',
        params,
      }),
    }),
    submitLoanApplication: builder.mutation<
      ResponseResult,
      {
        id: string;
        termInMonths?: number;
        requestedAmount?: number;
        fundedAmount?: number;
        quoteExpiryAt?: Date;
        applicationExpiryAt?: Date;
        repaymentFrequency?: Frequency;
        applicationType?: LoanApplicationType;
        residencyStatus?: ResidencyStatus;
        status?: LoanApplicationStatusEnum;
        debtConsolidationBenefits?: DebtConsolidationBenefitEnum[];
      }
    >({
      query: (params) => ({
        url: `/loan-applications`,
        method: 'PATCH',
        body: params,
      }),
    }),
    cancelLoanApplication: builder.mutation<
      ResponseResult,
      {
        applicationId: string;
        userId: string;
        status: LoanApplicationStatusEnum;
        cancellationReasonId?: string;
        cancellationReason?: string;
        cancellationReasonOtherText?: string;
      }
    >({
      query: ({
        applicationId,
        status,
        cancellationReasonId,
        cancellationReasonOtherText,
        cancellationReason,
        userId,
      }) => ({
        url: `/loan-applications/${applicationId}`,
        method: 'PUT',
        body: {
          status,
          userId,
          cancellationReasonId,
          cancellationReason,
          cancellationReasonOtherText,
        },
      }),
      invalidatesTags: ['Application'],
    }),
    resumeDisbursal: builder.mutation<
      PublishMessageResponse,
      { loanApplicationId: string; message: PublishMessageDto }
    >({
      query: ({ loanApplicationId, message }) => {
        return {
          url: `/${loanApplicationId}/resume-disbursal`,
          method: 'POST',
          body: message,
        };
      },
    }),
    getLoanApplicationCount: builder.query<LoanApplicationCount, void>({
      query: () => ({
        url: '/loan-applications/count',
        method: 'GET',
        timeout: 60000,
      }),
      providesTags: ['ApplicationCount'],
    }),
    getBankStatementReferencesByLoanApplicationId: builder.query<
      (BankStatementReference & {
        operations: Pick<BankStatementOperation, 'rawResponse' | 'status' | 'updatedAt'>[];
      })[],
      string
    >({
      query: (applicationId) => ({
        url: `/loan-applications/${applicationId}/bank-statement-references`,
        method: 'GET',
      }),
      providesTags: ['BankStatementReference'],
    }),
    updateDebtConsolidationBenefitAndFundedAmount: builder.mutation<
      void,
      { applicationId: string; debtConsolidationBenefits: DebtConsolidationBenefitEnum[]; fundedAmount: number }
    >({
      query: ({ applicationId, debtConsolidationBenefits, fundedAmount }) => ({
        url: `/loan-applications/${applicationId}/update-debt-consolidation-purpose-and-funded-amount`,
        method: 'PUT',
        body: {
          debtConsolidationBenefits,
          fundedAmount,
        },
      }),
    }),
    getInterestRateAdjustments: builder.query<InterestRateAdjustmentRecordDTO, string>({
      query: (applicationId) => ({
        url: `/loan-applications/${applicationId}/interest-rate/adjustments`,
        method: 'GET',
        params: {
          applicationId,
        },
      }),
      providesTags: ['InterestRateAdjustments'],
    }),
    adjustInterestRate: builder.mutation<void, { applicationId: string; finalInterestRate: string }>({
      query: ({ applicationId, finalInterestRate }) => ({
        url: `/loan-applications/${applicationId}/interest-rate/adjustments`,
        method: 'POST',
        body: {
          finalInterestRate,
        },
      }),
      invalidatesTags: ['InterestRateAdjustments', 'Application'],
    }),
    renameLoanApplication: builder.mutation<void, { loanApplicationId: string; displayName: string }>({
      query: ({ loanApplicationId, displayName }) => ({
        url: `/loan-applications/${loanApplicationId}/update-display-name`,
        method: 'PATCH',
        body: {
          displayName,
        },
      }),
      invalidatesTags: ['Application'],
    }),
  }),
});

export const {
  useGetLoanApplicationQuery,
  useLazyGetLoanApplicationQuery,
  useGetLoanApplicationByIdQuery,
  useGetAllLoanApplicationsByUserIdQuery,
  useGetAllApplicationsByIdpIdentifierQuery,
  useGetAllApplicationsQuery,
  useSubmitLoanApplicationMutation,
  useCancelLoanApplicationMutation,
  useResumeDisbursalMutation,
  useGetLoanApplicationCountQuery,
  useGetBankStatementReferencesByLoanApplicationIdQuery,
  useUpdateDebtConsolidationBenefitAndFundedAmountMutation,
  useGetInterestRateAdjustmentsQuery,
  useLazyGetInterestRateAdjustmentsQuery,
  useAdjustInterestRateMutation,
  useRenameLoanApplicationMutation,
} = loanApplicationApi;
