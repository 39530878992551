import { ChangeFlagInformationDto, LoanVariationFlag, VaultPaymentInformationDto } from '@harmoney/api-interfaces';
import { CollapsibleCard, Divider, IconV2 } from '@harmoney/ui-design-system';
import { amountOrNA, capitalizeTitle, DATE_FORMAT, valueOrNA } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import dayjs from 'dayjs';

import { Field } from '../shared';

import { Flags } from './LoanView';

interface Props {
  paymentInformationData: VaultPaymentInformationDto;
  flags?: ChangeFlagInformationDto[];
}

export const PaymentInformationDetails = ({ paymentInformationData, flags }: Props) => {
  if (!paymentInformationData) return null;
  const paymentPlanFlag = flags?.filter((f) => f.flagName == LoanVariationFlag.PAYMENT_ARRANGEMENT_FLAG);

  return (
    <CollapsibleCard title="Payment information" className="mb-6">
      {paymentPlanFlag.length > 0 && (
        <>
          <div className="p-4 flex gap-2 text-sm">
            <IconV2 icon="ic:round-info" className="text-secondary-lighter-1" width={24} />
            <Flags flags={paymentPlanFlag} />
          </div>
          <Divider className="text-grey-2" />
        </>
      )}
      <div className="grid grid-cols-2 px-4">
        <div className="border-r-grey-1 border-r-2 p-4">
          <Field>
            <span>First payment date:</span>
            <span>{dayjs(paymentInformationData?.firstPaymentDate).format(DATE_FORMAT) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>First payment amount:</span>
            <span>{amountOrNA(paymentInformationData?.firstPaymentAmount)}</span>
          </Field>
          <Field>
            <span>Recurring payment date:</span>
            <span className="flex flex-col">
              <span className="text-base">{paymentPlanFlag.length > 0 && 'Paused'}</span>
              <span>{dayjs(paymentInformationData?.recurringPaymentDate).format(DATE_FORMAT) ?? 'N/A'}</span>
            </span>
          </Field>
          <Field>
            <span>Recurring payment amount:</span>
            <span>
              {paymentInformationData?.recurringPaymentAmount
                ? formatCurrency(Number(paymentInformationData?.recurringPaymentAmount), 2)
                : '$0'}
            </span>
          </Field>
          <Field>
            <span>Recurring payment frequency:</span>
            <span>{valueOrNA(capitalizeTitle(paymentInformationData?.recurringPaymentFrequency))}</span>
          </Field>
          <Field>
            <span>Monthly contractual payment amount:</span>
            <span>{amountOrNA(paymentInformationData?.monthlyContractualPaymentAmount)}</span>
          </Field>
        </div>
        <div className="p-4">
          <Field>
            <span>Monthly contractual payment date:</span>
            <span className="flex flex-col">
              <span>{dayjs(paymentInformationData?.monthlyContractualPaymentDate).format(DATE_FORMAT) ?? 'N/A'}</span>
            </span>
          </Field>
          <Field>
            <span>Pay off amount:</span>
            <span>
              {paymentInformationData?.payOffAmount
                ? formatCurrency(Number(paymentInformationData?.payOffAmount), 2)
                : '$0'}
            </span>
          </Field>
          <Field>
            <span>Pay ID:</span>
            <span>{valueOrNA(paymentInformationData?.payId)}</span>
          </Field>
          <Field>
            <span>AutoMatcher BSB</span>
            <span>{valueOrNA(paymentInformationData?.autoMatcherBsb)}</span>
          </Field>
          <Field>
            <span>AutoMatcher Account</span>
            <span>{valueOrNA(paymentInformationData?.autoMatcherBankAccountNumber)}</span>
          </Field>
        </div>
      </div>
    </CollapsibleCard>
  );
};
