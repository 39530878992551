import { BranchRecord } from '../branch';

export const FULL_HISTORY_START_DATE = '2023-01-01';
export const MINIMUM_LOAN_AMOUNT = 2_000;
export const MAXIMUM_LOAN_AMOUNT = 70_000;
export const LEGAL_DISCLAIMER_LINKS = {
  AU: {
    websiteTermsOfUse: 'https://harmoney.com.au/legal/website-terms-of-use',
    electronicCommunicationsConsent: 'https://www.harmoney.com.au/legal/electronic-communication-terms',
    privacyPolicy: 'https://harmoney.com.au/legal/privacy-policy',
  },
  NZ: {
    websiteTermsOfUse: '',
    electronicCommunicationsConsent: '',
    privacyPolicy: '',
  },
};

export const CONTACT_NUMBER: BranchRecord<string> = {
  AU: '1300 042 766',
  NZ: '0800 427 666',
};

export const CONTACT_NUMBER_HREF: BranchRecord<string> = {
  AU: 'tel:1300042766',
  NZ: 'tel:0800427666',
};
