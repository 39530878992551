import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { BankAccountDto, DisbursementDto } from '@harmoney/api-interfaces';
import { useAppSelector, useGetDisbursalBankAccountsQuery } from '@harmoney/redux';
import { Card, ToggleGroup, useFormContext } from '@harmoney/ui-design-system';
import { Label } from '@harmoney/ui-design-system';
import { normalizeBankAccountDetails } from '@harmoney/ui-utils';
import { normalizeBsbOrAccountNumber, transformBsb } from '@harmoney/utilities';
import { LoanPurpose } from '@prisma/client';

import { ADD_ANOTHER_BANK_ACCOUNT, BankAccountSelector } from '../../../fulfilment/Payment/components';
import { maskNormalizedBankAccount } from '../../../fulfilment/Payment/utils';
import { NewBankAccountSection } from '../UpdateDDBankAccount/components';

type Props = {
  fromBankAccount: BankAccountDto;
  payment?: DisbursementDto;
  userId: string;
  loanPurpose: LoanPurpose;

  onSuppliedOwnershipChanged?: (isSet: boolean) => void | Promise<void>;
  onNewBankAccountSelected?: (bankAccount: string) => void | Promise<void>;
};

export default function UpdateSinglePersonalLoanDisbursalBankAccount({
  fromBankAccount,
  payment,
  userId,
  loanPurpose,
  onSuppliedOwnershipChanged,
  onNewBankAccountSelected,
}: Props) {
  const token = useAppSelector((state) => state?.accessToken?.value);

  const [updateDisbursal, setUpdateDisbursal] = useState(false);

  const { data: bankAccounts } = useGetDisbursalBankAccountsQuery(userId, {
    skip: !userId || !token,
  });

  const disbursalId = payment?.id;
  const bankAccountAccessor = `updates.${disbursalId}`;
  const fromBankAccountAccessor = `${bankAccountAccessor}.fromBankAccount`;
  const newBankAccountAccessor = `${bankAccountAccessor}.newBankAccount`;

  const { setValue, resetField, control } = useFormContext();

  const selectedBankAccount = useWatch({
    control,
    name: `${newBankAccountAccessor}.updateDisbursalToBankAccount`,
  });

  const findBankAccount = useCallback(
    (bankAccountDetails: string) => {
      return bankAccounts?.find((bankAccount) => {
        const normalizedBankAccountTo = `${transformBsb(bankAccount.bsb)}-${normalizeBsbOrAccountNumber(bankAccount.accountNumber)}`;
        return bankAccountDetails === normalizedBankAccountTo;
      });
    },
    [bankAccounts]
  );

  const filteredBankAccounts = useMemo(() => {
    return (
      bankAccounts?.filter((bankAccount) => {
        const normalizedBankAccountFrom = `${transformBsb(fromBankAccount.bsb)}-${normalizeBsbOrAccountNumber(fromBankAccount.accountNumber)}`;
        const normalizedBankAccountTo = `${transformBsb(bankAccount.bsb)}-${normalizeBsbOrAccountNumber(bankAccount.accountNumber)}`;
        return normalizedBankAccountFrom !== normalizedBankAccountTo;
      }) ?? []
    );
  }, [bankAccounts, fromBankAccount]);

  useEffect(() => {
    if (!updateDisbursal) resetField(bankAccountAccessor);
  }, [bankAccountAccessor, resetField, updateDisbursal]);

  useEffect(() => {
    if (!updateDisbursal) return;
    const { accountBsb, accountNumber, accountName, bankName, bankSlug } = normalizeBankAccountDetails(fromBankAccount);
    setValue(fromBankAccountAccessor, {
      accountBsb,
      accountNumber,
      accountName,
      bankName,
      bankSlug,
      paymentId: payment?.id,
      type: 'bsb',
    });
    setValue(newBankAccountAccessor, {});
  }, [fromBankAccount, fromBankAccountAccessor, newBankAccountAccessor, payment?.id, setValue, updateDisbursal]);

  useEffect(() => {
    if (selectedBankAccount !== ADD_ANOTHER_BANK_ACCOUNT) return;

    onSuppliedOwnershipChanged?.(false);
    resetField(`${newBankAccountAccessor}.accountBsb`);
    resetField(`${newBankAccountAccessor}.accountNumber`);
    resetField(`${newBankAccountAccessor}.accountName`);
    resetField(`${newBankAccountAccessor}.bankName`);
    resetField(`${newBankAccountAccessor}.bankSlug`);
  }, [selectedBankAccount, disbursalId, onSuppliedOwnershipChanged, resetField, newBankAccountAccessor]);

  useEffect(() => {
    if (selectedBankAccount === ADD_ANOTHER_BANK_ACCOUNT) return;
    const bankAccount = findBankAccount(selectedBankAccount);

    if (!bankAccount) return;

    const { accountBsb, accountNumber, accountName, bankName, bankSlug } = normalizeBankAccountDetails(bankAccount);

    setValue(`${newBankAccountAccessor}.accountBsb`, accountBsb);
    setValue(`${newBankAccountAccessor}.accountNumber`, accountNumber);
    setValue(`${newBankAccountAccessor}.accountName`, accountName);
    setValue(`${newBankAccountAccessor}.bankName`, bankName);
    setValue(`${newBankAccountAccessor}.bankSlug`, bankSlug);
    setValue(`${newBankAccountAccessor}.type`, 'bsb');
  }, [selectedBankAccount, findBankAccount, disbursalId, setValue, newBankAccountAccessor]);

  useEffect(() => {
    onNewBankAccountSelected?.(selectedBankAccount);
  }, [onNewBankAccountSelected, selectedBankAccount]);

  return (
    <Card>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <span>Type</span>
          <strong>{loanPurpose.displayName}</strong>
        </div>
        <hr className="border-grey-2" />

        <strong>Current</strong>
        <div className="flex flex-row justify-between">
          <span>Account number</span>
          <div className="flex flex-col">
            <strong>{fromBankAccount?.bankName ?? fromBankAccount?.bankCode}</strong>
            <span>{maskNormalizedBankAccount(fromBankAccount?.bsb, fromBankAccount?.accountNumber)}</span>
          </div>
        </div>
        <hr className="border-grey-2" />

        <span>Do you want to update this disbursal account?</span>
        <div className="flex flex-col items-center">
          <ToggleGroup
            name="__updateDisbursal"
            onValueChange={(e) => {
              const value = e.toLowerCase() === 'true';
              setUpdateDisbursal(value);
            }}
            options={[
              { label: <Label>Yes</Label>, value: 'true' },
              { label: <Label>No</Label>, value: 'false' },
            ]}
          />
        </div>

        {updateDisbursal && (
          <>
            {!!filteredBankAccounts?.length && (
              <BankAccountSelector
                maskBankDetails
                bankAccountType="updateDisbursalToBankAccount"
                bankAccounts={filteredBankAccounts}
                registerField={`${newBankAccountAccessor}.updateDisbursalToBankAccount`}
              />
            )}

            {selectedBankAccount === ADD_ANOTHER_BANK_ACCOUNT && (
              <NewBankAccountSection
                registerFieldPrefix={bankAccountAccessor}
                onCheckProofOfOwnership={(checked) => {
                  onSuppliedOwnershipChanged?.(checked);
                }}
                onBankDetailsChanged={(bankDetails) => {
                  setValue(`${newBankAccountAccessor}.bankName`, transformBsb(bankDetails.bankCode));
                }}
              />
            )}
          </>
        )}
      </div>
    </Card>
  );
}
