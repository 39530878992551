import React from 'react';
import { Card, IconV2 } from '@harmoney/ui-design-system';

import { getBenefitsList } from './utils';

export const BenefitsCard = () => {
  const benefits = getBenefitsList();

  return (
    <Card className="relative mt-8 sm:mt-6 w-full py-16 flex flex-col items-center justify-center flex-1 gap-8">
      <span className="font-medium text-lg">Why our customers love us!</span>
      <div className="flex flex-col gap-6">
        {benefits.map((benefit) => (
          <div key={benefit.title} className="flex flex-row items-center gap-2">
            <IconV2 icon={benefit.icon} className="size-8" />
            <span>{benefit.title}</span>
          </div>
        ))}
      </div>
    </Card>
  );
};
