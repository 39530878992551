export const enum AdjustmentTypeEnum {
  REFUND = 'refund',
  REVERSAL = 'reversal',
  INTEREST_ADJUSTMENT = 'interest_adjustment',
}

export const enum AdjustmentPostingTypeEnum {
  REFUND = 'refund',
  REVERSAL = 'reversal',
}
