import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Layout, NestedLayout } from '@harmoney/components';
import { useBranchFromUrl } from '@harmoney/hooks';
import { injectStore, setupStore } from '@harmoney/redux';
import {
  eventAnalytics,
  GTMScript,
  isDevelopment,
  isPreview,
  isProduction,
  isStaging,
  useEventAnalytics,
} from '@harmoney/ui-app-shell';
import { startsWith, trimStart } from 'lodash';

import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

// eslint-disable-next-line
import 'react-toastify/dist/ReactToastify.minimal.css';
import '../../../libs/ui-design-system/src/global-styles/styles.scss';

const store = setupStore();
injectStore(store);

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const getIAMParams = () => {
    const query = new URLSearchParams(router.asPath.split(/\?/)[1]);
    const authorizationParams = {};
    query.forEach((value, key) => {
      if (startsWith(key, 'iam_')) {
        authorizationParams[trimStart(key, 'iam_')] = value;
      }
    });
    return authorizationParams;
  };
  const s1FirstLogin = (router.query?.['ext-s1FirstLogin'] as string) === 'true';
  const loadGTM = isProduction() || isStaging();

  useEventAnalytics();

  useEffect(() => {
    const handleRouteChange = () => {
      const pathname = window.location.pathname;
      const cleanPath = pathname.replace(/^\/|\/$/g, '');
      eventAnalytics.page('S2', cleanPath || 'Unknown');
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const getLayout =
    Component.getLayout ||
    (() => {
      return (
        <Layout>
          <NestedLayout>
            <div className="mx-auto max-w-xl p-4">
              <Component {...pageProps} />
            </div>
          </NestedLayout>
        </Layout>
      );
    });

  const auth0Domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
  const auth0ClientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
  const auth0Audience = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE;

  const [auth0RedirectUri, setAuth0RedirectUri] = useState('');
  const branch = useBranchFromUrl();

  const [gtmIframeSrc, setGtmIframeSrc] = useState('');

  useEffect(() => {
    //eslint-disable-next-line valid-typeof
    if (typeof window !== undefined) {
      setAuth0RedirectUri(window.location.origin);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const domain = window.location.hostname.endsWith('.nz') ? '.co.nz' : '.com.au';
      if (isProduction()) {
        setGtmIframeSrc(`https://analytics.harmoney${domain}`);
      } else {
        setGtmIframeSrc(`https://analytics.harmoneylabs${domain}`);
      }
    }
  }, []);

  if (!auth0RedirectUri) return null;

  return (
    <>
      {loadGTM && <GTMScript GTM_ID={process.env.NEXT_PUBLIC_GTM_ID} GTMSrc={gtmIframeSrc} />}
      <ErrorBoundary>
        <Auth0Provider
          domain={auth0Domain}
          clientId={auth0ClientId}
          cacheLocation={'localstorage'}
          useRefreshTokens={!isDevelopment() || !isPreview()}
          authorizationParams={{
            redirect_uri: s1FirstLogin ? `${auth0RedirectUri}?ext-s1FirstLogin=true` : auth0RedirectUri,
            audience: auth0Audience,
            acr_values: auth0RedirectUri,
            'ext-branch': branch,
            ...getIAMParams(),
          }}
          onRedirectCallback={(appState?: AppState) => {
            if (appState?.returnTo !== window.location.href) {
              window.location.href =
                appState?.returnTo || (s1FirstLogin ? `${auth0RedirectUri}?ext-s1FirstLogin=true` : auth0RedirectUri);
            }
          }}
        >
          <Provider store={store}>{getLayout(<Component {...pageProps} />)}</Provider>
          <ToastContainer className="!mx-auto" />
        </Auth0Provider>
        {loadGTM && (
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src="${gtmIframeSrc}/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}" height="0" width="0" style="display: none; visibility: hidden;" />`,
            }}
          />
        )}
      </ErrorBoundary>
    </>
  );
}

export default CustomApp;
