import { MonoovaBSBValidationOutputDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const monoovaApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBankDetailsFromBsb: builder.query<MonoovaBSBValidationOutputDto, string>({
      query: (bsb) => ({
        url: `/monoova/bsb/validate/${bsb}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetBankDetailsFromBsbQuery } = monoovaApi;
