import dayjs from 'dayjs';

export const isDateOverlapDetected = (
  existingStartDate: Date,
  existingEndDate: Date,
  startDate: Date,
  endDate: Date
) => {
  if (dayjs(endDate).isBefore(existingStartDate) || dayjs(startDate).isAfter(existingEndDate)) {
    return false;
  }
  return true;
};
