import { BranchRecord } from '@harmoney/api-interfaces';
import { isWithinCutOffTime } from '@harmoney/ui-utils';
import { BranchEnum } from '@prisma/client';

type QuoteCards = {
  imageSrc: string;
  text: string;
};

export function getQuoteCardsByBranch(branch: BranchEnum) {
  const withinCutoffTime = isWithinCutOffTime(branch);

  const cardsByBranch: BranchRecord<QuoteCards[]> = {
    AU: [
      {
        imageSrc: '/assets/images/repayment-fees.svg',
        text: 'No early repayment fees',
      },
      {
        imageSrc: '/assets/images/money-in-minutes.svg',
        text: 'Money in minutes',
      },
      {
        imageSrc: '/assets/images/fair-rates.svg',
        text: 'Fair rates',
      },
    ],
    NZ: [
      {
        imageSrc: '/assets/images/repayment-fees.svg',
        text: 'No early repayment fees',
      },
      {
        imageSrc: '/assets/images/money-in-minutes.svg',
        text: withinCutoffTime ? 'Money in minutes' : 'Money tomorrow',
      },
      {
        imageSrc: '/assets/images/fair-rates.svg',
        text: 'Fair rates',
      },
    ],
  };

  return cardsByBranch[branch];
}
