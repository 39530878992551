import { zAccountNumber, zBsbNumber } from '@harmoney/ui-utils';
import { z } from 'zod';

import { ADD_ANOTHER_BANK_ACCOUNT } from '../../../fulfilment/Payment/components';

export function zBankAccount() {
  return z.object({
    type: z.literal('bsb').default('bsb'),
    accountBsb: zBsbNumber(),
    accountNumber: zAccountNumber(),
    accountName: z.string().refine((v) => !!v.trim(), { message: "Please input the customer's name" }),
    bankName: z.string(),
    bankSlug: z.string().default(''),
  });
}

export const updateDisbursalBankAccountDefault = {
  fromBankAccount: {
    accountBsb: '',
    accountName: '',
    accountNumber: '',
    bankName: '',
    bankSlug: '',
    paymentId: '',
    type: 'bsb',
  },
  newBankAccount: { accountBsb: '', accountName: '', accountNumber: '', bankName: '', bankSlug: '', type: 'bsb' },
  notes: '',
  updateDisbursalToBankAccount: undefined,
} satisfies UpdateDisbursalBankAccountSchema;

export const updateDisbursalBankAccountSchema = z.object({
  fromBankAccount: zBankAccount().merge(z.object({ paymentId: z.string() })),
  newBankAccount: zBankAccount(),
  updateDisbursalToBankAccount: z.union([z.literal(ADD_ANOTHER_BANK_ACCOUNT), z.string()]).optional(),
  notes: z.string().min(1, { message: 'Please add a note' }),
});

export type UpdateDisbursalBankAccountSchema = z.infer<typeof updateDisbursalBankAccountSchema>;
