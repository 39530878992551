import { BranchRecord } from '@harmoney/api-interfaces';
import { CommonOptionProps } from '@harmoney/ui-design-system';
import { PaymentTypeEnum } from '@prisma/client';

type ProviderOptions = CommonOptionProps[];

export const bnplProviderOptions: BranchRecord<ProviderOptions> = {
  AU: [
    {
      label: 'Select Provider',
      value: '',
    },
    {
      label: 'Afterpay',
      value: 'afterpay',
    },
    {
      label: 'Zip',
      value: 'zip',
    },
    {
      label: 'Laybuy',
      value: 'laybuy',
    },
    {
      label: 'Klarna',
      value: 'klarna',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],
  NZ: [
    {
      label: 'Select Provider',
      value: '',
    },
    {
      label: 'Afterpay',
      value: 'afterpay',
    },
    {
      label: 'Laybuy',
      value: 'laybuy',
    },
    {
      label: 'Zip',
      value: 'zip',
    },
    {
      label: 'Humm',
      value: 'humm',
    },
    {
      label: 'Genoapay',
      value: 'genoapay',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],
};
export const PaymentTypeOptions = [
  {
    label: 'BPAY',
    value: PaymentTypeEnum.BPAY,
  },
  { label: 'BSB', value: PaymentTypeEnum.BSB },
];
export const providerOptions: BranchRecord<ProviderOptions> = {
  AU: [
    {
      label: 'Select Provider',
      value: '',
    },
    {
      label: 'Commonwealth Bank ',
      value: 'commonwealth-bank',
    },
    {
      label: 'Westpac',
      value: 'westpac',
    },
    {
      label: 'ANZ',
      value: 'anz',
    },
    {
      label: 'NAB',
      value: 'nab',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],
  NZ: [
    {
      label: 'Select Provider',
      value: '',
    },
    {
      label: 'ANZ',
      value: 'anz',
    },
    {
      label: 'ASB',
      value: 'asb',
    },
    {
      label: 'BNZ',
      value: 'bnz',
    },
    {
      label: 'Westpac',
      value: 'westpac',
    },
    {
      label: 'Kiwibank',
      value: 'kiwibank',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],
};
