import { useBranch } from '@harmoney/hooks';
import { Spinner } from '@harmoney/ui-design-system';
import { BranchEnum } from '@prisma/client';

import { CommonProps } from '../../common-props';
import { Wizard } from '../../Wizard';
import LoanAgreementGeneralTerms from '../LoanAgreementGeneralTerms/LoanAgreementGeneralTerms';
import LoanDisclosure from '../LoanAgreementGeneralTerms/LoanDisclosure/LoanDisclosure';
import { PaymentForUnutilisedLimit } from '../Payment/PaymentForUnutilisedLimit';

export function LoanDocWizardForUnutilisedLimit({ ...props }: CommonProps) {
  const branch = useBranch();

  if (!branch) {
    return <Spinner />;
  }

  const components = {
    [BranchEnum.AU]: [
      { component: PaymentForUnutilisedLimit, urlPart: 'payment' },
      { component: LoanAgreementGeneralTerms, urlPart: 'loan-agreement-general-terms' },
      { component: LoanDisclosure, urlPart: 'loan-agreement-details' },
    ],
    [BranchEnum.NZ]: [
      { component: PaymentForUnutilisedLimit, urlPart: 'payment' },
      { component: LoanDisclosure, urlPart: 'loan-agreement-details' },
      { component: LoanAgreementGeneralTerms, urlPart: 'loan-agreement-general-terms' },
    ],
  };

  return (
    <Wizard>
      {components[branch]?.map(({ component: Component, urlPart }, index) => (
        <Component key={index} {...props} taskFriendlyURL={`${props.taskFriendlyURL}/${urlPart}`} />
      ))}
    </Wizard>
  );
}
