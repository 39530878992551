import { AdjustmentTypeEnum } from '@harmoney/api-interfaces';
import { PaymentReversalReasonEnum } from '@prisma/client';

export const adjustmentTypeOptions = [
  { label: 'Select an option', value: '' },
  { label: 'Initiate refund', value: AdjustmentTypeEnum.REFUND },
  { label: 'Initiate reversal', value: AdjustmentTypeEnum.REVERSAL },
  // { label: 'Initiate interest adjustment', value: AdjustmentTypeEnum.INTEREST_ADJUSTMENT },
];

export const reversalReasonOptions = [
  { label: 'Select an option', value: '' },
  { label: 'Automatic reversal', value: PaymentReversalReasonEnum.AUTOMATIC_REVERSAL },
  { label: 'Complaints resolution', value: PaymentReversalReasonEnum.COMPLAINTS_RESOLUTION },
  {
    label: 'Customer request refund',
    value: PaymentReversalReasonEnum.CUSTOMER_REQUEST_REFUND,
  },
  { label: 'Debt sold payment', value: PaymentReversalReasonEnum.DEBT_SOLD_PAYMENT },
  {
    label: 'Fix earlier transaction',
    value: PaymentReversalReasonEnum.FIX_EARLIER_TRANSACTION,
  },
  { label: 'Late dishonor', value: PaymentReversalReasonEnum.LATE_DISHONOR },
  { label: 'Loan cancellation', value: PaymentReversalReasonEnum.LOAN_CANCELLATION },
  {
    label: 'Payment applied to wrong account',
    value: PaymentReversalReasonEnum.PAYMENT_APPLIED_TO_WRONG_ACCOUNT,
  },
  {
    label: 'Payment dropped from bank file',
    value: PaymentReversalReasonEnum.PAYMENT_DROPPED_FROM_BANK_FILE,
  },
  { label: 'Record earlier payment', value: PaymentReversalReasonEnum.RECORD_EARLIER_PAYMENT },
  { label: 'Return double payment', value: PaymentReversalReasonEnum.RETURN_DOUBLE_PAYMENT },
  { label: 'Reverse fee', value: PaymentReversalReasonEnum.REVERSE_FEE },
  { label: 'Rewrite cancellation', value: PaymentReversalReasonEnum.REWRITE_CANCELLATION },
  { label: 'Technical issue', value: PaymentReversalReasonEnum.TECHNICAL_ISSUE },
  {
    label: 'Transaction details wrong',
    value: PaymentReversalReasonEnum.TRANSACTION_DETAILS_WRONG,
  },
  { label: 'Other', value: PaymentReversalReasonEnum.OTHER },
];
