import { useEffect } from 'react';

import { eventAnalytics } from '../analytics/event-analytics';
import { isDevelopment, isPreview } from '../util';

export const useEventAnalytics = () => {
  useEffect(() => {
    if (isDevelopment() || isPreview() || eventAnalytics.isLoaded) {
      return;
    }

    const handleEventAnalyticsInitialisation = async () => {
      await eventAnalytics.load();
      eventAnalytics.page('S2', window.location.pathname);
    };

    handleEventAnalyticsInitialisation();
  }, []);
};
