import { LoanDisclosureIdentifiersDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const loanDocumentApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getLoanDocumentIdentifiers: builder.query<LoanDisclosureIdentifiersDto, string>({
      query: (loanApplicationId) => ({
        url: `/loan-agreement/${loanApplicationId}/details`,
        method: 'GET',
      }),
      providesTags: ['LoanDocument'],
    }),
    getLoanDocumentUrl: builder.query<string, string>({
      query: (loanApplicationId) => ({
        url: `/loan-agreement/${loanApplicationId}/document-url`,
        method: 'GET',
      }),
    }),
    getLoanVariationDocumentsDataUrl: builder.query<string, string>({
      query: (loanApplicationId) => ({
        url: `/loan-agreement/${loanApplicationId}/variation-document-url`,
        method: 'GET',
      }),
    }),
    acceptGeneralLoanAgreement: builder.mutation<null, string>({
      query: (loanApplicationId) => ({
        url: `/loan-agreement/${loanApplicationId}/accept-general-loan-agreement`,
        method: 'PUT',
      }),
    }),
    acceptLoanDisclosure: builder.mutation<null, string>({
      query: (loanApplicationId) => ({
        url: `/loan-agreement/${loanApplicationId}/loan-disclosure-date`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetLoanDocumentIdentifiersQuery,
  useLazyGetLoanDocumentIdentifiersQuery,
  useGetLoanDocumentUrlQuery,
  useGetLoanVariationDocumentsDataUrlQuery,
  useAcceptGeneralLoanAgreementMutation,
  useAcceptLoanDisclosureMutation,
} = loanDocumentApi;
