import { CollapsibleCard } from '@harmoney/ui-design-system';
import { DATE_TIME_FORMAT } from '@harmoney/ui-utils';
import dayjs from 'dayjs';

import { BranchEnum, LoanApplication } from '.prisma/client';

interface Props {
  loanApplicationData: LoanApplication;
  loanVariationDocumentsData;
  loanDocumentsData;
}

export const LoanAgreement = ({ loanApplicationData, loanVariationDocumentsData, loanDocumentsData }: Props) => {
  const generalTermsAcceptedAt = loanApplicationData?.loanAgreementGeneralTermsAcceptedAt;
  const disclosureAcceptedAt = loanApplicationData?.loanDisclosureAcceptedAt;
  const branch = loanApplicationData?.branch;

  return (
    <CollapsibleCard title="AU: Loan Agreement / NZ: Loan Contract" className="mb-6">
      <div className="grid-cols grid px-4">
        <div className="odd:border-r-grey-1 p-4 odd:border-r-2">
          {loanApplicationData && (
            <>
              <div className="grid grid-cols-2 mb-4">
                <span>
                  AU: Loan agreement general terms / <br /> NZ: Loan disclosure statement accepted at:
                </span>
                {branch === BranchEnum.AU && (
                  <span>{generalTermsAcceptedAt ? dayjs(generalTermsAcceptedAt).format(DATE_TIME_FORMAT) : 'N/A'}</span>
                )}
                {branch === BranchEnum.NZ && (
                  <div className="flex flex-col">
                    <span>{disclosureAcceptedAt ? dayjs(disclosureAcceptedAt).format(DATE_TIME_FORMAT) : 'N/A'}</span>
                    <span>
                      <a href={`${loanDocumentsData?.url}`} target="_blank" rel="noreferrer">
                        View file
                      </a>
                    </span>
                  </div>
                )}
              </div>
              <div className="grid grid-cols-2">
                <span>
                  AU: Loan agreement details / <br /> NZ: Loan contract terms accepted at:
                </span>
                {branch === BranchEnum.AU && (
                  <div className="flex flex-col mb-4">
                    <span>{disclosureAcceptedAt ? dayjs(disclosureAcceptedAt).format(DATE_TIME_FORMAT) : 'N/A'}</span>
                    <span>
                      <a href={`${loanDocumentsData?.url}`} target="_blank" rel="noreferrer">
                        View file
                      </a>
                    </span>
                  </div>
                )}
                {branch === BranchEnum.NZ && (
                  <span>{generalTermsAcceptedAt ? dayjs(generalTermsAcceptedAt).format(DATE_TIME_FORMAT) : 'N/A'}</span>
                )}
              </div>
              {generalTermsAcceptedAt && disclosureAcceptedAt && loanVariationDocumentsData?.url !== null && (
                <span className="grid grid-cols-2">
                  <span>
                    AU: Variation due to different Settlement Date / <br /> NZ: Variation due to different Date of
                    Advance
                  </span>
                  <span>
                    <a href={`${loanVariationDocumentsData?.url}`} target="_blank" rel="noreferrer">
                      View file
                    </a>
                  </span>
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </CollapsibleCard>
  );
};
