import { useEffect, useMemo, useState } from 'react';
import { PreQualifiedQuoteSourceMapper } from '@harmoney/api-interfaces';
import { Button, IconV2, UncontrolledCheckbox } from '@harmoney/ui-design-system';
import { CheckedState } from '@radix-ui/react-checkbox';
import classNames from 'classnames';

type FilterGroupProps = {
  name: string;
  items: {
    label: string;
    value: string;
    checked: boolean;
  }[];
  handleChange: (id: string) => void;
};

const FilterGroup = ({ name, items, handleChange }: FilterGroupProps) => {
  return (
    <div className="mb-4">
      <span className="font-medium text-[10px] mb-2 block uppercase text-grey-4">{name}</span>
      {items.map((item) => (
        <div key={item.value}>
          <UncontrolledCheckbox
            name={item.value}
            label={item.label}
            checked={item.checked}
            onCheckedChange={() => handleChange(item.value)}
            className="text-sm mb-1"
            checkIconSize="tiny"
          />
        </div>
      ))}
    </div>
  );
};

const handleFilterChange = (filterObject: any[], id: string, setFilter: any) => {
  const updatedFilters = filterObject.map((filter) => {
    if (filter.id === id) {
      filter.checked = !filter.checked;
    }
    return filter;
  });

  const filteredIds = updatedFilters.filter((filter) => filter.checked).map((filter) => filter.id);
  setFilter(filteredIds);
};

export const PreQualifiedQuoteFilters = ({
  sourceFilter = [],
  setSourceFilter,
}: {
  sourceFilter: string[];
  setSourceFilter: (filter: string[]) => void;
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const sourceFilterObject = useMemo(() => {
    return Object.keys(PreQualifiedQuoteSourceMapper).map((key) => ({
      id: key,
      label: PreQualifiedQuoteSourceMapper[key],
      checked: sourceFilter?.includes(key),
    }));
  }, [sourceFilter]);

  useEffect(() => {
    sourceFilterObject.forEach((option) => {
      option.checked = sourceFilter.includes(option.id);
    });
  }, [sourceFilter, sourceFilterObject]);

  const handleSelectAll = (checked: CheckedState) => {
    if (!checked) {
      sourceFilterObject.forEach((option) => {
        option.checked = false;
      });
      setSourceFilter([]);
      setAllChecked(false);
      setIndeterminate(false);
    } else if (checked === true) {
      const filteredSource = [];
      sourceFilterObject.forEach((option) => {
        option.checked = true;
        filteredSource.push(option.id);
      });
      setSourceFilter(filteredSource);
      setAllChecked(true);
      setIndeterminate(false);
    }
  };

  const handleSourceFilterChange = (id: string) => {
    handleFilterChange(sourceFilterObject, id, setSourceFilter);
  };

  useEffect(() => {
    const areNoFiltersSelected = sourceFilter.length === 0;
    const areAllFiltersSelected = sourceFilter.length === 2;

    if (!areNoFiltersSelected && !areAllFiltersSelected) {
      setAllChecked(false);
      setIndeterminate(true);
    } else if (areAllFiltersSelected) {
      setAllChecked(true);
      setIndeterminate(false);
    } else if (areNoFiltersSelected) {
      setAllChecked(false);
      setIndeterminate(false);
    }
  }, [sourceFilter]);

  return (
    <div
      className={classNames(
        'transition-width duration-300 border-r border-r-grey-2 relative p-6',
        collapsed ? 'min-w-6 min-h-dvh' : 'col-span-4 min-w-[240px] min-h-dvh'
      )}
    >
      <div className={classNames(collapsed ? 'hidden' : 'block')}>
        <h2>Filters</h2>
        <UncontrolledCheckbox
          name="all-application-status"
          label="Select all / Clear all"
          alignLabel="right"
          className="font-medium text-sm mb-4"
          checked={indeterminate === true ? 'indeterminate' : allChecked}
          onCheckedChange={handleSelectAll}
          checkIconSize="tiny"
          iconWidth={16}
        />
        <FilterGroup
          name="quick quote source"
          items={sourceFilterObject.map((source) => ({
            id: source.id,
            label: source.label,
            value: source.id,
            checked: source.checked,
          }))}
          handleChange={handleSourceFilterChange}
        />
      </div>
      <Button
        onClick={() => setCollapsed(!collapsed)}
        variant="text"
        className="absolute top-[60px] right-[-20px] !text-grey-2"
      >
        <span className="border rounded-pill flex bg-grey-1">
          <IconV2 icon={`ic:round-chevron-${collapsed ? 'right' : 'left'}`} width={34} />
        </span>
      </Button>
    </div>
  );
};
