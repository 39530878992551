import { PaymentArrangementDto, VaultPaymentInformationDto } from '@harmoney/api-interfaces';
import { getNextRepaymentDateAfterPaymentArrangementExpiry } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { RepaymentFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';

interface RepaymentProps {
  title: string;
  amount: number;
  frequency: RepaymentFrequencyEnum;
  startDate: string;
  endDate?: string;
  numberOfPayments?: number;
  icon: JSX.Element;
}
export interface RepaymentPlanProps {
  paymentArrangementPlan: RepaymentProps;
  originalPaymentPlan: RepaymentProps;
}
const formatDate = (date: string | Date) => dayjs(date).format('YYYY-MM-DD');

export const RepaymentPlan = (
  paymentArrangement: PaymentArrangementDto,
  vaultPaymentInfo: VaultPaymentInformationDto
): RepaymentPlanProps => {
  if (!paymentArrangement && !vaultPaymentInfo) return null;
  if (!paymentArrangement?.endDate && !paymentArrangement?.noEndDate) return null;

  const paymentArrangementPlan = {
    title: 'Payment arrangement',
    amount: paymentArrangement?.paymentAmount,
    frequency: paymentArrangement?.paymentFrequency,
    startDate: paymentArrangement?.startDate,
    endDate: paymentArrangement?.endDate,
    numberOfPayments: paymentArrangement?.numberOfPayments,
    icon: <Icon icon="mdi:calendar-month" className="text-grey-4" width={24} />,
  };

  const originalPaymentPlan = {
    title: 'After which it will revert to original repayment plan',
    amount: vaultPaymentInfo?.recurringPaymentAmount,
    frequency: RepaymentFrequencyEnum[vaultPaymentInfo?.recurringPaymentFrequency],
    startDate: getNextRepaymentDateAfterPaymentArrangementExpiry(
      formatDate(vaultPaymentInfo?.recurringPaymentDate),
      RepaymentFrequencyEnum[vaultPaymentInfo?.recurringPaymentFrequency],
      paymentArrangement?.endDate
    ),
    icon: <Icon icon="mdi:calendar-month" className="text-grey-4" width={24} />,
  };

  return { paymentArrangementPlan: paymentArrangementPlan, originalPaymentPlan: originalPaymentPlan };
};
